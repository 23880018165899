import { AuthService, AuthResponseData } from '../../services/auth/auth.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { GlobalService } from '../../services/global.service';
import { LoggingLocalRoutingEvents, ToastEnum } from '../../classes-enums-interfaces-types/enums/enums.enum';
import { mGlobal } from '../../mglobal';
import { LoggingLocalService } from '../../services/logging-local-service/logging-local-service.service';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.page.html',
    styleUrls: ['./sign-up.page.scss'],
  })
  export class SignUpPage implements OnInit, AfterViewInit {

    route : ActivatedRoute
	isSignedUp : boolean
	error: string = null;
	logoWithSubtitle = '../../../assets/pixelImages/logo-with-subtitle-dm-trans.png'
	spotLogo = '../../../assets/spotifyFullLogo.svg'

  constructor(
      private authService: AuthService,
      private router: Router,
      activatedRoute:ActivatedRoute,
      private toastController : ToastController,
	  private cookieService : CookieService,
	  private gs: GlobalService,
      private loggingLocal : LoggingLocalService
	)
    {this.route = activatedRoute}

    ngOnInit(): void {
		


	}

    ngAfterViewInit(){
        this.isSignedUp = this.gs.isSignedUp()


    }
	

    ionViewWillEnter(){ 
		

    }

	ionViewDidEnter(){

        this.loggingLocal.addLog(LoggingLocalRoutingEvents.ReachedSignUpPage)
        const beErrorMessage = this.route.snapshot.queryParams?.beErrorMessage
        //const beErrorMessage = ''
        if(beErrorMessage){
            
            this.gs.showToast({msg:beErrorMessage,duration:3000, type:ToastEnum.error})
        }
        console.log('login will enter')
        
		this.gs.setPendingHistoryBackClick(false)
	}

  onSubmit(form: NgForm) {
    if (!form.valid) { 
      return;
    } 

    let authObs: Observable<AuthResponseData>;

    const signUpDisplayName=form.value.signUpDisplayName
    //const signUpCode=form.value.signUpCode
    //redirects to backend, only needed for development with different , drue to cross site scripting
    this.authService.signup(signUpDisplayName);
     
    //form.reset();
  } 

  
} 
 