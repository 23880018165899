import {
    Directive,
    ElementRef,
    Input,
    AfterViewInit,
    NgZone,
    EventEmitter,
    Output
} from "@angular/core"; 
import { GestureController, GestureDetail } from "@ionic/angular";

// src : https://forum.ionicframework.com/t/ionic-5-long-press-gesture-example/188746/6

@Directive({
    selector: "[long-press]"
})
export class LongPressDirective implements AfterViewInit {

    @Output() tap = new EventEmitter();
    @Output() press = new EventEmitter();
    @Output() pressInit = new EventEmitter();
    @Input("delay") delay = 800;
	@Input("disableDir") disableDir = false;

	// needed to prevent event on elem2 that shows up at after click on elem1 at same place
	// to get trigger by same userAction 
	minDelayBetweenTaps = 100;
	static eventAllowedToStart = true
	static eventAllowedToStartTimeout

    action: any; //not stacking actions

    private positions = {
        start: {
            x: undefined as number,
            y: undefined as number
        },
        current: {
            x: undefined as number,
            y: undefined as number,
        }
    }

    private longPressDurationReached = false;
	private longPressActive = false;
	
	// used to not trigger tap when scrolling 
	private dragLimitX : number = 15;
	private dragLimitY : number = 10;

	gesture

    constructor(
        private el: ElementRef,
        private gestureCtrl: GestureController,
        private zone: NgZone
    ) { }

    ngAfterViewInit() {
        this.loadLongPressOnElement();
    }

    loadLongPressOnElement() {
        this.gesture = this.gestureCtrl.create({
            el: this.el.nativeElement,
            threshold: 0,
            gestureName: 'long-press',
			canStart: (gd : GestureDetail) => { 
				const target = gd.event.target as HTMLElement;
				const canStart = LongPressDirective.eventAllowedToStart &&
				// for child click not to cause parent long press directive
				// #opt maybe more efficient to add long press on all other elems than child button click override
				!target.classList.contains('ignore-long-press-directive'); 
				return canStart
			
			},
            onStart: ev => {

				console.log('gest start')
				this.longPressActive = true;

				this.positions = {
                    start: { x: ev.startX, y: ev.startY },
                    current: { x: ev.currentX, y: ev.currentY }
                };

                this.longPressAction();

            },

            onMove: ev => {
                this.positions.current = { x: ev.currentX, y: ev.currentY };
            },

            onEnd: ev => {
				if(this.longPressDurationReached){
					console.log("long press")
					this.press.emit(ev);
					
				} else {
					const xDistance = Math.abs(this.positions.start.x - this.positions.current.x);
					const yDistance = Math.abs(this.positions.start.y - this.positions.current.y);
					if(xDistance > this.dragLimitX || yDistance > this.dragLimitY){
						console.log('dragged')
						// User dragged finger
						return;
					}
					console.log('tap')	
					this.tap.emit(ev);
				}
				
				if (this.action) {
					clearInterval(this.action);
				}
				
                this.longPressDurationReached = false;
				console.log('gest end')
				this.eventAllowedToStartTimeOut() 
            }
        });
        this.gesture.enable(!this.disableDir);
    }

	ngOnDestroy() {
		this.gesture.destroy();
	  }
	

    private longPressAction() {
        if (this.action) {
            clearInterval(this.action);
        }
        this.action = setTimeout(() => {
            this.zone.run(() => {
                // Check distance
                const xDistance = Math.abs(this.positions.start.x - this.positions.current.x);
                const yDistance = Math.abs(this.positions.start.y - this.positions.current.y);
                if(xDistance > 15 || yDistance > 15){
                    // User dragged finger
					console.log('dragged')
                    return;
				}
				console.log("press init")
				this.pressInit.emit()
                this.longPressDurationReached = true;
            });
        }, this.delay);
    }

	private eventAllowedToStartTimeOut(){
		LongPressDirective.eventAllowedToStart = false
		if(LongPressDirective.eventAllowedToStartTimeout){
			clearInterval(LongPressDirective.eventAllowedToStartTimeout)
		}
		LongPressDirective.eventAllowedToStartTimeout = setTimeout(() => {
			LongPressDirective.eventAllowedToStart = true;
		}, this.minDelayBetweenTaps)
		 
	}
}