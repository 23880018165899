import { ENV } from '../../../../../environments/environment';
import {
    SelectStatesEnum,
    SearchStatesEnum,
} from '../../../classes-enums-interfaces-types/enums/find-tune/enums.enum';
import {
    ISegmentSearch,
    ISegmentSelect,
	TuneWithLyrics,
} from '../../../classes-enums-interfaces-types/interfaces/interfaces';
import { Tune } from '../../../classes-enums-interfaces-types/classes/classes';
import { IListElementType } from '../../../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model';
import { MyServerService } from '../../my-server/my-server.service';
import { SegmentStatesEnum } from '../../../classes-enums-interfaces-types/enums/find-tune/enums.enum';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Subject, BehaviorSubject, Subscriber, Observable, Subscription } from 'rxjs';
import { ListElementTypesEnum } from '../../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum';
//import { mGlobal } from '../../mGlobal.js';
import { mGlobal, test1 } from '../../../mglobal';
import { PlayerService } from '../../music/player.service';
import { Location } from '@angular/common'

//import type {SearchStatesEnum} from './../../classes-enums-interfaces-types/interfaces';
import * as Sentry from "@sentry/angular-ivy";
import { ErrorHandlerService } from '../../error-handler/error-handler.service';


// Local states is not sufficient, this service main task is to handle back clicks while keeping the correct state.
var selectStateInit : SelectStatesEnum
if (ENV.PRODUCTION) {
    selectStateInit = SelectStatesEnum.catOne;
} else {
    selectStateInit = SelectStatesEnum.catOne;
}

@Injectable({
    providedIn: 'root',
})
export class FindTuneStateService {
    constructor(
        private router: Router,
        private ps: PlayerService,
        private mServer: MyServerService,
        private location : Location,
		private eh : ErrorHandlerService,
		private ngZone: NgZone
		
    ) {console.log(test1)}

	
    elemSelected: boolean = false;
    selectedTune: Tune = null;
    searchText: string = 'love';
    segment: SegmentStatesEnum = SegmentStatesEnum.search;
    segmentPrev: SegmentStatesEnum = null;
    segmentChanged$ = new Subject<SegmentStatesEnum>();
    ///selectedElemSubj = new BehaviorSubject(null);

	searchB$ = new BehaviorSubject<string>('')

	selectedTuneObs: Subscription = null

    //not accourding to protocol state handling, should interact with any routing from any route and reset state then
    initState() {
        this.elemSelected = false;
        //this.selectedTune = null
        //this.selectedElemSubj.next(null);
        if(ENV.DEBUG){
            this.selectedTune = mGlobal.debugTune;
            ///this.selectedElemSubj.next(mGlobal.debugTune);
        }



        this.initSegment();
        this.searchText = '';
        this.searchB$.next(this.searchText);

        this.select.state = selectStateInit;
        this.select.statePrevs = [];
        this.select.stateB$.next({state:selectStateInit, statePrev:null});

        this.search.state = SearchStatesEnum.noSearch;
        this.search.statePrevs = [];
        this.search.stateB$.next(SearchStatesEnum.noSearch);
        this.searchB$.next('');
		this.search.hasSearchedB$.next(false)

		if(this.selectedTuneObs == null){
			this.selectedTuneObs = this.ps.selectedTune$.subscribe((tune) => {
				if(tune)
					this.selectedTune = tune
			})

		}
    }



    initSegment() {
        this.router.navigateByUrl(`/find-tune/search`);
        //this.router.navigateByUrl(`/find-tune/select`);
        this.segmentPrev = null;
        this.segment = SegmentStatesEnum.search;
        this.segmentChanged$.next(this.segment);
    }

    select: ISegmentSelect = {
        listElemClicked: (listElem: any) => {
            //Todo, make instanceof work by creating TuneV? from server objcet

            if(listElem.type==ListElementTypesEnum.tune){ 

				this.ps.loopTuneRequest(listElem)

			} else {
				// happens for slimSpotTrack
				const parentThis = this;
				this.mServer
					//.getTracksTuneByTrackId(listElem.trackId)
					.getTracksFirstTuneByTrackNameAndArtistName(listElem.trackName, listElem.artistName)
					.subscribe(
						(tune: Tune) => {
						//#fix
							// if (tune == this.selectedTune && tune){
							// 	this.ps.pauseTrack()
							// 	this.router.navigate(
							// 		['/edit-tune'],
							// 		{ state: {slimSpotTrack : listElem}}
							// 	)
							// }
							if (tune) {
								//this.selectedTune = tune;
								this.ps.loopTuneRequest(tune);
								// this.selectedElemSubj.next(
								// 	this.selectedTune
								// );
							} else {
								//should never happen
								
								parentThis.router.navigate(
									['/edit-tune'],
									{ state: { slimSpotTrack: listElem } } 
								);
							}
						},
						(e) => {
							
							if (e.status == 404) {
								
								parentThis.router.navigate(
									['/edit-tune'],
									{ state: { slimSpotTrack: listElem, } }
									);
								} else {
									console.error('getTracksTune error', e);
									this.eh.logSentryError(e)
							}
						}
					);
			}
        },
        //state: SelectStatesEnum.catOne,
        state: selectStateInit,
        statePrevs: [],
        stateB$: new BehaviorSubject<{ state:SelectStatesEnum, statePrev:SelectStatesEnum}>({state:selectStateInit,statePrev:null}),
		
	};

    search: ISegmentSearch = {
        hasSearchedB$: new BehaviorSubject<boolean>(false),
        listElemClicked: (listElem: any) => {
            console.log('containerTune');
            if (listElem.type == ListElementTypesEnum.slimSpotTrack) {
                if (mGlobal.isAdmin && false) {
                    this.router.navigate(['/edit-tune'], {
                        state: { slimSpotTrack: listElem },
                    });
                } else {
                    const parentThis = this;
                    this.mServer
                        //.getTracksTuneByTrackId(listElem.trackId)
                        .getTracksFirstTuneByTrackNameAndArtistName(listElem.trackName, listElem.artistName)
                        .subscribe(
                            (tune: Tune) => {
							
								// will be tune or slimSpotTrack from user playlist
							
                                if (tune) {
                                    //this.selectedTune = tune;
                                    this.ps.loopTuneRequest(tune);
                                    // this.selectedElemSubj.next(
                                    //     this.selectedTune
                                    // );
                                } else {
                                 
									this.ps.pauseTrack();
									this.ngZone.run(() => {
										this.router.navigate(
											['/edit-tune'],
											{ state: { slimSpotTrack: listElem } } 
										);
									})
                                }
                            },
                            (e) => {
							//this.eh.logSentryError(e)

                                if (e.status == 404) {
                                    this.ps.pauseTrack();
									this.ngZone.run(() => {
										this.router.navigate(
											['/edit-tune'],
											{ state: { slimSpotTrack: listElem, } }
										);
									})
                                } else {
									this.eh.logSentryError(e)
                                    console.error('getTracksTune error', e);
                                }
                            }
                        ); 
                }
                //will never currently happen tunes are not pre loaded
            } else if (listElem.type == ListElementTypesEnum.tune) {

				if(this.selectedTune == listElem && this.selectedTune){
					this.ps.pauseTrack()
					this.mServer.getTrackHasMultipleTunes(this.selectedTune.slimTuneTrack.trackId).subscribe( (hasMultipleTunes : boolean) => {
			
						let redirectRoute
						if(hasMultipleTunes){
							redirectRoute = '/track'	
						} else {
							redirectRoute = '/edit-tune'
						}
			
						this.ngZone.run(() => {
							this.router.navigateByUrl(redirectRoute,{ state: {slimTuneTrack : this.selectedTune.slimTuneTrack}})
						})
			
					}, e => {
						console.log('error getting track has multiple tunes',e)	
						this.eh.logSentryError(e)
					})
				} else {
					this.ps.loopTuneRequest(listElem)
				}
				//const tune = this.getTuneFromTrigger(trigger)
				//this.ps.nextLoopItr(1)
				this.selectedTune = listElem;
                ///this.selectedElemSubj.next(this.selectedTune);
            }
        },
        state: SearchStatesEnum.noSearch,
        statePrevs: [],
        stateB$: new BehaviorSubject<SearchStatesEnum>(
            SearchStatesEnum.noSearch
        ),

    };

    searchEntered(searchText: string) {

		this.searchText = searchText;
		if(this.segment == SegmentStatesEnum.select && this.select.state == SelectStatesEnum.listSpotPlaylistAll){
			this.setSelectState(SelectStatesEnum.spotPlaylistsSearch)
		} else {
			this.setSegment(SegmentStatesEnum.search);
			if (searchText.charAt(0) == '#') {
				this.setSearchState(SearchStatesEnum.tunes);
			} else {
				this.setSearchState(SearchStatesEnum.tracks);
			}
			this.search.hasSearchedB$.next(true);
		}
		this.searchB$.next(searchText);
		console.log(`searchText: ${searchText}`);
    }

    hardBack() {
        
		Sentry.addBreadcrumb({
			category: "pauseUndefined",
			message: "hardBack",
			level: "info",
		  });
        //this.ps.pauseTrack()
        const hasHistory = this.router.navigated;
        if(hasHistory)
            this.location.back()
    }

    sendTune() {}

    backClick() {
        if (this.segment == SegmentStatesEnum.select) {
            if (this.select.state == SelectStatesEnum.catOne) {
                this.setSegmentBack();
            } else {
                this.setSelectStateToPrev();
            }
        } else if (this.segment == SegmentStatesEnum.search) {
            if (!this.search.statePrevs || this.search.statePrevs.length == 0) {
                this.setSegmentBack();
            } else {
                this.setSearchStateToPrev();
            }
        }
    }

    getSearch() {
        return this.searchText;
    }

    private setSegmentBack() {
        if (this.segmentPrev != null) {
            this.setSegment(this.segmentPrev);
            this.segmentPrev = null;
            this.segmentChanged$.next(this.segment);
        } else {
            this.hardBack();
        }
    }

    setSegment(segment: SegmentStatesEnum) {
        if (this.segment != segment) {
            let lowerCaseSegmentStr = segment.toLocaleLowerCase();
            this.router.navigateByUrl(`/find-tune/${lowerCaseSegmentStr}`);
            this.segmentPrev = this.segment;
            this.segment = segment;
        }
        this.segmentChanged$.next(segment);
    }

    setSelectState(state: SelectStatesEnum) {
		const statePrev = this.select.state
        this.select.statePrevs.push(this.select.state);
        this.select.state = state;
        this.select.stateB$.next({state:this.select.state, statePrev: statePrev});
    }

    setSearchState(state: SearchStatesEnum) {
        if(this.search.state != SearchStatesEnum.noSearch){
            this.search.statePrevs.push(this.search.state);
        } 
        this.search.state = state;
        this.search.stateB$.next(state);
    }

    setSelectStateToPrev() {
		const statePrev = this.select.state
        this.select.state = this.select.statePrevs.pop();
        this.select.stateB$.next({state:this.select.state, statePrev:statePrev} );
    }

    setSearchStateToPrev() {
        this.search.state = this.search.statePrevs.pop();
        this.search.stateB$.next(this.search.state);
    }
}


