import { UnsupportedInAppBrowserComponent } from './../components/modals/unsupported-in-app-browser/unsupported-in-app-browser.component';
import { SaveSendAction } from './../components/footer-bar/footer-bar.module';
import { MPlayerState } from 'native-volume-control-npm-package-name';
import { IArtist } from '../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model';
import { MyServerService } from '../services/my-server/my-server.service'
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, concatMap, mergeMap, takeUntil } from 'rxjs/operators'
import { interval, concat, combineLatest, timer } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatRadioButton, MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { mGlobal } from '../mglobal'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ENV } from '../../../environments/environment';
import { Tune } from '../classes-enums-interfaces-types/classes/classes';
import { PlayerService } from '../services/music/player.service';
import {Http} from '@capacitor-community/http'
import { CookieService } from 'ngx-cookie-service';
import { ErrorHandlerService } from '../services/error-handler/error-handler.service';
import { GlobalService } from '../services/global.service';
import { ModalController } from '@ionic/angular';
import { ModalsE } from './../classes-enums-interfaces-types/enums/enums.enum';
import { AddTuneToListComponent } from '../components/add-tune-to-list/add-tune-to-list.component';
import { AddedTuneData } from '../classes-enums-interfaces-types/interfaces/interfaces';

@Component({
    selector: 'app-play-tune',
    templateUrl: './play-tune.component.html',
    styleUrls: ['./play-tune.component.scss']
})

export class PlayTuneComponent implements OnInit, AfterViewInit {

	spotFullLogoIcon = './assets/spotifyFullLogo.svg'
	optionsIcon = './assets/ellipsis-vertical.svg'
	logoWithSubtitle = '../../../assets/pixelImages/logo-with-subtitle-dm-trans.png'

    trackImg: string;
    trackName: string = "Track Name";
    artistName: string = "Artist Name";   
    trackId: string = "Track Id";
    tune: Tune

    dbId: string;
    tuneNumber;

    sliderTime: number;
    trackDuration: number;

    startTime: number = 0;
    stopTime: number = 15000;
    currentTime: number = 0;

    playerStateB: boolean = false;

    isLooping: boolean = false
    toggleLoopTextLoop: string = "Loop"
    toggleLoopTextPause: string = "Pause"
    toggleLoopText: string = this.toggleLoopTextLoop
    music

    isPlayerReady = false

	version = ENV.DEPLOY_VERSION_MINOR
    debug = ENV.DEBUG

    isSignedUp : boolean

	hasLoopedOnce = false

	footerBarShow : boolean = false

	aLink 

	saveSendActionE = SaveSendAction

	tuneMissingOnServer: boolean = false
	
	tracksImageLoaded : boolean = false

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private mServer: MyServerService,
        public ps: PlayerService,
        private changeDetector : ChangeDetectorRef,
        private cookieService : CookieService,
		private eh : ErrorHandlerService,
		private gs : GlobalService,
		private modalCtrl : ModalController,
		private router : Router
    ) { }

    ngOnInit(): void {



    }

    ngAfterViewInit(): void {
        
        //async template pipe is more work to debug 
        this.ps.isPlayerReady$.subscribe( isPlayerReady => {
    
            this.isPlayerReady = isPlayerReady
            this.changeDetector.detectChanges()
            
        })

        this.route.queryParams.subscribe(params => {
            this.tuneNumber = params["tune"];
            if (this.tuneNumber) {
                this.aLink = `${ENV.DOMAIN_OF_FRONTEND}/play-tune?tune=${this.tuneNumber}`
                console.log(`tuneNumber: ${this.tuneNumber}`);
                this.mServer.getTuneByNumber(this.tuneNumber, true)
                    .subscribe((res: HttpResponse<Tune>) => {

                        if(res.status == 204){
                            this.tuneMissingOnServer = true
                        } else {
                            this.tune = res?.body
                            this.trackName = this.tune.slimTuneTrack.trackName
                            this.artistName = this.tune.slimTuneTrack.artistName
                            this.trackImg = this.tune.slimTuneTrack.trackImg

                            this.tracksImageLoaded = true
                        }
                        this.changeDetector.detectChanges()
                    }),
                    e => {

                        console.log('getTuneByNumber Error')
                        console.log(e)
                        this.eh.logSentryError(e)

                    }
            } else {
                this.tune = mGlobal.debugTune
                this.trackName = mGlobal.debugTune.slimTuneTrack.trackName
                this.artistName = mGlobal.debugTune.slimTuneTrack.artistName
                this.trackImg = mGlobal.debugTune.slimTuneTrack.trackImg
            }
        },
            e => {
                console.log("paramError");
                console.log(e);
                this.eh.logSentryError(e)

        });

    }
    
    ionViewWillEnter() {



    }

	ionViewDidEnter(){

        this.isSignedUp = this.gs.isSignedUp()
		this.tuneMissingOnServer = false	

        this.ps.playerStateChanged$.subscribe((playerState : MPlayerState) => {
			if(playerState.isPlaying){
				this.hasLoopedOnce = true
			}
            this.toggleLoopGui(playerState.isPlaying)
        })

		this.resetPage()
		
		this.gs.setPendingHistoryBackClick(false)
		 
		 this.ifUnsupportedInAppBrowserPresentModal()
		
	}

	async ifUnsupportedInAppBrowserPresentModal(){

		if(this.gs.isRunningOnAndroid() && this.gs.isRunningInMessengerBrowser()){
			const modal = await this.modalCtrl.create({

				component: UnsupportedInAppBrowserComponent,
				cssClass: 'unsupported-in-app-browser-modal',
				id: ModalsE.unsupportedInAppBrowser,
				htmlAttributes: {"style":"--backdrop-opacity:0.5"},
	  
			  });
	
			  modal.present();
	
			  modal.addEventListener('ionModalDidPresent', (event : any) => {
				
				const heightToSet = event?.srcElement?.childNodes[0]?.clientHeight
				document.documentElement.style.setProperty('--unsupported-browser-modal-height', heightToSet+'px');
				this.changeDetector.detectChanges()
				  console.log('modalDidPresent')
			  })
	
			  modal.addEventListener('ionModaldidDismiss', (event : any) => {
	
				  console.log('modalDidDismiss')
			  })
	
	
		}
		


		  // #opt do I need to remove the listener on dismiss?

	}


    /*   sliderMoved(){
    
        this.ps.seekTrack(this.sliderTime)
        .subscribe( data =>
          {
            console.log("sliderMoved");
          },
        err =>{
          console.log("Error sliderMoved");
          console.log(err);
        });;
    
      } */

    toggleLoopTuneClick(){

		this.ps.loopTuneRequest(this.tune) // keep independent of browser for false positives
		// #improve
		this.ifUnsupportedInAppBrowserPresentModal()		
		
    }

    toggleLoopGui(isPlaying) {

		this.toggleLoopText = isPlaying ? this.toggleLoopTextPause : this.toggleLoopTextLoop
			this.changeDetector.detectChanges()
		}

	refreshPage(){
		window.location.reload()
	}

	testLog(){
		console.log('testLog')
	}

	resetPage(){
		this.hasLoopedOnce = false
	}

	popMoreTunesClick(){

		this.ps.pauseTrack()
		this.router.navigate(
			['/track'],
			{ state: { slimTuneTrack: this.tune.slimTuneTrack } } 
		);
	}

	async popAddToListClick() {
        const modal: HTMLIonModalElement = await this.modalCtrl.create({
            component: AddTuneToListComponent,
            breakpoints: [0.7],
            initialBreakpoint: 0.7,
            componentProps: { tune: this.tune },
            id: ModalsE.addToTuneList,
        })
        await modal.present()

    }

	popSpotClick() {
        window.open(
            `https://open.spotify.com/track/${this.tune.slimTuneTrack.trackId}`,
            '_blank'
        )
    }

}
