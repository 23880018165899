import { FooterBarModule } from './../components/footer-bar/footer-bar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { KnownShortcomingsPageRoutingModule } from './known-shortcomings-routing.module';

import { KnownShortcomingsPage } from './known-shortcomings.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    KnownShortcomingsPageRoutingModule,
	FooterBarModule
  ],
  declarations: [KnownShortcomingsPage]
})
export class KnownShortcomingsPageModule {}
