import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ModalsE } from '../../../classes-enums-interfaces-types/enums/enums.enum';

@Component({
  selector: 'app-rename-tune-list',
  templateUrl: './rename-tune-list.component.html',
  styleUrls: ['./rename-tune-list.component.scss'],
})
export class RenameTuneListComponent implements OnInit {

  constructor(private modalCtrl : ModalController) { }
	updatedListName : string
    ngOnInit() {}

    confirmRename() {
        return this.modalCtrl.dismiss(this.updatedListName,'confirm', ModalsE.renameTuneList);
    }

    cancelRename() {
        this.modalCtrl.dismiss(null, 'cancel', ModalsE.renameTuneList);
    }


}
