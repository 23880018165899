<ion-header>
  <ion-toolbar>
    <ion-title>Tune it wait list</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
	<ion-grid>
		<ion-row >
			<ion-card>
				Enter your email below to be notified when a stable-ish version of Tune it is launched!
			</ion-card>
		</ion-row>
		<ion-row>
			<ion-item lines="full">
				<ion-input
					label = ""
					[(ngModel)]="email"
					type="text"
					
					placeholder="Type email here">
				</ion-input>
			</ion-item>
		</ion-row>
		<ion-row id="buttonRow">
			<ion-button (click)="sendClicked()">
				SEND
			</ion-button>
		</ion-row>
		<ion-row id='imgRow'>
			<ion-img src="{{logoSrc}}"></ion-img>
		</ion-row>
	</ion-grid>
</ion-content>
