import { Component, OnInit } from '@angular/core';
import { ModalsE } from '../../../classes-enums-interfaces-types/enums/enums.enum';
import { ModalController } from '@ionic/angular';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-read-private-spot-lists-info',
  templateUrl: './read-private-spot-lists-info.component.html',
  styleUrls: ['./read-private-spot-lists-info.component.scss'],
})
export class ReadPrivateSpotListsInfoComponent implements OnInit {
    readPrivateSpotLists: any;

  constructor(
    private modalCtrl : ModalController,
    private gs : GlobalService
    
    ) { }

  ngOnInit() {}

  ngAfterViewInit(){

    this.readPrivateSpotLists = this.gs.getReadPrivateSpotLists()
     console.log('')
}


  grant() {
    this.modalCtrl.dismiss(null,'grant', ModalsE.readPrivateSpotListsInfo);
    }

    revoke() {
        this.modalCtrl.dismiss(null, 'revoke', ModalsE.readPrivateSpotListsInfo);
    }

    close() {
        this.modalCtrl.dismiss(null, 'close', ModalsE.readPrivateSpotListsInfo);
    }


}
