import { EditTunePage } from './edit-tune.page';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, ActivatedRouteSnapshot, RouterState, RouterStateSnapshot } from '@angular/router';

const routes: Routes = [
{
  path: '',
  component: EditTunePage,
} 
];

@NgModule({ 
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EditTuneRoutingModule {}
