import { GlobalService } from './../global.service';
import { Injectable } from '@angular/core';

import {ENV} from '../../../../environments/environment'
//import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor} from '@capacitor/core'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
    
    registationToken
    incomingNotification$ = new Subject<any>()
    addListeners
    cachedChatListMessages: any;
    constructor(
        //private afMessaging: AngularFireMessaging,
        private http: HttpClient,
        private gS : GlobalService
    ) { }

    /*
        initFcm(){
            this.listen()
        } 

        listen() {
            this.afMessaging.messages
            .subscribe((message) => { console.log(message); });
        }
    
        async requestPushPermission() : Promise<any> {
            console.log('break')
            this.afMessaging.requestToken
            .subscribe(
                (token) => { 
                    console.log('Permission granted! Save to the server!', token); 
                    this.registationToken = token
                    return 'ok'
                },
                (error) => { 
                    console.error(error); 
                    return error
                },  
            );
        }

        getAfMessaging() : any {
            return this.afMessaging
        } 
    */

    initFcm(){
        if(this.gS.isNativeCapPlatform()){
            this.addListenersasync()
        }
    }

    async addListenersasync(){
        await PushNotifications.addListener('registration', token => {
            this.registationToken = token.value
            console.info('Registration token: ', token.value);
        });
      
        await PushNotifications.addListener('registrationError', err => {
          console.error('Registration error: ', err.error);
        });
      
        await PushNotifications.addListener('pushNotificationReceived', notification => {
            console.log('Push notification received: ', notification);

            if(notification.data.type == 'message'){
                this.incomingNotification$.next(notification)
                this.cachedChatListMessages.push(notification)
            }
            

        });
      
        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
    }
      
    async registerNotifications(){
        let permStatus = await PushNotifications.checkPermissions();
        
        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }
        
        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }
        
        await PushNotifications.register();
    }
      
    async getDeliveredNotifications(){
        const notificationList = await PushNotifications.getDeliveredNotifications();
        console.log('delivered notifications', notificationList);
    }
 
    requestReturnPushMsgFcm(){
        const body = {
            registationToken : this.registationToken
        }

        this.http.put(ENV.DOMAIN_OF_BACKEND + '/return-test-fcm-message', body).subscribe((res) => {
            console.log(res)
        }, e => {
            console.error(e)
        })
    }

    getCachedChatListMessages(){
        return this.cachedChatListMessages
        this.cachedChatListMessages = []
    }


}
