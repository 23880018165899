<ion-header>
  <ion-toolbar>
    <ion-title>Known Shortcomings (temporary)</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
	<ion-grid>
		<h1>
			UI/UX
		</h1>
		<ol>
			<li *ngFor="let sc of uiUxScs; let i = index">{{ sc }}</li>
		</ol>
		<h1>
			Features 
		</h1>
		<ol>
			<li *ngFor="let sc of featuresScs; let i = index">{{ sc }}</li>
		</ol>
		<h1>
			Spotify Player
		</h1>
		<ol>
			<li *ngFor="let sc of playerScs; let i = index">{{ sc }}</li>
		</ol>
		<h1>
			iOS
		</h1>
		<ol>
			<li *ngFor="let sc of iosScs; let i = index">{{ sc }}</li>
		</ol>
	
	</ion-grid>

	 
</ion-content>
<app-footer-bar>
</app-footer-bar>
