

import {Document, VariableExpressionOperator} from 'mongoose'
import {Types} from 'mongoose'
import { BeErrorLogTypeEnum, FadeCurveTypesE, ListElementTypesEnum, SpotExtraPermissions } from '../enums/enums.shared.enum'
import { Tune, User } from '../../../frontend/src/app/classes-enums-interfaces-types/classes/classes.js';
import { ISpotDevice } from '../../../shared/shared.interface.js';

export interface IImageV1 {
    height: number
    width: number
    url: string
}

export interface IAlbumV1 {
    id: string
    images: []
    name: string
}

export interface ITag {
    createdAt: Date
    name: string
    popularity: number
    searches: number
    tunes: Types.ObjectId[]
}

export interface ITagDoc extends ITag, Document {}

export interface IArtistV1 {
    createdAt: Date
    idArtist: string // id collides with mongoDb _id
    name: string
    popularity: number
    tunes: ITuneV3[]
}

export interface IArtistDoc extends IArtistV1, Document {}

export interface ITrackV1 {
    album: IAlbumV1
    artists: {
        idDb: Types.ObjectId
        name: string
    }[]
    available_markets: string[]
    durationMs: number
	external_ids: {
		isrc: string,
		ean: string,
		upc: string
	}
    id: string
    idPrev: string
    language: string
    lyrics: {
		api:{
			genius : {
				noLyricsAvailable : boolean,
				incorrectLyrics : boolean
			}
		}
        arr: string[]
        str: string
        fetchedDate: Date
    }
    popularity: number
	metaDataMusic : {
		fetchedDate: Date
		instrumentalness: number
	}
    name: string
}
 
export interface ITuneV3 {
	_id: string
    categories: Types.ObjectId
    createdAt: Date
    createdBy: {
        id: Types.ObjectId
		spotId: string
    }
    fadeOptions: {
        fadeCurve: {
            constants: Map<string, number>
            curveType: FadeCurveTypesE
            expression: string
        }
        fadeInDuration: number
        fadeOutDuration: number
        lowestVolumePercent: number
    }
    instrumental: boolean
	inTuneLists : Types.ObjectId[]
    loops: number
    lyrics: {
        lyricsStr: string //#opt change to str
        wordRange: {
            startIndex: number
            stopIndex: number
        }
    }
    popularity: number
    slimTuneTrack: {
        artistName: string
		durationMs:number //#opt, just saved me a headache
        trackId: string
        trackImg: string
        trackName: string
    }
    startTime: number
    stopTime: number
    tags: {
        addedBy?: Types.ObjectId
        name: string
        tag: Types.ObjectId
    }[]
    tuneNumber: number
    tuneTrack: Types.ObjectId
    type: ListElementTypesEnum
    triggers: Types.ObjectId[]
}

//ITuneV3Pop extends Omit<ITuneV3, 'categories'|'createdBy'| 'tags'| 'tuneTrack'|'triggers'> {	

export interface ITuneV3Doc extends Omit<ITuneV3,"_id">, Document<Types.ObjectId> {
	_id:Types.ObjectId
}
// export interface ITuneV3FE extends ITuneV3 {
// 	_id?:string
// }
// https://stackoverflow.com/questions/65013802/loose-type-definition-with-omit-and-keystring-unknown
// For defined Omit, might be required for more complex populates
type KnownKeys<T> = {
	[K in keyof T]: string extends K ? never : number extends K ? never : K
  } extends { [_ in keyof T]: infer U } ? U : never;
  
  type KnownPart<T> = Pick<T, KnownKeys<T>>

  type IndexableKeys<T> = {
	[K in keyof T]: string extends K ? K : number extends K ? K : never
  } extends { [_ in keyof T]: infer U } ? U : never;
  type IndexPart<T> = Pick<T, IndexableKeys<T>>

 export type OmitE<T, K extends PropertyKey> = 
  Omit<KnownPart<T>, K> & Omit<IndexPart<T>, K> extends
  infer O ? { [P in keyof O]: O[P] } : never;

// This is incorrect as I shouldn't extend and omit here, that is done in each call case
export interface ITuneV3DocPop extends Omit<ITuneV3Doc, 'categories'|'createdBy'| 'tags'| 'tuneTrack'|'triggers'> {
	categories : ICategoryV2[]
	createdBy : {
		id:IUser
		spotId:string
	}
	tags : ITag[]
	tuneTrack : ITuneTrackV1
	triggers : ITriggerDoc[]
}

export interface ITuneV3DocPop
//extends ITuneV3Doc {
{
	categories : ICategoryV2[]
	createdBy : {
		id:IUser
		spotId:string
	}
	tags : ITag[]
	tuneTrack : ITuneTrackV1
	triggers : ITriggerDoc[]
}

export interface ITuneTrackV1 {
    createdAt: Date
    track: ITrackV1
    tunes: ITuneV3[]
}

export interface ITuneTrackV1Doc extends ITuneTrackV1, Document<Types.ObjectId> {}

export interface ISavedTune {
	savedAt: Date,
	tune : Types.ObjectId
}

export interface ISavedTunePop {
	savedAt: Date,
	tune : ITuneV3DocPop
}

export interface IUserTune {
	savedAt: Date,
	tune : Types.ObjectId
}

export interface IUserTunePop {
	savedAt: Date,
	tune : ITuneV3DocPop
}


export interface IUser {
    //add custom user cat
    contacts: Types.ObjectId[]
    conversations: Types.ObjectId[]
    createdAt: Date
    createdTunes: Types.ObjectId[]
    displayName: string
    displayNumber: number
    email: string
    isAdmin: boolean
	// decap
    savedTunes: ISavedTune[]
    signUpCodeCode: null
    spotId: string
    spotName: string
    spotExtraPermissions : SpotExtraPermissions[]
    triggers: Types.ObjectId[]
	tuneListMCreated: Types.ObjectId[]
	tuneListMSaved: Types.ObjectId[]
	//decap
	tunes: Types.ObjectId[]
    updatedAt: Date
}

export interface IUserPop extends Omit<IUser, "contacts" | "conversations" | "createdTunes" | "savedTunes" | "triggers" | "tuneListMCreated" | "tuneListMSaved" | "tunes"> {
    //add custom user cat
    contacts: IUser[]
    conversations: any[]
    createdTunes: ITuneV3[]
    triggers: ITrigger[]
	tuneListMCreated: ITuneList[]
	tuneListMSaved: ITuneList[]
	//decap
}


export interface ISignUpCode {
    //add custom user cat
    code: string
    user: Types.ObjectId[]
	userEmail : string,
	spotId : string,
	spotName : string,

    createdAt: Date
    updatedAt: Date
}

export interface ISignUpCodeDoc extends Omit<ISignUpCode,"_id">, Document<Types.ObjectId> {
	_id:Types.ObjectId
}

export interface IUserDoc extends IUser, Document{}

export interface ITrigger {
	
	autoCompletes : {
		autoComplete:string,
		createdAt:Date,
		createdBy:Types.ObjectId,
		tunes:{
			tune:Types.ObjectId,
			triggerTimes:number
		}[],
	}[]
	createdAt:Date
	triggerSentence:string,
	notApplicable:boolean
}

export interface ITriggerFE {
	
	_id:string,
	autoCompletes : {
		autoComplete:string,
		createdAt:Date,
		createdBy:string,
		tunes:{
			tune:string,
			triggerTimes:number
		}[],
	}[]
	createdAt:Date
	triggerSentence:string,
	notApplicable:boolean
}

export interface ITriggerDoc extends ITrigger, Document {}

export interface ICategoryV2 {
	name:string,
	child:Types.ObjectId //#opt change name to children
	parent:Types.ObjectId
	tunes:Types.ObjectId
}

export interface IWaitList {
	email:string,
	createdAt:Date
}

export interface ITuneList {
	_id : Types.ObjectId,
	createdAt : Date,
	createdBy : Types.ObjectId
	name : string,
	number: number,
    // should and need to be maintained in order
    // aka elem with index 0 is first in list and index tunes.length last in list
    // after any tune addition or removal
	tunes: {
        addedAt : Date,
        index : number
        tune : Types.ObjectId,
    }[]
        
	savedByUsers : Types.ObjectId[]
	updatedAt: Date
}

export interface ITuneListPopSearched extends ITuneListPop{

    

}

export interface ITuneListPop extends Omit<ITuneList, "createdBy" | "tunes" | "savedByUsers"> {
	createdBy: Types.ObjectId;
	savedByUsers: IUser[];
	tunes: ITuneV3[];
}

export interface ITuneListPopLean {
	createdBy : {
		displayName : string,
	},
	name : string,
	number:number;
	_id : string,
}



export interface ITuneListDoc extends Omit<ITuneList,"_id">, Document<Types.ObjectId> {
	_id:Types.ObjectId
}

export interface UsersTuneListM {
	tuneListMCreated : ITuneListPopLean[]
	tuneListMSaved : ITuneListPopLean[]
}
export interface IFeLogErrorBase {
	
	dateOfOccurence:Date,
	error : {
		msg : string,
		err : string
	},
}
export interface IFeLogError extends IFeLogErrorBase {
	dateLogged:Date,
	dateOfOccurence : Date, // if logged to localstorage
	error : {
		msg : string,
		err : string
	},
	
	contextData : {
		isPwa : boolean
		route : string,
		spot : {
			id : string,
			spotAciveDeviceId: string,
			spotDevices: ISpotDevice[]
		},
		tuneItVersion : string 
		userAgent :  string,
	}
	user: Types.ObjectId
}

export interface IBeErrorLogDataTest  {
	test1 : string,
	test2 : string
}


export interface IBeErrorLogDataLyrics {

	trackNameFromFe : string
	artistNameFromFe : string
	trackNameSentToApi : string,
	artistNameSentToApi : string,

}

export type BeErrorLogTypeDataMap = {
	[BeErrorLogTypeEnum.mongooseSchemaInitDummy] : void
	[BeErrorLogTypeEnum.lyricsNotFoundGenius] : IBeErrorLogDataLyrics,
	[BeErrorLogTypeEnum.lyricsTooLongGenius] : IBeErrorLogDataLyrics,
	[BeErrorLogTypeEnum.test] : IBeErrorLogDataTest,

}

export type BeErrorLogData = IBeErrorLogDataLyrics | IBeErrorLogDataTest



export interface IBeErrorLog <T extends BeErrorLogTypeEnum>{
	error : {
		errorType : BeErrorLogTypeEnum,
		// Mixed in schema, validated when saved. Type cast when fetching.
		data  : BeErrorLogTypeDataMap[T] 
	}

	generalContext : {
		user : Types.ObjectId,
		swVersion : string,
	}
}

export type IBeErrorLogDoc = IBeErrorLog<BeErrorLogTypeEnum> & Document<Types.ObjectId>


export interface IFeLogErrorSentry {
	dateOfOccurence : Date, // if logged to localstorage

	contextData : {
		isPwa : boolean
		spot : {
			id : string,
			spotAciveDeviceId: string,
			spotDevices: ISpotDevice[]
            name:string,
            email:string
		},
		tuneItVersion : string 
		userAgent :  string,
	}
	userId : string

}

export interface IFeSetSentryContextError {
	setContextError : string
}

export interface ITuneListByNumberResult {
	tunesReorder : ITuneReorder[],
	tuneListName? : string,
	tuneListCreatedByDisplayName? : string
	tuneListId? : string
	alreadySavedByThisUser : boolean
}

export interface ITuneReorder {
    tune : Tune,
    index? : number
    addedAt? : Date
}


