import { LoopProgressBarModule } from './../loop-progress-bar/loop-progress-bar.module';
import { SelectedTrackRoutingModule } from './selected-track-routing.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SelectedTrackComponent } from './selected-track.component'
import { DirectivesModule } from '../../directives/directives.module';
import { PoweredByRowModule } from '../powered-by-row/powered-by-row.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SelectedTrackRoutingModule,
    DirectivesModule,
	PoweredByRowModule
  ],
  declarations: [SelectedTrackComponent],

  exports:[SelectedTrackComponent]
})
export class SelectedTrackModule {}
