<ion-item 
	lines="full"
	id="trigger-element-{{triggerElementNbr}}"
	(click)="onTap($event)"
	class="trigger-item"
	>
	<!--long-press (tap)="onTap($event)"
	long-press (press)="onLongPress()" -->
    <ion-grid  >
        <ion-row *ngIf="trigger?.triggerSentence">
            <ion-col class="ion-text-wrap"
            >{{trigger?.triggerSentence}}
               
            </ion-col>
        </ion-row>
        <ion-row *ngIf="trigger?.autoCompletes[0]?.autoComplete">
            <ion-col class="ion-text-wrap">
                {{trigger?.autoCompletes[0]?.autoComplete}}
            </ion-col>
        </ion-row>
        <ion-row>
            <app-list-element 
                *ngIf="tune"
				[noPaddingLeftEnable]="true"
                [borderStyle]="'none'"
                [elem]="tune?.slimTuneTrack"
				[disableTouchGesture]="true"
				(listElemPopOpen)="listElemPopOpen($event)"
				[listElementHtmlContext]="ListElementHtmlContextE.myLibTriggerList"
			>
            </app-list-element>
        </ion-row>
    </ion-grid>
</ion-item>

<ion-popover 
	side="end"
	trigger-action="none"
	dismiss-on-select="true"
	show-backdrop="true"
	alignment="center"
	animated="false"
	>
	<ng-template >
	
		<ion-list class="pop-over-list">
			<ion-item class="pop-over-item"  *ngIf="(spotId == tune?.createdBy?.spotId)"
				[button]="true"
				(click)="popEditClick()">
				Edit Tune
			</ion-item>
			<ion-item
				class="pop-over-item" 
				[button]="true"
				(click)="popAddToListClick()">
				Add to list
			</ion-item>
			<ion-item
				class="pop-over-item" 
				[button]="true"
				(click)="popRemoveClick()">
				Remove
			</ion-item>
			<ion-item
				class="pop-over-item" 
				[button]="true"
				(click)="popMoreTunesClick()">
				More Tunes
			</ion-item>
			<ion-item
				class="pop-over-item" 
				(click)="popSpotClick()"
				lines="none">
				Open Spotify
			</ion-item>
		</ion-list>
	
	</ng-template>
</ion-popover>
