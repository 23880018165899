
import { Meta } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyMetaService {

  constructor(
    private meta: Meta
  ) { }

    mAddMeta(){
      this.meta.addTags([
        { name: 'og:title', content: 'Tune it' },
        { name: 'og:description',content:'Description' },
        { name: 'og-image', content: 'https://trudd-ae.ey.r.appspot.com/logo'},
        { name: 'og-url', content: 'https://trudd-ae.ey.r.appspot.com'}
      ]);
  }
}
