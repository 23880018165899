import { Tune } from './../../classes-enums-interfaces-types/classes/classes';
import { SearchStatesEnum, SegmentStatesEnum } from '../../classes-enums-interfaces-types/enums/find-tune/enums.enum';
import { FindTuneStateService } from '../../services/states/find-tune/find-tune-state.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { NgModule, Component, Input, ViewChild, OnInit, SimpleChange, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ENV } from '../../../../environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {NgSwitch, NgSwitchDefault} from '@angular/common';
import { FacebookDataService } from '../../services/facebook-data/facebook-data.service';
import {Location, LocationStrategy, PathLocationStrategy} from "@angular/common"
import { DataService } from '../../services/music/data.service';
import { Observable, Subscription } from 'rxjs';
import { MyServerService } from '../../services/my-server/my-server.service';
import { ListElementTypesEnum } from '../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
 
@Component({
  selector: 'app-search-handler',
  templateUrl: './search-handler.component.html',
  styleUrls: ['./search-handler.component.scss'],
})
export class SearchHandlerComponent implements OnInit {

    //@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

    constructor(    
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private mServer: MyServerService,
        private fb: FacebookDataService,
        private location : Location,
        private ds : DataService,
        private findTuneState: FindTuneStateService,
		private eh : ErrorHandlerService,
		private changeDetector : ChangeDetectorRef	

	) 
    {} 
    fetchOffset = {
        artist:0, 
        track:0,
        mix:0,
    };
    fetchLimit : number = 20;
    elemtId : string = ""; 

    elemArr = []
    elemMixArr = [];
    elemArtistArr = []
    elemTrackArr=[];
    elemTunesArr:Tune[]=[]

    listElementTypes = ListElementTypesEnum;
    forwardButtonClickFunc;
    noMoreServerElems = false;
    stateSubscriber
    searchSubscriber 
    subscriptionSearch : Observable<String>
    searchstateB$ = this.findTuneState.search.stateB$
    hasSearched : boolean = false
    hasSearchedB$ = this.findTuneState.search.hasSearchedB$
    hasSearchedSubscriber
    searchLimit = 10
    searchText = ''
	searchState : SearchStatesEnum

	// ViewChild ionScroll is fucked and cannot get i to work
	infScrollDisabled : boolean = false; 

	searchStateEnum = SearchStatesEnum



    elemClicked(listElem){
        console.log('searchElemClicked')
        this.findTuneState.search.listElemClicked(listElem)
    }

    showAllTracks(){}

    showAllArtists(){}  
 
    ngOnInit() {

        
    }

    ngAfterViewInit(){

        console.log('SearchHandlerInit')
        this.searchSubscriber = this.findTuneState.searchB$
        
        .subscribe((searchText:string) => {

			if(this.findTuneState.segment == SegmentStatesEnum.search){
				
				if(searchText!=''){        
			
					this.searchText = searchText
					this.elemMixArr = []
					this.fetchOffset.mix = 0
					this.fetchOffset.track = 0
					if(this.findTuneState.search.state == SearchStatesEnum.tunes){

						this.searchText = searchText.replace(' ', '')
						this.getTunesWithTag(searchText)

					} else {

						this.fetchSpotElems(searchText)

					}

				}
			}

        },
        e => {
            console.error('Search subscriber error',e)
			this.eh.logSentryError(e)
        })   

        this.hasSearchedSubscriber = this.findTuneState.search.hasSearchedB$
        .subscribe((hasSearched:boolean) => {
            this.hasSearched = hasSearched
            console.log(`has searched: ${hasSearched}`)
        },
        e => {
            console.error('hasSearchedB$',e)
			this.eh.logSentryError(e)
        })

		this.stateSubscriber = this.findTuneState.search.stateB$
        .subscribe( (searchState : SearchStatesEnum)=> {
            console.log('Search handler search state sub')

            this.elemArr=[] // to show user input changed gui, add spinner #opt
			this.searchState = searchState
            switch(searchState) {

                case(SearchStatesEnum.artists):{ 
                    this.infScrollDisabled = false
                    this.ds.search(
                        this.findTuneState.getSearch(),
                        'artist',
                        this.fetchLimit,
                        this.fetchOffset.artist
                        )
                        .subscribe((spotArtists : any) => { //#TODO, fetch only if not buffered
                            this.elemArtistArr = this.ds.spotFetchFormatToMFormat(spotArtists);
                            this.elemArr = this.elemArtistArr
                        },
						e => {
							console.error('ds search error',e)
							this.eh.logSentryError(e)
						});

                break; 
                }
                case(SearchStatesEnum.tracks):{
                    this.infScrollDisabled = false
                    this.ds.search(
                        this.findTuneState.getSearch(),
                        'track',
                        this.fetchLimit,
                        this.fetchOffset.track
                        )
                    .subscribe((spotTracks : any) => { //#TODO, fetch only if not buffered
                        this.elemTrackArr = this.ds.spotFetchFormatToMFormat(spotTracks);
                        this.elemArr = this.elemTrackArr
                    },
					e => {
						console.error('ds search error',e)
						this.eh.logSentryError(e)
					});

                
                break;
                }
                case(SearchStatesEnum.artistsTracksMixed):{
                    this.infScrollDisabled = false
                    break;
                }
                case(SearchStatesEnum.tunes):{ // Tmp, before pagination of tunes
                    this.infScrollDisabled = true
                }
            }
        },
        e => {
            console.error('Search state subscriber error',e)
			this.eh.logSentryError(e)

        },
        () => {
            console.log('Search state subscriber complete')
        })
       
    }

    getTunesWithTag(tag){
        this.mServer.getTunesWithTag(tag, true).subscribe((res : any ) => { // tunes : Tune[]

			if(res.status == 204){

				console.log('No tunes found with tag')
				this.searchState = SearchStatesEnum.noTunesFound

			} else {
				
				this.elemTunesArr = res.body.tunes
				this.elemArr = this.elemTunesArr

			}

			this.changeDetector.detectChanges()

        }, e => {
            console.error(`Error getting tunes by tag: `,e)
			this.eh.logSentryError(e)
        })
    }

    async fetchSpotElems(searchText:string) : Promise<Boolean> {

	
        return new Promise<Boolean>((resolve,reject) => {
        if(searchText != ''){
			this.ds.search(searchText,'track',this.fetchOffset.track,this.searchLimit)
				.subscribe((spotElems : any) => {
					
					console.log(spotElems)
					if(spotElems.length < 1 || !spotElems){
						this.searchState = SearchStatesEnum.noSpotTracksFound
						reject(false)
					} else {
						this.fetchOffset.track += this.searchLimit;
						//Sort takes a function that every element is compared with in a pyramid knockout fashion, 
						///let popSortedSpotElems = [...spotElems.artists.items, ...spotElems.tracks.items]
						//let popSortedSpotElems = [...spotElems.artists.items, ...spotElems.tracks.items]
						//.sort(({popularity:a},{popularity:b}) => b-a);
	
						///this.elemMixArr = this.elemMixArr.concat(this.ds.spotFetchFormatToMFormat(popSortedSpotElems.slice(0,this.searchLimit)))
						this.elemArr = this.elemArr.concat(this.ds.spotFetchFormatToMFormat(spotElems.tracks.items.slice(0,this.searchLimit)))
						resolve(true)
					}
	
				},
				e => {
					console.error(`Error fetching Spotify`,e)
					this.eh.logSentryError(e)
					reject(false)
				}
				);
			} else {
				reject(false)
			}
        })
    }

    async endOfScroll(event){
        try {
            if(
                this.findTuneState.search.state != SearchStatesEnum.noSearch
                && this.findTuneState.search.state != SearchStatesEnum.tunes
            )
            {
                console.log('endOfScroll')
                var fetchDone$ = await this.fetchSpotElems(this.searchText)
        
                if(fetchDone$ == true){
                    event.target.complete()
                } else {
                    console.log('fetch not done')
                }
                /*       var fetchDoneSub = fetchDone$.subscribe((fetchDone : Boolean) => 
                {
                event.target.complete()
                },
                err=>{
                console.log('End of scroll fetch sub error '+err)
                event.target.complete()
                },
                () => {
                console.log('end of scoll complete in function')
                }
                ) */
            } else {
                event.target.complete()
            }
        } catch(e){
            console.log('endOfSroll await error '+e)
			this.eh.logSentryError(e)
        }
    }

    ngOnDestroy():void{
        console.log('Search Handler Destroy')
    }
    
}  
  