import { PwaInstallPromptIosModule } from './../components/pwa-install-prompt/ios/pwa-install-prompt-ios.module';
import { DirectivesModule } from './../directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FooterBarModule } from '../components/footer-bar/footer-bar.module';
import { IonicModule, IonMenu } from '@ionic/angular';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DevPageRoutingModule } from './dev-routing.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {} from '@angular/material/icon';
import { DevPage } from './dev.page';
import { SelectedTrackModule } from '../components/selected-track/selected-track.module';
import { LyricsModule } from '../components/lyrics/lyrics.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DevPageRoutingModule,
    FooterBarModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    DirectivesModule,
    SelectedTrackModule,
	PwaInstallPromptIosModule,
	LyricsModule

  ],
  declarations: [DevPage]
})
export class DevPageModule {}
