import { TriggerComponent } from './my-library/trigger/trigger.component'
import { PlayTuneModule } from './play-tune/play-tune.module'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
/* 
import { AuthComponent } from "./auth/auth.component";
import { EditTuneComponent } from "./edit-tune/edit-tune.component";
import { FunnelComponent } from "./funnel/funnel.component";
import { PlayTuneComponent } from "./play-tune/play-tune.component"; */
import { mCanActivateService } from './services/m-can-activate.service'
import { ENV } from '../../environments/environment'

export const routes: Routes = [
    //{ path: 'logged-in', component: LoggedInComponent},
    {
        path: '',
        //component:FindTunePage,
        //loadChildren: () => import('./find-tune/find-tune.module').then(c => c.FindTunePageModule),
        pathMatch: 'full',
        redirectTo: ENV.DEBUG ? 'dev-router' : 'find-tune',
        //redirectTo: '/find-tune'
        //redirectTo: '/dev-router'
        //canActivate:[mCanActivateService]
    },

    {
        path: 'play-tune',
        loadChildren: () =>
            import('./play-tune/play-tune.module').then(
                (c) => c.PlayTuneModule
            ),
        canActivate: [mCanActivateService],
    },
    {
        path: 'dev',
        loadChildren: () =>
            import('./dev/dev.module').then((c) => c.DevPageModule),
        canActivate: [mCanActivateService],
    },

    {
        path: 'edit-tune',
        loadChildren: () =>
            import('./edit-tune/edit-tune.module').then(
                (m) => m.EditTuneModule
            ),
        canActivate: [mCanActivateService],
    },

    {
        path: 'dev',
        loadChildren: () =>
            import('./dev/dev.module').then((m) => m.DevPageModule),
        //canActivate: [mCanActivateService]
    },
    {
        path: 'find-tune',
        loadChildren: () =>
            import('./find-tune/find-tune.module').then(
                (c) => c.FindTunePageModule
            ),
        canActivate: [mCanActivateService],
    },
    /*   { path: 'funnel', component: FunnelComponent},
          { path: 'sign-up', component: AuthComponent}, */

    {
        path: 'chat',
        loadChildren: () =>
            import('./chat-folder/chat/chat.module').then(
                (m) => m.ChatPageModule
            ),
    },
    {
        path: 'chat-list',
        loadChildren: () =>
            import('./chat-folder/chat-list/chat-list.module').then(
                (m) => m.ChatListPageModule
            ),
    },
    {
        path: 'dev-router',
        loadChildren: () =>
            import('./dev-router/dev-router.module').then(
                (m) => m.DevRouterPageModule
            ),
    },
    {
        path: 'sign-up',
        loadChildren: () =>
            import('./auth/sign-up/sign-up.module').then(
                (m) => m.SignUpPageModule
            ),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./auth/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'my-library',
        loadChildren: () =>
            import('./my-library/my-library.module').then(
                (m) => m.MyLibraryPageModule
            ),
        canActivate: [mCanActivateService],
    },
    {
        path: 'track',
        loadChildren: () =>
            import('./track/track.module').then((m) => m.TrackPageModule),
        canActivate: [mCanActivateService],
    },
    {
        path: 'wait-list',
        loadChildren: () =>
            import('./wait-list/wait-list.module').then(
                (m) => m.WaitListPageModule
            ),
    },
    {
        path: 'waitlist',
        redirectTo: '/wait-list',
    },
    {
        path: 'known-shortcomings',
        loadChildren: () =>
            import('./known-shortcomings/known-shortcomings.module').then(
                (m) => m.KnownShortcomingsPageModule
            ),
    },
    {
        path: 'knownshortcomings',
        redirectTo: '/known-shortcomings',
    },
    {
        path: 'tune-list',
        loadChildren: () =>
            import('./tune-list/tune-list.module').then(
                (m) => m.TuneListPageModule
            ),
        canActivate: [mCanActivateService],
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminPageModule),
        canActivate: [mCanActivateService],
    },
    {
        path: '**',
        //component:FindTunePage,
        //loadChildren: () => import('./find-tune/find-tune.module').then(c => c.FindTunePageModule),
        pathMatch: 'full',
        redirectTo: '/find-tune',
        //canActivate:[mCanActivateService]
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminPageModule),
    },
]
//{ path: 'logged-in', component: LoggedIn];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            preloadingStrategy: PreloadAllModules,
            urlUpdateStrategy: 'eager',
        }),
    ],
    //{ preloadingStrategy: PreloadAllModules }
    exports: [RouterModule],
})
export class AppRoutingModule {}
