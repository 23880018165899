
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {ENV} from '../../../../environments/environment'
@Injectable({
   providedIn: 'root'
})
export class SEOService { 
 
  constructor(@Inject(DOCUMENT) private dom) { 
  }
    
  updateCanonicalUrl(){
/*     try{    
        const head = this.dom.getElementsByTagName('head')[0];
        var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
        // if (element==null) {
        //   element= this.dom.createElement('link') as HTMLLinkElement;
        //   head.appendChild(element);
        // }
        element.setAttribute('rel','apple-touch-icon')
        element.setAttribute('sizes','192x192')
        element.setAttribute('href', ENV.DOMAIN_OF_BACKEND + '/icon?resolution=192x192')
    } catch(e){
        console.error('error setting apple-touch-icon',e)
    } */
  }
}  