
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoopProgressBarComponent } from './loop-progress-bar.component';
import { IonicModule } from '@ionic/angular';
@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [ LoopProgressBarComponent ],
  exports: [ LoopProgressBarComponent ],
})
export class LoopProgressBarModule {}
