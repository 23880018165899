import { ListElementHtmlContextE } from './../../classes-enums-interfaces-types/enums/enums.enum';
import { Router } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TriggerSlim, Tune } from '../../classes-enums-interfaces-types/classes/classes';
import { AlertController, IonPopover, ModalController } from '@ionic/angular';
import { LongPressDirective } from '../../directives/long-press/long-press.directive';
import { GlobalService } from '../../services/global.service';
import { DevicePlatformE, ModalsE } from '../../classes-enums-interfaces-types/enums/enums.enum';
import { AddTuneToListComponent } from '../../components/add-tune-to-list/add-tune-to-list.component';
import { MyServerService } from '../../services/my-server/my-server.service';
import { IUserTune } from '../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared';
import { PlayerService } from '../../services/music/player.service';

@Component({
  selector: 'app-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.scss'],
})
export class TriggerComponent implements OnInit, AfterViewInit {

	@ViewChild(IonPopover) ionPop : IonPopover
	// Historically messy convention for tune vs trigger
	// Should be trigger later when triggers and tunes are []
	@Input("trigger") trigger : any
	@Input("triggerElementNbr") triggerElementNbr // needed for ionPopOver id in list-element  
	@Output('triggerClick') triggerClick = new EventEmitter<any>()
	@Output() addedTuneToListClick = new EventEmitter();
	@Output() removeTriggerClick = new EventEmitter();

	loopItr=0
	spotId
	tune

	ListElementHtmlContextE = ListElementHtmlContextE
	constructor(
		private router : Router,
		private gs : GlobalService,
		private modalCtrl: ModalController,
		private alertController : AlertController,
		private mServer : MyServerService,
		private ps : PlayerService
	) { }

	ngOnInit() {


	}

    ngAfterViewInit(): void {
        
        //console.log('trigger inti')
		this.tune = this.trigger.autoCompletes[0].tunes[0].tune
		this.spotId = this.gs.getSpotId()

    }

	ngOnDestroy(){
		//console.log("trigger ngDestroy")
	  }

	triggerClickLocal(){

	}

	popEditClick(){
		const tuneClone = JSON.parse(JSON.stringify(this.tune))
		const triggerClone = JSON.parse(JSON.stringify(this.trigger))
		delete triggerClone.autoCompletes[0].tunes[0].tune
		tuneClone.triggers = [triggerClone]
		this.router.navigateByUrl('/edit-tune',{ state: {tune : tuneClone}})
	}

	popSpotClick(){
		window.open(`https://open.spotify.com/track/${this.tune.slimTuneTrack.trackId}`,'_blank')	
	}

	async popAddToListClick(){
		const modal = await this.modalCtrl.create({
			component: AddTuneToListComponent,
			breakpoints:[0.7],
			initialBreakpoint:0.7,
			componentProps: { 'tune' : this.tune },
			id: ModalsE.addToTuneList,
			handle:true

		  });
		  await modal.present();
  
		  const { data, role } = await modal.onDidDismiss();
	  
		  if (role === 'added') {
			this.addedTuneToListClick.emit({tune :this.tune, addTuneToListData:data})
		  }
		
	}

	async popRemoveClick(){

        const  tuneNameToDelete = this.trigger?.autoCompletes[0]?.tunes[0]?.tune?.slimTuneTrack?.trackName ?? undefined
		const alert = await this.alertController.create({
			header: 'Confirm',
			message: `Tune\n "${tuneNameToDelete}" \n removal`,
			cssClass: 'delete-list-alert',
			buttons: [
				{ 
					text :'Cancel',
					role: 'cancel',
					cssClass: 'alert-delete-list-cancel-button'
				},
				{
					text:'Remove',
					role: 'destructive',
					cssClass: 'm-alert-cancel-button',
					handler: () => {
						console.log('Confirm Okay');
						this.removeTriggerClick.emit(this.trigger)
					},
				}
			]
		  });

		  alert.present()
	}

	popMoreTunesClick(){

		this.ps.pauseTrack()
		this.router.navigate(
			['/track'],
			{ state: { slimTuneTrack: this.tune.slimTuneTrack } } 
		);
	}

	onTap(event){

		console.log('tap trig')
		// #clean
		if(event?.event?.target?.id=='option-button') {

		} else {
			this.triggerClick.next(this.tune) 
		}
	}

	onLongPress(){
		console.log('longpress trig')
		this.loopItr+=2
		//if(this.gs.getDevicePlatform() == DevicePlatformE.ios){
			this.ionPop.present()
		//}
	}

	listElemPopOpen(event){
		this.ionPop.present(event)
	}	

}
