import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

	@Output() searchEntered = new EventEmitter<string>();
	@Output() clearClicked = new EventEmitter();
	// @Output() filterBtnClicked = new EventEmitter();

	// get isFilterBtnBound(): boolean {
	// 	return this.filterBtnClicked.observers.length > 0;
	//   }

	@Input() placeholder = 'Search here...';

	searchText = '#demoSwe';
	searchInitText = ''
	
	customMenu = '../../../assets/menu.svg';
	customMenuNotification = '../../../assets/menu-notification.svg';
    customFunnel = '../../../assets/funnel-outline.svg';

  	constructor(public gs : GlobalService) { }

  	ngOnInit() {
		this.searchText = this.searchInitText
	}

	searchBlur($event) {
		console.log('searchBlur')
		//this.gs.showToast({msg:'blur'})
	}

	searchInputEvent(event){
		console.log('nativeSearch')
		//event.preventDefault();
		//this.gs.showToast({msg:'input'})
	
	}

	searchKeyUp(event){
		console.log('searchKeyUp')
		if (event.key === 'Enter') {
			event.target.blur();
			if(this.searchText.charAt(0) === '#'){
				this.searchText=this.searchText.replace('#','')
				this.searchText=this.searchText.replace(' ','')
				this.searchText= '#' + this.searchText
				
			}
			this.searchEntered.emit(this.searchText);
		  }
		//this.gs.showToast({msg:'keyup'})
	}

	nativeSearch($event){
		this.gs.showToast({msg:'nativeSearch'})
		// console.log('nativeSearch')
		// if(this.gs.isNativeCapPlatform()){
		// 	Keyboard.hide();
		// }
        // this.searchEntered.emit(this.searchText);
	}

	searchButtonClicked($event) {
		this.gs.showToast({msg:'searchButtonClicked'})
    }

	searchButtonClearClicked() {
		this.clearClicked.emit()
	}

	filterButtonClicked() {
		this.gs.requestSideMenuToggle(true,'lib-filter-menu')
	}

    menuButtonClicked(){
		this.gs.requestSideMenuToggle(true)
	}

}
