import type { ISpotDevice } from '../../shared/dist/shared.interface'

//import { ISpotDevice } from './../dist/esm/backend/classes-enums-interfaces-types/interfaces/interfaces.shared';

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/order */
//#opt take server delay into account

import { registerPlugin, WebPlugin } from '@capacitor/core'
import type {
    FadeOutFadeInVolumeOptions,
    NativeVolumeControlPlugin,
    MPlayerState,
} from './definitions'
import axios from 'axios'
import { BehaviorSubject, Observable, timer } from 'rxjs'
import { get } from 'scriptjs'
import * as _ from 'lodash' // opt only one

// #fix ugly should be in shared interface a bit tricky with compile
enum NvcEvent {
    Ready = 'ready',
    AutoplayFailed = 'autoplay_failed',
    NotReady = 'not_ready',
    InitializationError = 'initialization_error',
    AuthenticationError = 'authentication_error',
    PlaybackError = 'playback_error',
    PlayerStateChanged = 'player_state_changed',
    AccountError = 'account_error',
}

export class NativeVolumeControlWeb
    extends WebPlugin
    implements NativeVolumeControlPlugin {
    constructor() {
        super()
    }

    headers: Object
    fadeInDuration = 700
    fadeSteps = 10
    fadeInSteps = this.fadeSteps
    fadeOutEndVolume = 60
    fadeInInterval = this.fadeInDuration / this.fadeInSteps
    fadeInVolumeInc =
        (100 - this.fadeOutEndVolume) /
        (this.fadeInDuration / this.fadeInInterval)
    fadeOutDuration = 800
    fadeOutSteps = this.fadeSteps
    fadeOutInterval = this.fadeOutDuration / this.fadeOutSteps
    loopSubscriberFadeIn
    loopSubscriberFadeOut
    player
    playTrackSub
    prevTrackIdPlayed: string
    spotAccessToken: string
    unplayedAbsoluteTime: number
    deviceId: string
    private playerStateChanged$ = new BehaviorSubject<boolean>(false)
    playBackTransfered: boolean = false

    //? Should be lister for consisteny
    getIsPlaying$(): BehaviorSubject<boolean> {
        return this.playerStateChanged$
    }

    async decVolume(): Promise<void> {
        console.log(`web sdk dec volume`)
        this.player.getVolume().then((volume) => {
            //   console.log(`volume: ${volume}`)
            this.player.setVolume(volume - 0.1)
        })
        return
    }

    async disconnectPlayerAndRemoveListerns(): Promise<void> {
        console.log(`disconnect player`)
        try {
            const removeListenerRes: boolean[] = []

            removeListenerRes[0] = await this.player.removeListener(
                'ready'
            )
            removeListenerRes[1] = await this.player.removeListener(
                'autoplay_failed'
            )
            removeListenerRes[2] = await this.player.removeListener(
                'not_ready'
            )
            removeListenerRes[3] = await this.player.removeListener(
                'initialization_error'
            )
            removeListenerRes[4] = await this.player.removeListener(
                'authentication_error'
            )
            removeListenerRes[5] = await this.player.removeListener(
                'playback_error'
            )
            removeListenerRes[6] = await this.player.removeListener(
                'player_state_changed'
            )
            removeListenerRes[7] = await this.player.removeListener(
                'account_error'
            )

            await this.player.disconnect()

            console.log('player disconnected succ')
            console.log('removeListenerRes')
            console.log(removeListenerRes)
            return
        } catch (e) {
            console.error(`web sdk`, e)
            throw e
        }
    }

    async setVolume(options: { volume: number }): Promise<void> {
        console.log(`web sdk set volume`)
        try {
            await this.player.setVolume(options.volume)
            return
        } catch (e) {
            console.error(`web sdk set volume`, e)
            throw e
        }
    }

    async echo(options: { value: string }): Promise<{ value: string }> {
        console.log('ECHO', options)
        return options
    }

    async getDeviceId(): Promise<{ deviceId: string }> {
        return { deviceId: this.deviceId }
    }

    async getAvailableDevices(): Promise<{ spotDevices: ISpotDevice[] }> {
        //async geAvailableDevices(): Promise<{spotDevices:any[]}> {
        try {
            const res = await axios.get(
                'https://api.spotify.com/v1/me/player/devices',
                {
                    headers: this.headers,
                }
            )

            let spotDevices: ISpotDevice[] = []
            //let spotDevices : any[] = []
            for (const sD of res.data.devices) {
                spotDevices.push({
                    id: sD.id,
                    isActive: sD.is_active,
                    name: sD.name,
                    type: sD.type,
                    volumePercent: sD.volume_percent,
                })
            }

            return { spotDevices: spotDevices }
        } catch (e) {
            console.error(`Error getting devices for Spotify`, e)
            throw e
        }
    }

    initPlayerWeb(playerName?: string): void {
        playerName ?? 'Tune it player'
        //@ts-ignore
        this.player = new Spotify.Player({
            name: playerName,
            getOAuthToken: (cb) => {
                cb(this.spotAccessToken)
            },
            volume: 1.0, // for maximum fade effect
        })

        this.player.addListener('ready', ({ device_id }) => {
            console.log('Ready with Device ID', device_id)
            this.deviceId = device_id
            this.notifyListeners(NvcEvent.Ready, true)
            //this.ps.webApiTransferPlayback(device_id)
        })

        this.player.addListener('initialization_error', ({ message }) => {
            this.notifyListeners(NvcEvent.InitializationError, message)
        })

        this.player.addListener('autoplay_failed'),
            () => {
                console.error('Auto play failed')
                this.notifyListeners(NvcEvent.InitializationError,null)
            }

        // Not Ready
        this.player.addListener('not_ready', ({ device_id }) => {
            console.log('Device ID has gone offline', device_id)
            this.notifyListeners(NvcEvent.NotReady, device_id)
        })

        this.player.addListener('authentication_error', ({ message }) => {
            console.error(message)
            this.notifyListeners(NvcEvent.AuthenticationError,message)
        })

        this.player.addListener('playback_error', ({ message }) => {
            console.error('playBackError', message)
            this.notifyListeners(NvcEvent.PlaybackError,message)
        })

        this.player.addListener('player_state_changed', (state) => {
            console.log('state')
            console.log(state) // just state doesnt show all fields in sentry

            if (state) {
                const isPlaying = state?.paused == false ? true : false

                const playerState: MPlayerState = {
                    // 1 = only used due to unsynced track_id to start loop
                    artistName:
                        state?.track_window?.current_track.artists[0].name, //1
                    currentTrackId: state?.track_window?.current_track?.id,
                    isPlaying: isPlaying,
                    playbackPosition: state.position,
                    trackName: state?.track_window?.current_track.name, //1
                    isLoading: state?.loading, // used as position doesnt update whe pasued -> true -> false
                    // for trying to remove 502
                }
                this.notifyListeners(NvcEvent.PlayerStateChanged, playerState)
            }
        })

        this.player.addListener('account_error', ({ message }) => {
            console.error(message)
            this.notifyListeners(NvcEvent.AccountError,message)
        })
        this.player.connect().then((success) => {
            if (success) {
                console.log(
                    'The Web Playback SDK successfully connected to Spotify!'
                )
            } else {
                console.error('Error connecting Web SDK')
                throw new Error('Error connecting to Web SDK')
            }
        })
    }

    async getPlaybackTime(): Promise<{ playbackTime }> {
        //catched in player service
        const state = await this.player.getCurrentState()
        return { playbackTime: state.position }
    }
    async getSpotAccessToken(): Promise<{ value: string }> {
        return { value: this.spotAccessToken }
    }

    async getPlayer(): Promise<{ player: any }> {
        return this.player
    }

    async getVolume(): Promise<{ volume: number }> {
        console.log(`web sdk getVolume`)
        let volFloat
        let volInt
        try {
            volFloat = await this.player.getVolume()
            //console.log(`getVolume: ${vol}`)
            volInt = Math.round(volFloat * 10) / 10
        } catch (e) {
            console.error(`Error getting volume`, e)
            throw e
        }
        return { volume: volInt }
    }

    async incVolume(): Promise<void> {
        return
    }

    async loopTune(options: {
        trackId: string
        startTime: number
        stopTime: number
    }): Promise<void> {
        console.log(options)
        return
    }

    async pauseTrack(): Promise<void> {
        console.log(`web sdk pause`)
        try {
            await this.player.pause()
            return
        } catch (e) {
            console.error(`web sdk`, e)
            throw e
        }
    }

    async playTrackId(options: { trackId: string }): Promise<void> {
        console.log('PlayTrackId webPlugin')
        try {
            /*             if (!this.playBackTransfered) {
                await this.webApiTransferPlayback()
            } */
            const res = await axios.put(
                'https://api.spotify.com/v1/me/player/play',
                {
                    uris: [`spotify:track:${options.trackId}`],
                },
                {
                    params: { device_id: this.deviceId, market: 'SE' },
                    headers: this.headers,
                }
            )

            return
        } catch (e) {
            console.error(`playTrackId error`, e)
            throw e
        }
    }

    async playTrackIdTime(options: {
        trackId: string
        time: number
    }): Promise<void> {
        console.log(`playTrackIdTime webPlugin`)
        try {
            console.log(this.headers)
            const res = await axios.put(
                'https://api.spotify.com/v1/me/player/play',
                {
                    uris: [`spotify:track:${options.trackId}`],
                    position_ms: options.time,
                },
                {
                    params: {
                        device_id: this.deviceId,
                        market: 'SE',
                    },
                    headers: this.headers,
                }
            )
            /*if (!this.playBackTransfered) {
                await this.webApiTransferPlayback()
            } */
            return
        } catch (e1) {
            // #Todo really fix, unusably ugly with never throw
            console.error(`playTrackIdTime error`, e1)
            //Handle status 502 code here
            try {
                let handleNoActiveDeviceWorked: boolean = this.handleNoActiveDevice(
                    e1
                )
                if (handleNoActiveDeviceWorked) {
                    console.log('Transfered playback in playTrackIdTime')
                } else {
                    throw e1
                }
            } catch (e2) {
                console.error('Error in playTrackIdTime hnad', e2)
                throw e1
            }
        }
    }

    async activatePlayerElement() {
        this.player.activateElement()
        return
    }

    async resumeTrack(): Promise<void> {
        console.log('web sdk resumeTrack')
        try {
            await this.player.resume()
            return
        } catch (e) {
            console.error('web sdk resumeTrack error', e)
            throw e
        }
    }

    async seekTrackAbsolute(options: { absoluteTime: number }): Promise<void> {
        console.log(`seekTrackAbs webPlugin`)
        try {
            const tic = new Date().getTime()
            await this.player.seek(options.absoluteTime)
            const ticToc: number = new Date().getTime() - tic
            console.log(`seekTicTok ${ticToc}`)
            return
        } catch (e) {
            console.error(`seekTrackAbs webPlugin error`, e)
            throw e
        }
    }

    async seekTrackAbsoluteAndResume(options: {
        absoluteTime: number
    }): Promise<void> {
        console.log(`seekTrackAbsAndResume webPlugin`)
        try {
            await this.player.seek(options.absoluteTime)
            await this.player.resume()
            return
        } catch (e) {
            console.error(`seekTrackAbs webPlugin error`, e)
            throw e
        }
    }

    async seekTrackRelative(options: { relativeTime: number }): Promise<void> {
        console.log(`seekTrackRel webplugin`)
        try {
            const playerState = await this.player.getCurrentState()
            await this.player.seek(playerState.position + options.relativeTime)
            return
        } catch (e) {
            console.error(`seekTrackRel websdk`, e)
            throw e
        }
    }

    async setSpotAccessToken(options: {
        spotAccessToken: string
    }): Promise<void> {
        this.spotAccessToken = options.spotAccessToken
        this.headers = { Authorization: `Bearer ${this.spotAccessToken}` }

        return
    }

    //Non interface functions
    async fadeOutFadeInVolume(
        options: FadeOutFadeInVolumeOptions
    ): Promise<void> {
        console.log('Web fadeInOut')
    }

    //clearTimers() {
    //can proably bug if spam play button
    // OBS dependent on variable name, FIX get all active timers instead
    /*     clearTimers()
    {
        if(typeof this.loopSubscriberFadeOut !== 'undefined')
        {
        this.loopSubscriberFadeOut.unsubscribe();
        }
 
        if(typeof this.loopSubscriberFadeIn !== 'undefined')
        {
        this.loopSubscriberFadeIn.unsubscribe();
        }
 
        if(typeof this.playTrackSub !== 'undefined')
        {
        this.playTrackSub.unsubscribe();
        }
 
        this.playTrack$=null;
    } */

    async playTune(options: {
        trackId: string
        startTime: number
        stopTime: number
    }): Promise<void> {
        return
    }

    async webApiTransferPlayback(): Promise<void> {
        console.log('webTransferPlayback')
        try {
            const body = {
                device_ids: [this.deviceId],
                play: true,
            }

            await axios.put('https://api.spotify.com/v1/me/player', body, {
                headers: this.headers,
            })
            this.playBackTransfered = true
            return
        } catch (e) {
            console.error(`Error transfer playback`, e)
            throw e
        }
    }

    async webApiPlayTrackId(trackId: string): Promise<void> {
        console.log('webPlayApi')

        try {
            const body = {
                device_id: [this.deviceId],
                uris: [`spotify:track:${trackId}`],
            }

            await axios.put('https://api.spotify.com/v1/me/player/play', body, {
                params: { device_id: this.deviceId },
                headers: this.headers,
            })

            return
        } catch (e) {
            console.error(`Error webPlayApi`, e)
            throw e
        }
    }

    async initNative(options: {
        successCallback: Function
        failureCallback: Function
        accessToken: string
    }): Promise<void> {}

    async handleUrlOpen(options: { accessToken: string }) {}

    async resetLoop(): Promise<void> {
        return
    }

    handleNoActiveDevice(err): boolean {
        try {
            const noActiveDeviceError = {
                error: {
                    status: 404,
                    message: 'Player command failed: No active device found',
                    reason: 'NO_ACTIVE_DEVICE',
                },
            }

            if (_.isEqual(err, noActiveDeviceError)) {
                this.webApiTransferPlayback()
                return true
            } else {
                return false
            }
        } catch (e: any) {
            console.error('handleNoActiveDevice error', e)
            throw e
        }
    }

    handleAccessTokenExpirted(err) {
        try {
            const accessTokenExpiredError = {
                error: {
                    status: 401,
                    message: 'The access token expired',
                },
            }

            if (_.isEqual(err, accessTokenExpiredError)) {
                //#Todo refreshToken
            }
        } catch (e) {
            console.error('Error, fetching access token after 401')
            throw e
        }
    }

    handleServiceUnavailableError(err) {
        try {
            const accessTokenExpiredError = {
                error: {
                    status: 503,
                    message: 'The access token expired',
                },
            }

            if (_.isEqual(err, accessTokenExpiredError)) {
                //#Todo refreshToken
            }
        } catch (e) {
            console.error('Error, fetching access token after 401')
            throw e
        }
    }
}

/* const NativeVolumeControl = new NativeVolumeControlWeb()
export {NativeVolumeControl} */
