import { toastController } from '@ionic/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ENV } from '../../../environments/environment';
import { ToastOptions } from '@ionic/core';
import { GlobalService } from '../services/global.service';
import { ToastEnum } from '../classes-enums-interfaces-types/enums/enums.enum';
import { ErrorHandlerService } from '../services/error-handler/error-handler.service';
@Component({
  selector: 'app-wait-list',
  templateUrl: './wait-list.page.html',
  styleUrls: ['./wait-list.page.scss'],
})
export class WaitListPage implements OnInit {

	constructor(
		private http : HttpClient,
		private gs : GlobalService,
		private eh : ErrorHandlerService
	) { }

	logoSrc = `${ENV.DOMAIN_OF_BACKEND}/logo/subtitle`
	
	
	email : string = ''
	version : string = ENV.DEPLOY_VERSION_MAJOR + '.' + ENV.DEPLOY_VERSION_MINOR

  	ngOnInit() {
  	
		
	}

	sendClicked(){
		if(this.email){
			console.log('sendClicked')
			this.http.post(`${ENV.DOMAIN_OF_BACKEND}/wait-list-sign-up`,
			{email:this.email},{observe:'response'}).subscribe(async (res) => {
				if(res.status==200){
					this.gs.showToast({
						type:ToastEnum.success,
						msg:'You have been signed up to the wait list! No confirmation email will be sent, fyi',
						duration:7000
					})

				}
			}, async e => {
					console.error(e)
					this.eh.logSentryError(e)

					this.gs.showToast({ 
						msg:'Error signing up for wait list',
						duration:7000,
						type:ToastEnum.error
					}) 
				}
			)
		} 



	}

}
