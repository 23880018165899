import { mCanActivateService } from './../services/m-can-activate.service';
import { CategoryHandlerComponent } from './category-handler/category-handler.component';
import { SearchHandlerComponent } from './search-handler/search-handler.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FindTunePage } from './find-tune.page';
import { FooterBarComponent } from '../components/footer-bar/footer-bar.component';
import { findTuneRouteGuard } from './route-guards/find-tune.guard';

const routes: Routes = [

	{
		path: '',
		component: FindTunePage,
		canActivate:[findTuneRouteGuard],
		children: [
			{
				path:'search',
				loadChildren:() => import ('./search-handler/search-handler.module').then(c=>c.SearchHandlerModule),
			},
			{
				path:'select',
				loadChildren:() => import ('./category-handler/category-handler.module').then(c=>c.CategoryHandlerModule),
			}
		]
	},
	{
		path: '**',
		redirectTo:''
	}

  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FindTuneRoutingModule {}
