<ion-header>
    <ion-toolbar>
      <ion-title>Sign up</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ion-grid id="main-grid">

        <!-- <div class="alert alert-danger" *ngIf="error">
            <p>{{ error }}</p>
        </div> -->
		<ion-row id="img-row">
			<ion-col id="img-col">
				<ion-img id='logo-img' [src]="logoWithSubtitle"></ion-img>
			</ion-col>
		</ion-row>
		<ion-row size="10">
            <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
				<ion-grid id='form-grid'>
                <ion-row class="textRow">
                    <ion-label for="text">Tune it display name</ion-label>
                </ion-row>
                <ion-row>
                    <ion-input
						label = ""
                        color="seondary"
                        type="text"
                        class="form-control"
                        ngModel
                        name="signUpDisplayName"
                        required
                        placeholder="...you can change it later"
                    ></ion-input>
                </ion-row>
<!--                 <br>
                <ion-row class="textRow">
                    <ion-label for="number">Sign up code</ion-label>
                </ion-row>
                <ion-row>
                    <ion-input
						label = ""
                        type="string"
                        inputmode="number"
                        class="form-control"
                        ngModel
                        name="signUpCode"
                        required
                    ></ion-input>
                </ion-row>

                <br> -->

			<ion-row id="sign-up-button-row">
				<ion-button
					id="sign-up-button"
					class="themed-ion-button"
					type="submit"
					size="large"
					[disabled]="!authForm.valid"
				>
				Sign up
				</ion-button>
			</ion-row>
			<ion-row id="with-spot-row">
				<p id="with-p">
					with 		
				</p>
				<div id='spot-logo-div'>
					<ion-img id='spot-logo' [src]="spotLogo">
					</ion-img>
				</div>
				<!-- <ion-col id="with-col" class="with-spot-col">
	
				</ion-col>
				<ion-col id='spot-logo-col'class="with-spot-col">
			
				</ion-col> -->
			</ion-row>
			</ion-grid>
		</form>
		</ion-row>
		<ion-row class="description-text">
				Already signed up?
		</ion-row>
	
		<ion-row >
			<p class="description-text">
				Login <a routerLink="/login"> here</a>
			</p>
		</ion-row>
     

    </ion-grid>

</ion-content>
<!-- if user got here by accident then they can get back -->
<app-footer-bar 
	*ngIf="isSignedUp"
	sendDisabled="true">
</app-footer-bar>