<ion-header>
  <ion-toolbar>
    <ion-title>admin</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
<ion-grid>
    <ion-row>
        <ion-button (click)="clearLocalLogs">
            Clear Local Logs
        </ion-button>
    </ion-row>
</ion-grid>
</ion-content>
