<!-- <div id="container-div"> -->
	<ion-toolbar >
		<ion-title>Info</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()" [strong]="true" color="primary">Close</ion-button>
			</ion-buttons>
	</ion-toolbar>
	<!-- <ion-content> -->
	<ion-grid id="main-grid">
		<div *ngIf="devicePlatform==devicePlatformE.android" class="msg-div" >
			{{msgAndroid1}}<ion-icon src="{{androidLogo}}"></ion-icon>
			{{msgAndroid2}}<ion-icon src="{{chromeLogo}}"></ion-icon>
			{{msgAndroid3}}
		</div>
		<div *ngIf="devicePlatform==devicePlatformE.ios" class="msg-div" >
			{{msgIos1}} <ion-icon src="{{appleLogoWhite}}"></ion-icon>
			{{msgIos2}}<ion-icon src="{{safariLogo}}"></ion-icon>
			{{msgIos3}}
		</div>
	
	</ion-grid>
	<!-- </ion-content> -->
<!-- </div>
 -->