import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DevRouterPage } from './dev-router.page';

const routes: Routes = [
  {
    path: '',
    component: DevRouterPage
  }
]; 

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DevRouterPageRoutingModule {}
 