import { FindTuneStateService } from './../services/states/find-tune/find-tune-state.service'
import { GlobalService } from './../services/global.service'
import { MenuController, Platform } from '@ionic/angular'
import { HttpParams, HttpClient } from '@angular/common/http'
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewChildren,
    QueryList,
    AfterViewInit,
} from '@angular/core'
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { ENV } from '../../../environments/environment'
import { ITune } from '../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model'
import { catchError, concatMap } from 'rxjs/operators'
import { FacebookDataService } from '../services/facebook-data/facebook-data.service'
import {
    SearchBarPlaceholdersEnum,
    SegmentStatesEnum,
    SelectStatesEnum,
} from '../classes-enums-interfaces-types/enums/find-tune/enums.enum'

import { MyMetaService } from '../services/my-meta/my-meta.service'
import { NgNavigatorShareService } from 'ng-navigator-share'
import { env } from 'process'
import { SEOService } from '../services/seo/seo.service'
import { CategoryHandlerModule } from './category-handler/category-handler.module'
import { SaveSendAction } from '../components/footer-bar/footer-bar.module'
import { PlayerService } from '../services/music/player.service'
import { ENTER } from '@angular/cdk/keycodes'
import { Keyboard } from '@capacitor/keyboard'
import { SearchBarComponent } from '../components/search-bar/search-bar.component'
import { LoggingLocalService } from '../services/logging-local-service/logging-local-service.service'
import { LoggingLocalRoutingEvents } from '../classes-enums-interfaces-types/enums/enums.enum'
// Potentail leaner code improvements
// Move back forward bar up to search component and use some form of ng-content
// structure to render custom templates/components from child components to
// only have one backforward bar.

@Component({
    selector: 'app-find-tune',
    templateUrl: './find-tune.page.html',
    styleUrls: ['./find-tune.page.scss'],
})
export class FindTunePage implements OnInit, AfterViewInit {
    //@ViewChild("searchField") searchField : IonInput;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private fb: FacebookDataService,
        public findTuneState: FindTuneStateService,
        private myMeta: MyMetaService,
        private seo: SEOService,
        private router: Router,
        public mShare: NgNavigatorShareService,
        public ps: PlayerService,
        private platform: Platform,
        public menuCtrl: MenuController,
        private gS: GlobalService,
        private loggingLocal: LoggingLocalService
    ) {}
    //@ViewChildren(ElementContainerHomeComponent) searchEleContArr: QueryList<SearchElementContainerComponent>;

    saveSendAction = SaveSendAction.send
    segmentButton = 'category-handler'

    serializedPanes: string = ''

    selectedSegmentIndex = 0
    segmentStatesEnum = SegmentStatesEnum
    segmentActive: SegmentStatesEnum = SegmentStatesEnum.search

    segmentContainer

    tunesArray: ITune[] = []

    prevTuneId = ''

    playInitiated = 0
    spotifyServerDelay = 300 + 50

    numberOfTimers = 0
    timerArray = []
    isTuneSelected = false

    playerStateChanged$ = this.ps.playerStateChanged$.asObservable()

    //searchInitText = ENV.PRODUCTION ? '' : '#demoSwe'
    //searchInitText = ENV.PRODUCTION ? '' : 'nightmare'

    segmentChanged$ = this.findTuneState.segmentChanged$.subscribe(
        (segment: SegmentStatesEnum) => {
            this.segmentActive = segment
        }
    )

    searchBarPlaceholder = SearchBarPlaceholdersEnum.searchHere

    readonly separatorKeysCodes: number[] = [ENTER]

    ngOnInit(): void {
        // this.gS.darkLightMode.subscribe<ColorThemeE>(( mode )=> {
        // })
    }

    ngAfterViewInit(): void {
        console.log('Init findTUne:' + ENV.DOMAIN_OF_BACKEND)
        this.ps.selectedTune$.subscribe((selectedTune) => {
            if (selectedTune) this.isTuneSelected = true
        })
        this.myMeta.mAddMeta()
        this.seo.updateCanonicalUrl()
        this.findTuneState.initState()
        //this.router.navigateByUrl(`/find-tune/select`);

        this.platform.backButton.subscribeWithPriority(80, () => {
            this.findTuneState.backClick()
        })

        this.findTuneState.segmentChanged$.subscribe((segment) => {
            if (segment == SegmentStatesEnum.search) {
                this.searchBarPlaceholder = SearchBarPlaceholdersEnum.searchHere
            }
        })
        this.findTuneState.select.stateB$.subscribe((state) => {
            if (state.state == SelectStatesEnum.listSpotPlaylistAll) {
                this.searchBarPlaceholder =
                    SearchBarPlaceholdersEnum.searchPlaylists
            } else {
                this.searchBarPlaceholder = SearchBarPlaceholdersEnum.searchHere
            }
        })
    }

    ionViewWillEnter() {
        //this.searchText = ''
        console.log('x')
    }

    ionViewDidEnter() {
        this.loggingLocal.addLog(LoggingLocalRoutingEvents.ReachedFindTunePage)
        this.gS.setPendingHistoryBackClick(false)
    }

    ionDidLeave() {
        //this.platform.backButton.unsubscribe()
    }

    onsegmentChanged$($event) {
        this.findTuneState.setSegment($event.detail.value)
    }

    logForm() {}

    backClickFind() {
        this.findTuneState.backClick()
    }

    keyDownEvent(event) {
        alert(event.keyCode)
    }

    searchBarSearchEntered(searchText: string) {
        this.findTuneState.searchEntered(searchText)
    }
}
