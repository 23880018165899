
enum SegmentStatesEnum  {
    select='SELECT',
    search='SEARCH',
  } 

enum SelectStatesEnum {
    catOne = 'CATEGORY_ONE',
    catTwo = 'CATEGORY_TWO',
    listTunes = 'LIST_TUNES',
	listSpotPlaylistAll = 'LIST_SPOT_PLAYLISTS_ALL',
	spotPlaylistsSearch = 'LIST_SPOT_PLAYLISTS_SEARCH',
	spotPlaylist = 'SPOT_PLAYLIST',
};  

enum SearchStatesEnum {
    noSearch = 'NO_SEARCH',
	noTunesFound = 'NO_TUNES_FOUND',
	noSpotTracksFound = 'NO_SPOT_TRACKS_FOUND',
    artistsTracksMixed = 'ARTIST_TRACKS_MIX',
    artists = 'ARTISTS',
    tracks = 'TRACKS',
    tunes = 'TUNES'
}; 

export enum SearchHandlerState {

	noSearch = 'NO_SEARCH',

	searched = 'SEARCHED',

}

enum SearchBarPlaceholdersEnum {
	searchHere = 'Search here...',
	searchPlaylists = 'Search playlists'
}

// This is defined as unexported interface in official spot ts
// And needs to be enum to be used with spotTs searchResult type
// Used not to type manual string. Maybe more proper solutions can be done
enum SpotifySearchTypesEnum {

	album= 'album',
	artist= 'artist',
	track= 'track',
	playlist= 'playlist',
	show= 'show',
	episode= 'episode',
	audiobook= 'audiobook',
	
}

export {
	SearchStatesEnum,
	SelectStatesEnum,
	SegmentStatesEnum,
	SearchBarPlaceholdersEnum,
	SpotifySearchTypesEnum
}
    


  
   
    
    