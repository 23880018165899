import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
	SearchBarComponent
  ],
  imports: [
    CommonModule,
	IonicModule,
	FormsModule
  ],
  exports:[
    CommonModule,
    IonicModule,
	SearchBarComponent
  ]
})
export class SharedModule { }
