import { ChatMessageComponent } from './chat-message/chat-message.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ChatPageRoutingModule } from './chat-routing.module';
import { ChatPage } from './chat.page';
import { FooterBarModule } from '../../components/footer-bar/footer-bar.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChatPageRoutingModule,
    FooterBarModule,
  ],
  declarations: [
      ChatPage,
      ChatMessageComponent
    ],

    
})
export class ChatPageModule {}
