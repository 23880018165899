import { SaveOrUpdateTuneError, ToastEnum } from './../classes-enums-interfaces-types/enums/enums.enum';
import { GlobalService } from './../services/global.service';
import { MHttpService } from './../services//helper-functions/mhttp/mhttp.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NativeVolumeControl } from 'native-volume-control-npm-package-name'
import { Component, ErrorHandler, OnInit } from '@angular/core';
import { Tune } from '../classes-enums-interfaces-types/classes/classes';
import { ENV } from '../../../environments/environment'
import { PlayerService } from '../services/music/player.service';
import {mGlobal} from '../mglobal'
import { FcmService } from '../services/fcm/fcm.service';
import { DataService } from '../services/music/data.service';
import { HelperFunctionsService } from '../services/helper-functions/helper-functions.service';
import { Http, HttpResponse } from '@capacitor-community/http';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Browser } from '@capacitor/browser';
import { MatChipInputEvent,MatChipsModule } from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatInputModule} from '@angular/material/input';
import { FindTuneStateService } from '../services/states/find-tune/find-tune-state.service';
import { errAsync, ResultAsync, okAsync } from 'neverthrow';
import { ModalsE, SaveTuneError } from '../classes-enums-interfaces-types/enums/enums.enum';
import { LongPressDirective } from '../directives/long-press/long-press.directive';
import { Animation, AnimationController, IonMenu, MenuController, ModalController, ModalOptions } from '@ionic/angular';
import { AddTuneToListComponent } from '../components/add-tune-to-list/add-tune-to-list.component';
import { LyricsComponent } from '../components/lyrics/lyrics.component';

export interface Fruit {
    name: string;
  }

@Component({
    selector: 'app-dev',
    templateUrl: './dev.page.html',
    styleUrls: ['./dev.page.scss'],
})
export class DevPage implements OnInit {

	startTimeS : string = '3,55,,,,7'


	async testToast(){
		await this.gs.showToast({msg:"testMsg0", duration:15000})
	}

 
	testDecimalChange(){

		let value = this.startTimeS.replace(/,/g, '.');
		// Splitting into two string and replacing all the dots (.'s) in the second string
		let firstOccuranceIndex = value.search(/\./) + 1; // Index of first occurance of (.)
		let resultStr = value.substr(0, firstOccuranceIndex) + value.slice(firstOccuranceIndex).replace(/\./g, '');
		let resultNbr = Number(resultStr)
		
		//#opt will still brake if trying to make something stupid like pasting text

		let startTimeSNum : number
		if(typeof this.startTimeS === 'string'){
			startTimeSNum = Number(this.startTimeS)
			//startTimeSNum = this.startTimeS.replace(',')
			if(startTimeSNum < 0){
				//startTimeS = 0
			}
		}


        //this.startTimeS = Math.round(this.startTimeS*100)/100
        //this.tune.startTime = this.startTimeS * 1000
		
	}

	customBackIcon = '../../assets/arrow-back-outline.svg'
	customMenu = '../../../assets/menu.svg';
    echoRes: string = 'initEcho'
    clickCounter: number = 0
    fetchedVolume: number = 333
    volumeToSet: number = 0
    seekTrackTime: number = 10000
    spotAccessToken: string = undefined
    spotWebSdkVol: any = .5; 
    displayError = 'de'
    airgram
    aAuth
    deviceId
    sdkPlayerName ='devPlayer'
    volume = 0.5
    backendDomainRemote = 'https://tune-it.app'
    backendDomainLocal = ENV.DOMAIN_OF_BACKEND
    backendDomain = this.backendDomainRemote 
    browser
    fruits: Fruit[] = [{name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}];
    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    pressInc = 0
    timeoutHandler
    count: any;

    isTuneSelected: boolean;
	ngIfTestA = [
		{value:"1",show:true},
		{value:"2",show:false},
		{value:"3",show:true}
	]

    testTune1
    testTune2
	toastItr = 0

	lyrics = 'test lyrics'

	animation : Animation
    constructor(
        
        public findTuneState: FindTuneStateService,
        private ps: PlayerService,
        private ds: DataService,
        private fcm : FcmService,
        private hF : HelperFunctionsService,
        private iab: InAppBrowser,
        private http: HttpClient,
        private mhttp: MHttpService,
		private animationCtrl: AnimationController,
		private menuCtrl : MenuController,
		private modalCtrl : ModalController,
		private gs : GlobalService,

    ){}

    ngOnInit() {
        this.ps.selectedTune$.subscribe((selectedTune) => {
            if (selectedTune) this.isTuneSelected = true;
        });
        this.testTune1 = mGlobal.debugTunes[0]
        this.testTune2 = mGlobal.debugTunes[1]
        this.initAsync()
		//this.menuButtonClicked()
		
 
    }

	ionViewDidEnter(){
		this.gs.setPendingHistoryBackClick(false)
	}

	printVisItr(){
		console.log(`visItr: ${this.gs.visibilityChangeVisItr}`)
		console.log(`hiddebnItr: ${this.gs.visibilityChangeHiddenItr}`)
	}

	spotDL1(){
		window.open("https://open.spotify.com/track/3lA6AlyXVUaYF6Sc9pIqjb?si=0d5b35aadff6412b",'_blank')	
	}

	anim(){
		this.animation.play()
	}

	async presentModal(){
		const modal : HTMLIonModalElement = await this.modalCtrl.create({
			component: LyricsComponent,
			breakpoints: [0.7],
			initialBreakpoint: 0.7,
			id: 'DEBUG'
		  });
		  await modal.present();
	}


	ngAfterViewInit(){
		this.animation = this.animationCtrl.create()
		.addElement(document.querySelector('#animTest'))
		.duration(1000)
		.fromTo('opacity', '1', '0.1');
	}

    async initAsync(){
        //const errA = [errAsync(ValidateTuneError.TooShort), errAsync(SaveTuneError.validateTuneError)] 

        /* const resA = await ResultAsync.combineWithAllErrors(errA) // as ResultAsync<Tune,any[]>
        const res1 : any[] = []
        const res2 = resA.mapErr(e=> {
            res1.push(e)
            console.log(e)
        })
        console.log(res2) */

        const testOk = okAsync('this is ok')
        const shouldBeNull = testOk.mapErr(e => {
            return e
        })

        console.log(shouldBeNull)

		//const o : ModalOptions
		const modal = await this.modalCtrl.create({
			component: AddTuneToListComponent,
			componentProps: { 'tune' : this.testTune1 },
			breakpoints:[0.7],
			initialBreakpoint:0.7,
			id:ModalsE.addToTuneList
		  });
		  //modal.present();
	  
		//   const { data, role } = await modal.onWillDismiss();
	  
		//   if (role === 'confirm') {
			
		//   }

    }
	lpItr = 0
    longPress(){
        this.timeoutHandler = setInterval(() => {
            this.pressInc++;
            console.log(this.pressInc)
          }, 300);
    }

	onLongPress(){
		console.log('LP')
		this.lpItr++
	}

	onTap(){
		console.log('Tap')
	}

    endCount(){
        console.log("endCounttt")
        if (this.timeoutHandler) {
         clearTimeout(this.timeoutHandler);
         this.timeoutHandler = null; 
       }
    }

	throwSentryError(){
		console.log('throwSentryError')
		throw new Error("Sentry Test Error");
	}

    getLyrics(){
        const trackName = 'Wind Of Chang'
        const artistName = 'Scorpions'

        const params = new HttpParams()
        .set("trackName",trackName)    
        .set("artistName",artistName)    
        this.http.get(`${ENV.DOMAIN_OF_BACKEND}/get-lyrics`,{params:params}).subscribe(lyrics => {
            console.log(lyrics)
        }, e => {
            console.error("Error getting lyrics",e)
        })
    }

    toggleLoop(n)
    {   
		try{
			let tune
			if(n==1){
				tune = this.testTune1
			} else if(n==2){
				tune = this.testTune2
			}
			this.ps.loopTuneRequest(tune)
		} catch (e) {
			console.error('Error toggle loop dev',e)
		}
    }


      remove(fruit: Fruit): void {
        const index = this.fruits.indexOf(fruit);
    
        if (index >= 0) {
          this.fruits.splice(index, 1);
        }
      }

    testInAppBrowser(){
        const inAppBrowserOptions : InAppBrowserOptions = {
            toolbarposition:'top',
            toolbar:'yes',
            //closebuttoncolor:'#FF0000',
            location:'yes',
            toolbartranslucent:'no'
        }
        const browser = this.iab.create('https://google.com','_inAppBrowser',inAppBrowserOptions);
        browser.show()
    }

    testWindowHref(){
        window.location.href = 'https://google.com'
    }

    injectWebSdk() {
        this.ps.initSpotifyPlayer(this.sdkPlayerName)
        //this.spotWebSdk.injectSpotifyWebSdk(this.sdkPlayerName)
    }

    async getDeviceId() {
        this.deviceId = this.ps.getNvcDeviceId()
		console.log('ps device id',this.deviceId)
    }

    testButtonOneClick() {
        console.log('testt button clicked')
        this.clickCounter++
        this.asyncTestButtonOneClick()
    }

    async asyncTestButtonOneClick() {
        let echoResObj = await NativeVolumeControl.echo({
            value: this.clickCounter.toString() + 1
        })

        this.echoRes = echoResObj.value

        console.log(this.echoRes)
    }

    async setVolumeClick() {
        NativeVolumeControl.setVolume({ volume: this.volumeToSet })
    }

    async decVolumeClick() {
        NativeVolumeControl.decVolume()
    }

    async incVolumeClick() {
        NativeVolumeControl.incVolume()
    } 

    loopTuneClick() {
        this.ps.loopTuneRequest(
            mGlobal.debugTune
        )
    }

    resumeNVC(){
        NativeVolumeControl.resumeTrack()
    }

    async playTimeNVC() {
        NativeVolumeControl.playTrackIdTime(
            { 
                trackId: "71CFZjdSm2WSh5S6TPd69A",
                time:10000
            }
        )
    }

    async playNVC() {
        NativeVolumeControl.playTrackId(
            { trackId: "71CFZjdSm2WSh5S6TPd69A" }
        )
    }

    async pauseNVC(){
        NativeVolumeControl.pauseTrack() 
    }

    async seekTrackAbsolute() {
        NativeVolumeControl.seekTrackAbsolute({
            absoluteTime: this.seekTrackTime
        })
    }

    async playId(id) {
        try{
            await this.ps.playTrackId(id)
        } catch (e){
            console.error(e)
        }
    }

    async playIdAndResume(id) {
        try{
            this.ps.nvcWeb.activatePlayerElement()
            await this.ps.nvcWeb.playTrackIdTime({trackId:"3jG2DVbOteREs74udyVzJG",time:7000})
            //await this.hF.delay(3000)
            await this.ps.nvcWeb.resumeTrack()
            await this.ps.nvcWeb.playTrackIdTime({trackId:id,time:11000})
        } catch (e){
            console.error(e)
        }
    }

    async playIdTime() {
        try{
            await this.ps.nvcWeb.playTrackIdTime({trackId:"3jG2DVbOteREs74udyVzJG",time:7000})
        } catch (e){
            console.error(e)
        }
    }

    constructWebPlayer(){
        try{
            this.ps.constructSpotWebPlayer()
        } catch(e){
            console.error('Error const. web player',e)
        }
    }

    async resume(){
        try{
            await this.ps.nvcWeb.resumeTrack()
        } catch (e){
            console.error(e)
        }
    }

    async pause() {
        try{
            this.ps.pauseTrack()
        } catch (e){
            console.error(e)
        }
    }

    activateElem(){
        try{
            this.ps.nvcWeb.activatePlayerElement()
            console.log('activateElem')
        } catch (e){
            console.error(e)
        }       
    }

    webApiTransferPlayBack() {
        this.ps.nvcWeb.webApiTransferPlayback()
    }

    getVolume() {
        this.ps.getVolume()
    }

    limitVolume(){
        if(this.volume < 0){
            this.volume = 0
        } else if(this.volume > 1){
            this.volume = 1
        }
        console.log(this.volume)
    }

    decVol(){
        this.volume -= .1
        this.limitVolume()
        NativeVolumeControl.setVolume({
            volume:this.volume
        })
    }

    incVol(){
        this.volume += .1
        this.limitVolume()
        NativeVolumeControl.setVolume({
            volume:this.volume
        })
    }

    loop() {
        this.ps.loopTuneRequest(mGlobal.debugTune
            )
    }

    webApiPlay() {
        this.ps.webApiPlay().subscribe(res => {
            console.log(res)
        }, e => {
            console.error('Error web api play', e)
        })
    }

    async requestFcmPushPermission(){
        this.fcm.registerNotifications()
        /*this.fcm.getAfMessaging().requestToken
        .subscribe(
          (token) => { 
            this.displayMsg = token
            alert(token)
          },
          (error) => { 
              console.error(error); 
              alert(error)
              this.displayMsg = error
          },  
        ); */
    }

    requestReturnPushMsgFcm(){
        this.fcm.requestReturnPushMsgFcm() 
    } 

    setCookieBackend(){

        const options = {
            url: `${this.backendDomain}/set-cookies`,
            headers: { 'Content-Type': 'application/json' },
            data: { foo: {
                nestedObj:'nestedObjValue'
            }, cool: true },
        };
        
        Http.post(options).then((res) => {
            console.log('cap http plugin ')
            console.log(res)
        }).catch((e) => {
            console.log('cap http plugin error ')
            console.log(e)
        })
    }

    async getCookies(){
        try{
            const cookies = await Http.getCookies({url:ENV.DOMAIN_OF_BACKEND});
            console.log('gottenCookies')
            console.log(cookies)

        }catch(e:any){
            console.error('Get cookies',e)
        }
    }

    async setCookieHttpPlugin(){
        try{

            const options = {
              url: this.backendDomain,
              key: 'language',
              value: 'en',
            };
          
            await Http.setCookie(options);
          
        } catch(e:any) {
            console.log(e)
        }
    }

    toggleBackend(){
        if(this.backendDomain == this.backendDomainLocal){
            this.backendDomain = this.backendDomainRemote
        } else {
            this.backendDomain = this.backendDomainLocal
        }
    }

    async onlineCheckN(){
        try{
            const data = await Http.get({
                url:ENV.DOMAIN_OF_BACKEND+'/online-check',
                webFetchExtra: {
                    credentials:'include'
                }
            })
            console.log(data)
        } catch(e:any){
            console.error('onlineCheckN',e)
        }
    }

    async onlineCheckWeb(){
        this.http.get(ENV.DOMAIN_OF_BACKEND+'/online-check',{withCredentials:true}).subscribe(res => {
            console.log(res)
        })
    }

    async onlineCheckMyHttp(){
        this.mhttp.get(ENV.DOMAIN_OF_BACKEND+'/online-check').subscribe(res => {
            console.log(res)
        }), e => {
            console.error('mHttp test',e)
        }
    }

    async clearAllCookiesN(){
        await Http.clearAllCookies()
    }
 
    async testSlugTrigger(){ 
    console.log('testing slug') 

        
    /*         const capBrowser = await Browser.open({
                url : 'http://192.168.43.89:8080/test-deeplink'
                //url : 'http://192.168.43.210:8080/test-deeplink'
            })
    */

    }

    testPostM(){
        this.mhttp.post(
            ENV.DOMAIN_OF_BACKEND+'/test-post',
            {testBody:'testBodyValue'}
        ).subscribe(res => {
            console.log(res)
        }, e => {
            console.error('error test post',e)
        })
    }

	async menuButtonClicked() {

		//const toggleRes = await this.menuCtrl.toggle() 
		//const openRes =  await this.menuCtrl.open()
		//console.log(openRes)
		this.gs.requestSideMenuToggle(true)

	}

	installPwa(){
		const dp : any = this.gs.getDeferredPrompt()
		console.log('install pwa')
		if(dp){
			console.log('dp not null')
			dp.prompt().then((choiceResult => {
				console.log('instal prompt clicked')
				console.log(choiceResult)
				this.gs.deferredPrompt = null;
				})
			)
		}
	}

	setIncorrectSpotToken(){
		this.ps.setNvcSpotAccessToken('jfng')
	}

	async getSpotTokenNVC(){
		const spotAccessToken = await this.ps.getNvcSpotAccessToken()
		console.log(`spotAccessTokenNVC: ${spotAccessToken}`)		
	}

	async getSpotDevices(){ 
		const spotDevices = await this.ps.getSpotDevices()
		console.log('SpotDevices:')
		console.log(spotDevices)
	}

	async playTrackIdPs(){
		await this.ps.playTrackId(this.testTune1.slimTuneTrack.trackId)
	}

	showToastError(){
		this.gs.showToast({header:SaveOrUpdateTuneError.validateTuneError,msg: "Tune needs to have a message when it should be suggested",type:ToastEnum.error, duration: 50000})
		this.toastItr++
	}

	async searchPlaylist(){
		const searchStr = "Goa bitar"
		this.ds.search(searchStr,'playlist',0,20).subscribe(res => {
			console.log(res)
		})
	}
	
}
