
<ion-app>
	<ion-menu side=start menuId=main-menu contentId="main-router">
		<ion-header>
		<ion-toolbar>
			<ion-title>Menu</ion-title>
			<ion-buttons slot="end">
				<ion-menu-toggle menu="main-menu">
					<ion-button  fill='clear' size='large'>
						<ion-icon slot='icon-only' [src]='customBackIcon'></ion-icon>
					</ion-button>
				</ion-menu-toggle>
			</ion-buttons>
		</ion-toolbar>
		</ion-header>
		<ion-content scrollY=false class="ion-padding">

		<ion-list lines="inset" id="menuBarList">
			<ion-item shape=round lines="full" id='displayNameItem' >
				<ion-grid id="displayNameGrid">
					<ion-row style="font-size: small;">
						Signed in as
					</ion-row>
					<ion-row style="font-size: x-large;">
						{{displayName}}
					</ion-row>
				</ion-grid>
			</ion-item>
			<ion-item class="listItem" (click)=logoutClicked() detail="true" lines="inset">
				Logout
			</ion-item>
			<ion-item class="listItem" lines="inset" id="settings-item" detail="true">
				Settings
			</ion-item>
			<ion-item class="listItem" (click)=refreshPage() detail="true" lines="inset">
				Refresh page
			</ion-item>

			<ion-item class="listItem" (click)=navKnownShortcomings() detail="true" lines="inset">
				Known Shortcomings
			</ion-item>
			<ion-item id='version-item'>
				<ion-label>
		
					<div id="current-release-container">
						<div>
							Current Version {{deployVersion}}{{preProd}} 1
						</div>
						<div *ngIf="(gs.pwaUpdateAvailable$ | async)?.pwaReadyForInstall" id="circle" ></div>
						
					</div>
					<div *ngIf="(gs.pwaUpdateAvailable$ | async)?.pwaReadyForInstall" id="latest-release-container">
						
						<div class="release-div">
							Latest Version {{availableVersion}}
						</div>
						<div class="release-div">
							Released {{latestVersionReleaseDate}}
						</div>
					</div>
					<p *ngIf="(gs.pwaUpdateAvailable$ | async)?.pwaReadyForInstall" id="update-info-p">
						Refresh page above to update
					</p>

				</ion-label>

			</ion-item>
		</ion-list>
		</ion-content>
	</ion-menu>

    <ion-router-outlet id="main-router"></ion-router-outlet>
    <app-pwa-install-prompt-ios 
		*ngIf="showIosPwaInstallPromptIos"
		style="width:100vw"
		(shouldShow)="pwaPromptCloseClick($event)"
	>
    </app-pwa-install-prompt-ios>
</ion-app>

<ion-modal id="settings-modal" #modal trigger="settings-item">
    <ng-template>
      <div class="wrapper">
		<ion-header>
			<ion-toolbar>
				<ion-title>Settings</ion-title>
				<ion-buttons slot="end">
					<ion-button (click)="closeSettingsModal()">
						Close
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
        <ion-list lines="none">
          <ion-item button="true" detail="false">
            <ion-label style="text-align: start;">Dark Mode (lol)</ion-label>
			<ion-toggle 
				justify="space-between"
				
				checked="{{ darkMode ? true : false }}"
				(ionChange) = "menuDarkModeChange($event)"
			>
			</ion-toggle>
          	</ion-item>
          	<ion-item button="true" detail="false" [disabled]="(hideLyricsAlways == true)">
            <ion-label style="text-align: start;">Show lyrics on first loop</ion-label>
				<ion-toggle 
					justify="space-between"
					checked="{{ showLyricsOnFirstLoop ? true : false }}"
					(ionChange) = "showLyricsOnFirstLoopChange($event)"
				></ion-toggle>
          	</ion-item>
		  	<ion-item button="true" detail="false">
				<ion-label style="text-align: start;">Always hide Lyrics</ion-label>
				<ion-toggle 
					justify="space-between"
					checked="{{ (hideLyricsAlways==true) ? true : false }}"
					(ionChange) = "hideLyricsAlwaysChange($event)"
				></ion-toggle>
			</ion-item>
			<ion-item button="true" detail="false">
				<ion-label style="text-align: start;">Experimental Looping</ion-label>
				<ion-toggle 
					justify="space-between"
					checked="{{ (experimentalLooping==true) ? true : false }}"
					(ionChange) = "experimentalLoopingChange($event)"
				></ion-toggle>
			</ion-item>
            <ion-item id="loop-times-item" lines="none" button="true" detail="false">
				<ion-label slot="start" style="text-align: start;">Loop Times</ion-label>
            
                <ion-input
                id="loop-times-input"
                    step="1"
                    min="{{loopLimit.min ?? 1}}"
                    max="{{loopLimit.max ?? 7}}"
                    [(ngModel)]="loopTimesLimit"
                    slot="end"
                    type="numeric"
                    inputmode="numeric"
                    (change)=loopLimitChanged()
                    (keyup.enter)="gs.closeKeyboard($event)"
                    enterkeyhint="done"
                ></ion-input>
           
			</ion-item>
            <ion-item button="true" detail="false">
				<ion-label style="text-align: start;">Spotify permissions</ion-label>
                <ion-button
                    (click)="moreInfoSpotPrivateLibPermssions()"
                    fill="clear"
                    id="read-private-spot-lists-info"
                    >
                    <ion-icon    slot="icon-only" [src]="infoIcon"></ion-icon>
                </ion-button>
			</ion-item>
            <ion-item *ngIf="displayName=='TheAdmin'" button="true" detail="false">
				<ion-label style="text-align: start;">Admin Page</ion-label>
                <ion-button
                    (click)="adminPageClicked()"
                    >
                </ion-button>
			</ion-item>
        </ion-list>
      </div>
    </ng-template>
  </ion-modal>

  
