import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IonTextarea, ModalController } from '@ionic/angular';
import { ModalsE } from '../../../classes-enums-interfaces-types/enums/enums.enum';

@Component({
  selector: 'app-unsupported-in-app-browser',
  templateUrl: './unsupported-in-app-browser.component.html',
  styleUrls: ['./unsupported-in-app-browser.component.scss'],
})


export class UnsupportedInAppBrowserComponent implements OnInit, AfterViewInit {

  constructor(
	//private renderer: Renderer2,
	
	private changeDetector : ChangeDetectorRef,
	private modalControl : ModalController
	) { 
	
  }
  @ViewChild(IonTextarea) mIonTextArea: IonTextarea;

  nativeTextArea: HTMLTextAreaElement
  
  htmlMsg1 = `Hi 👋
	You seem to be in Facebook's in-app browser which blocks Spotify's web player from playing music.
	To resolve this
  `

  htmlListMsg1P1 = "1) Click"
  htmlListMsg1P2 = "in the upper right corner"
  htmlListMsg2 = `2) Select "Open in <Your browser>" e.g "Open in Chrome"`
  htmlMsg2 = 
  `To fix this permanently, see steps below
   or watch YT video here`

  listReadMore1P1 = "1) Click menu"
  listReadMore1P2 = "in the upper left corner"

  listReadMore2P1 = "2) Click settings"
  listReadMore2P2 = "at the top"
  
  listReadMore3P1 = "3) Scroll down and click "
  listReadMore3P2 = '"Photos & media"'

  listReadMore4P1 = '4) Enable "Open links in external browser"'

  upArrowHead = '../../../assets/up-outline.svg'
  downArrowHead = '../../../assets/down-outline.svg'
  logo = '../../../assets/pixelImages/icon.png'

  messengerMenuLM = '../../../assets/screenshots/menuMessengerLM.png'
  messengerMenuDM = '../../../assets/screenshots/menuMessengerDM.png'
  threeDotsLM = '../../../assets/screenshots/threeDotsLM.png'
  threeDotsSVG = '../../../assets/ellipsis-vertical-circle.svg'
  menuSVG = '../../../assets/menu.svg'

  settings = "../../../assets/settings.svg"
  photosAndMediaPurple = "../../../assets/screenshots/photosAndMediaPurple.png"


  accordExpanded = false

  mainGrid

  ngOnInit() {}

  closeModal(){

	try{
		return this.modalControl.dismiss(null,'cancel',ModalsE.unsupportedInAppBrowser)
	} catch (e){
		console.log(`Error closing modal`,e)
	}
	
  }

  	ngAfterViewInit(){
		this.afterViewInitAsync()
		this.mainGrid = document.getElementById("main-grid");
  	}

	async afterViewInitAsync(){
		// this.nativeTextArea = await this.mIonTextArea.getInputElement()
		// this.renderer.setStyle(this.nativeTextArea, 'line-height', '1.5');
		// this.renderer.setStyle(this.nativeTextArea, 'font-size', 'medium');
		//this.renderer.setStyle(this.nativeTextArea, 'height', '16em');
		this.changeDetector.detectChanges()
	}


  accordionItemToggle(){

	this.accordExpanded = !this.accordExpanded
	this.changeDetector.detectChanges()
	
	if(this.mainGrid){

		this.mainGrid.scrollTo({
			top: this.mainGrid.scrollHeight,
			behavior: 'smooth' // Smoothly scroll to the bottom
		});
	}
  }

}
