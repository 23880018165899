import { ReadPrivateSpotListsInfoComponent } from './components/modals/read-private-spot-lists-info/read-private-spot-lists-info.component'
import { err } from 'neverthrow'

import { AuthService } from './services/auth/auth.service'
import { GlobalService } from './services/global.service'
import { PlayerService } from './services/music/player.service'
import {
    Keyboard,
    KeyboardResizeOptions,
    KeyboardResize,
} from '@capacitor/keyboard'
import { MyMetaService } from './services/my-meta/my-meta.service'
import {
    Component,
    HostListener,
    Inject,
    NgZone,
    Renderer2,
    ChangeDetectorRef,
    AfterViewInit,
    OnInit,
} from '@angular/core'
import { HttpClient } from '@angular/common/http' // Needs to be imported in evety class but only one object is used.
import {
    MenuController,
    ModalController,
    Platform,
    ToggleChangeEventDetail,
} from '@ionic/angular'
import { ListElementComponent } from './components/list-element/list-element.component'
import { Router } from '@angular/router'
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app'
import { Tune } from './classes-enums-interfaces-types/classes/classes'
import { mGlobal } from './mglobal'
import { FcmService } from './services/fcm/fcm.service'
import { ENV } from '../../environments/environment'
import { DOCUMENT, Location, NgSwitchDefault } from '@angular/common'
//import { Router } from '@angular/router';\
import * as _ from 'lodash'
import { NativeVolumeControl } from 'native-volume-control-npm-package-name'
import { BehaviorSubject, Subject, timeInterval } from 'rxjs'
import { AppStateService } from './services/app-state.service'
import { CookieService } from 'ngx-cookie-service'
import {
    BrowsersE,
    ColorThemeE,
    DevicePlatformE,
    ModalsE,
    ToastEnum,
    VersionUpdateEventsE,
} from './classes-enums-interfaces-types/enums/enums.enum'
import { Http } from '@capacitor-community/http'
import {
    SwUpdate,
    VersionEvent,
    VersionDetectedEvent,
    VersionReadyEvent,
} from '@angular/service-worker'
import {
    ListElementTypesEnum,
    SpotExtraPermissions,
} from '../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum'
import { mCanActivateService } from './services/m-can-activate.service'
import { MyServerService } from './services/my-server/my-server.service'
//';
import { enableProdMode } from '@angular/core'

import { ErrorHandlerService } from './services/error-handler/error-handler.service'
import {
    MAppData,
    UserStoredSettings,
} from './classes-enums-interfaces-types/interfaces/interfaces'
import { Meta } from '@angular/platform-browser'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'tune-it'
    spotifyRedirectUriIos = 'commusicmagictuneit'
    androidRedirectUri = 'app://tune_it'
    loginRedirect = 'login-frontend'
    appIsActive$: Subject<void> = new Subject<void>()
    showIosPwaInstallPromptIos: boolean = false
    customBackIcon = '../assets/arrow-back-outline.svg'
    infoIcon = '../assets/information-circle-outline.svg'
    darkMode: boolean = true
    showLyricsOnFirstLoop: boolean = true
    hideLyricsAlways: boolean = false
    deferredPrompt: any
    deployVersion = `${ENV.DEPLOY_VERSION_MAJOR}.${ENV.DEPLOY_VERSION_MINOR}`
    availableVersion: string
    hiddenToVisPending: boolean = false
    latestVersionReleaseDate: string
    experimentalLooping: boolean
    preProd: string = ENV.PRE_PROD ? '_PP' : ''
    readPrivateSpotLists: boolean
    loopLimit: {
        max: number
        min: number
        default: number
    }
    loopTimesLimit: number

    constructor(
        private renderer: Renderer2,
        private http: HttpClient,
        private myMeta: MyMetaService,
        private router: Router,
        private zone: NgZone,
        private fcm: FcmService,
        private location: Location,
        private appStateService: AppStateService,
        private cookieService: CookieService,
        private ps: PlayerService,
        public gs: GlobalService,
        private auth: AuthService,
        private menuCtrl: MenuController,
        private readonly swUpdate: SwUpdate,
        @Inject(DOCUMENT) private document: Document,
        private changeDetector: ChangeDetectorRef,
        private mCanActivate: mCanActivateService,
        private eh: ErrorHandlerService,
        private meta: Meta,
        private modalCtrl: ModalController,
        private mServer: MyServerService
    ) {
        this.initializeApp()
    }

    displayName: string
    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        console.log('onbeforeinstallprompt')
        console.log(e)
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        //e.preventDefault();
        // Stash the event so it can be triggered later.
        //this.gS.setDeferredPrompt(e)
    }

    /* ngOnInit() {

    } */

  

    ngOnInit() {
        console.log('app comp init')
        //setTimeout(() => this.gs.setPwaUpdateAvailable({pwaReadyForInstall:fa}),5000)

        this.meta.updateTag({ property: 'og:title', content: 'Tune it' })
        this.meta.updateTag({
            property: 'og:description',
            content: 'Music GIFs',
        })
        this.meta.updateTag({
            property: 'og:image',
            content: ENV.DOMAIN_OF_BACKEND + '/logo/subtitle',
        })
        this.meta.updateTag({ property: 'og:image:width', content: '1275' })
        this.meta.updateTag({ property: 'og:image:height', content: '1275' })
        this.meta.updateTag({
            property: 'og:url',
            content: ENV.DOMAIN_OF_FRONTEND,
        })
        this.meta.updateTag({ property: 'og:type', content: 'website' })

        this.loopLimit = mGlobal.loopLimit

        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                artwork: [
                    {
                        src: `https://${ENV.DOMAIN_OF_BACKEND}/icon?resolution=96x96`,
                        sizes: `72x72`,
                        type: `image/png`,
                    },

                    {
                        src: `https://${ENV.DOMAIN_OF_BACKEND}/icon?resolution=96x96`,
                        sizes: `96x96`,
                        type: `image/png`,
                    },
                    {
                        src: `https://${ENV.DOMAIN_OF_BACKEND}/icon?resolution=128x128`,
                        sizes: `144x144`,
                        type: `image/png`,
                    },
                    {
                        src: `https://${ENV.DOMAIN_OF_BACKEND}/icon?resolution=192x192`,
                        sizes: `192x192`,
                        type: `image/png`,
                    },
                    // {
                    //   src: `https://${ENV.DOMAIN_OF_BACKEND}/icon?resolution=256x256`,
                    //   sizes: `256x256`,
                    //   type: `image/png`,
                    // },
                    // {
                    //   src: `https://${ENV.DOMAIN_OF_BACKEND}/icon?resolution=384x384`,
                    //   sizes: `384x384`,
                    //   type: `image/png`,
                    // },
                    {
                        src: `https://${ENV.DOMAIN_OF_BACKEND}/icon?resolution=512x512`,
                        sizes: `512x512`,
                        type: `image/png`,
                    },
                ],
            })
        }

        // this.spotWebSdk.injectSpotifyWebSdk()
       
        //this.fcm.initFcm()
        //this.showThemePickerToast()
        //this.menuCtrl.open()
        this.gs.sideMenuToggle$.subscribe(async (data) => {
            let menuId = data.menuId ?? 'main-menu'
            let openRes
            if (data.open) {
                openRes = await this.menuCtrl.open(menuId)
            } else {
                openRes = await this.menuCtrl.close()
            }
        })



        this.swUpdate.versionUpdates.subscribe(
            (vE: VersionEvent) => {
                console.log('swUpdate sub.')
                switch (vE.type) {
                    case VersionUpdateEventsE.versionUpdateDetected:
                        console.log(`An PWA update was found on the server`)
                        console.log(`Hash ${JSON.stringify(vE.version)}`)

                        break
                    // imp ng14 update
                    // case VersionUpdateEventsE.noVersionUpdateDetected :
                    // 	console.log(`No PWA update was found on the server`)
                    // 	break
                    case VersionUpdateEventsE.versionReady:
                        const vEReady: VersionReadyEvent = vE
                        console.log(
                            `Current ng version: ${JSON.stringify(
                                vE.currentVersion
                            )}`
                        )
                        console.log(
                            `Most recent ng version: ${JSON.stringify(
                                vE.latestVersion
                            )}`
                        )

                        const mAppData: MAppData = vEReady.latestVersion
                            ?.appData as MAppData
                        this.availableVersion = `${mAppData?.mVersionMajor}.${mAppData?.mVersionMinor}`
                        this.latestVersionReleaseDate = mAppData?.releaseDate

                        this.gs.setPwaUpdateAvailable({
                            pwaReadyForInstall: true,
                            mVersion: this.availableVersion,
                            versionReleaseDate: this.latestVersionReleaseDate,
                        })
                        break
                    case VersionUpdateEventsE.versionFailedInstall:
                        console.error('Failed to install PWA update', vE.error)
                        //this.eh.logSentryError(vE.error)
                        break
                }
            },
            (e) => {
                console.error('swUpdate error', e)
                this.eh.logSentryError(e)
            }
        )

        this.gs.userSettings$.subscribe((settings) => {
            if (settings) {
                this.darkMode =
                    settings.prefersColorTheme == ColorThemeE.light
                        ? false
                        : true

                this.showLyricsOnFirstLoop = settings.showLyricsOnFirstLoop
                this.experimentalLooping = settings.experimentalLooping
                this.changeDetector.detectChanges()
            }
        })

        this.gs.showPwaPrompt$.subscribe(
            (showInfo) => {
                if (showInfo?.show) {
                    if (showInfo?.devicePlatform == DevicePlatformE.ios) {
                        this.showIosPwaInstallPromptIos = true
                        this.changeDetector.detectChanges()
                    } else if (
                        showInfo.devicePlatform == DevicePlatformE.android
                    ) {
                    }
                }
            },
            (e) => {
                console.error('Error show Pwa prompt', e)
                this.eh.logSentryError(e)
            }
        )

        document.onvisibilitychange = (event) => {
            console.log(`visibility state changed: ${document.visibilityState}`)
            if (document.visibilityState == 'visible') {
                this.gs.visibilityChangeVisItr++
                if (this.hiddenToVisPending) {
                    this.hiddenToVisPending = false
                    const currentPath = this.router.url

                    if (!this.ps.isCurrentSpotTokenValid()) {
                        this.ps.setWebSdkPlayerNeedConstruction(true)
                        this.auth.login({ redirectPath: currentPath })
                    } else if (
                        this.gs.getDevicePlatform() == DevicePlatformE.ios
                    ) {
                        // 2 seperate problems
                        // 53 from web socket
                        // -> noDevices needs restart of player
                        // no auto play
                        // activateElem -> ok
                        this.ps.setWebSdkPlayerNeedConstruction(true)
                        this.ps.constructSpotWebPlayer()
                    }
                }
            }

            if (document.visibilityState == 'hidden') {
                this.gs.visibilityChangeHiddenItr++
                this.hiddenToVisPending = true
            }
        }

        this.updateGlobalStyles()

        this.readPrivateSpotLists = this.gs.getReadPrivateSpotLists()

        this.gs.userSettings$.subscribe((settings: UserStoredSettings) => {
            if(settings){
                this.loopTimesLimit = settings?.loopLimit
            }
        })

        console.log('')
    }

    ngAfterViewInit() {

        this.mCanActivate.freshSpotTokenFetched$.subscribe(
            (data: { displayName: string }) => {
                if(data.displayName){ // should be data check but only 1 field for now
                    this.displayName = data.displayName
                }
            }
        )
        this.initDebugTunes()
        

    }

    loopLimitChanged() {
        this.gs.setLoopLimit(Number(this.loopTimesLimit))
    }

    updateGlobalStyles() {
        const root = document.documentElement
        /* if (this.gs.isRunningIos()) {
			document.documentElement.style.setProperty( '--footer-bar-height', '12dvh');
		  //this.renderer.setStyle(root, '--footer-bar-height', '12dvh');
		}  */
    }

    initSentry() {}

    /* async showThemePickerToast() {
				const toast = await this.toastController.create({
				message: 'What color theme do you prefer?',
				duration: 150000,
				cssClass:'theme-picker-toast',
				buttons: [
					{
						text: 'Dark',
						role: 'info',
						handler: () => { 
							this.setAndStoreColorTheme(ColorTheme.dark)
						}
					},
					{
						text: 'Light', 
						role: 'info',
						handler: () => { 
							this.setAndStoreColorTheme(ColorTheme.light)
						}
					}
				]
				});
				await toast.present();
			} */

    async initializeApp() {
        this.initSentry()
        // Only works in fullscreen aka PWA for Chrome Android 2301
        // Setting also added to manifest file
       /*  if (this.gs.getDevicePlatform() == DevicePlatformE.android) {
            screen.orientation
                .lock('portrait')
                .then((res) => {
                    console.log(`Result locking screen ${res}`)
                })
                .catch((e) => {
                    console.error(`Error locking screen,`, e)
                    //this.eh.logSentryError(e)
                })
        } */

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                console.log('appOpen')
                console.log(event.url)
                let slug

                //comes from spotify redirect ios after playUriAndAuth
                // Not used, used for testing with customUriPath
                if (event.url.includes(this.spotifyRedirectUriIos)) {
                    const url = new URL(event.url)
                    const hash = url.hash.replace('#', '')

                    const result: any = {}
                    hash.split('&').forEach((item) => {
                        result[item.split('=')[0]] = decodeURIComponent(
                            item.split('=')[1]
                        )
                    })
                    if (result?.access_token) {
                        NativeVolumeControl.handleUrlOpen({
                            accessToken: result.access_token,
                        })
                    }
                } else if (event.url.includes('spotTokens')) {
                    const url = new URL(event.url)
                    const hash = new URLSearchParams(url.hash.replace('#', ''))
                    const spotTokens = decodeURIComponent(
                        hash.get('spotTokens') || ''
                    )
                    const connectSid = decodeURIComponent(
                        hash.get('connectSid') || ''
                    )

                    const setCookies = async () => {
                        try {
                            if (spotTokens) {
                                await Http.setCookie({
                                    url: ENV.DOMAIN_OF_BACKEND,
                                    key: 'spotTokens',
                                    value: spotTokens,
                                })
                                this.ps.setSpotAccessToken(spotTokens)
                            }

                            if (connectSid) {
                                await Http.setCookie({
                                    url: ENV.DOMAIN_OF_BACKEND,
                                    key: 'connect.sid',
                                    value: connectSid,
                                })
                            }
                        } catch (e) {
                            console.error('Setting cookies from BE', e)
                            this.eh.logSentryError(e)
                        }
                    }
                    /* 
                    const setCookies = async () => {

                        try{
                            const cookies : any = {}
                            if(spotTokens){
                                await Preferences.set({
                                    key:'spotTokens',
                                    value:spotTokens
                                })
                                cookies.spotTokens = spotTokens
                            }

                            if(connectSid){
                                cookies['connect.sid'] = connectSid
                            }

                            await Http.post({
                                headers: { 'Content-Type': 'application/json' },
                                data : cookies,
                                url : ENV.DOMAIN_OF_BACKEND+'/set-cookies',
                            })
                        } catch(e) {
                            console.error('Error setting Cookies with post',e)
                        }
                    } */

                    setCookies()
                } else if (event.url.includes(this.loginRedirect)) {
                    this.cookieService.deleteAll()
                    this.router.navigateByUrl('/login')
                } else if (event.url.includes(this.androidRedirectUri)) {
                    slug = event.url.split('tune_it').pop()
                } else {
                    if (ENV.PRODUCTION) {
                        slug = event.url.split('.app').pop()
                    } else {
                        if (event.url.includes(':8100'))
                            slug = event.url.split(':8100').pop()
                    }
                    console.log(`Checking slug`)
                }
                if (slug) {
                    console.log(`slug: ${slug}`)
                    this.router.navigateByUrl(slug)
                }
            })
        })

        App.addListener('appStateChange', (event: AppState) => {
            const isActive = event.isActive
            if (!isActive) {
                this.appStateService.appStateActiveChange(isActive)
            }
        })

        if (this.gs.isNativeCapPlatform()) {
            const resizeOption: KeyboardResizeOptions = {
                mode: KeyboardResize.Ionic,
            }
            Keyboard.setResizeMode(resizeOption)

            Keyboard.addListener('keyboardDidHide', () => {
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                }
            })
        }

        if (!ENV.PRODUCTION) {
            const devicePlatform = this.gs.getDevicePlatform()

            /*if( devicePlatform == DevicePlatformE.android && false){
			if(!this.gs.isRunningInBrowserName(BrowsersE.chrome)){
				const modal = await this.modalCtrl.create({
					component: NotRecommendedBrowserComponent,
					//cssClass: '.rename-tune-list-modal',
					id: ModalsE.notRecommendedBrowser,
					componentProps: {
						devicePlatform : DevicePlatformE.android
					},
					cssClass: 'not-supported-browser-modal'
				  });

				  modal.present()
			}
		} else if ( devicePlatform == DevicePlatformE.ios){
			if(!this.gs.isRunningInBrowserName(BrowsersE.safari)){
				const modal = await this.modalCtrl.create({
					component: NotRecommendedBrowserComponent,
					//cssClass: '.rename-tune-list-modal',
					id: ModalsE.notRecommendedBrowser,
					componentProps: {
						devicePlatform : DevicePlatformE.ios
					},
					cssClass: 'not-supported-browser-modal'
				  });

				modal.present()
			}
		} */
        }
    }

    ngOnDestroy() {
        this.ps.disconnectPlayer()
    }

    initDebugTunes() {
        const debugTune1 = new Tune()
        debugTune1._id = '3'
        debugTune1.slimTuneTrack = {
            artistName: 'Nicki Minaj',
            durationMs: 339506,
            trackId: '1TZv3bujNaYz646eezRE91',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e026d8b9f3e7337f6bff76ceff6',
            trackName: 'Seeing Green (with Drake & Lil Wayne)',
        }

        debugTune1.startTime = 10000
        debugTune1.stopTime = 15000
        debugTune1.tags = [
            {
                tag: null,
                name: 'tag1',
                canDelete: false,
            },
            {
                tag: null,
                name: 'tag2',
                canDelete: false,
            },
            {
                tag: null,
                name: 'tag3',
                canDelete: false,
            },
            {
                tag: null,
                name: 'tag4',
                canDelete: false,
            },
        ]

        const debugTune2 = new Tune()

        debugTune2.tuneTrack = '617d6d8df5d96846743cbee3'
        debugTune2.slimTuneTrack = {
            artistName: 'Eminem',
            durationMs: 272417,
            trackId: '3stOygN0I7CIvkEB2LJGbv',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e02cb66bcc14c6f857c127d5969',
            trackName: 'Survival',
        }
        debugTune2.startTime = 11000
        debugTune2.stopTime = 21000
        debugTune2.tags = null /*  [
            {
                name:'enTag2',
                tag:null
            }
        ]
        */
        const debugTune3 = new Tune()

        debugTune3.slimTuneTrack = {
            artistName: 'Carola',
            durationMs: 245333,
            trackId: '4eJKkCCcp1ibRtNt50Y8J1',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e025764448ce3004baea1761910',
            trackName: 'På egna ben',
        }
        debugTune3.startTime = 11000
        debugTune3.stopTime = 16000
        debugTune3.tags = [
            {
                tag: null,
                name: 'tag1',
                canDelete: false,
            },
        ]

        const debugTune4 = new Tune()

        debugTune4.slimTuneTrack = {
            artistName: 'Eminem',
            durationMs: 297786,
            //trackId: "4xkOaSrkexMciUUogZKVTS",
            trackId: 'nonSenseTrackId',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e026ca5c90113b30c3c43ffb8f4',
            trackName: "'Till I Collapse",
        }
        debugTune4.startTime = 12000
        debugTune4.stopTime = 16000
        debugTune4.tags = [
            {
                tag: null,
                name: 'tag1',
                canDelete: false,
            },
            {
                tag: null,
                name: 'tag1',
                canDelete: false,
            },
        ]

        const debugTune5 = new Tune()

        debugTune5.slimTuneTrack = {
            artistName: 'The Pointer Sisters',
            durationMs: 230640,
            trackId: '1ot6jEe4w4hYnsOPjd3xKQ',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e024650ca0a8f88129d4667acc5',
            trackName: "I'm So Excited",
        }

        const debugTune6 = new Tune()

        debugTune6.slimTuneTrack = {
            artistName: 'Bom Chack',
            durationMs: 230640,
            trackId: '47xLbuUdmD03ATrke934S1',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e024650ca0a8f88129d4667acc5',
            trackName: 'Freestyler',
        }
        debugTune6.startTime = 45000
        debugTune6.stopTime = 55000

        const debugTune7 = new Tune()

        debugTune7.slimTuneTrack = {
            artistName: 'Odyssey',
            durationMs: 230640,
            trackId: '1a4JG31yqWFwsFccPIxAYO',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e0227b546ab65d835f1726291ff',
            trackName: 'Roots',
        }
        debugTune7.startTime = 43648
        debugTune7.stopTime = 57662

        const debugTune8 = new Tune()
        debugTune8._id = '61e0743088761d4d54a1c62d'
        debugTune8.type = ListElementTypesEnum.tune
        debugTune8.slimTuneTrack = {
            artistName: 'Scorpions',
            durationMs: 312360,
            trackId: '3ovjw5HZZv43SxTwApooCM',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e02787674b6a114f98cad6f834b',
            trackName: 'Wind Of Chang',
        }
        debugTune8.startTime = 43648
        debugTune8.stopTime = 48662
        _.set(debugTune8, 'lyrics.lyricsStr', 'This is the wind of change')
        debugTune8.tags = [
            {
                tag: null,
                name: 'tag1',
                canDelete: false,
            },
            {
                tag: null,
                name: 'tag1',
                canDelete: false,
            },
        ]
        _.set(debugTune8, 'triggers[0].triggerSentence', 'a')
        _.set(debugTune8, 'triggers[0].autoCompletes[0].autoComplete', 'b')

        /*       this.router.navigateByUrl('/find-tune') //Hack, ionic ass */
        /*       this.myMeta.mAddMeta() */

        mGlobal.debugTune = debugTune8
        mGlobal.debugTunes = [debugTune8, debugTune4]
    }

    pwaPromptCloseClick(shouldShow: boolean) {
        this.showIosPwaInstallPromptIos = shouldShow
    }

    menuDarkModeChange(e) {
        // Need .detail apperently
        const toggleE: ToggleChangeEventDetail = e.detail
        this.darkMode = toggleE.checked
        this.gs.setDarkLightMode(
            // darkmode on null and undefined
            this.darkMode === false ? ColorThemeE.light : ColorThemeE.dark
        )
    }

    showLyricsOnFirstLoopChange(e) {
        // Need .detail apperently
        const toggleE: ToggleChangeEventDetail = e.detail
        this.showLyricsOnFirstLoop = toggleE.checked
        this.gs.setShowLyricsOnFirstLoop(toggleE.checked)
    }

    hideLyricsAlwaysChange(e) {
        // Need .detail apperently
        const toggleE: ToggleChangeEventDetail = e.detail
        this.hideLyricsAlways = toggleE.checked
        this.gs.setHideLyricsAlways(toggleE.checked)
    }

    experimentalLoopingChange(e) {
        const toggleE: ToggleChangeEventDetail = e.detail
        this.experimentalLooping = toggleE.checked
        this.gs.setExperimentalLooping(toggleE.checked)
    }

    readPrivateSpotListChange(e) {
        const toggleE: ToggleChangeEventDetail = e.detail

        e.target.checked = this.readPrivateSpotLists

        if (toggleE.checked) {
            this.auth.login({
                redirictPath: '/category-handler',
                spotExtraPermissions: [
                    SpotExtraPermissions.userLibraryRead,
                    SpotExtraPermissions.playlistReadPrivate,
                    SpotExtraPermissions.playlistReadCollaborative,
                ],
            })
        } else {
            this.mServer.revokeSpotPermissionsRedirect()
        }
    }

    revokeSpotPrivateLibPermssions() {}

    async moreInfoSpotPrivateLibPermssions() {
        const modal = await this.modalCtrl.create({
            component: ReadPrivateSpotListsInfoComponent,
            id: ModalsE.readPrivateSpotListsInfo,
            cssClass: 'ion-modal-default',
        })
        modal.present()

        const { data, role } = await modal.onWillDismiss()

        if (role === 'grant') {
            this.auth.login({
                redirictPath: '/category-handler',
                spotExtraPermissions: [
                    SpotExtraPermissions.userLibraryRead,
                    SpotExtraPermissions.playlistReadPrivate,
                    SpotExtraPermissions.playlistReadCollaborative,
                ],
            })
        } else if (role === 'revoke') {
            this.mServer.revokeSpotPermissionsRedirect()
        }
    }

    logoutClicked() {
        this.auth.logout()
        this.menuCtrl.close()
    }

    refreshPage() {
        window.location.reload()
    }

    permissions() {}

    navKnownShortcomings() {
        this.router.navigateByUrl('known-shortcomings')
        this.menuCtrl.close()
    }

    checkPendingCachedFeError() {
        const lsErrors = localStorage.getItem('pendingFeErrorsToLog')
        if (lsErrors) {
        }
    }

    settingModalOpenRequestClicked() {}

    closeSettingsModal() {
        this.modalCtrl.dismiss(null, 'cancel', 'settings-modal')
    }

    adminPageClicked(){
        this.router.navigateByUrl('/admin')
    }
}
