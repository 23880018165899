import { CategoryHandlerRoutingModule } from './category-handler-routing.module';
import { SharedModule } from './../../shared/shared.module';
import { ListElementModule } from '../../components/list-element/list-element.module';
import { NgModule } from '@angular/core';
import { CategoryHandlerComponent } from './category-handler.component';
 
@NgModule({
  imports: [
    SharedModule,
    ListElementModule,
    CategoryHandlerRoutingModule
 
  ], 
  declarations: [CategoryHandlerComponent],
  exports:[CategoryHandlerComponent]
})
export class CategoryHandlerModule { }
 