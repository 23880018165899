<!-- This is already in an grid col -->
<ion-grid
    #swipeArea
    id='selected-track-grid'>
	<app-powered-by-row></app-powered-by-row>
    <ion-row 
    	id='selected-track-row'
		(click)="onTap()"	
	>
	<ion-col id='selected-track-col'>
			<!--long-press (press)="onLongPress()"
			long-press (tap)="onTap()" -->
            <ion-item id="selected-track-item">
				<ion-thumbnail slot='start'>
					<ion-img [src]='selectedGuiElem.trackImg'>
					</ion-img>
					</ion-thumbnail>
				<div id='nameContainer'>
					<div class='div-container-top' >
						<div id='dummy-div'></div>
						<div 
							class='text-div'
							id='text-div-top'>
							{{selectedGuiElem.trackName}}
						</div>
					</div>
					<div class='text-div'>
						{{selectedGuiElem.artistName}}
					</div>
				</div>
				<ion-button
					(click)='toggleLoopClick($event)' 
					id='toggle-play-button'
					class="ignore-long-press-directive"
					>
					<ion-icon 
					slot='icon-only'
					[src]='toggleLoopIcon'
					class='toggle-play-icon '
					>
					</ion-icon>
				</ion-button>
            </ion-item>
        </ion-col>
    </ion-row>  
<ion-grid>

	<ion-popover 
	side="end"
	trigger="selected-track-grid"
	trigger-action="context-menu"
	dismiss-on-select="true"
	show-backdrop="true"
	alignment="center"
	
	>
	<ng-template id="pop-over-selected-track">
		<ion-list class="pop-over-list">
			<ion-item *ngIf="(selectedTune?.createdBy?.spotId == spotId)"
				class="pop-over-item"
				[button]="true"
				(click)="popEditClick()"
			>
				Edit Tune
			</ion-item>
			<ion-item
				*ngIf="saveTunePopOver"
				(click)="popSaveClick()"
				[button]="true"
				class="pop-over-item">
					Save Tune
			</ion-item>
			<ion-item
				[button]="true"
				(click)="popAddToListClick()"
				class="pop-over-item">
				Add to list
			</ion-item>
			<ion-item
			(click)="popSpotClick()"
			class="pop-over-item"
			lines="none">
				Open Spotify
			</ion-item>
		</ion-list>
	</ng-template>
</ion-popover>
