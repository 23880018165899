
<ion-header slot="fixed">
	<ion-toolbar>
	  <ion-buttons slot="start">
		<ion-button color="medium" (click)="cancelAtTL()">Cancel</ion-button>
	  </ion-buttons>
	  <ion-title>Add Tune to list</ion-title>
	</ion-toolbar>
</ion-header>
<!-- <ion-content id="main-contentt"> -->
	<ion-grid style="padding-top: 3dvh;">
		<ion-row>
			<ion-button id="open-modal" class="themed-ion-button">New Tune List</ion-button>
		</ion-row>
		<ng-container *ngIf="(!tuneLists || tuneLists?.length == 0) && tuneListsLoaded; then noTuneListTemplate else tuneListTemplate">
		</ng-container>
	</ion-grid>
<!-- </ion-content> -->

<ion-modal 
	id="{{modalsE.createTuneList}}" 
	#modal 
	trigger="open-modal"
>
	<ng-template>
		<ion-toolbar >
			<ion-buttons slot="start">
				<ion-button color="medium" (click)="cancelCTL()">Cancel</ion-button>
			</ion-buttons>
			<ion-title>List name</ion-title>
			<ion-buttons slot="end">
				<ion-button (click)="confirmCTL()" [strong]="true" color="primary">Done</ion-button>
				</ion-buttons>
			</ion-toolbar>
		<ion-grid id="list-name-grid">
		<ion-row>
			<ion-item id="list-name-item">
				<ion-input  
					type="text"
					[(ngModel)]="newListName" >
				</ion-input>			
			</ion-item>
		</ion-row>
		</ion-grid>
	</ng-template>
</ion-modal>


<ng-template #noTuneListTemplate>
	<ion-row style="font-size:5vw; margin-top: 3dvh;" >
		You have no Tune lists yet :(
	</ion-row>
</ng-template>

<ng-template #tuneListTemplate>
	<ion-row>
	<ion-content (touchmove)="onScrollStart($event)" id="tune-list-content" >
		<!-- 		<cdk-virtual-scroll-viewport itemSize="56" minBufferPx="900" maxBufferPx="1350"> -->
			<!-- 	<ion-col id="tune-list-content-col">  -->
					<ion-list id="tune-list-ion-list" lines="full">
						<ion-item lines=full class=tune-list-item *ngFor="let tuneList of tuneLists"
							(click)="tuneListClicked(tuneList)">
							{{tuneList.name}}
						</ion-item>
					</ion-list>	
					<ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
						<ion-infinite-scroll-content></ion-infinite-scroll-content>
					</ion-infinite-scroll>
			<!-- 	</ion-col> -->
		<!-- 		</cdk-virtual-scroll-viewport> -->
			</ion-content>
		</ion-row>
</ng-template>
