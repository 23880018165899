import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ListElementComponent} from './list-element.component'
import { LongPressDirective } from '../../directives/long-press/long-press.directive';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
	DirectivesModule

  ],
  declarations: [
    ListElementComponent
    ],

    exports:[
      ListElementComponent 
    ]

})
export class ListElementModule { }
  