
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoweredByRowComponent } from './powered-by-row.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
	IonicModule
  ],
  declarations: [ PoweredByRowComponent ],
  exports: [ PoweredByRowComponent ],
})
export class PoweredByRowModule {}
