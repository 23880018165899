import { SaveTuneError, UpdateTuneError } from './../../classes-enums-interfaces-types/enums/enums.enum';
import { Message } from './../../classes-enums-interfaces-types/classes/classes';
import { MHttpReturn, MHttpService } from './../helper-functions/mhttp/mhttp.service';
import { Capacitor } from '@capacitor/core';
import { HelperFunctionsService } from './../helper-functions/helper-functions.service';
import { AuthAccessToken } from './../auth/auth.service';
import { Tune } from '../../classes-enums-interfaces-types/classes/classes';
import {TuneTrackV1} from '../../classes-enums-interfaces-types/classes/classes'
import { Injectable } from '@angular/core';
import { ENV } from '../../../../environments/environment';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { async, Observable } from 'rxjs';
import { PlayerService } from '../music/player.service';
import { Http, HttpParams as CHttpParams } from '@capacitor-community/http';
import { E } from '@angular/cdk/keycodes';
import { errAsync, fromPromise, okAsync, ResultAsync } from 'neverthrow';
import { TuneWithLyrics } from '../../classes-enums-interfaces-types/interfaces/interfaces';
import { ToastController } from '@ionic/angular';
import { toastController } from '@ionic/core';
import { ITriggerFE, ITuneList, ITuneListPop, ITuneListByNumberResult, UsersTuneListM, ITuneListPopLean } from '../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { GlobalService } from '../global.service';
import { SpotExtraPermissions } from '../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum';


@Injectable({
  providedIn: 'root'
})

export class MyServerService {

    backendRedirectPath = 'backend-redirect-iab'
    browsers : any[] = []

    constructor(
        private ps : PlayerService,
        private http: HttpClient,
        private mhttp : MHttpService,
		private toast : ToastController,
		private eh : ErrorHandlerService,
		private gs : GlobalService
    ) { }
 
    getCategories(){
        return this.http.get(
            ENV.DOMAIN_OF_BACKEND + "/get-categories"
        )
    }

    getSlimTunesInCat(catParent : any, catChild : any, fetchOffset:number, fetchLimit:number) : Observable<Object>{

		// create httpParams from object below

        const params : HttpParams = new HttpParams()
		.set("catParent", catParent)
		.set("catChild", catChild)
		.set("offset", fetchOffset)
		.set("limit", fetchLimit)

        return this.http.get(
            ENV.DOMAIN_OF_BACKEND + "/get-slim-tunes-in-cat",
            {params:params}
        );
    }

	

	// #opt multiple optional parameters should be handled with one option parameter to return relevant types 
    getTracksTuneByTrackId(trackId) : Observable<Tune>
    getTracksTuneByTrackId(trackId, sendLyrics:true) : Observable<TuneWithLyrics>
    getTracksTuneByTrackId(trackId, sendLyrics:boolean, sendStatus : boolean) : Observable<HttpResponse<TuneWithLyrics>>
    getTracksTuneByTrackId(trackId, sendLyrics? : boolean, sendStatus? : boolean, ) : Observable<Tune |TuneWithLyrics | HttpResponse<TuneWithLyrics>>{
        const params : HttpParams = new HttpParams()
		.set('trackId',trackId)
		
		if(sendLyrics == true){
			params.set('sendLyrics',true)

			if(sendStatus ){
				return this.http.get<TuneWithLyrics>(
					ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id',
					{
						params:params,
						//sendStatus : sendStatus
					}
			)
			} else {
				return this.http.get<TuneWithLyrics>(
					ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id',
					{
						params:params,
					}
				)
			}
		} else {
			if(sendStatus){
				return this.http.get<TuneWithLyrics>(
					ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id',
					{
						params:params,
						observe : 'response'
					}
				)
			} else {
				return this.http.get<Tune>(
					ENV.DOMAIN_OF_BACKEND + '/get-tracks-tune-by-track-id',
					{
						params:params,
					}
				)
			}
		}
    }

	getLyricsFromTuneId(tuneId : string){

        const params = new HttpParams()
        .set("tuneId",tuneId)    
    
        return this.http.get<string[]>(
			`${ENV.DOMAIN_OF_BACKEND}/get-lyrics-from-tune-id`,
			{params:params})
    }

	getLyricsFromTuneTrackId(tuneTrackId : string){
		const params = new HttpParams()
        .set("tuneTrackId",tuneTrackId)    
    
        return this.http.get<string[]>(
			`${ENV.DOMAIN_OF_BACKEND}/get-lyrics-from-tune-track-id`,
			{params:params})
	}

	getLyricsFromGeniusFromTrackAndArtistName(trackName : string, artistName : string, trackId : string) : Observable<HttpResponse<string[]>>{

        const params = new HttpParams()
        .set("trackName",trackName)    
        .set("artistName",artistName)    
        .set("trackId",trackId)    
        return this.http.get<string[]>(
            `${ENV.DOMAIN_OF_BACKEND}/get-tracks-lyrics-and-create-tune-track-if-needed`,
            { params: params, observe: 'response' }
        )
    }

		getTracksFirstTuneByTrackNameAndArtistName(trackName : string, artistName : string) : Observable<Tune>
		getTracksFirstTuneByTrackNameAndArtistName(trackName : string, artistName : string, sendLyrics : boolean) : Observable<TuneWithLyrics>
		getTracksFirstTuneByTrackNameAndArtistName(trackName : string, artistName : string, sendLyrics : boolean, sendStatus : boolean) : Observable<HttpResponse<TuneWithLyrics>>
		getTracksFirstTuneByTrackNameAndArtistName(trackName : string, artistName : string, sendLyrics? : boolean, sendStatus? : boolean ) 
		: Observable<Tune |TuneWithLyrics | HttpResponse<ArrayBuffer> | HttpResponse<TuneWithLyrics>>{
			let params : HttpParams = new HttpParams()
			.set('trackName',trackName)
			.set('artistName',artistName)
			
			if(sendLyrics == true){
				params = params.set('sendLyrics',true)

				if(sendStatus ){
					return this.http.get<ArrayBuffer>(
						ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name',
						{
							params:params,
							observe : 'response'
						}
				)
				} else {
					return this.http.get<TuneWithLyrics>(
						ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name',
						{
							params:params,
						}
					)
				}
			} else {
						
				if(sendStatus ){
					return this.http.get<ArrayBuffer>(
						ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name',
						{
							params:params,
							observe : 'response'
						}
					)
				} else {
					return this.http.get<Tune>(
						ENV.DOMAIN_OF_BACKEND + '/get-tracks-first-tune-by-track-name-and-artist-name',
						{
							params:params,
						}
					)
				}
			}
		}

		getTracksTunesByTrackNameAndArtistName(trackName : string, artistName : string) : Observable<Tune[]>
		getTracksTunesByTrackNameAndArtistName(trackName : string, artistName : string, sendStatus : boolean) : Observable<HttpResponse<Tune[]>>
		getTracksTunesByTrackNameAndArtistName(trackName : string, artistName : string, sendStatus? : boolean ) 
		: Observable<Tune[] | HttpResponse<Tune[]> >{
			let params : HttpParams = new HttpParams()
			.set('trackName',trackName)
			.set('artisName',artistName)

			if(sendStatus ){
				return this.http.get<Tune[]>(
					ENV.DOMAIN_OF_BACKEND + '/get-tracks-tunes-by-track-name-and-artist-name',
					{
						params:params,
						observe : 'response'
					}
				)
			} else {
				return this.http.get<Tune[]>(
					ENV.DOMAIN_OF_BACKEND + '/get-tracks-tunes-by-track-name-and-artist-name',
					{
						params:params,
					}
				)
			}
		}

    getTuneById(tuneId) : Observable<any>{
        let params : HttpParams =  new HttpParams()
		.set(
            "tuneId",tuneId           
		)
        
        return this.http.get(ENV.DOMAIN_OF_BACKEND + '/get-tune-by-id',{params:params})
    }

	
	getTuneByNumber(tuneNumber) : Observable<Tune>
	getTuneByNumber(tuneNumber, observeRes : true) : Observable<HttpResponse<Tune>>
	getTuneByNumber(tuneNumber, observeRes? : true) : Observable<Tune | HttpResponse<Tune>> {
		const params : HttpParams = new HttpParams()
		.set('tuneNumber',tuneNumber)

		if (observeRes) {
			return this.http.get<Tune>(ENV.DOMAIN_OF_BACKEND + '/get-tune-by-number', { params: params, observe: 'response' });
		} else {
			return this.http.get<Tune>(ENV.DOMAIN_OF_BACKEND + '/get-tune-by-number', { params: params });
		}
	}

    getTunesWithTag(tagName:string, observe? : boolean){
		// convert CHttpparams to HttpParams
		const params : HttpParams = new HttpParams()
		.set('tagName',tagName)
		
		if(observe){
			return this.http.get(ENV.DOMAIN_OF_BACKEND + '/get-tunes-with-tag',{params:params, observe:'response'})
		} else {
			return this.http.get(ENV.DOMAIN_OF_BACKEND + '/get-tunes-with-tag',{params:params})
		}
    }
 
    getTunesByQuery(queryStr:string,offset:number = 0){
    //query = 'field:value' 
		// let queryObj
		// if(queryStr)
        // 	queryObj = JSON.parse(queryStr)
		// else
		// 	queryObj = null

		const params : HttpParams = new HttpParams()
		.set('dbQuery',queryStr)
		.set('offset',offset)

		return this.http.get(ENV.DOMAIN_OF_BACKEND + '/get-tunes-by-query',{params:params})
    }

    //#todo stay in EditTA after save instead of update, return tune instead
    saveTune(tune:Tune) : ResultAsync<Tune, SaveTuneError[]>{
        const body = {
            accessToken:this.ps.getSpotAccessToken(),
            tune:tune,
        }

        const promise = new Promise<Tune>( (resolve ,reject) => {
            this.http.post<Tune>(ENV.DOMAIN_OF_BACKEND + "/save-tune",body)
                .subscribe((tune) => {   
                    console.log(`tuneNumber: ${tune.tuneNumber}`);
                    resolve(tune)
                }, e => {
                    console.error("Error server save tune",e);
					this.eh.logSentryError(e)
                    reject(e)
                }
            )
        })

		//Opt could error of something else than tuneError
        return ResultAsync.fromPromise<Tune,SaveTuneError[]>(promise,(e : any) => [SaveTuneError.saveTuneServerError])

    }

    updateTune(tuneId : string, tuneUpdate : object) : ResultAsync<Tune, UpdateTuneError[]>{
        const body = {
            tuneId: tuneId,
            tuneUpdate: tuneUpdate
        }

        const promise = new Promise<Tune>( (resolve,reject) => {
            this.http.patch<{tune:Tune, message:string}>(ENV.DOMAIN_OF_BACKEND + "/update-owning-tune",body)
            .subscribe((res) => {   
                console.log(`tuneNumber: ${res.tune.tuneNumber}`);
                resolve(res.tune)
            },
            e =>{
                console.error("Error update tune",e);
                reject('Server Error')
            }) 
        })

        return ResultAsync.fromPromise<Tune,UpdateTuneError[]>(promise,(err) => [UpdateTuneError.updateTuneError])
    }

    getContactByDisplayName(contactDisplayName:string){

		const params : HttpParams = new HttpParams()
		.set('contactDisplayName',contactDisplayName)
        
        return this.http.get(ENV.DOMAIN_OF_BACKEND+'/get-contact-by-display-name',{params:params})

    }

    initializeConversation(user){
        return this.http.get(ENV.DOMAIN_OF_BACKEND+'/initialize-conversation')
    }

	//# decap from Cap Http
    setBackendCookies(cookies,frontendRedirectPath){
	/*this.http.post(`${ENV.DOMAIN_OF_BACKEND}/set-cookies`,{cookies:cookies},{withCredentials:true})
            .subscribe( () => {
                console.log('FE, did set cookies BE')
                this.router.navigateByUrl(frontendRedirectPath)
                this.closeBrowsers()
            }, (e) => {
                console.error('FE, error setting cookies BE',e)
                 this.router.navigateByUrl('login')
                 this.closeBrowsers()
            }) */


		const options = {
			url: `${ENV.DOMAIN_OF_BACKEND}/set-cookies`,
			data: { cookies : cookies },
		};
		
		Http.post(options).then((res) => {
			console.log('cap http plugin ')
			console.log(res)
		}).catch((e) => {
			console.log('cap http plugin error ')
			console.log(E)
			this.eh.logSentryError(e)
		})

    }

	saveTuneToUserLib(tuneId){
		this.http.put(`${ENV.DOMAIN_OF_BACKEND}/user/save-tune-to-lib`,{tuneId:tuneId}).subscribe(
			async res => {
				console.log(`save-tune-to-lib Res: ${res}`)
				if(typeof res === 'string'){
					this.gs.showToast({msg:res,duration:4000})
				}
			}
		), e => {
			console.error('Error saving tune to user lib',e)
			this.eh.logSentryError(e)

		}
	}

	getUserTuneLists(offset = 0) : Observable<UsersTuneListM>{
		const params : HttpParams = new HttpParams()
		.set('offset',offset)

		return this.http.get<UsersTuneListM>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-lists`)
	}

	createTuneList(tuneListName : string, tunes : Tune[]) : Observable<ITuneListPop>{
		return this.http.put<ITuneListPop>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-list/create`,{tuneListName:tuneListName,tunes:tunes})
	}

    // allow duplicates were allowed but is not anymore post 2408
	addTuneToTuneList(tuneListId : string, tuneId : string, observe? : boolean, allowDuplicate? : false) : Observable<ITuneListPop[]|any>{
		if(observe){
			return this.http.post<ITuneListPop[]>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-list/add-tune`,{tuneListId:tuneListId, tuneId:tuneId, allowDuplicate:allowDuplicate},{observe:'response'})
		} else {
			return this.http.post<ITuneListPop[]>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-list/add-tune`,{tuneListId:tuneListId, tuneId:tuneId,  allowDuplicate:allowDuplicate})
		}
	}

	removeTrigger(triggerId : string) : Observable<void>{
		return this.http.patch<void>(`${ENV.DOMAIN_OF_BACKEND}/user/remove-trigger`,{triggerId:triggerId})
	}

	removeTuneFromTuneList(tuneListId : string, tuneId : string) : Observable<ITuneListPop>{
		return this.http.patch<ITuneListPop>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-list/remove-tune`,{tuneListId:tuneListId, tuneId:tuneId})
	}

	renameTuneList(tuneListId : string, tuneListName : string) : Observable<ITuneListPop>{
		return this.http.patch<ITuneListPop>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-list/rename`,{tuneListId: tuneListId, tuneListName: tuneListName})
	}

	removeCreatedTuneList(tuneListId : string) : Observable<ITuneListPop>{
		return this.http.delete<ITuneListPop>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-list/remove/created`,{params : {tuneListId:tuneListId}})
	}

	removeSavedTuneList(tuneListId : string) : Observable<ITuneListPop>{
		return this.http.delete<ITuneListPop>(`${ENV.DOMAIN_OF_BACKEND}/user/tune-list/remove/saved`,{params : {tuneListId:tuneListId}})
	}

	sendFeErrorToServer(body, observeRes : boolean = false) : Observable<any> {
		if( observeRes) {
			return this.http.post(`${ENV.DOMAIN_OF_BACKEND}/log-error-from-fe`,body, {observe:'response'})
		} else {
			return this.http.post(`${ENV.DOMAIN_OF_BACKEND}/log-error-from-fe`,body)
		}
	} 

	getTuneListByNumber(tuneListNumber : number, offset : number, limit : number) : Observable<ITuneListByNumberResult>
	getTuneListByNumber(tuneListNumber : number, offset : number, limit : number, observeRes : boolean) : Observable<HttpResponse<ITuneListByNumberResult>>
	getTuneListByNumber(tuneListNumber : number, offset : number = 0, limit : number = 15, observeRes? : boolean) : Observable<ITuneListByNumberResult|HttpResponse<ITuneListByNumberResult>> {
		const params : HttpParams = new HttpParams()
		.set('tuneListNumber',tuneListNumber)
		.set('offset',offset)
		.set('limit',limit)

		if(observeRes){
			return this.http.get<ITuneListByNumberResult>(`${ENV.DOMAIN_OF_BACKEND}/get-tune-list-by-number`, {observe: 'response', params:params})
		} else {
			return this.http.get<ITuneListByNumberResult>(`${ENV.DOMAIN_OF_BACKEND}/get-tune-list-by-number`, {params:params})
		}
	}

    getTuneListsFromSearch(search : string , offset : number, limit : number) : Observable<ITuneListPopLean[]>
    getTuneListsFromSearch(search : string , offset : number, limit : number, observeRes : boolean) : Observable<HttpResponse<ITuneListPopLean[]>>
    getTuneListsFromSearch(search : string , offset : number = 0, limit : number = 15, observeRes? : boolean) : Observable<ITuneListPopLean[]|HttpResponse<ITuneListPopLean[]>> {
        const params : HttpParams = new HttpParams()
        .set('search',search)
        .set('offset',offset)
        .set('limit',limit)

        if(observeRes){
            return this.http.get<ITuneListPopLean[]>(`${ENV.DOMAIN_OF_BACKEND}/get-tune-lists-by-search`, {observe: 'response', params:params})
        } else {
            return this.http.get<ITuneListPopLean[]>(`${ENV.DOMAIN_OF_BACKEND}/get-tune-lists-by-search`, {params:params})
        }
    }

	saveTuneList(tuneListId:string, observeRes : boolean = false) : Observable<any>{
        const body = {
            tuneListId:tuneListId,
        }

		if(observeRes){
			return this.http.post(`${ENV.DOMAIN_OF_BACKEND}/user/tune-lists/save`,body, {observe:'response'})
		} else {
			return this.http.post(`${ENV.DOMAIN_OF_BACKEND}/user/tune-lists/save`,body)
		}

    }

	getUsersTriggers(offset : number =0, limit : number = 10){
		const params : HttpParams = new HttpParams()
		.set('offset',offset)
		.set('limit',limit)

		return this.http.get<{ triggers:ITriggerFE[], allTriggersFetched : boolean }>(ENV.DOMAIN_OF_BACKEND+'/user/triggers',{params:params})
	}

	getUsersTriggersFromSearch(query : string, offsetTuneId : string = null){
		
		let params : HttpParams = new HttpParams()
		.set('searchQuery',query)

		if(offsetTuneId)
			params = params.set('offsetTuneId',offsetTuneId)

		return this.http.get<{ triggers:ITriggerFE[], offsetTuneId : string, allTriggersFetched:boolean }>(ENV.DOMAIN_OF_BACKEND+'/user/triggers-search',{params:params})
	}
 
	getTrackTunes(trackId){
		const params : HttpParams = new HttpParams()
		.set('trackId',trackId)

		return this.http.get<Tune[]>(ENV.DOMAIN_OF_BACKEND+'/track/tunes',{params:params})
	}

	getTrackHasMultipleTunes(spotTrackId){
		const params : HttpParams = new HttpParams()
		.set('spotTrackId',spotTrackId)

		return this.http.get<boolean>(ENV.DOMAIN_OF_BACKEND+'/track/has-multiple-tunes',{params:params})
	}

    requestTuneListReorder(tuneListId,tuneArrayWithIdsReordered){

        const body = {
            tuneListId: tuneListId,
            tuneArrayWithIdsReordered: tuneArrayWithIdsReordered
        };

		return this.http.patch<HttpResponse<void>>(ENV.DOMAIN_OF_BACKEND+'/user/tune-list/reorder',body, {observe : 'response'})
	}

    revokeSpotPermissionsRedirect(){

        const spotExtraPermissionsToRemove = [

            SpotExtraPermissions.userLibraryRead,
            SpotExtraPermissions.playlistReadPrivate,
            SpotExtraPermissions.playlistReadCollaborative,   
        ]

        let params = spotExtraPermissionsToRemove.join(',');
        const search = new URLSearchParams()
        search.append('spotExtraPermissionsToRemove', params)
        const url = new URL(ENV.DOMAIN_OF_BACKEND+'/auth/spotify/revoke-permissions')
        url.search = search.toString()

        window.location.href = url.toString()
  
        //let params = new HttpParams().set('spotExtraPermissionsToRemove', spotExtraPermissionsToRemove.join(','));

		//return this.http.patch<HttpResponse<void>>(ENV.DOMAIN_OF_BACKEND+'/auth/spotify/revoke-permissions',body, {observe : 'response'})
		//return this.http.get<HttpResponse<void>>(ENV.DOMAIN_OF_BACKEND+'/auth/spotify/revoke-permissions',{params: params, observe : 'response'})
	}
}
