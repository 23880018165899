import { Injectable, Renderer2, RendererFactory2, Inject } from '@angular/core';
import  { ITune } from "../../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model"
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ENV } from '../../../../environments/environment';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FacebookDataService {

mFbId =  "2241502182535955";
truddPageAccessToken = "EAAKkgroxCXsBAOpKseo10VB9nDYCQ7arERZCvVfWIflrcHZA25ynNFaXGoTKrJgV1ykCOM2IfxZAZCZCZBAuuoIW1X8hgKdGLZC1L2iJBmvWGsOdrhg93R3QCKmploIuxZA0ZB0QVTr2FJngjksXwqtejMAG8xsZBy5XedvAF5gldvGnJ8dxfORBVl1znmQR7U374ZD";
psid = ""

private renderer: Renderer2;

constructor
  (
    //private renderer : Renderer2,
    @Inject(DOCUMENT) private _document,
    private http: HttpClient,
    rendererFactory: RendererFactory2
  )
  { this.renderer = rendererFactory.createRenderer(null, null);}

 /*  injectMessengerExtensionHtmlCode()
  {
    const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    //s.src = 'https://path/to/your/script';
    s.text = ``;
    s.text = 
    `(function(d, s, id)
    { 
      var js, fjs = d.getElementsByTagName(s)[0]; 
      if (d.getElementById(id)) {return;} 
      js = d.createElement(s); js.id = id; 
      js.src = "//connect.facebook.net/en_US/messenger.Extensions.js"; 
      fjs.parentNode.insertBefore(js, fjs); 
    }(document, 'script', 'Messenger')); 
    window.extAsyncInit = function() { 
      MessengerExtensions.getContext('743831329900923', 
        function success(thread_context){
          console.log('psid: '+ thread_context)
         //alert(thread_context)
        },
        function error(err){
          console.log("sdk error: "+err);
          //alert("error: " + err);
        }
      );
    };`
    //this.elementRef.nativeElement.appendChild(s);;
    this.renderer.appendChild(this._document.body, s);

  } */

  sendTune(tuneId)
  {

    let body;
    body.psid = this.psid;
    body.tuneId = tuneId;

    {
      this.http.post(ENV.DOMAIN_OF_BACKEND + "/facebook/send-tune",body)
          .subscribe
          (
            data=> 
            {
              console.log(data);
            },
            err =>
            {
              console.log("Error testPostData");
              console.log(err);
            }
          
          ); 
    }
  }
}