import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-powered-by-row',
  templateUrl: './powered-by-row.component.html',
  styleUrls: ['./powered-by-row.component.scss'],
})
export class PoweredByRowComponent implements OnInit {

	spotFullLogoIcon = './assets/spotifyFullLogo.svg'
  	constructor() { }

  	ngOnInit() {}

}
