import {
    _id,
    SlimSpotTrackV1,
    SlimSpotArtistV1,
    SlimTuneTrackV1,
} from '../../classes-enums-interfaces-types/classes/classes.js'
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    NgZone,
    Renderer2,
    ErrorHandler,
    ChangeDetectionStrategy,
} from '@angular/core'
import { ListElementTypesEnum } from '../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum'
import { Router } from '@angular/router'
import { mGlobal } from '../../mglobal'
import { IonPopover, ModalController } from '@ionic/angular'
import { GlobalService } from '../../services/global.service'
import { AddTuneToListComponent } from '../add-tune-to-list/add-tune-to-list.component'
import {
    DevicePlatformE,
    ListElementHtmlContextE,
    ModalsE,
} from '../../classes-enums-interfaces-types/enums/enums.enum'
import { MyServerService } from '../../services/my-server/my-server.service'
import { LongPressDirective } from '../../directives/long-press/long-press.directive.js'
import {
    AddedTuneData,
    ListElementAddedTuneToListData,
} from '../../classes-enums-interfaces-types/interfaces/interfaces.js'
import { PlayerService } from '../../services/music/player.service'
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service'
@Component({
    selector: 'app-list-element',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './list-element.component.html',
    styleUrls: ['./list-element.component.scss'],
})
export class ListElementComponent implements OnInit {
    @ViewChild(IonPopover) ionPop: IonPopover

    @Input('elem') elem
    @Input('borderStyle') borderStyle = 'solid'
    @Input('listElementNbr') listElementNbr = ''
    @Input('disableTouchGesture') disableTouchGesture: boolean = false
    @Input() noPaddingLeftEnable: boolean = false
    @Input() reorderActive: boolean = false

    @Input('enablePopOverRemoveTune') enablePopOverRemoveTune: boolean = false
    @Output() listElemClicked: EventEmitter<any> = new EventEmitter()
    @Output() listElemPopOpen: EventEmitter<any> = new EventEmitter()
    @Output() removeTuneFromTuneListClick = new EventEmitter()
    @Output() addedTuneToListClick =
        new EventEmitter<ListElementAddedTuneToListData>()
    @Input() listElementHtmlContext: ListElementHtmlContextE =
        ListElementHtmlContextE.emptyClass
    displayElem
    tempImgSrc =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTmSxPruQWjh92SIZCIJFiEWXPR2JT-5zd3hQ&usqp=CAU'
    listElemTypes = ListElementTypesEnum
    isOpen = false
    spotId: string
    popOverTriggerStr: string

    threeDotsIcon = '../../assets/ellipsis-vertical.svg'

    isListElemTune: boolean = true

    isSignedUp: boolean = this.gS.isSignedUp()
    isAdmin = mGlobal.isAdmin

    constructor(
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private modalCtrl: ModalController,
        public gS: GlobalService,
        private mServer: MyServerService,
        private ps: PlayerService,
        private ngZone: NgZone,
        private eh: ErrorHandlerService
    ) {}

    ngOnInit(): void {
        //console.log('elemInit')
        // if(this.enablePopOver){
        // 	this.popOverTriggerStr = `list-element-${this.listElementNbr}`
        // }
    }

    ngAfterViewInit(): void {
        this.spotId = this.gS.getSpotId()

        setTimeout(() => {
            this.listElemChanged()
            this.changeDetector.markForCheck()    
            
        })

        const rootStyles = getComputedStyle(document.documentElement)
        const globalMaxWidth = rootStyles
            .getPropertyValue(this.listElementHtmlContext)
            .trim()
        //this.renderer.setStyle(this.el.nativeElement, '--max-width-name-container', '50px');
        document.documentElement.style.setProperty(
            '--max-width-name-container',
            globalMaxWidth
        )
        //document.documentElement.style.setProperty('--max-width-name-container', '50px');

    }

    listElemChanged() {
        if (this.elem) {
            if (this?.elem?.hasOwnProperty('_id')) {
                // TOdo convert fetched elems into class object
                if (this.elem.type == ListElementTypesEnum.tune) {
                    //#FIXTS due to async between FE BE Schema

                    let trackImg = this.elem.slimTuneTrack?.trackImg
                    if (!trackImg) {
                        trackImg = this.elem.slimTuneTrack.images[2].url
                    }

                    this.displayElem = {
                        artistName: this.elem.slimTuneTrack.artistName,
                        trackImg: trackImg,
                        trackName: this.elem.slimTuneTrack.trackName,
                    }
                }
            } else if (this.elem.type == ListElementTypesEnum.slimSpotTrack) {
                this.isListElemTune = false
                this.displayElem = {
                    artistName: this.elem.artistName,
                    trackImg: this.elem.images[2].url,
                    trackName: this.elem.trackName,
                }
            } else {
                // SlimSpotArtist
                this.isListElemTune = false
                this.displayElem = this.elem
            }
        }
    }

    ngOnDestroy() {
        //console.log("listElem ngDestroy")
    }

    sendEvent(event) {
        console.log('list elem tap')
        if (event?.event?.target?.id == 'option-button') {
            // do nothing so Ionic can trigger option open
        } else {
            if (this.listElemClicked.observers.length > 0) {
                this.listElemClicked.emit(this.elem)
            } else {
                // assumes tune, change for artist later
                this.ps.loopTuneRequest(this.elem)
            }
        }

        //console.log(this.listElementNbr)
        //console.log("Send event");
    }

    // const elements  = document.getElementsByClassName('m-span');
    // const width = (elements[0] as HTMLElement).offsetWidth;
    // for (let i = 0; i < elements.length; i++){
    // 	(elements[i] as HTMLElement).style.maxWidth = width + 'px';
    // }

    // const nameContainer = document.getElementById('nameContainer')
    // const currentWidth = nameContainer.offsetWidth
    // nameContainer.style.maxWidth = currentWidth + 'px'
    //this.el.nativeElement.style.setProperty('--nameContainerWidth', nameContainer.offsetWidth + 'px')
    //console.log('post init')

    async popEditClick() {
        if (this.elem.type == ListElementTypesEnum.slimSpotTrack) {
            // only for admin I think

            this.mServer
                .getTracksFirstTuneByTrackNameAndArtistName(
                    this.elem.trackName,
                    this.elem.artistName
                )
                .subscribe(
                    (tune) => {
                        this.ngZone.run(() => {
                            this.router.navigateByUrl('/edit-tune', {
                                state: { tune: tune },
                            })
                        })
                    },
                    (e) => {
                        console.error(
                            'Error fetching tune from track and artist name',
                            e
                        )
                    }
                )
        } else {
            this.mServer.getTuneById(this.elem._id).subscribe((tune) => {
                this.ngZone.run(() => {
                    this.router.navigateByUrl('/edit-tune', {
                        state: { tune: this.elem },
                    })
                }),
                    (err) => {
                        console.log(err)
                        this.eh.logSentryError(err)
                    }
            })
        }
    }

    popSpotClick() {
        window.open(
            `https://open.spotify.com/track/${this.elem.slimTuneTrack.trackId}`,
            '_blank'
        )
    }

    async popAddToListClick() {
        const modal: HTMLIonModalElement = await this.modalCtrl.create({
            component: AddTuneToListComponent,
            breakpoints: [0.7],
            initialBreakpoint: 0.7,
            componentProps: { tune: this.elem },
            id: ModalsE.addToTuneList,
        })
        await modal.present()

        const { data, role } = await modal.onDidDismiss<AddedTuneData>()

        if (role === 'added') {
            this.addedTuneToListClick.emit({
                tune: this.elem,
                addedTuneToListData: data,
            })
        }
    }

    popRemoveFromListClick() {
        this.removeTuneFromTuneListClick.emit(this.elem)
    }

    popMoreTunesClick() {
        this.ps.pauseTrack()

        if (this.elem.type == ListElementTypesEnum.slimSpotTrack) {
            this.router.navigate(['/track'], {
                state: { slimSpotTrack: this.elem },
            })
        } else {
            this.router.navigate(['/track'], {
                state: { slimTuneTrack: this.elem.slimTuneTrack },
            })
        }
    }
    /*   artistNameConcat(elem) : string
  {
    let artistConcatName : string;
    if(elem.artists.length() > 1)
    {
      artistConcatName = elem.artists[0].name;
      for(let i=1;i<this.elem.artists.length();i++)
      {
        artistConcatName += ", " + elem.artists[i].name
      } 
    }
    else
    {
      artistConcatName = elem.artists[0].name;
    }
     return artistConcatName;
  } 
  */

    onLongPress(event) {
        console.log('longpress list-elem')
        event.stopPropagation()
        this.ionPop.present(event.event)
    }

    optionClick(event) {
        event.stopPropagation()
        if (this.listElemPopOpen.observers.length > 0) {
            this.listElemPopOpen.emit(event)
        } else {
            // assumes tune, change for artist later
            this.ionPop.present(event)
        }
    }
}
