import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ENV } from '../../../../environments/environment';
import {Location} from '@angular/common'
import * as _ from 'lodash';
import { MyServerService } from 'src/app/services/my-server/my-server.service';
import { FcmService } from 'src/app/services/fcm/fcm.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.page.html',
  styleUrls: ['./chat-list.page.scss'],
})
export class ChatListPage implements OnInit, AfterViewInit {

  conversations: any[];
  customSearch = '../../../assets/search-outline.svg' 
  customMenu = '../../../assets/menu.svg'
  userDisplayName = 'user1Name'
  contactSearch
  foundUsers

  constructor(
      private http: HttpClient,
      private router: Router,
      private location: Location,
      private mServer : MyServerService,
      private fcm : FcmService,
	  private gs : GlobalService
  ) { } 

  ngOnInit() {


  }

  ngAfterViewInit(){


    const params = new HttpParams()
    .set('userDisplayName', this.userDisplayName)
    this.http.get(`${ENV.DOMAIN_OF_BACKEND}/user/conversations`,{params}).subscribe((conversations : any[] ) => {
        
      var tmpSlimConversations = []
      var slimParticipants = {}
      const slimconversations = conversations.map( conversation => {
          slimParticipants = conversation.participants.filter(participant => {
          return participant.displayName != this.userDisplayName
        })
        conversation.participants = slimParticipants
      })
      this.conversations = conversations
        
    }, e => {
        console.error(`Error getting user conversations`,e)
    })

    this.fcm.incomingNotification$.subscribe(notification => {
        const conversationId = notification.data.conversataionId
        
    })

    this.fcm.incomingNotification$.subscribe(notification => {
        const data = notification.data
        const msg = data.msg
        const conversationId = data.conversationId
        const conversation = this.conversations.find(conversation => conversation._id == conversationId)
        conversation.messages.push(msg)
    })
  }

	ionViewDidEnter(){
		this.gs.setPendingHistoryBackClick(false)
	}

  conversationClicked(conversation){
      //this.http.get(`${ENV.DOMAIN_OF_BACKEND}/get-user-conversation}`).subscribe(userConversation => {
        this.router.navigate(['/chat'], {state : { conversationId : conversation._id} })
  }

  backClick(){
      this.location.back()
  }

  contactEntered(){
    this.mServer.getContactByDisplayName(this.contactSearch)
    .subscribe((contact) => {
        
    }, e =>{
        console.error(`Error getting contact`,e)
        
    })
  }

  foundUserClicked(foundUser : any){
    this.mServer.initializeConversation(foundUser)
    //#opt
    .subscribe(conversataionId => {
        this.router.navigate(['/chat'], {state : { conversationId : conversataionId} })
    }, e => {
        console.error('Error initializing conversation',e)
    })
    this.router.navigate(['/chat'])
  }

  

}
