import { CategoryHandlerComponent } from './category-handler.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [

  {
    path: '',
    //redirectTo:'/find-tune/select'
    component: CategoryHandlerComponent, 
  },
]

  
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CategoryHandlerRoutingModule {}
