import { LyricsModule } from './../components/lyrics/lyrics.module';
import { LoopProgressBarModule } from './../components/loop-progress-bar/loop-progress-bar.module';
import { FooterBarModule } from './../components/footer-bar/footer-bar.module';
import { PlayTuneRoutingModule } from './play-tune-routing.module';
import { PlayTuneComponent } from './play-tune.component';

import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { PoweredByRowModule } from '../components/powered-by-row/powered-by-row.module';
 
@NgModule({
  imports: [
    SharedModule,
    PlayTuneRoutingModule,
    FooterBarModule,
    LoopProgressBarModule,
	LyricsModule,
	PoweredByRowModule
   
  ], 
  declarations: [PlayTuneComponent],
  exports:[PlayTuneComponent]
})
export class PlayTuneModule { }
 