import { TuneListPlayerService } from './../services/tune-list-player/tune-list-player.service'
import { GlobalService } from './../services/global.service'
import { mCanActivateService } from './../services/m-can-activate.service'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core'

import { CookieService } from 'ngx-cookie-service'
import { PlayerService } from '../services/music/player.service'
import { ActivatedRoute } from '@angular/router'
//import { Tune } from '../classes-enums-interfaces-types/classes/classes';
import { MyServerService } from '../services/my-server/my-server.service'
import { ErrorHandlerService } from '../services/error-handler/error-handler.service'
import {
    ITuneListPop,
    ITuneListByNumberResult,
} from '../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared'
import { Tune } from '../classes-enums-interfaces-types/classes/classes'
import {
    ListElementHtmlContextE,
    LoggingLocalRoutingEvents,
    ModalsE,
    ToastEnum,
} from '../classes-enums-interfaces-types/enums/enums.enum'
import { Subscriber, Subscription } from 'rxjs'
import { ENV } from '../../../environments/environment'
import { HttpResponse } from '@angular/common/http'
import { UnsupportedInAppBrowserComponent } from '../components/modals/unsupported-in-app-browser/unsupported-in-app-browser.component'
import { ModalController } from '@ionic/angular'
import { mGlobal } from '../mglobal'
import { LoggingLocalService } from '../services/logging-local-service/logging-local-service.service'
@Component({
    selector: 'app-tune-list',
    templateUrl: './tune-list.page.html',
    styleUrls: ['./tune-list.page.scss'],
})
export class TuneListPage implements OnInit, AfterViewInit {
    tunes: Tune[]
    tuneListNumber: number
    selectedTune: Tune
    tuneListCreatedByDisplayName: string = ''
    tuneListName: string = ''
    tuneListId: string
    alreadySavedByThisUser: boolean = false
    isTuneSelected: boolean
    userDisplayName: string
    isPlayerReady: boolean = false

    selectedTuneSub: Subscription
    isPlayerReadySub: Subscription

    version = ENV.DEPLOY_VERSION_MINOR

    allTunesFetched: boolean = false
    tuneFetchOffset = 0
    tuneFethchLimit = 15

    ListElementHtmlContextE = ListElementHtmlContextE

    logoWithSubtitle =
        '../../../assets/pixelImages/logo-with-subtitle-dm-trans.png'
    shuffleIcon = '../../../assets/shuffle.svg'
    tuneListMissingOnServer: boolean = false

    isInitialLoad = true
    shuffleActive: boolean

    constructor(
        private ps: PlayerService,
        private route: ActivatedRoute,
        private mServer: MyServerService,
        private changeDetector: ChangeDetectorRef,
        private eh: ErrorHandlerService,
        private cookieService: CookieService,
        private mCanActivate: mCanActivateService,
        private gs: GlobalService,
        private modalCtrl: ModalController,
        private tuneListPlayer: TuneListPlayerService,
        private loggingLocal : LoggingLocalService
    ) {}

    ngOnInit(): void {
        //async template pipe is more work to debug
        // # todo fetching a deleted list
    }

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe(
            (params) => {
                this.tuneListNumber = params['list']
                if (this.tuneListNumber) {
                    this.mServer
                        .getTuneListByNumber(
                            this.tuneListNumber,
                            this.tuneFetchOffset,
                            this.tuneFethchLimit,
                            true
                        )
                        .subscribe(
                            (res: HttpResponse<ITuneListByNumberResult>) => {
                                if (res.status == 204) {
                                    this.tuneListMissingOnServer = true
                                } else {
                                    if (res?.body?.tunesReorder.length < 1) {
                                        this.allTunesFetched = true
                                    }
                                    this.alreadySavedByThisUser =
                                        res?.body?.alreadySavedByThisUser
                                    this.tuneListId = res?.body?.tuneListId
                                    this.tunes = res?.body?.tunesReorder.map(
                                        (tuneReorder) => tuneReorder.tune
                                    )
                                    this.tuneListCreatedByDisplayName =
                                        res?.body?.tuneListCreatedByDisplayName
                                    this.tuneListName = res?.body?.tuneListName

                                    this.tuneFetchOffset +=
                                        res?.body?.tunesReorder.length

                                    this.tuneListPlayer.setTunes(
                                        this.tunes,
                                        this.tuneListNumber,
                                        true
                                    )
                                }

                                //this.changeDetector.detectChanges()
                            },
                            (e) => {
                                console.log('getTuneListByNumber Error')
                                console.log(e)
                                this.eh.logSentryError(e)
                            }
                        )
                }
            },
            (e) => {
                console.log('paramError')
                console.log(e)
                this.eh.logSentryError(e)
            }
        )

        this.isPlayerReadySub = this.ps.isPlayerReady$.subscribe(
            (isPlayerReady) => {
                this.isPlayerReady = isPlayerReady
                this.isPlayerReady = true
                this.changeDetector.detectChanges()
            }
        )

        this.selectedTuneSub = this.ps.selectedTune$.subscribe((tune) => {
            this.selectedTune = tune
            this.isTuneSelected = tune ? true : false
        })

        this.tuneListPlayer.shuffleActive$.subscribe((shuffle) => {
            this.shuffleActive = shuffle
        })
    }

    ionViewWillEnter() {
        // #opt make login Subject


    }

    ionViewDidEnter() {

        this.userDisplayName = this.gs.getUserDisplayName()

        if (!this.isInitialLoad) {
            this.isInitialLoad = false
            this.initVariables()
        }

        this.gs.setPendingHistoryBackClick(false)

        this.ifUnsupportedInAppBrowserPresentModal()

        this.loggingLocal.addLog(LoggingLocalRoutingEvents.ReachedTuneList)
        
    }

    async ifUnsupportedInAppBrowserPresentModal() {
        if (
            this.gs.isRunningOnAndroid() &&
            this.gs.isRunningInMessengerBrowser()
        ) {
            const modal = await this.modalCtrl.create({
                component: UnsupportedInAppBrowserComponent,
                cssClass: 'unsupported-in-app-browser-modal',
                id: ModalsE.unsupportedInAppBrowser,
                htmlAttributes: { style: '--backdrop-opacity:0.5' },
            })

            modal.present()

            modal.addEventListener('ionModalDidPresent', (event: any) => {
                const heightToSet =
                    event?.srcElement?.childNodes[0]?.clientHeight
                document.documentElement.style.setProperty(
                    '--unsupported-browser-modal-height',
                    heightToSet + 'px'
                )
                this.changeDetector.detectChanges()
                console.log('modalDidPresent')
            })

            modal.addEventListener('ionModaldidDismiss', (event: any) => {
                console.log('modalDidDismiss')
            })
        }
    }

    ngOnDestroy() {
        if (this?.selectedTuneSub) this.selectedTuneSub.unsubscribe()

        if (this?.isPlayerReadySub) this.isPlayerReadySub.unsubscribe()
    }

    saveTuneListClicked() {
        //#opt will always be string already on FE
        this.mServer.saveTuneList(this.tuneListId, true).subscribe((res) => {
            if (res.status == 200) {
                this.gs.showToast({
                    msg: `Now following Tune list ${this.tuneListName}`,
                })
            } else {
                this.gs.showToast({
                    msg: 'Error saving Tune list',
                    header: 'Error',
                    type: ToastEnum.error,
                })
            }
        })
    }

    endOfScrollTunes(event) {
        this.mServer
            .getTuneListByNumber(
                this.tuneListNumber,
                this.tuneFetchOffset,
                this.tuneFethchLimit
            )
            .subscribe((res: ITuneListByNumberResult) => {
                if (res.tunesReorder.length < 1) {
                    this.allTunesFetched = true
                } else {
                    const fetchedTunes: Tune[] = res.tunesReorder.map(
                        (tuneReorder) => tuneReorder.tune
                    )
                    this.tunes = this.tunes.concat(fetchedTunes)
                    this.tuneFetchOffset += res.tunesReorder.length
                    this.tuneListPlayer.appendToTunes(fetchedTunes)
                }

                event.target.complete()
                //this.changeDetector.detectChanges()
            }),
            (e) => {
                console.log('getTuneListByNumber Error')
                console.log(e)
                this.eh.logSentryError(e)
                event.target.complete()
            }
    }

    tuneClicked(tune: Tune, index: number) {
        this.ps.loopTuneRequest(tune)
        this.tuneListPlayer.setTuneIndex(index)
    }

    initVariables() {
        this.tunes = []
        this.tuneListNumber = null
        this.selectedTune = null
        this.tuneListCreatedByDisplayName = ''
        this.tuneListName = ''
        this.tuneListId = null
        this.isTuneSelected = false
        this.userDisplayName = ''
        this.isPlayerReady = false
        this.allTunesFetched = false
        this.tuneFetchOffset = 0
        this.tuneFethchLimit = 15
        this.tuneListMissingOnServer = false
        this.alreadySavedByThisUser = false
    }

    shuffleClicked() {
        this.tuneListPlayer.setShuffle(!this.shuffleActive)
    }
}

// init variables to default state
