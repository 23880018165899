import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FooterBarModule } from '../components/footer-bar/footer-bar.module';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { TrackPageRoutingModule } from './track-routing.module';
import { PoweredByRowModule } from '../components/powered-by-row/powered-by-row.module';
import { TrackPage } from './track.page';
import { LoopProgressBarModule } from '../components/loop-progress-bar/loop-progress-bar.module';
import { LyricsModule } from '../components/lyrics/lyrics.module';

@NgModule({
  imports: [
    FormsModule,
    TrackPageRoutingModule,
	LoopProgressBarModule,
	PoweredByRowModule,
	SharedModule,
	FooterBarModule,
	LyricsModule
  ],
  declarations: [TrackPage]
})
export class TrackPageModule {}
