import { Injectable } from '@angular/core'
import {
    LoggingLocalChecks,
    LoggingLocalErrors,
    LoggingLocalRoutingEvents,
} from '../../classes-enums-interfaces-types/enums/enums.enum'
import { mGlobal } from '../../mglobal'
import { Storage } from '@ionic/storage-angular'

interface loggingLocalEntry {
    event: LoggingLocalErrors | LoggingLocalRoutingEvents | LoggingLocalChecks
    timestamp: string
    error?: {
        message: string
        stack: string
    }
    message?: string
}

@Injectable({
    providedIn: 'root',
})
export class LoggingLocalService {
    private logKey = 'event_logs'
    private storage: Storage | null = null
    private storageInitialized: Promise<void>
    private logQueue: Array<() => void> = []

    constructor(private storageModule: Storage) {
        this.storageInitialized = this.init()
    }

    async init() {
        this.storage = await this.storageModule.create()
        this.processLogQueue()
    }

    private processLogQueue() {
        while (this.logQueue.length > 0) {
            const logOperation = this.logQueue.shift()
            if (logOperation) {
                logOperation()
            }
        }
    }

    async addLog(
        loggingLocalEnum: LoggingLocalErrors | LoggingLocalRoutingEvents | LoggingLocalChecks ,
        { error = null, msg = null }: { error?: Error; msg?: any } = {}
    ) {
        if (mGlobal.logLocal) {
            const logOperation = async () => {
                const logEntry: loggingLocalEntry = {
                    event: loggingLocalEnum,
                    // exact time doesnt matter with relation to int offsets, order matters
                    timestamp: new Date().toISOString(),
                }

                // Conditionally add optional properties if they exist
                if (error) {
                    logEntry.error = {
                        message: error.message,
                        stack: error.stack,
                    }
                }

                if (msg) {
                    logEntry.message = msg
                }

                // Retrieve existing logs or initialize an empty array
                const logs = (await this.storage.get(this.logKey)) || []
                // Add the new log entry
                logs.push(logEntry)
                // Save the updated logs array
                await this.storage.set(this.logKey, logs)
            }

            if (this.storage) {
                await logOperation()
            } else {
                this.logQueue.push(logOperation)
            }
        }
    }

    async getLogs() {
        return (await this.storage.get(this.logKey)) || []
    }

    async clearLogs() {
        await this.storage.remove(this.logKey)
    }
}
