<ion-tab-bar slot="back" *ngIf="!inAppBrowser">
    <ion-tab-button 
	[class.ion-tab-button-ios]="isIos" 
		(click)='backClick()'
		[disabled]="!canGoBack"
	>
        <ion-icon class="icon-color" 
		[src]="customBackIcon"></ion-icon>
        <!-- <ion-label >Back</ion-label> -->
    </ion-tab-button>

    <ion-tab-button [class.ion-tab-button-ios]="isIos" (click)="chatClicked()"  tab='chat'  >
        <ion-icon class="icon-color" [src]="customChatIcon"></ion-icon>
        <!-- <ion-label  >Chat</ion-label> -->
    </ion-tab-button>

    <!--     tab="home" -->
    <ion-tab-button 
	[class.ion-tab-button-ios]="isIos" 
        (click)="homeBtnClick()"
    >
        <ion-icon id='logo-icon' class="icon-color" [src]='logoIcon'></ion-icon>
        <!-- <ion-label >Home</ion-label> -->
    </ion-tab-button>

    <ion-tab-button [class.ion-tab-button-ios]="isIos"   disabled=false  (click)="myLibraryClick()">
        <ion-icon class="icon-color" [src]='customLibraryIcon'>
        </ion-icon>
		
        <!-- <ion-label >Your library</ion-label> -->
    </ion-tab-button>

    <ion-tab-button 
	
		[ngSwitch]='saveSendAction'
		(click)='saveSendClicked()'
		[disabled]="!(selectedTune && !sendDisabled)"
		[class.ion-tab-button-ios]="isIos" 
		>
        <ion-icon *ngSwitchCase="saveSendActionEnum.save" class="icon-color" [src]="customSaveIcon"></ion-icon>
        <ion-icon *ngSwitchDefault class="icon-color" [src]="customSendIcon"></ion-icon>
        
        <!-- <ion-label >Send</ion-label> -->
    </ion-tab-button>

</ion-tab-bar>
<!-- <div id="selection-indicator-container"> -->
	<div 
		*ngIf="homeSel || librarySel"
		[ngClass]="{'home-selected': homeSel, 'library-selected': librarySel}"
		id="selection-indicator"
		[ngStyle]="getTabButtonStyles()"
		>
	</div>
<!-- </div> -->

