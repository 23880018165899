<!-- <div id="container-div"> -->
	<ion-toolbar >
		<ion-title>Info</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="closeModal()" [strong]="true" color="primary">Close</ion-button>
			</ion-buttons>
	</ion-toolbar>
	<!-- <ion-content> -->
	<ion-grid id="main-grid">
		<ion-row >
		
<!-- 			<ion-textarea
				[readonly]="true"
				value="{{htmlMsg}}"
				[autoGrow]="true"
				
			>
		
			</ion-textarea>		 -->
			<div id="msg-div-0" class="msg">
				{{htmlMsg1}}
			</div>
			<div id="msg-div-1" >
				{{htmlListMsg1P1}} <ion-icon src="{{threeDotsSVG}}"></ion-icon> {{htmlListMsg1P2}}
			</div>
			<div id="msg-div-2" >
				{{htmlListMsg2}}
			</div>
			<div id="msg-div-3" >
				{{htmlMsg2}}
			</div>
		</ion-row>
		<ion-row id="accordion-container-row">
			
			
			<cdk-accordion class="cdk-accordion">
				<!-- crazy that it needs bool as string -->
				<cdk-accordion-item
					
				  	expanded="{{accordExpanded}}"
					role="button"
					#accordionItem="cdkAccordionItem"
					class="cdk-accordion-item"

				>
					<ion-row (click)="accordionItemToggle()" class="accordion-item-header-row">
						<ion-col id='accordion-item-header-col-label'>
							Read more
						</ion-col>
						<ion-col id='accordion-item-header-col-icon' size="2">
							<ion-icon id="accordion-item-header-icon" [src]="accordionItem.expanded ? upArrowHead : downArrowHead ">
							</ion-icon>    
						</ion-col> 
					</ion-row>
					<div
						role="region"
						[style.display]="accordionItem.expanded ? '' : 'none'"
						class="accordion-content" 
					>
						<div class="msg-list">
							{{listReadMore1P1}} <ion-icon class="ion-icon-border" src="{{menuSVG}}"></ion-icon> {{listReadMore1P2}}
						</div>
						<div class="msg-list">
							{{listReadMore2P1}} <ion-icon class="ion-icon-border" src="{{settings}}"></ion-icon> {{listReadMore2P2}}
						</div>
						<div class="msg-list">
							{{listReadMore3P1}} <img src="{{photosAndMediaPurple}}"> {{listReadMore3P2}}
						</div>
						<div class="msg-list">
							{{listReadMore4P1}}
						</div>
					</div>
				</cdk-accordion-item>
			</cdk-accordion>
		</ion-row>
	</ion-grid>
	<!-- </ion-content> -->
<!-- </div>
 -->