
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwaInstallPromptIosComponent } from './pwa-install-prompt-ios.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [ PwaInstallPromptIosComponent ],
  exports: [ PwaInstallPromptIosComponent ],
})
export class PwaInstallPromptIosModule {}
