import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { ENV } from '.././environments/environment';
import { ErrorHandlerService } from './app/services/error-handler/error-handler.service';
import * as Sentry from "@sentry/angular-ivy";

if (ENV.PRODUCTION) {
  enableProdMode();
}

//enableProdMode();
platformBrowserDynamic()
.bootstrapModule(AppModule)
.then( ref => {

	let customLogItr = 0;

	// ['log','debug','info','warn','error'].forEach(function (verb) {
    // console[verb] = (function (method, verb) {
    //     return function () {
    //         method.apply(console, arguments);
	// 		const log = document.querySelector('#log');
	// 		if(log){
	// 			var msg = document.createElement('div');
	// 			msg.classList.add(...[verb, 'custom-log-message']);
	// 			if(verb != 'log'){
	// 				msg.textContent = customLogItr +  ' ' + verb + ': ' + Array.prototype.slice.call(arguments).join(' ');
	// 			} else {
	// 				msg.textContent = customLogItr +  ' ' + Array.prototype.slice.call(arguments).join(' ');
	// 			}
	// 			log.appendChild(msg);
	// 		}

	// 		customLogItr++
    //     };
    // 	})(console[verb], verb);
	// });
	
	Sentry.init({
		// Called for message and error events
		beforeSend : async (event) => {
			// Modify or drop the event here
			try{
				const eh = ref.injector.get(ErrorHandlerService);
				//const tuneItContext : IFeLogErrorSentry | IFeSetSentryContextError = await eh.getTuneItSentryContext()
				// buggy PoS should work, known problem
				//Sentry.setContext("tuneItContext", tuneItContext); 
				const tuneItContext : any = await eh.getTuneItSentryContext()
				event.contexts.tuneItContext = tuneItContext
	
				return event;
			} catch (e) {
				console.error("Sentry beforeSend error",e);
			}
			},
		dsn: ENV.SENTRY_DSN,
		integrations: [
			// Registers and configures the Tracing integration,
			// which automatically instruments your application to monitor its
			// performance, including custom Angular routing instrumentation
			/* new Sentry.BrowserTracing({
				//tracePropagationTargets: ["localhost", "https:tune-it.app"],
				routingInstrumentation: Sentry.routingInstrumentation,
			}), */
			// Registers the Replay integration,
			// which automatically captures Session Replays
			new Sentry.Replay({
				// Additional SDK configuration goes in here, for example:
				maskAllText: false,
				blockAllMedia: false,
				maskAllInputs: false,
				block:[],
				mask:[],
				ignore:[]
			}),
		],
		
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.0,
		
		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.0,
		replaysOnErrorSampleRate: 1.0,

		normalizeDepth:10
		
	});

	success => console.log(`Bootstrap Sentry success`)
})
.catch(err => console.error("Boostrap Sentry error",err));
