import { GlobalService } from './../../services/global.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ENV } from '../../../../environments/environment';
import { Location} from '@angular/common'
import { IonContent, IonInfiniteScroll } from '@ionic/angular';

import { Router } from '@angular/router';
import { mGlobal } from '../../mglobal'
import { FcmService } from '../../services/fcm/fcm.service';
import { Message} from '../../classes-enums-interfaces-types/classes/classes'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
    })
export class ChatPage implements OnInit, AfterViewInit {


    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(IonContent, { static: false }) content: IonContent;

  constructor(
    private http: HttpClient,
    private location: Location,
    private router: Router,
    private mGlobal : GlobalService,
    private fcm : FcmService,
	private gs : GlobalService

  ) { } 

 
  logoIcon = '../assets/logo_philip_black_n_white_v0.svg'
  customBack = '../assets/arrow-back-outline.svg' 

  debugChatConversation = {
    messages : [
        {
            text: `Lorem ipsum dolor sit amet, ne pri illud necessitatibus, has ullum altera ad.
            Sed purto suscipiantur id, id sit ancillae vivendum lobortis. Sit soleat mentitum
            corrumpit te. An vix aeque iudicabit honestatis, eius causae id vis`,
            isSender:true
        },
        {
            text:` ei mel cotidieque consectetuer. Ponderum gubergren mei at, putant tacimates definitionem et vim. Saperet repudiandae mel at.`,
            isSender:false
        },
        {
            text:`Eam autem nusquam contentiones an, tale nonumy corpora nam an, pro iudico graeci audiam ad. Usu justo petentium no. Est case altera dicunt id, has ne quidam impedit insolens. Id dolor ancillae eum. No quot tritani nec.`,
            isSender:true
        },
        
    ],
    participants : [
        {
            user1 : {
                displayName:'user1'
            }
        },
        {
            user2 : {
                displayName:'user2'
            }
        }
    ]
  }

    
    messages = []
    participants =  []
    
    typedMessage
    conversationId
    conversationName : any = 'conversationName'

  ngOnInit() {


  }

  ngAfterViewInit(): void {
    this.conversationId = this.router.getCurrentNavigation().extras?.state?.conversationId ?? '61d18a396f0ce84a84932520'
    if(this.conversationId){
        
        const params = new HttpParams()
        .set('conversationId',this.conversationId)
        this.http.get(`/user/conversation`,{params}).subscribe( (conversation :any ) => {
            this.messages = conversation.messages
            const participants = conversation.participants.map(participant => participant.displayName)
            this.conversationName = participants.filter(participant => participant != mGlobal.userDisplayName)
            console.log(conversation)
            this.content.scrollToBottom()
        }, e => {
            console.error(`Error getting conversation`,e)
        })
    } else {

    }

    this.fcm.incomingNotification$.subscribe(notification => {
        const data = notification.data
        if(data.conversationId = this.conversationId){
            const msg : Message = data.msg
            this.messages.push(msg)
        }
    })
  }

ionViewDidEnter(){
	this.gs.setPendingHistoryBackClick(false)
}

  endOfScroll($event){
      this.infiniteScroll.complete()
  }

  backClick(){
    this.location.back()
  }

  messageEntered(){

    const msg = new Message()
    msg.text = this.typedMessage
    msg.senderId = mGlobal.userId
    this.messages.push(msg)
    this.http.post(
        `/user/conversation/message`,
        {
            conversationId : this.conversationId,
            message : msg,   
        }
    ).subscribe(() => {

    }, e => {
        console.error(`Error sending chat message`, e)
    })
    //console.log(this.typedMessage)
  }


}
