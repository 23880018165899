import { DevicePlatformE, ModalsE } from './../../../classes-enums-interfaces-types/enums/enums.enum';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-not-recommended-browser',
  templateUrl: './not-recommended-browser.component.html',
  styleUrls: ['./not-recommended-browser.component.scss'],
})
export class NotRecommendedBrowserComponent implements OnInit {

  constructor(
	private modalControl : ModalController,
	private gs : GlobalService
  ) { }

	devicePlatform : DevicePlatformE = this.gs.getDevicePlatform()	
	devicePlatformE = DevicePlatformE
  
	msgIos1 = `Hi 👋

	You seem to be viewing this on an iOS `

	msgIos2 = `device.
	
	At this point this Web App has only been tested with Safari `
	
	msgIos3 = `
	
	It is recommended to use this Web App in Safari on iOS.`

	msgAndroid1 = `Hi 👋

	You seem to be viewing this on an Android `

	msgAndroid2 = ` device

	At this point this Web App has only been tested with Chrome ` 
	
	msgAndroid3 = `
	
	It is highly recommended to use this Web App in Chrome.`
	msg = ''
	
	
	chromeLogo = '../../../assets/google-chrome-logo.svg'
	androidLogo = '../../../assets/android-head-logo.svg'
	
	safariLogo = '../../../assets/safari-border-logo.svg'
	appleLogoWhite = '../../../assets/apple-logo-white.svg'

  	ngOnInit() {

	}

	closeModal(){
		
	try{
		return this.modalControl.dismiss(null,'cancel',ModalsE.notRecommendedBrowser)
	} catch (e){
		console.log(`Error closing modal`,e)
	}
	}

}
