import { IonicModule } from '@ionic/angular';
import { SearchHandlerRoutingModule } from './search-handler-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchHandlerComponent } from './search-handler.component';
import { ListElementModule } from '../../components/list-element/list-element.module';

@NgModule({
  imports: [
    CommonModule,
    SearchHandlerRoutingModule,
    IonicModule,
    ListElementModule

  ], 
  declarations: [
      SearchHandlerComponent, 
    ],
  exports:[
    SearchHandlerComponent
  ]
})
export class SearchHandlerModule { }
