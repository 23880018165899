import { TuneListPage } from './../../tune-list/tune-list.page'
import { Injectable } from '@angular/core'
import { MPlayerState } from 'native-volume-control-npm-package-name'
import { PlayerService } from '../music/player.service'
import { ITuneList } from '../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared'
import { Tune } from '../../classes-enums-interfaces-types/classes/classes'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import * as _ from 'lodash'
import { listenerCount } from 'process'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class TuneListPlayerService {
    /*  #TODO 
    
    create shuffle button in myLib and TuneListPage
    @check get index from played tune or find in list, when pressing in middle of list
    dont pause on lock screen if playing tune list */

    tuneListPlayerEnabled: boolean = false

    pendingJumpToNextTune: boolean = false
    tuneListLoopLimit: number = 1

    tuneListNumber: number = -1
    tunes: Tune[] = []
    tuneIndex: number = 0

    shouldShuffle: boolean = false

    tunesShuffled: Tune[] = []
    tunesShuffledPlayed: Tune[] = []

    shuffleActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    )

    constructor(private ps: PlayerService, private router: Router) {
        this.ps.playerStateChanged$.subscribe((playerState: MPlayerState) => {
            console.log(`${this.tuneListPlayerEnabled} ${this.pendingJumpToNextTune} `)
            if (this.tuneListPlayerEnabled) {
                if (this.pendingJumpToNextTune) {
                    if (this.shouldShuffle) {
                        this.tunesShuffledPlayed.push(this.tunesShuffled.pop())
                        this.ps.loopTuneRequest(this.tunesShuffled[0])
                    } else {
                        this.tuneIndex++
                        if (this.tuneIndex >= this.tunes.length) {
                            this.tuneIndex = 0
                        }
                        console.log(
                            `request tune ${
                                this.tunes[this.tuneIndex].slimTuneTrack
                                    .trackName
                            }`
                        )
                        this.ps.loopTuneRequest(this.tunes[this.tuneIndex])
                    }
                }
            }
        })

        this.ps.loopTimesLimitReached$.subscribe((loopLimitReached) => {
            console.log(`loopLimitReached: ${loopLimitReached}`)
            if (this.tuneListPlayerEnabled)
                this.pendingJumpToNextTune = loopLimitReached
        })

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //#hack #opt
                // only tune-list page and my-library have tune lists
                // my-library doesnt have sub routes atm so enable manually from there
                if (this.router.url.includes('tune-list')) {
                    this.tuneListPlayerEnabled = true
                } else {
                    this.setInitValues()
                }

                console.log('Navigation started!')
                // Add your logic here
            }
        })
    }

    // needed atm for my-library which disable because doesnt have sub routes
    setTuneListPlayerEnabled(enable: boolean): void {
        this.tuneListPlayerEnabled = enable
    }

    nextTune(): void {

        if(this.tuneListPlayerEnabled){
            if (this.shouldShuffle) {
                if (this.tunesShuffled.length === 0) {
                    // for reshuffling toggle reshuffle button, this will repeate previous shuffle list
                    this.tunesShuffled = this.tunesShuffledPlayed.reverse()
                    this.tunesShuffledPlayed = []
                } else {
                    this.tunesShuffledPlayed.push(this.tunesShuffled.pop())
                }

                this.ps.loopTuneRequest(
                    this.tunesShuffled[this.tunesShuffled.length - 1]
                )
            } else {
                this.tuneIndex++
                if (this.tuneIndex > this.tunes.length - 1) {
                    this.tuneIndex = 0
                }
                this.ps.loopTuneRequest(this.tunes[this.tuneIndex])
            }
        }
    }

    prevTune(): void {
        if(this.tuneListPlayerEnabled){
            if (this.shouldShuffle) {
                if (
                    this.tunesShuffledPlayed.length === 0 ||
                    !this.tunesShuffledPlayed
                ) {
                    this.ps.loopTuneRequest(
                        this.tunesShuffled[this.tunesShuffled.length - 1]
                    )
                    this.tunesShuffledPlayed = []
                } else {
                    this.tunesShuffled.push(this.tunesShuffledPlayed.pop())
                    this.ps.loopTuneRequest(
                        this.tunesShuffled[this.tunesShuffled.length - 1]
                    )
                }
            } else {
                this.tuneIndex--
                if (this.tuneIndex < 0) {
                    this.tuneIndex = 0
                }

                this.ps.loopTuneRequest(this.tunes[this.tuneIndex])
            }
        }
    }

    setTunes(
        tunes: Tune[],
        tuneListNumber: number,
        enableTuneListPlayer?: true
    ): void {
        this.setInitValues()

        if (enableTuneListPlayer) this.tuneListPlayerEnabled = true
        // not to risk index getting out of

        this.tuneListNumber = tuneListNumber
        this.tunes = tunes
        //this.tunes = _.cloneDeep(tunes)
    }

    appendToTunes(tunesToAppend: Tune[]): void {
        // Better practice and I want action when appending as well
        // this.tunes = this.tunes.concat(_.cloneDeep(tunesToAppend))
        this.tunes = this.tunes.concat(tunesToAppend)
        if (this.shuffleArray) {
            // #
            this.tunesShuffled = this.tunesShuffled.concat(
                this.shuffleArray(tunesToAppend)
            )
        }
    }

    shuffleArray(array: any[]): any[] {
        let currentIndex: number = array.length,
            temporaryValue,
            randomIndex

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex)
            currentIndex -= 1

            // And swap it with the current element.
            temporaryValue = array[currentIndex]
            array[currentIndex] = array[randomIndex]
            array[randomIndex] = temporaryValue
        }

        return array
    }

    setShuffle(shouldShuffle: boolean) {
        this.shouldShuffle = shouldShuffle
        this.shuffleActive$.next(shouldShuffle)
    }

    setTuneIndex(index: number) {
        // could come out of sy

        this.tuneIndex = index
        console.log(`index: ${index}`)
    }

    setInitValues() {
        this.tuneListPlayerEnabled = false
        this.tuneIndex = 0
        this.tunes = []
        this.tunesShuffled = []
        this.tunesShuffledPlayed = []
        this.pendingJumpToNextTune = false
        this.shouldShuffle = false
        this.tuneListNumber = -1
    }
}
