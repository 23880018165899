<ion-header>
	<ion-toolbar>
		<ion-title class="local-title">
			Tune List 
		</ion-title>
		<ion-buttons slot="end">
			<ion-button id='version-button' disabled="true"> v.{{version}} </ion-button>
		  </ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-grid *ngIf="!tuneListMissingOnServer; else noTuneListFoundTemplate" id="main-grid">
	<div *ngIf="!isPlayerReady" class="loading-overlay">
		<ion-spinner name="crescent"></ion-spinner>
    </div>
    <ion-row id="tune-list-name-row" class="m-list-header-row" >
        <ion-col id="tune-list-name-col">
            {{tuneListName}} by {{tuneListCreatedByDisplayName}}
        </ion-col>
        <ion-col id=shuffle-btn-col>
            <ion-button [ngClass]="{'toggled-button': shuffleActive}" id="shuffle-button" class="themed-ion-button" (click)="shuffleClicked()">
                <ion-icon
                    slot="icon-only"
                    [src]="shuffleIcon">
                    
                </ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
	<ion-row *ngIf="tuneListCreatedByDisplayName != userDisplayName && !alreadySavedByThisUser && userDisplayName" id="follow-tune-list-row">
		<ion-button class="themed-ion-button" (click)="saveTuneListClicked()">
			Follow Tune List
		</ion-button>
	</ion-row>

	<ion-row id="main-content-row">
		<ion-col id="main-content-col">
			<ion-content>
                <ion-list id="tune-list-tunes-ion-list">
                    <!-- intentional exception of not going to /track on click -->	
                    <app-list-element  
                        *ngFor = "let tune of tunes; let i = index"
                        id="list-element-{{i}}"
                        [elem] = "tune" 
                        [listElementNbr]="i"
                        [listElementHtmlContext]="ListElementHtmlContextE?.tuneListPage"
                        (listElementClick)="tuneClicked($event,i)"
                        >
                    </app-list-element>
                    
                </ion-list> 
			<ion-infinite-scroll
				[disabled]="allTunesFetched"
				(ionInfinite)='endOfScrollTunes($event)'   
			>
			<ion-infinite-scroll-content
				loadingText="Loading more data..."
			>
			</ion-infinite-scroll-content>
	</ion-infinite-scroll>  
			<app-lyrics 
				slot="fixed"
				[selectedTuneLyrics]="selectedTune?.lyrics?.lyricsStr"
		></app-lyrics>
	</ion-content>

	</ion-col>
	</ion-row>
</ion-grid>

<app-loop-progress-bar id="loop-pb"></app-loop-progress-bar>
<app-selected-track 
	id='selected-track-component'
	*ngIf='isTuneSelected'
	[saveTunePopOver]="true"
>
</app-selected-track>

<app-footer-bar>

</app-footer-bar>

<ng-template #noTuneListFoundTemplate>sssss
	<ion-grid id='no-tune-list-found-grid' >
		<ion-row id='img-row'>
			<ion-col id="img-col">
				<ion-img [src]="logoWithSubtitle"></ion-img>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col id="no-tune-found-text-col">
				No Tune list found for number {{tuneListNumber}}
			</ion-col>
		</ion-row>
	</ion-grid>
</ng-template>

