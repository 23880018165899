import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LongPressDirective } from './long-press/long-press.directive';
import { CommaToDotDirective } from './commaToDot/comma-to-dot.directive';



@NgModule({
  declarations: [
    LongPressDirective,
    CommaToDotDirective
  ],
  imports: [ 
    CommonModule
  ],
  exports: [
    LongPressDirective
  ]
})
export class DirectivesModule { }
