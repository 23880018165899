<!-- <ion-menu side="start" menuId="first" contentId="content">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Start Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item>Menu Item</ion-item>
        <ion-item>Menu Item</ion-item>
        <ion-item>Menu Item</ion-item>
        <ion-item>Menu Item</ion-item>
        <ion-item>Menu Item</ion-item>
      </ion-list>
    </ion-content>
</ion-menu> -->

<ion-grid id="main-grid">
	<app-search-bar 
		(searchEntered)="searchBarSearchEntered($event)"
		[placeholder]="searchBarPlaceholder"	
	>
	</app-search-bar>
    <ion-row id='segment-row'>
		<ion-segment value='{{segmentActive}}' (ionChange)="onsegmentChanged$($event)">
			<ion-segment-button class="left-segment-button" value='{{segmentStatesEnum.search}}'>
				<ion-label  class="segment-button-label">Search</ion-label>
			</ion-segment-button>
			<ion-segment-button checked class="right-segment-button" value='{{segmentStatesEnum.select}}'>
				<ion-label class="segment-button-label">Categories</ion-label>
			</ion-segment-button>
		</ion-segment>
    </ion-row>

    <ion-row style='flex-grow:1'>
        <ion-col id='outlet-col'>
            <ion-router-outlet></ion-router-outlet>
            <app-lyrics 
                id="lyrics"
                [selectedTuneLyrics]="(ps.selectedTune$ | async)?.lyrics?.lyricsStr">
            </app-lyrics>
        </ion-col>
    </ion-row>
</ion-grid>

<ion-row id="loop-progress-bar-row">
	<app-loop-progress-bar id="loop-pb"></app-loop-progress-bar>
</ion-row>

<ion-row *ngIf='isTuneSelected' id="selected-track-row">
	<app-selected-track 
		id='selected-track-component'
>
	</app-selected-track>
</ion-row>


<app-footer-bar 
	[saveSendAction]="saveSendAction"
	(barBackEmittor)='backClickFind()'
	>
</app-footer-bar>

