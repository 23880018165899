<!-- <ion-grid> -->
<ion-row id='search-row'>
	<ion-col id='menu-button-col' class='search-field-button-col'>
		<ion-button id=menu-button class='search-field-button' fill='clear' size='default'(click)="menuButtonClicked()">
			<ion-icon slot='icon-only' [src]='customMenu' id='search-row-menu-icon' class="search-row-menu-icon"></ion-icon>
			<ion-icon *ngIf="(gs.pwaUpdateAvailable$ | async)?.pwaReadyForInstall" slot='icon-only' [src]='customMenuNotification' class="search-row-menu-icon"></ion-icon>
		</ion-button>
	</ion-col>
	<ion-col id='search-field-col'>

			<ion-searchbar
				class="ion-searchbar"
				enterkeyhint="search"
				inputmode="text"
				type="search"
				placeholder="Search here..."
				showClearButton="always"

				
				(keyup)="searchKeyUp($event)"
				
				[(ngModel)]="searchText"
				(ionClear)="searchButtonClearClicked()"
			>
			</ion-searchbar>
			<!--<ion-input 
				label = ""
				[(ngModel)]="searchText"
				id='search-input-field'
				placeholder={{placeholder}} 
				type="text"
				(keyup.enter)="searchButtonClicked()">
			</ion-input> -->
	
	</ion-col>
	<ion-col class='search-field-button-col'>
		<ion-button class='search-field-button' id='funnel-button' fill='clear' size='default'
			(click)="filterButtonClicked()">
			<ion-icon slot='icon-only' [src]='customFunnel' id='search-row-funnel-icon'></ion-icon>
		</ion-button>
	</ion-col>
</ion-row>
<!-- </ion-grid>
 -->