
import {inject} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FindTuneStateService } from '../../services/states/find-tune/find-tune-state.service';
import { SegmentStatesEnum } from '../../classes-enums-interfaces-types/enums/find-tune/enums.enum';

export const findTuneRouteGuard : CanActivateFn = (activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean | UrlTree => {
	
	const hasSubPath = activeRoute.children[0]?.url?.length //? '' : activeRoute.children[0].url[0].path
	if(hasSubPath){ // '' -> falsey
		return true
		
	} else {
		let urlTree
		const router = inject(Router);
		const findTuneService = inject(FindTuneStateService)
		switch (findTuneService.segment){
		case SegmentStatesEnum.search:
			//#opt return url tree instead for proper ng, tried once was irritating
			//this.router.navigateByUrl('/find-tune/search')
			urlTree = router.createUrlTree(['find-tune','search'])
			return urlTree
			break
		case SegmentStatesEnum.select:
			urlTree = router.createUrlTree(['find-tune','select'])
			return urlTree
			break
		default:
			//router.navigateByUrl('/find-tune/search')
			break
		}
	}
 
};  