import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  constructor() { }

  appStateActive$ = new Subject<boolean>()

  appStateActiveChange(isActive : boolean){
    this.appStateActive$.next(isActive)
  }
}
