
//Version number starts on 1, it shouldn't...
import { mGlobal } from '../../mglobal'
import { __extends } from 'tslib';
//import { FadeCurveTypesE, ListElementTypesEnum } from './../../../../enums/enums.shared.enum';
//M is ist prefix not to confuse with S data returned structure where names overlap
import { TagNamesFE } from '../interfaces/interfaces';
import { FadeCurveTypesE, ListElementTypesEnum } from '../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum';
import { ITuneTrackV1 } from '../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared';

export class MHttpError extends Error{
    
    statusCode:number

    constructor(message:string,statusCode: number){
        super(message)
        this.statusCode = statusCode 
    }
}

export class _id {
    _id:string=null
    constructor(){}
}

export class ElemType extends _id {
    type:ListElementTypesEnum
    constructor(){super()}
}

export class CategoryV2 extends _id {
    child : CategoryV2[] = null //#fix replace to children
    createdAt : Date = new Date()
    name: string = null
    parent: CategoryV2 = null
    constructor(){super()}
}

export class MAlbumV1 extends _id {
    id: string
    images: MImageV1[]
    name: string // added
    constructor(
        id : string,
        images : MImageV1[] 
    )
    {
        super()
        this.id=id
        this.images=images
    }
}

export class MArtistV1 extends _id {
    createdAt: Date= new Date()
    id:string
    name:string
    tunes:Tune[] | [string]
    type : ListElementTypesEnum // quick fix solution, use instanceof class instead, should be V2
    
    constructor(
        id:string,
        name:string,
        tunes:Tune[],
        type : ListElementTypesEnum,
        ){
            super()
            this.id=id
            this.name=name
            this.tunes=tunes
            this.type=type
    }
}

//unessary but why not 
export class MImageV1 extends _id {
    height:number
    width:number
    url:string    
    constructor(){super()}
}

export class MTrackV1 extends _id {
    album: MAlbumV1
    artists: MArtistV1[]
    availableMarkets: [string]
    durationMs: number
    id: string
    language: string
    lyrics: LyricsTrack
    popularity: number // pop is dynamic and needs to be updated in intervals
    name: string 
    //timestamp: Date
    createdAt: Date = new Date() // should be version 2
    constructor(
        album: MAlbumV1,
        artists: MArtistV1[],
        availableMarkets: [string],
        durationMs: number,
        id: string,
        language: string,
        popularity: number, // pop is dynamic and needs to be updated in intervals
        name: string ,
        //timestamp: Date

        ){
        super()
        this.album=album      
        this.artists=artists 
        this.availableMarkets=availableMarkets 
        this.durationMs = durationMs
        this.id=id
        this.language=language
        this.popularity= popularity 
        this.name=name 
    }
}

export  class SlimTuneTrackV1  {
    artistName:string 
    durationMs:number
    trackId:string 
    trackImg:string 
    trackName:string 
    constructor(
        artistName : string = null,
        durationMs :number=-1,
        trackId:string=null,
        trackImg:string=null,
        trackName:string=null,
    ){
        this.artistName=artistName,
        this.durationMs = durationMs,
        this.trackId=trackId,
        this.trackImg=trackImg,
        this.trackName=trackName
    }
}

export class TuneTrackV1 extends _id {
    createdAt: Date 
    track: MTrackV1
    tunes: Tune[] | [string]
    constructor(){super()}
} 

export class FadeOptions {

    curveType : FadeCurveTypesE = mGlobal.fadeParams.fadeCurveType
    fadeInDuration:number = mGlobal.fadeParams.fadeInDuration
    fadeOutDuration:number = mGlobal.fadeParams.fadeOutDuration
    lowestVolumePercent:number = mGlobal.fadeParams.lowestVolumePercentOfOrig
    constructor(){}
}

export interface TuneTag {
    name : string,
    tag : string
}

interface LyricsTune {
    lyricsStr:string,
    wordRange : {
        startIndex:number,
        stopIndex:number
    }
}

interface LyricsTrack {
    lyricsArray: string[],
    lyricsStr: string,
    fetchedDate: Date
}

export class Tune extends _id{
    constructor(){super()}
    categories : CategoryV2 = null
    createdAt : Date = new Date()
	createdBy : {
		_id:string,
		spotId:string
	} = null
    fadeOptions: FadeOptions = new FadeOptions()
    instrumental : boolean = false
    loops: number = 0
    lyrics: LyricsTune = null
    popularity : number = 0
    slimTuneTrack: SlimTuneTrackV1 = new SlimTuneTrackV1()
    startTime : number = null
    stopTime : number = null
    tags: TagNamesFE[] = []  // either _ids or tags when lean, will be string array before saving from FE mem.
    tuneNumber: number = null //#opt see backend model
    tuneTrack : ITuneTrackV1 | string = null
    triggers : TriggerSlim[] = []
    type : ListElementTypesEnum = null
    version : string = null
}

//#FIX Tune and be TuneV2 should be merged
export type SavedTune = {
	savedAt : Date;
	tune : Tune
}

type autoComplete = {
    autoComplete: string,
    // createdAt: Date,
    // createdBy: User | string,
    // tunes:[{
    //         tune: Tune | string,
    //         triggerTimes: number
    //     }], 
}

export class TriggerSlim extends _id {
    
    autoCompletes: autoComplete[]
    triggerSentence: string
	notApplicable: boolean

    constructor(
    ){
        super()
    }

}

export class User {
    constructor(
      public id: string,
      private _token: string,
      private _tokenExpirationDate: Date
    ) {}
  
    get token() {
      if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
        return null;
      }
      return this._token;
    }
}


//////////////////////////// On DB ///////////////////
  
// On client only

export class SlimSpotTrackV1 {
    artistId:string
    artistName:string
    durationMs:number
    trackId:string
    images:[MImageV1, MImageV1, MImageV1]
    trackName:string
    type:string = ListElementTypesEnum.slimSpotTrack
	addedAt : Date

    constructor(
        artistId:string,
        artistName:string,
        durationMs:number,
        images:[MImageV1, MImageV1, MImageV1],
        trackId:string,
        trackName:string,
		addedAt?: string
    ){
        this.artistId=artistId
        this.artistName=artistName
        this.durationMs =durationMs
        this.trackId=trackId
        this.images=images
        this.trackName=trackName

		if(addedAt)		
			this.addedAt = new Date(addedAt)
        //this.type=type //ARTIST, TRACK.TUNE
    }
}

export function slimSpotTrackToSlimTuneTrack(slimSpotTrack : SlimSpotTrackV1) : SlimTuneTrackV1{
    return new SlimTuneTrackV1(
        slimSpotTrack.artistName,
        slimSpotTrack.durationMs ,
        slimSpotTrack.trackId,
        slimSpotTrack.images[0].url,
        slimSpotTrack.trackName
    )
}

export class SlimSpotArtistV1{
    id:string
    images:[MImageV1, MImageV1, MImageV1]
    name:string
    type:string // tmp till making loaded tunes from db into classes
    constructor(
        id:string,
        images:[MImageV1, MImageV1, MImageV1],
        name:string,
        type:string    
    ){
        this.id=id
        this.images=images
        this.name=name
        this.type=type
    }
}

export class Message{
    dateSent: Date = new Date()
    text : string
    senderId: string
    tune: Tune
    tuneCorrespondingText: string
    referencingTextMessages : string

}

