<ion-content>

	<ion-grid *ngIf="!tuneMissingOnServer; else noTuneFoundTemplate" class="ion-justify-content-start ion-align-items-end">
		<ion-row id="cover-row">
			<ion-img  *ngIf=tracksImageLoaded id="album-cover" src="{{trackImg}}"></ion-img>
		</ion-row>
		<app-powered-by-row></app-powered-by-row>
		<ion-row id="loop-progress-bar-row">
			<app-loop-progress-bar id="loop-pb"></app-loop-progress-bar>
		</ion-row>
		<ion-row class="text-row" id="track-name-row">
			{{trackName}}
		</ion-row>
		<ion-row class="text-row">
			{{artistName}}
		</ion-row>
<!-- 		<ion-row>
			<a href={{aLink}}>Try open external</a>
		</ion-row> -->
		<ion-row id="playRow">
			<ion-col>
				<ion-button class="themed-ion-button play-row-button" id="options-button">
					<ion-icon slot='icon-only' id='options-icon' [src]="optionsIcon"></ion-icon>
				</ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="themed-ion-button play-row-button"
					[disabled] = "!isPlayerReady" 
					(click) = "toggleLoopTuneClick()"
					id="loop-pause-button"
				>
				{{toggleLoopText}}    
				</ion-button>
			</ion-col>
			<ion-col>
				v.{{version}}
			</ion-col>
		</ion-row>
<!-- 		<ion-row *ngIf="!isPlayerReady">
			<ion-spinner></ion-spinner>
		</ion-row> -->
		<!-- <ion-row>
			{{version}}
		</ion-row>
		<ion-row>
			<div id="log" class="custom-log">
			</div>
		</ion-row> -->

		<!-- <ion-row>
			<ion-button (click)="testLog()">
				test log
			</ion-button>
		</ion-row> -->
		<ion-row id="lyrics-row">
			<app-lyrics 
			[shouldShowOverride]="hasLoopedOnce"
			[disableScroll]="true"
			style=
				"width:100vw;
				justify-self: end;
				align-self: end;"
			[selectedTuneLyrics]="tune?.lyrics?.lyricsStr"

		></app-lyrics>
		</ion-row>
	
	</ion-grid>



</ion-content>
<app-footer-bar 
	[saveSendAction]='saveSendActionE.send'
>
	
</app-footer-bar>


<ion-popover 
	side="end"
	trigger-action="click"
	dismiss-on-select="true"
	show-backdrop="true"
	alignment="center"
	trigger="options-button"
>

<ng-template >
	<ion-list class="pop-over-list" lines="full" >
		<ion-item *ngIf="isSignedUp" 
			class="pop-over-list-item"
			[button]="true"
			(click)="popAddToListClick()">
			Add to list
		</ion-item>
		<ion-item 
			*ngIf="isSignedUp"
			class = "pop-over-list-item"
			[button] = "true"
			(click) = "popMoreTunesClick()"
		>
		More Tunes
		</ion-item>
		<ion-item
		class="pop-over-list-item"
			(click)="popSpotClick()"
			lines="none">
			Open Spotify
		</ion-item>
	</ion-list>
</ng-template>
</ion-popover>

<ng-template  #noTuneFoundTemplate>
	<ion-grid id='no-tune-found-grid'>
		<ion-row id='img-row'>
			<ion-col id="img-col">
				<ion-img [src]="logoWithSubtitle"></ion-img>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col id="no-tune-found-text-col">
				No Tune found for number {{tuneNumber}}
			</ion-col>
		</ion-row>
	</ion-grid>
</ng-template>

 
