import { SelectedTrackComponent } from './selected-track.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path:'selected-track',
        component:SelectedTrackComponent
    }
];

@NgModule({ 
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SelectedTrackRoutingModule {}
