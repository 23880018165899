import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-known-shortcomings',
  templateUrl: './known-shortcomings.page.html',
  styleUrls: ['./known-shortcomings.page.scss'],
})
export class KnownShortcomingsPage implements OnInit {

	uiUxScs : string[] = [
		"Animation between pages is laggy and pages show at the same time, can be improved",
		"Play/Pause main button should likely be changed from white",
		"Filter button doesn't do anything",
		"Searching for track in Spotify list is not implemented"
	]

	featuresScs : string[] = [
		"Find track by artist is not possible",
		"Searching for Tunes in your library is not implemented",
		"Removing a Tune from a list removes all duplicates of the Tune from the list",
		"Clicking a looping Tune in the Search list or in your Spotify playlists doesn't go to Track's page. Click the selected Tune at the bottom instead to go to Track's page.",
	]

	playerScs : string[] = [
		`Sometimes the Player doesn’t start correctly like 1/20. Solution is to "Refresh page" in the side menu or restart app atm :(`,
		`A popping sound can be heard when switching between Tunes with out pause in between. `
	]

	iosScs : string [] = [
		"Sound is not faded on loop restart. Impossible to fix. iOS <3",
		"After logging in with Spotify the screen is not fully filled. Rotate screen to landscape and back to fill the screen fully. Working on a permanent fix.",
		"Sending Tunes doesn't have a explaining message what the link is for."
	]

	constructor() { }

	ngOnInit() {
		
	}

}
