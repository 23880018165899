<ion-header>
  <ion-toolbar>
    <ion-title>Login</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content >
    <ion-grid id="main-grid">
			<ion-row id="img-row">
				<ion-col id="img-col">
					<ion-img id='logo-img' [src]="logoWithSubtitle"></ion-img>
				</ion-col>
			</ion-row>
            <ion-row id=button-row class="ion-align-items-center ion-justify-content-center">
                <ion-col>
                    <ion-button
						class="themed-ion-button"
                        size="large"
                        (click)="loginOrLogout()" 
                    >
						{{hasLoggedIn ? 'Logout' : 'Login'}}
                    </ion-button>
                </ion-col>
            </ion-row>
			<ion-row *ngIf=!hasLoggedIn id="with-spot-row">
				<p id="with-p">
					with 		
				</p>
				<div id='spot-logo-div'>
					<ion-img  id='spot-logo' [src]="spotLogo">
					</ion-img>
				</div>

				<!-- <ion-col id="with-col" class="with-spot-col">
	
				</ion-col>
				<ion-col id='spot-logo-col'class="with-spot-col">
			
				</ion-col> -->
			</ion-row>
            <br>
            <ion-row class='login-description'>
                Not signed up yet?
            </ion-row>
            <ion-row class='login-description'>
                <p>Sign up <a [routerLink]="['/sign-up']">here</a></p>
            </ion-row>   
			<br>
            <ion-row id="version-row">
                v{{env.DEPLOY_VERSION_MAJOR}}.{{env.DEPLOY_VERSION_MINOR}}{{preProd}}
            </ion-row>
    </ion-grid>
</ion-content>

<app-footer-bar
	sendDisabled="true">
</app-footer-bar>

