import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pwa-install-prompt-ios',
  templateUrl: './pwa-install-prompt-ios.component.html',
  styleUrls: ['./pwa-install-prompt-ios.component.scss'],
})
export class PwaInstallPromptIosComponent implements OnInit {

	@Output('shouldShow') shouldShow = new EventEmitter<boolean>()
	constructor() { }

	ngOnInit() {}

	iosShareButton = '../../../assets/shareIos.svg'
	closeButton = '../../../assets/close-outline.svg'
	addToHomeScreenIcon = '../../../assets/mATHS.svg'
	close(){
		console.log('click')
		this.shouldShow.next(false) 
	}

}
