import { AuthService } from './auth/auth.service'
import { MyServerService } from '../services/my-server/my-server.service'

import { Injectable } from '@angular/core'
import {
    Router,
    UrlTree,
    UrlCreationOptions,
    ParamMap,
    Navigation,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    NavigationExtras,
    Params,
} from '@angular/router'
import { GlobalService } from './global.service'
import { ENV } from '../../../environments/environment'
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common'

import { CookieService } from 'ngx-cookie-service'
import { PlayerService } from './music/player.service'
import { HelperFunctionsService } from './helper-functions/helper-functions.service'
import { BehaviorSubject, interval } from 'rxjs'
import { SegmentStatesEnum } from '../classes-enums-interfaces-types/enums/find-tune/enums.enum'
import { FindTuneStateService } from './states/find-tune/find-tune-state.service'
import { LoggingLocalService } from './logging-local-service/logging-local-service.service'
import { LoggingLocalRoutingEvents } from '../classes-enums-interfaces-types/enums/enums.enum'

/* 
preserveFragment?: boolean
check if last was this navication
eager / deferead loading
onSameUrlNavigation <-
return url tree without fragment 
1) check if contains fragment consume it, 2) send new url tree without
for now before login handle, just check for route then fragment 
*/

@Injectable({
    providedIn: 'root',
})
export class mCanActivateService {
    constructor(
        private router: Router,
        private location: Location,
        private ps: PlayerService,
        private hF: HelperFunctionsService,
        private mServer: MyServerService,
        private auth: AuthService,
        private cookieService: CookieService,
        private gS: GlobalService,
        private findTuneService: FindTuneStateService,
        private loggingLocal : LoggingLocalService
    ) {}

    prevNav: Navigation = null
    freshSpotTokenFetched$ = new BehaviorSubject<{
        displayName: string
    }>({
        displayName: '',
    })

    async canActivate(
        routeSnapshot: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        //return true
        /*
        better to use snapshout and state inputs than router as it is not updateed yet
        state is field of snapshot
         
        //Dont want apks out there with infinite permission or have to change key in spot dev dashboard
        if(this.gS.isNativeCapPlatform()){
            //TODO handle error
            this.sd.setSpotAccessToken((await NativeVolumeControl.getSpotAccessToken()).spotAccessToken) 
            console.log(`spotAccessToken: ${this.sd.getSpotAccessToken()}`)
            return true
        }  */
        // if(ENV.DEBUG)
        //     return true
        //return true
        //let toppath : string =  state.url
        const topPath: string = '/' + routeSnapshot.url[0].path
        console.log(ENV)
        /*if(!ENV.PRODUCTION && ENV.DOMAIN_OF_FRONTEND == 'http://localhost:8100' && routeSnapshot.fragment ){
            const fragment : string = routeSnapshot.fragment!
            const spotTokens = new URLSearchParams(fragment).get('spotTokens')
            this.cookieService.put('spotTokens', spotTokens)
        } */

        console.log('got to mCanActivate')
        console.log(this.router.url)
        console.log(topPath)
        /* if (path == '/dev') {
            return true
        } */
        if (topPath.includes('/sign-up')) {
            return true
        } else if (topPath.includes('/play-tune')) {
            if (this.ps.isCurrentSpotTokenValid()) {
                // console.log('got to delay init spot')
                // setTimeout( () => {
                // 	this.ps.initSpotifyPlayer()
                // }, 7000 )
                this.ps.initSpotifyPlayer()
                return true
            } else {
                let queryParams: Params = routeSnapshot.queryParams
                const tuneNumber = queryParams?.tune
                if (!tuneNumber)
                    throw 'No Tune number avaiable for play tune route'
                if (!this.ps.getSpotAccessToken()) {
                    this.auth.login({
                        redirectPath: topPath,
                        tune: tuneNumber,
                        calledFromGui: true,
                    })
                } else if (this.ps.hasCurrentSpotTokenExpired()) {
                    this.auth.login({ redirectPath: topPath, tune: tuneNumber })
                }
            }
        } else if (topPath.includes('/tune-list')) {
            if (this.ps.isCurrentSpotTokenValid()) {
                // console.log('got to delay init spot')
                // setTimeout( () => {
                // 	this.ps.initSpotifyPlayer()
                // }, 7000 )
                this.ps.initSpotifyPlayer()
                return true
            } else {
                let queryParams: Params = routeSnapshot.queryParams
                const tuneListNumber = queryParams?.list
                if (!tuneListNumber)
                    throw 'No Tune list number avaiable for play tune route'
                if (!this.ps.getSpotAccessToken()) {
                    this.auth.login({
                        redirectPath: topPath,
                        list: tuneListNumber,
                        calledFromGui: true,
                    })
                } else if (this.ps.hasCurrentSpotTokenExpired()) {
                    this.auth.login({
                        redirectPath: topPath,
                        list: tuneListNumber,
                    })
                }
                
            }
        } else {
            // if(ENV.DEBUG){
            // 	return true
            // }

            if (!this.cookieService.get('isSignedUp')) {

            this.loggingLocal.addLog(
                LoggingLocalRoutingEvents.NavigatingToSignUpPage,
                {
                    msg:`isSignedUp ${this.cookieService.get('isSignedUp')}`
                }
            )
            if (this.gS.isRunningAsPwa()) {
                    this.router.navigateByUrl('/sign-up')
                } else {
                    this.router.navigateByUrl('/sign-up')
                    //this.router.navigateByUrl('/wait-list')
                }
            } else if (this.ps.isCurrentSpotTokenValid()) {
                console.log(
                    `spotAccessToken fethed: ${this.ps.getSpotAccessToken()}`
                )

                this.ps.initSpotifyPlayer()

                // setTimeout( () => {
                // 	this.ps.initSpotifyPlayer()
                // },7000)

                this.freshSpotTokenFetched$.next({
                    displayName: this.cookieService.get('displayName'),
                })

                this.gS.setSpotFieldsFromCookie()

                return true

                //this.router.navigateByUrl('/login')
            } else {
                this.ps.setWebSdkPlayerNeedConstruction(true)
                this.auth.login({ redirectPath: topPath })
                //this.router.navigateByUrl('/login')
            }
        }
    }
}
