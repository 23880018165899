import { LoopProgressBarModule } from '../components/loop-progress-bar/loop-progress-bar.module'
import { DirectivesModule } from '../directives/directives.module';
import { FooterBarModule } from '../components/footer-bar/footer-bar.module';
import { MatIconModule } from '@angular/material/icon';
import { EditTuneRoutingModule } from './edit-tune-routing.module';
import { EditTunePage } from './edit-tune.page';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatSelectModule} from '@angular/material/select';
import { TextFieldModule} from '@angular/cdk/text-field'; 
import { MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSliderModule} from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { PoweredByRowModule } from '../components/powered-by-row/powered-by-row.module';
 
@NgModule({
  imports: [ 
    SharedModule,
    EditTuneRoutingModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    TextFieldModule,

    FormsModule,
    MatSliderModule,
    MatChipsModule,
    MatIconModule,
    FooterBarModule,

    CdkAccordionModule,
    LoopProgressBarModule,
    DirectivesModule,
	PoweredByRowModule
  ],
  declarations: [
    EditTunePage
    ],

    exports:[
    EditTunePage
    ]

})
export class EditTuneModule { }
 