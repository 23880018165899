<ion-header>
  <ion-toolbar>
    <ion-title>dev-router</ion-title>
  </ion-toolbar>
</ion-header>

<ion-grid>
	<ion-row>
		<ion-button [routerLink]="'/dev'">
			Dev2
		</ion-button>
	</ion-row>
	<ion-row>
	<ion-button [routerLink]="'/find-tune'">
		Find tune
	</ion-button>
	</ion-row>
	<ion-row>
	<ion-button [routerLink]="'/edit-tune'">
		Edit tune
	</ion-button>
	</ion-row>
	<ion-row>
	<ion-button [routerLink]="'/sign-up'">
		Sign up
	</ion-button>
	</ion-row>
	<ion-row>  
	<ion-button [routerLink]="'/play-tune'"  [queryParams]="{tune: '172'}">
		Play tune
	</ion-button>
	</ion-row>
	<ion-row>  
	<ion-button [routerLink]="'/chat'">
		Chat
	</ion-button>
	</ion-row>
	<ion-row>  
	<ion-button [routerLink]="'/chat-list'">
		Chat list
	</ion-button>
	</ion-row>

	<ion-row>  
	<ion-button [routerLink]="'/my-library'">
		My Library
	</ion-button>

	</ion-row>

	<ion-row style='margin-top: 3vh;'>
	<ion-checkbox [(ngModel)]=mGlobal.isAdmin>isAdmin</ion-checkbox>
	</ion-row>

	<ion-row>
		v{{env.DEPLOY_VERSION_MAJOR}}.{{env.DEPLOY_VERSION_MINOR}}
	</ion-row>


  </ion-grid>

