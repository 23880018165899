

<div id='lyrics-container' 
[ngClass]="disableScroll ? '' : 'scroll'" 
*ngIf="(selectedTuneLyricsFormat && ( shouldShow || shouldShowOverride))">

	<div class="content-wrapper" >
        {{selectedTuneLyricsFormat}}
    </div>


	<!-- <span id="lyrics-span">
		{{selectedTuneLyricsFormat}}
	</span> -->
</div>



