import { FooterBarRoutingModule } from './footer-bar-routing.module';
import { IonicModule } from '@ionic/angular';
import { FooterBarComponent } from './footer-bar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export enum SaveSendAction {
    save='SAVE',
    send='SEND',
    saveAndSend='SAVE_AND_SEND'
}


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FooterBarRoutingModule
  ],
  declarations: [
    FooterBarComponent
    ],

    exports:[
    FooterBarComponent,
    ]

})
export class FooterBarModule { }
 