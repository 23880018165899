

<!-- <ng-container *ngIf="reorderActive; else listElementTemplate">
    <ion-reorder>
        <ng-container *ngTemplateOutlet="listElementTemplate"></ng-container>
    </ion-reorder>
</ng-container>
    

<ng-template #listElementTemplate > -->

    <ng-container [ngSwitch]='displayElem?.type'>
        <ion-item 
            class="list-elem-item"
            [style.--border-style]="borderStyle"
            *ngSwitchCase='listElemTypes.slimSpotArtist'
            (click)='sendEvent($event)'
            >

            <ion-avatar slot='start'>
                <ion-img 
                    [src]='displayElem?.images[2]?.url'>
                </ion-img>
            </ion-avatar>
             <!-- <ion-thumbnail slot='start'>
                <ion-img [src]='displayElem.images[1].url'>
                </ion-img>
                </ion-thumbnail>
            -->     
            <div id='artist-name-container'>
                <div 
                id='artist-name-div'
                class='elem-font' >
                {{displayElem.name}}
                </div>
            </div>
        </ion-item>

        <!-- both TRACK and TUNE -->
        <ion-item 
            class="list-elem-item"[style.--border-style]="borderStyle"
            *ngSwitchDefault
            id="{{popOverTriggerStr}}"	
            (click)="sendEvent($event)"
        >
        <!--long-press [disableDir]="disableTouchGesture" (tap)="sendEvent($event)"
        long-press [disableDir]="disableTouchGesture" (press)="onLongPress($event)" -->
            <ion-thumbnail slot='start'>
                <ion-img 
                    class='ion-thumbnail-img'
                    [ngStyle]=" noPaddingLeftEnable ? {'padding-left':'0px'} : {}"
                    [src]='displayElem?.trackImg'
                    >
                </ion-img>
            </ion-thumbnail>
            <div class='name-container'>
                <div class='p-container-top' >
                    <div id='dummy-div'></div>
                    <div id='span-track-name' class='m-span span-top elem-font'>
                        {{displayElem?.trackName}}
                    </div>
                </div>
                <div class='p-container p-container-bottom'>
                    <span id='span-artist-name' class='m-span span-bottom elem-font'>
                        {{displayElem?.artistName}}
                    </span>
                </div>
            </div>
            
            <ng-container *ngIf="isSignedUp">
                <ion-button *ngIf = "!reorderActive; else reorderHandle" 
                    class="option-button"
                    icon-only 
                    slot="end"
                    fill="clear"
                    (click)="optionClick($event)"
                >
                <ion-icon

                    [src]="threeDotsIcon"
                >

                </ion-icon>
                </ion-button>
            </ng-container>
        </ion-item>
    </ng-container>
<!-- </ng-template> -->

<ng-template #reorderHandle>
    <ion-reorder class="reorder-handle" slot="end"></ion-reorder>
</ng-template>

<ion-popover 
	side="end"
	trigger-action="none"
	dismiss-on-select="true"
	show-backdrop="true"
	alignment="center"
>
<ng-template  >
	<ion-list class="pop-over-list" lines="full" >
		<ion-item class="pop-over-list-item" *ngIf="(isListElemTune && (spotId == elem?.createdBy?.spotId) || isAdmin)"
			[button]="true"
			(click)="popEditClick()">
			Edit Tune
		</ion-item>
		<ion-item *ngIf="isListElemTune && isSignedUp" 
		class="pop-over-list-item"
			[button]="true"
			(click)="popAddToListClick()">
			Add to list
		</ion-item>
		<ion-item *ngIf="enablePopOverRemoveTune && isSignedUp "
			class="pop-over-list-item"
			[button]="true"
			(click)="popRemoveFromListClick()">
			Remove from list
		</ion-item>
		<ion-item 
			*ngIf="isSignedUp"
			class = "pop-over-list-item"
			[button] = "true"
			(click) = "popMoreTunesClick()"
		>
		More Tunes
		</ion-item>
		<ion-item
		class="pop-over-list-item"
			(click)="popSpotClick()"
			lines="none">
			Open Spotify
		</ion-item>
	</ion-list>
</ng-template>
</ion-popover>