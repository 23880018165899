
<ion-row  id="container" >
		<ion-row id="close-row"><ion-icon (click)="close()" id="close-icon" src="{{closeButton}}"></ion-icon></ion-row>
		<ion-grid id="text-container">
			
			<ion-row id="first-row">
				For a better experience, install this web app by
			</ion-row>
			<ion-row class="list-row">
				<ion-col>1) Tap&nbsp;<ion-icon src="{{iosShareButton}}"></ion-icon> &nbsp;below</ion-col>
			</ion-row>
			<ion-row class="list-row">
				<ion-col>
					2) Tap Add to Home Screen &nbsp;<ion-icon id='aTHS' src="{{addToHomeScreenIcon}}"></ion-icon> &nbsp;
				</ion-col>
			</ion-row>
		</ion-grid>

		<div class="triangle-container">
			<svg height="5vh" width="5vh" viewBox="0 0 100 100">
				<polygon points="0,0 50,100 100,0" class="triangle" />
				<line class="line" x1="5" y1="0" x2="95" y2="0"></line>
		  </svg>
		 </div>
</ion-row>

