<ion-content style="--padding-bottom:{{contentPaddingBottom}}; --overflow:{{contentOverflow}}" >
    <ion-grid id="main-grid">
        <ion-row id='search-row' *ngIf='(mGlobal.isAdmin && !mGlobal.prod)'>
			<app-search-bar 
			(searchEntered)="searchBarSearchEntered($event)"
			[placeholder]="'searchBarPlaceholder'"	
		>
		</app-search-bar>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-img [src]="tune?.slimTuneTrack?.trackImg">
                </ion-img>
            </ion-col>
        </ion-row>

        <ion-row id="loop-p-b-row">
            <app-loop-progress-bar id="loop-p-b"></app-loop-progress-bar>
        </ion-row>
		<app-powered-by-row></app-powered-by-row>
        <ion-row *ngIf="mGlobal.isAdmin" class='ion-justify-content-center'>
			<ion-button class="themed-ion-button" (click)=debugTrigger()>
				Debug
            </ion-button>
            <ion-button (click)=prevTuneClicked() class="themed-ion-button">
                Prev Tune
            </ion-button>
            <ion-item id="input-item">
                <ion-input label = "" id='tuneIndexInput' type='number' min='0' max='2' (ionBlur)='tuneIndexLostFocus($event)'
                    ngModel='{{tuneIndex+1}}'>
                </ion-input>
                / {{loadedTunes?.length ?? 0}}
            </ion-item>
            <ion-button (click)=nextTuneClicked() class="themed-ion-button">
                Next Tune
            </ion-button>
        </ion-row>

        <br>
        <!--    <p>{{trackName}} &nbsp; • &nbsp; {{artistName}}</p> -->

        <ion-row>
            <ion-col>
                <p>
                    {{tune.slimTuneTrack.trackName}} &nbsp; • &nbsp; {{tune.slimTuneTrack.artistName}}
                </p>
            </ion-col>
        </ion-row>

        <ion-row id="slider-row" class='d-flex justify-content-center align-items-center'>
			<!-- <ion-col size="1"></ion-col> -->
            <ion-col id="slider-col" size="10">
				<ion-range aria-label="" id="slider-range" [(ngModel)]="sliderTimeGui" [max]="tune.slimTuneTrack.durationMs" [min]=0
					(ionKnobMoveStart)="sliderStartedMoving()"
					(ionKnobMoveEnd)="sliderStoppedMoving()"></ion-range>
            </ion-col>
            <ion-col id=slider-time-col size="2">
                {{sliderTimeCache/1000 | number:'1.0-0'}}
            </ion-col>
        </ion-row>

        <ion-row id="skip-time-row" class="center">
			<ion-col class="skip-time-col">
                <ion-button 
					(click)="rewind()"
					class="forwardRewindBtn themed-ion-button"
					>
                    <ion-icon 
						[src]="rewind5">
                    </ion-icon>
                </ion-button>
            </ion-col>
            <ion-col class="ion-justify-content-end ion-align-items-end skip-time-col">
                <ion-button 
                    buttontype="button"
					class="play-pause-loop-btn themed-ion-button"
                    (click)="togglePlayTrackClick()" 
					>
                    {{togglePlayText}}</ion-button>
            </ion-col>
            <ion-col class="skip-time-col">
                <ion-button 
                    buttonType="button"
                    (click)="loopTuneClick()"
					class="play-pause-loop-btn themed-ion-button"
					>
					{{toggleLoopText}}
                </ion-button>
            </ion-col>
			<ion-col class="skip-time-col">
                <ion-button 
					class="forwardRewindBtn themed-ion-button"
					(click)="forward()"
					>
                    <ion-icon 
						slot="icon-only"
						[src]="forward5">
                    </ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
        <!--<div class="d-flex flew-row flex-direction: column justify-content-center">
            <div class="d-flex justify-content-start" >
                <ion-button (click)="ps.seekTrackRelative(-timeShiftShift,tune.slimTuneTrack.trackId)" id="backward-backward-btn"><<</ion-button>
            </div>
            <div class="d-flex justify-content-start" >
                <ion-button type="button" (click)="ps.seekTrackRelative(-timeShift,tune.slimTuneTrack.trackId)" id="backward-btn"><</ion-button>
            </div>
            <div class="d-flex justify-content-start" >
                <ion-button type="button" (click)="ps.seekTrackRelative(timeShift,tune.slimTuneTrack.trackId)" id="forward-btn">></ion-button>
            </div>
            <div class="d-flex justify-content-start" >
                <ion-button type="button" (click)="ps.seekTrackRelative(timeShiftShift,tune.slimTuneTrack.trackId)" id="forward-forward-btn">>></ion-button>
            </div>
        </div> -->

        <ion-row >
            <ion-col class="ion-justify-content-end ion-align-items-end">
                <ion-button
                    style="float:right;"
                    buttonType="button" 
                    (click)="setStartTimeClick()"
					class="themed-ion-button"
                    >
                    Set start time
                </ion-button>
            </ion-col>
            <ion-col>
                <ion-button
					class="themed-ion-button"
                    style="float:left;"
                    buttonType="button"
                    (click)="setStopTimeClick()"
                    >
                    Set stop time
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row>

            <ion-col *ngIf=false>
                <ion-button (click)="storeTune()">
                    Store Tune
                </ion-button>
            </ion-col>

        </ion-row>

        <ion-row class="ion-align-items-center ion-justify-content-center disable-double-tap-zoom ">
            <ion-col class="ion-justify-content-end ion-align-items-end" size="2">
                <ion-row class="ion-justify-content-end">
                    <ion-button 
						class="incDecBtn themed-ion-button"
                        long-press (tap)="incStartTime()"
                        long-press (pressInit)="incDecTuneTime(1,'start')"
                        long-press (press)="clearIncDecTuneTimeInterval()"
                        >
							<ion-icon
								class="incDecIcon"
								[src]="upIcon"
								>
							</ion-icon>
                    </ion-button>
                </ion-row>
                <ion-row class="ion-justify-content-end ">
                    <ion-button 
						class="themed-ion-button"
						long-press (tap)="decStartTime()"
                        long-press (pressInit)="incDecTuneTime(-1,'start')"
                        long-press (press)="clearIncDecTuneTimeInterval()"
                        >
                        <ion-icon [src]=downIcon>

                        </ion-icon>
                    </ion-button>
                </ion-row>
            </ion-col>
            <ion-col size="3.5" class="ion-justify-content-center">
                <ion-item lines="full" class="startStopTimeItem">
                    <ion-label position="stacked" >Start time</ion-label>
                    <ion-input 
						aria-label="start time"
                        placeholder="0"
                        text-center
                        class="startStopTimeInput"
                        id="startTimeInput"
                        inputmode="decimal" 
                        max='tune.slimTuneTrack.durationMs/1000' 
                        min='0' 
                        step='0.1' 
                        type='text'
                        [(ngModel)]='startTimeS' 
                        (change)=startTimeChanged()
                        (keyup.enter)="gs.closeKeyboard($event)"
						enterkeyhint="done"
						>
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size="3.5" class="ion-justify-content-center" id="stopTimeCol" >
                <ion-item lines="full" class="startStopTimeItem">
                    <ion-label  class='startStopTimeLabel' position="stacked">Stop time</ion-label>
                    <ion-input 
						aria-label="stop time"
                        placeholder="0"
                        class="startStopTimeInput"
                        inputmode="decimal" 
                        max='tune.slimTuneTrack.durationMs/1000' 
                        min='0' 
                        step='0.1' 
                        type='text'
                        [(ngModel)]='stopTimeS' 
                        (change)=stopTimeChanged() 
                        (keyup.enter)="gs.closeKeyboard($event)"
						enterkeyhint="done">
                    </ion-input>
                </ion-item>
            </ion-col>
            <ion-col size="2">
                <ion-row >
                    <ion-button 
						class="themed-ion-button"
						long-press (tap)="incStopTime()"
                        long-press (pressInit)="incDecTuneTime(1,'stop')"
                        long-press (press)="clearIncDecTuneTimeInterval()">
                        <ion-icon
                        [src]="upIcon"
                        >
                        </ion-icon>
                    </ion-button>
                </ion-row>
                <ion-row>
                    <ion-button 
						class="themed-ion-button"
						long-press (tap)="decStopTime()"
                        long-press (pressInit)="incDecTuneTime(-1,'stop')"
                        long-press (press)="clearIncDecTuneTimeInterval()">
                        <ion-icon
                        [src]="downIcon"
                        >
                        </ion-icon>
                    </ion-button>
                </ion-row>
        </ion-col>
        </ion-row>
        <hr>
        <ion-row id="tagRow">
            <ion-col id="tagCol">
                <mat-form-field  
                    class = "{{ngMatMyClass}} mat-app-background"
                    id='tag-form'
                    appearance="fill"
                    style=" width: 100%;"
                    shouldLabelFloat='true'
                    floatLabel="always">
                    <mat-label  position='stacked' >Add tags here</mat-label>
                    <mat-chip-grid 
                        class = "{{ngMatMyClass}}" 
                        id="matChipList"
                        #chipList 
						>
						<span *ngFor="let tag of tune.tags">
							<mat-chip 
								*ngIf="!tag.canDelete"		
								[class.mat-chip-selected]="false"
								[removable]="false" 
								class="m-mat-chip my-light-theme" >
								{{'#'+tag.name}}
							</mat-chip>
							<mat-chip 
								*ngIf="tag.canDelete"		
								[class.mat-chip-selected]="true"
								[removable]="true" 
								(removed)=removeTag(tag)
								class="m-mat-chip my-light-theme" >
								{{'#'+tag.name}}
								<mat-icon matChipRemove>cancel</mat-icon>
							</mat-chip>

						</span>
                        <mat-chip 
                            *ngFor="let addedTagName of tagNamesToAdd"
                            [class.mat-chip-selected]="true" 
                            [removable]="true"
                            (removed)="removeAddedTagName(addedTagName)"
                            class="m-mat-chip"
                            color="primary"
                            selected>
                                {{'#'+addedTagName}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input  
                            placeholder="{{tagPlaceHolderActive}}"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputFor]="chipList"
                            [matChipInputAddOnBlur]="true"
                            (matChipInputTokenEnd)="addTagName($event.value,$event)"
                            [(ngModel)]="tagCurrentlyTyped"
                            (ngModelChange)="tagListChanged()"
                        >
                    </mat-chip-grid>
                </mat-form-field>
            </ion-col>
        </ion-row>
        <!--<ion-row>
            <ion-list>
                <ion-list-header>
                    <ion-label>
                        Categories
                    </ion-label>
                </ion-list-header>
                <ion-item class="radioItem">
                    <ion-label>Moods</ion-label>
                    <ion-radio slot="start" value="moodArray" id="radioTest"></ion-radio>
                </ion-item>        
                <ion-item class="radioItem">
                    <ion-label>Yes/No</ion-label>
                    <ion-radio slot="start" value="yesNoArray"></ion-radio>
                </ion-item>       
                <ion-item class="radioItem"> 
                    <ion-label> Reactions</ion-label>
                    <ion-radio slot="start" value="reactionArray"></ion-radio>
                </ion-item>             
            </ion-list>
        </ion-row>
        <div class="d-flex flex-row">
            <label id="example-radio-group-label">Categories</label>
            <mat-radio-group (change)="radioGrpChange($event)" #catOneRadioGrp id="mRadioGrpId"
                [(ngModel)]="checkBoxCategory" class="example-radio-group" aria-labelledby="example-radio-group-label">
                <mat-radio-button #moodRadioBtn [(value)]='moodArray' class="example-radio-button">
                    Mood
                </mat-radio-button>
                <mat-radio-button #yesNoRadioBtn [(value)]='yesNoArray' class="example-radio-button">
                    Yes/No
                </mat-radio-button>
                <mat-radio-button #reactionRadioBtn [(value)]='reactionArray' class="example-radio-button">
                    Reaction
                </mat-radio-button>

            </mat-radio-group>
            <div class="check-box-container-list">
                <ul>
                    <li *ngFor="let checkBoxName of checkBoxCategory.names; let i = index">
                        <mat-checkbox [(ngModel)]="checkBoxCategory.values[i]" class="example-margin">
                            {{checkBoxName}}
                        </mat-checkbox>
                    </li>
                </ul>
            </div>
        </div> -->

        <ion-row id="triggerRow">
		
            <ion-list lines="full" class="ion-no-margin" id="triggerList">
                <ion-item class="input-item">
                    <ion-label position="stacked">Message*</ion-label>
                    <ion-input 
						[disabled]="messageNa"
						label = ""
                        placeholder="E.g &quot;Lunch?&quot;"
                        [(ngModel)]="triggerSentence"
                        (ionBlur)="triggerBlur()"
                        (ionChange)="triggerChange()"
                        (keyup.enter)="gs.closeKeyboard($event)">
                    </ion-input>
                </ion-item>
                <ion-item class="input-item">
                    <ion-label position="stacked">Answer</ion-label>
                    <ion-input 
						[disabled]="messageNa"
						label = ""
                        placeholder={{answerPlaceholder}}
                        [(ngModel)]="autoComplete"    
                        (ionBlur)="autoCompleteBlur()"
                        (keyup.enter)="gs.closeKeyboard($event)"
                    >
                    </ion-input>
                </ion-item>
                <ion-item *ngIf="!triggerStarted" class="input-item">
                    <ion-label disabled=true position="stacked">Tune to send</ion-label>
                    <ion-input [disabled]="messageNa" label = "" placeholder="Hungry like a wolf - Duran Duran"></ion-input>
                </ion-item>
            </ion-list>
        </ion-row>
		<ion-row id="message-checkbox-row">
			<ion-checkbox 
				[(ngModel)]="messageNa"
				(ionChange)="messageNaChange($event)"
				>No Message
			</ion-checkbox>
		</ion-row>
        <ion-row> 
            <ion-col>
                <cdk-accordion class="example-accordion">
                    <cdk-accordion-item
                    expanded="{{debug}}"
                    role="button"
                    #accordionItem="cdkAccordionItem"
                    class="example-accordion-item">
						<ion-row class="accordion-item-header-row" (click)="mAccordionLyricsExpandedToggle()">
							<ion-col id='accordion-item-header-col-label'>
								Select Lyrics
							</ion-col>
							<ion-col id='accordion-item-header-col-icon' size="2">
								<ion-icon id="accordion-item-header-icon" [src]="accordionItem.expanded ? upArrowHead : downArrowHead ">
								</ion-icon>    
							</ion-col>    
						</ion-row>
						<ion-row
						role="region"
						[style.display]="mAccordionLyricsExpanded ? '' : 'none'"
						[attr.id]="'accordion-body-'"
						[attr.aria-labelledby]="'accordion-header-'"
						>
							<ion-col id="lyrics-spinner-col">
								<ion-spinner *ngIf="false; else lyricsArrTemplate"></ion-spinner>
							</ion-col>
							<ng-template #lyricsArrTemplate>
								<ion-grid (click)="lyricsGridClicked()">
									<ion-row>
										<ion-col id="no-lyrics-div" *ngIf="lyricsMissing; ">
											No lyrics found for track
										</ion-col>
									</ion-row>
									<ion-row>
										<ion-col>
											<ion-button  id="open-modal" expand="block" class="themed-ion-button">Type Lyrics instead</ion-button>
										</ion-col>
									</ion-row >
									<ion-row *ngIf="!lyricsMissing; " >
										<ion-col id="lyricsContainer">
											<span 
												class="select-text"
												style="white-space: pre-line"
												*ngFor="let word of lyricsArray; let i = index"
												id="{{i}}"
												[attr.data-lyricword]="word"
												>
												{{word}}
											</span>
										</ion-col>
									</ion-row>
	
								</ion-grid>

								<ion-modal id="custom-lyrics-modal" handle="false" trigger="open-modal" #modal handleBehaviour="none">
									<ng-template>
										<ion-header mode="ios" style="height:20%">
											<ion-toolbar style="height:100%">
												<ion-buttons slot="start">
													<ion-button id="modalToolbarDoneButton" (click)="modal.dismiss()">Close</ion-button>
												  </ion-buttons>
												  <ion-title>Lyrics</ion-title>
												  <ion-buttons slot="end" >
													<ion-button id="modalToolbarDoneButton" (click)="saveCustomLyrics()">Save</ion-button>
												  </ion-buttons>
											</ion-toolbar>
										  </ion-header>
<!-- 										  <div id="modalContent"> -->
											<ion-grid >
												<ion-row>
													<ion-textarea #mIonTextArea  auto-grow="false" placeholder="Type the lyrics here" id="modalTextArea" [(ngModel)]="customLyricsTextArea">
						
													</ion-textarea>
												</ion-row>
											</ion-grid>

						<!-- 				  </div> -->
						
									</ng-template>
								  </ion-modal>
								  
							</ng-template>
						</ion-row> 
                    </cdk-accordion-item>
				</cdk-accordion>
            </ion-col>
        </ion-row>
        <hr>

        <ion-row style="width:100%" >
            <ion-col>
                <ion-checkbox 
                    checked="true"
                    (ionChange)="saveTuneOnlyChange($event)"
                    id="saveOnlyBCheckBox"
					>
					Save without sending?
                </ion-checkbox>
            </ion-col>
        </ion-row>
		
    </ion-grid>
</ion-content>

<app-footer-bar 
    [selectedTune]='tune'
    [goBackAfterSaveSendAction]=true
    [saveSendAction]="saveSendAction"
    [getTuneUpdate]="getTuneUpdateBinded"
	[setTuneSaveFields]="setTuneSaveFieldsBinded"
	(barBackEmittor)="footerBarBackClick()"
	(tuneSavedOrSent)="footerBarTuneSaved()"
	>
</app-footer-bar>