import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { MyServerService } from '../services/my-server/my-server.service';
import { mGlobal } from '../mglobal'
import { SaveSendAction } from '../components/footer-bar/footer-bar.module';
import { PlayerService } from '../services/music/player.service';
import { MPlayerState } from 'native-volume-control-npm-package-name';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '../services/error-handler/error-handler.service';

interface ITuneDurationDiv {
	marginLeft:string,
	width : string,
	tune : any
	tuneIndex : number
}
@Component({
  selector: 'app-track',
  templateUrl: './track.page.html',
  styleUrls: ['./track.page.scss'],
})

export class TrackPage implements OnInit {

	slimTuneTrack
	tracksTunes = []
	mGlobal = mGlobal
	saveSendActionE = SaveSendAction
	selectedTune 

	tuneDurationDivContainerS : ITuneDurationDiv[][] = [[]]

	backIcon = '../../assets/chevron-back-outline.svg'
	forwardIcon = '../../assets/chevron-forward-outline.svg'

	toggleLoopTextLoop: string = "Loop"
    toggleLoopTextPause: string = "Pause"
    toggleLoopText: string = this.toggleLoopTextLoop

	tuneIndex = 0
	prevTuneIndex = null
	tuneDurationDivWidth = 0.9 // 90dvw

	get tuneIndexGui(){
		return `${this.tuneIndex+1} / ${this.tracksTunes?.length}`
	}

	constructor(
		private gs : GlobalService,
		private mServer : MyServerService,
		private changeDetector : ChangeDetectorRef,
		private ps : PlayerService,
		private router : Router,
		private eh : ErrorHandlerService
	) { }

	ngOnInit() {
		console.log('trackInit')
	}

	initVariables(){
		this.tuneIndex = 0
		this.prevTuneIndex = null
		this.tuneDurationDivContainerS = [[]]
		this.slimTuneTrack = null
		this.tracksTunes = []
		this.selectedTune = null
	}

	ngAfterViewInit(){
		this.ps.playerStateChanged$.subscribe((playerState : MPlayerState) => {

			this.toggleLoopText = playerState.isPlaying ? this.toggleLoopTextPause : this.toggleLoopTextLoop
			this.changeDetector.detectChanges()
		
        })
	}

	ionViewWillEnter(){


	}

	ionViewDidEnter(){

        this.gs.setPendingHistoryBackClick(false)

		this.initVariables()

		const debugSlimTuneTrack = {
            artistName: 'George Michael',
            trackId: '4jDmJ51x1o9NZB5Nxxc7gY',
            trackImg:
                'https://i.scdn.co/image/ab67616d00001e0264c19b24ce947ffa363f8f96',
            trackName: 'Careless Whisper',
            durationMs: 300106,
        }

		if( history?.state?.slimTuneTrack){
			this.slimTuneTrack = history?.state?.slimTuneTrack ?? debugSlimTuneTrack
		} else if (history?.state?.slimSpotTrack){
			const slimSpotTrack = history?.state?.slimSpotTrack
			this.slimTuneTrack = {	
				artistName: slimSpotTrack.artistName,
				trackId: slimSpotTrack.trackId,
				trackImg: slimSpotTrack.images[0].url,
				trackName: slimSpotTrack.trackName,
				durationMs: slimSpotTrack.durationMs,
			}
		}

		this.mServer.getTracksTunesByTrackNameAndArtistName(this.slimTuneTrack?.trackName,this.slimTuneTrack?.artistName).subscribe( tunes => {
			this.tracksTunes = tunes.sort((a, b) => a.startTime - b.startTime);
			
			this.selectedTune = this.tracksTunes[0]
			//this.changeDetector.detectChanges()
			this.populateTimeSlider()
			
		} , e => {
			console.error("Error getting track's Tunes",e)
			this.eh.logSentryError(e)
		})
		
	}

	populateTimeSlider(){

		const tuneDurationContainerWidthPx = this.tuneDurationDivWidth * window.innerWidth;

		for( const [index1,tune] of this.tracksTunes.entries()){
			
			let foundSlot : boolean = false
			
			const tuneWidth = Math.round(( tune.stopTime - tune.startTime ) / this.slimTuneTrack.durationMs * tuneDurationContainerWidthPx) + 'px'
			const marginLeft = Math.round( tune.startTime  / this.slimTuneTrack.durationMs * tuneDurationContainerWidthPx) + 'px'

			const tuneDurationDivObj : ITuneDurationDiv = {
				marginLeft:marginLeft,
				width:tuneWidth,
				tune: tune,
				tuneIndex : index1
			}

			console.log(tune)

			if(this.tuneDurationDivContainerS[0].length == 0){
				this.tuneDurationDivContainerS[0][0] = tuneDurationDivObj;
			} else {

				for( const [index2,tuneDurationDivContainer] of this.tuneDurationDivContainerS.entries()){
					if(foundSlot){
						break
					}

					if(tuneDurationDivContainer[tuneDurationDivContainer.length-1].tune.stopTime < tune.startTime){

							tuneDurationDivContainer.push(tuneDurationDivObj)
							foundSlot = true;
						
					} else {
						this.tuneDurationDivContainerS.push([tuneDurationDivObj])
						foundSlot = true
					}

				}
			}
			
			if(index1==0){

			}
		}
		console.log('yeay1')
		this.changeDetector.detectChanges()
		console.log('yeay2')
		console.log('yeay')
	}
	

	toggleLoopTuneClick(){
        this.ps.loopTuneRequest(this.selectedTune)
    }

	editNewTune(){
		this.router.navigate(
			['/edit-tune'],
			{ state: { 
				slimTuneTrack: this.selectedTune.slimTuneTrack,
				trackPagePrevRoute: this.gs.getPrevRoute()
			} } 
		);
	}

	nextTune(){
		
		this.prevTuneIndex = this.tuneIndex

		this.tuneIndex++
		if(this.tuneIndex > this.tracksTunes.length-1)
			this.tuneIndex = 0
		
		this.selectedTune = this.tracksTunes[this.tuneIndex]

		const tuneDivPrev = document.getElementById('tune-div-'+this.prevTuneIndex)
		const tuneDiv = document.getElementById('tune-div-'+this.tuneIndex)
		if(tuneDivPrev)
			tuneDivPrev.classList.remove('tune-div-selected')
		if(tuneDiv)
			tuneDiv.classList.add('tune-div-selected')

		this.ps.loopTuneRequest(this.selectedTune)

	}

	prevTune(){
		this.prevTuneIndex = this.tuneIndex
		this.tuneIndex--
		if(this.tuneIndex < 0)
			this.tuneIndex = this.tracksTunes.length - 1

		this.selectedTune = this.tracksTunes[this.tuneIndex]

		const tuneDivPrev = document.getElementById('tune-div-'+this.prevTuneIndex)
		const tuneDiv = document.getElementById('tune-div-'+this.tuneIndex)

		if(tuneDivPrev)
			tuneDivPrev.classList.remove('tune-div-selected')
		if(tuneDiv)
			tuneDiv.classList.add('tune-div-selected')

		this.ps.loopTuneRequest(this.selectedTune)

	}



}
