import { NotRecommendedBrowserComponent } from './../components/modals/not-recommended-browser/not-recommended-browser.component'

import {
    IFeLogError,
    IFeLogErrorBase,
} from './../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared'
import { HttpClient } from '@angular/common/http'
import {
    MNgThemesE,
    ColorThemeE,
    DevicePlatformE,
    ToastEnum,
    UserSettingsKeysE,
    BrowsersE,
    ModalsE,
} from './../classes-enums-interfaces-types/enums/enums.enum'
import {
    AlertController,
    ModalController,
    ToastController,
} from '@ionic/angular'

import { getPlatform, Injectable, Injector, OnInit } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject, interval } from 'rxjs'
import { RouteEnum } from '../classes-enums-interfaces-types/enums/enums.enum'
import {
    IMGlobalError,
    PwaUpdateAvailable,
    TuneItLocalStorage,
    TuneItLocalStorageFlags,
    UserStoredSettings,
} from '../classes-enums-interfaces-types/interfaces/interfaces'
import { Preferences } from '@capacitor/preferences'
import {
    ActivationEnd,
    NavigationEnd,
    NavigationStart,
    ResolveEnd,
    Router,
} from '@angular/router'
import { MError } from '../../../../backend/classes-enums-interfaces-types/classes/errors.shared'
import { PlayerService } from './music/player.service'
import { ENV } from '../../../environments/environment'
import * as Sentry from '@sentry/angular-ivy'
import { HelperFunctionsService } from './helper-functions/helper-functions.service'
import { ErrorHandlerService } from './error-handler/error-handler.service'
import { Keyboard } from '@capacitor/keyboard'
import {
    CookieFields,
    SpotExtraPermissions,
} from '../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum'
import { mGlobal } from '../mglobal'

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    capPlatform: string
    webOS: string
    sideMenuToggle$: BehaviorSubject<{ open: boolean; menuId?: string }> =
        new BehaviorSubject<{ open: boolean; menuId?: string }>({
            open: false,
            menuId: null,
        })
    deferredPrompt: any
    mNgTheme: MNgThemesE = MNgThemesE.dark
    darkLightMode$: BehaviorSubject<ColorThemeE> =
        new BehaviorSubject<ColorThemeE>(ColorThemeE.dark)
    showPwaPrompt$: BehaviorSubject<{
        devicePlatform: DevicePlatformE
        show: boolean
    }> = new BehaviorSubject(null)
    debug = ENV.DEBUG
    private spotId
    visibilityChangeVisItr = 0
    visibilityChangeHiddenItr = 0
    isToastShowing = false
    silentTrackId = '5XSKC4d0y0DfcGbvDOiL93'
    //silentTrackId = '7iL6o9tox1zgHpKUfh9vuC'
    pwaUpdateAvailable$: BehaviorSubject<PwaUpdateAvailable> =
        new BehaviorSubject<PwaUpdateAvailable>({ pwaReadyForInstall: false })
    settingsLoad

    userSettingsDefault: UserStoredSettings = {
        loopLimit: mGlobal.loopLimit.default,
        prefersColorTheme: ColorThemeE.dark,
        showLyricsOnFirstLoop: true,
        hideLyricsAlways: false,
        experimentalLooping: true,
        hideLibraryPermissionInfo: false,
    }

    localStorageFlags: TuneItLocalStorageFlags = null

    localStorageFlagsDefault: TuneItLocalStorageFlags = {
        hasDismissedBrowserRecommendation: false,
        hasDismissedPwaInstallPrompt: false,
    }

    userSettings: UserStoredSettings
    userSettings$: BehaviorSubject<UserStoredSettings> =
        new BehaviorSubject<UserStoredSettings>(null)

    mRouterEventParms = {
        // to not reload ui if action in lower page was canceled e.g. edit tune, no need to reload gui tune lib list
        pendingHistoryBackClick: <boolean>false,
        previousUrl: <string>null,
    }

    currentUrl: string = null
    spotName: any
    spotEmail: string

    constructor(
        private cookieService: CookieService,
        private toastController: ToastController,
        private router: Router,
        private injector: Injector,
        private helper: HelperFunctionsService,
        private modalControl: ModalController
    ) {
        this.capPlatform = Capacitor.getPlatform()
        this.webOS = this.getDevicePlatform()

        this.initAsync()
        //this.platform = 'ios'

        this.router.events //.pairwise()
            .subscribe((event) => {
                //console.log('gService router event')
                //console.log(event)
                if (event instanceof NavigationStart) {
                    if (event.navigationTrigger === 'popstate') {
                        // circular depency
                        Sentry.addBreadcrumb({
                            category: 'pauseUndefined',
                            message: 'global popstate',
                            level: 'info',
                        })
                    }
                }
                if (event instanceof NavigationEnd) {
                    //this.mRouterEventParms.pendingHistoryBackClick = false
                    //console.log("mmm navEnd")
                    //this.injector.get(PlayerService).pauseTrack()

                    this.mRouterEventParms.previousUrl = this.currentUrl
                    this.currentUrl = event.urlAfterRedirects

                    if (
                        event.urlAfterRedirects == '/sign-up' ||
                        event.urlAfterRedirects == '/login'
                    ) {
                        console.log('sign up router reg.')
                        // why set timeout?
                        setTimeout(() => {
                            console.log(
                                `devicePlatform: ${this.getDevicePlatform()}`
                            )
                            if (
                                this.getDevicePlatform() ==
                                DevicePlatformE.android
                            ) {
                                console.log(this.deferredPrompt)
                                if (this.deferredPrompt) {
                                    this.deferredPrompt
                                        .prompt()
                                        .then((userChoice) => {
                                            console.log(
                                                `Pwa user prompt outcome: ${userChoice?.outcome}`
                                            )
                                        })
                                }
                            } else if (
                                (this.getDevicePlatform() ==
                                    DevicePlatformE.ios &&
                                    !this.isRunningAsPwa()) ||
                                this.debug
                            ) {
                                this.showPwaPrompt$.next({
                                    devicePlatform: DevicePlatformE.ios,
                                    show: true,
                                })
                            }
                        }, 800)
                    }

                    this.runningRecommendedBrowserCheck(event.urlAfterRedirects)
                }
            })
    }
    spotAccessToken: string = null

    async initAsync() {
        // use a key like a value and stringify with JSON if more settings need to be added

        try {
            const settings: string = (
                await Preferences.get({ key: 'userSettings' })
            ).value
            this.userSettings = JSON.parse(settings)

            if (this.userSettings) {
                for (const key in this.userSettingsDefault) {
                    if (!this.userSettings.hasOwnProperty(key)) {
                        this.userSettings[key] = this.userSettingsDefault[key]
                    }
                }
            } else {
                this.userSettings = this.userSettingsDefault
                this.userSettingUpdatePreferences()
            }

            this.setDarkLightMode(this.userSettings.prefersColorTheme)

            this.userSettings$.next(this.userSettings)

            const flags: string = (await Preferences.get({ key: 'flags' }))
                .value

            this.localStorageFlags = JSON.parse(flags)
            if (this.localStorageFlags) {
                for (const key in this.localStorageFlags) {
                    if (!this.localStorageFlags.hasOwnProperty(key)) {
                        this.localStorageFlags[key] =
                            this.localStorageFlags[key]
                    }
                }
            } else {
                this.localStorageFlags = this.localStorageFlagsDefault
                this.storageFlagsUpdatePreferences()
            }

            this.runningRecommendedBrowserCheck(this.currentUrl)
        } catch (e) {
            console.error(`Error parsing user settings from JSON`)
            this.injector.get(ErrorHandlerService).logSentryError(e)
        }
    }

    runningRecommendedBrowserCheck(route: string) {
        if (!route.includes('/play-tune') || !route.includes('/tune-list')) {
            if (
                this.localStorageFlags != null &&
                !this.localStorageFlags.hasDismissedBrowserRecommendation &&
                !this.isRunningRecommendedBrowser()
            ) {
                let modal
                setTimeout(() => {
                    modal = this.modalControl
                        .create({
                            component: NotRecommendedBrowserComponent,
                            id: ModalsE.notRecommendedBrowser,
                            cssClass: 'ion-modal-default',
                        })
                        .then((modal) => {
                            modal.onDidDismiss().then(() => {
                                this.localStorageFlags.hasDismissedBrowserRecommendation =
                                    true
                                this.storageFlagsUpdatePreferences()
                            })
                            modal.present()
                        })
                }, 1000)
            }
        }
    }

    isNativeCapPlatform(): boolean {
        return this.capPlatform == 'web' ? false : true
    }

    isRunningRecommendedBrowser(): boolean {

        if (this.getDevicePlatform() == DevicePlatformE.ios) {
            if (this.isRunningInBrowserName(BrowsersE.safari)) {
                return true
            }
        } else if (this.getDevicePlatform() == DevicePlatformE.android) {
            if (this.isRunningInBrowserName(BrowsersE.chrome)) {
                return true
            }
        } else {
            // better to not be annoying if unexptec behavuior
            return true
        }
        
    }

    getCapPlatform() {
        return this.capPlatform
    }

    getDevicePlatform(): DevicePlatformE {
        const userAgent = navigator.userAgent
        //console.log('userAgent')
        //console.log(userAgent)
        if (/android/i.test(userAgent)) {
            return DevicePlatformE.android
        } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
            return DevicePlatformE.ios
        } else {
            return DevicePlatformE.unknown
        }
    }

    isRunningIos(): boolean {
        return this.getDevicePlatform() == DevicePlatformE.ios
    }

    isRunningInMessengerBrowser(): boolean {
        var ua = navigator.userAgent || navigator.vendor
        return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
    }

    isRunningInChromeSim(): boolean {
        const isChrome = window.navigator.userAgent.indexOf('Mobile') !== -1
        return isChrome
    }

    hasActiveSession() {
        return this.cookieService.check('sid') ? true : false
    }

    // #opt this is umbrella for all extra read library permissions aka started,
    // private collaborative as they are accepted together atp

    getSpotExtraPermissionReadPrivateLists(): boolean {

        let spotExtraPermissionsStr: string = this.cookieService.get(
            'spotExtraPermissions'
        )
        if (!spotExtraPermissionsStr) return false

        let spotExtraPermissions: SpotExtraPermissions = JSON.parse(
            spotExtraPermissionsStr
        )
        return spotExtraPermissions.includes(
            SpotExtraPermissions.userLibraryRead
        )
            ? true
            : false
            
    }

    isRunningOnAndroid(): boolean {
        return /Android/i.test(navigator.userAgent)
    }

    isRunningInBrowserName(browserName: BrowsersE): boolean {
        const userAgent = navigator.userAgent
        const vendor = navigator.vendor

        switch (browserName) {
            case BrowsersE.chrome:
                return /Chrome/.test(userAgent) && /Google Inc/.test(vendor)
            case BrowsersE.safari:
                // Note: Safari's user agent also includes "Version/" prefix
                return /Safari/.test(userAgent) && /Apple Computer/.test(vendor)
            // Add more cases as needed for other browsers
            default:
                return false
        }
    }

    //can only clear non httpOnly cookies, httpOnly is cleared on backend
    clearAllCookies() {
        this.cookieService.deleteAll()
    }

    getUserId(): string {
        //#opt error handling
        return this.cookieService.get('userId')
    }

    globalError$ = new BehaviorSubject<IMGlobalError>({
        error: null,
        forRoute: null,
    })

    // only supports two toast at the same time, will bug nicely if more than 2 toast or different duration
    // #OPT input parametes should be able to do cleaner w/o o.<>
    async showToast(
        // msg: string,
        // header: string = null,
        // duration: number = 3000,
        // color: string = 'dark',
        // type:  ToastEnum = ToastEnum.default
        o: {
            msg: string
            header?: string
            duration?: number
            color?: string
            type?: ToastEnum
        } // dark means contrast, e.g light in dm
    ) {
        o.duration = o.duration ?? 3000
        o.type = o.type ?? ToastEnum.default

        const toast = await this.toastController.create({
            color: o.color,
            duration: o.duration,
            header: o.header,
            message: o.msg,
        })

        const cssClasses: string[] = []

        if (o.type == ToastEnum.warning && o.color != 'dark') {
            toast.color = 'warning'
            cssClasses.push('toast-white-border')
        } else if (o.type == ToastEnum.error && o.color != 'dark') {
            toast.header = o.header ?? 'Error'
            toast.color = 'danger'
            cssClasses.push('toast-white-border')
        } else {
            cssClasses.push('toast-with-border')
        }

        if (this.isToastShowing) {
            cssClasses.push('ion-toast-double-space')
        }

    toast.cssClass = cssClasses
        toast.present()
        this.isToastShowing = !this.isToastShowing
        setTimeout(() => {
            this.isToastShowing = !this.isToastShowing
        }, o.duration)
    }

    getUserAgent() {
        return window.navigator.userAgent
    }

    requestSideMenuToggle(open: boolean, menuId?: string) {
        this.sideMenuToggle$.next({ open: open, menuId: menuId })
    }

    getDeferredPrompt() {
        return this.deferredPrompt
    }

    setDeferredPrompt(dP: any) {
        if (dP) this.deferredPrompt = dP
    }
    /* 
    setAuthToken(newAuthToken){

        console.log("New Auth Token " + newAuthToken);
        this.authToken = newAuthToken.split("=")[1].split("&")[0]; 
        
    }; */

    setDarkLightMode(mode: ColorThemeE) {
        if (mode == ColorThemeE.light || mode == ColorThemeE.dark) {
            this.userSettings.prefersColorTheme = mode
            this.userSettingUpdatePreferences()

            if (mode == ColorThemeE.light) {
                document.body.classList.remove('m-ionic-dm')
                document.body.classList.add('m-ionic-lm')
                this.mNgTheme = MNgThemesE.light
            } else if (mode == ColorThemeE.dark) {
                document.body.classList.remove('m-ionic-lm')
                document.body.classList.add('m-ionic-dm')
                this.mNgTheme = MNgThemesE.dark
            }

            this.userSettings$.next(this.userSettings)
        }
    }

    setShowLyricsOnFirstLoop(show: boolean) {
        if (show !== null) {
            this.userSettings.showLyricsOnFirstLoop = show
            this.userSettingUpdatePreferences()
            this.userSettings$.next(this.userSettings)
        }
    }

    setHideLibraryPermissionInfo(hideInfo: boolean) {
        if (hideInfo !== null) {
            this.userSettings.hideLibraryPermissionInfo = hideInfo
            this.userSettingUpdatePreferences()
            this.userSettings$.next(this.userSettings)
        }
    }

    setHideLyricsAlways(hide: boolean) {
        if (hide !== null) {
            this.userSettings.hideLyricsAlways = hide
            this.userSettingUpdatePreferences()
            this.userSettings$.next(this.userSettings)
        }
    }

    setExperimentalLooping(experimentalLooping: boolean) {
        if (experimentalLooping !== null) {
            this.userSettings.experimentalLooping = experimentalLooping
            this.userSettingUpdatePreferences()
            this.userSettings$.next(this.userSettings)
        }
    }

    userSettingUpdatePreferences() {
        const userSettings = JSON.stringify(this.userSettings)

        Preferences.set({
            key: 'userSettings',
            value: userSettings,
        })
    }

    async storageFlagsUpdatePreferences() {
        const flags = JSON.stringify(this.localStorageFlags)

        await Preferences.set({
            key: 'flags',
            value: flags,
        })
    }

    // seemds decap, was to see if routing sub event was done by backclick
    setPendingHistoryBackClick(value: boolean) {
        console.log(`set pending back ${value}`)
        this.mRouterEventParms.pendingHistoryBackClick = value
    }

    getPrevRoute(): string {
        return this.mRouterEventParms.previousUrl
    }

    isRunningAsPwa(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches
            ? true
            : false
    }

    setSpotFieldsFromCookie() {
        this.spotId = this.cookieService.get('spotId')
        this.spotName = this.cookieService.get('spotName')
        this.spotEmail = this.cookieService.get('spotEmail')
    }

    getSpotId(): string {
        return this.spotId
    }

    getSpotName(): string {
        return this.spotName
    }

    getSpotEmail(): string {
        return this.spotEmail
    }

    getTuneItVersion(): string {
        return `${ENV.DEPLOY_VERSION_MAJOR}.${ENV.DEPLOY_VERSION_MINOR}`
    }

    setPwaUpdateAvailable(value: PwaUpdateAvailable) {
        this.pwaUpdateAvailable$.next(value)
    }

    setLoopLimit(loopLimit: number) {
        this.userSettings.loopLimit = loopLimit
        this.userSettingUpdatePreferences()
        this.userSettings$.next(this.userSettings)
    }

    closeKeyboard(event) {
        event.srcElement.blur() // drops input cursor
        if (this.isNativeCapPlatform()) Keyboard.hide()
    }

    isNativeSafari(): boolean {
        const isStandalone =
            'standalone' in window.navigator && window.navigator['standalone']
        // In-app browsers usually don't have these properties
        const hasSafariInterface = 'webkitFullscreenEnabled' in document

        if (!hasSafariInterface && !isStandalone) {
            return true
        }

        return false
    }

    isSignedUp(): boolean {
        // signed up means registered, with a display name
        return this.cookieService.get('isSignedUp') == 'true' ? true : false
    }

    getUserDisplayName(): string {
        return this.cookieService.get('displayName')
    }

    getReadPrivateSpotLists(): boolean {
        const spotExtraPermissionsStr = this.cookieService.get(
            CookieFields.spotExtraPermissions
        )
        if (!spotExtraPermissionsStr) return false
        const spotExtraPermissions = JSON.parse(spotExtraPermissionsStr)

        // umbrella for all 3 extra read permissions which are all accepted together
        const res: boolean = spotExtraPermissions.includes(
            SpotExtraPermissions.playlistReadPrivate
        )

        return res
    }
}
