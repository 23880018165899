<ion-header>
  <ion-toolbar>
    <ion-buttons slot='start'>
        <ion-button>
            <ion-icon slot="icon-only" [src]='customMenu'></ion-icon>
          </ion-button>
    </ion-buttons>   
    <ion-item id="search-item-field" >
        <ion-input  
			label = ""
            [(ngModel)]="contactSearch"
            id='search-input-field'
            placeholder="Search for contact"
            type="text"
            (keydown.enter)="contactEntered()"
        >
        </ion-input>
      </ion-item>
    <ion-buttons slot='end'>
        <ion-button>
            <ion-icon slot="icon-only" [src]='customSearch'></ion-icon>
          </ion-button>
    </ion-buttons>   
  </ion-toolbar>
</ion-header> 

<ion-content>
    <ion-item
        *ngFor = 'let foundUser of foundUsers'
        (click)="foundUserClicked(foundUser)"
    > <span>
        {{foundUser.displayName}}
    </span>   
    </ion-item>
    <ion-list>
        <ion-item
            *ngFor = 'let foundUser of foundUsers'
            (click)="foundUserClicked(foundUser)"
        > <span>
            {{foundUser.displayName}}
        </span>   
        </ion-item>
    </ion-list>
    <ion-list lines='inset'> 
        <ion-item
            *ngFor = 'let conversation of conversations'
            (click)='conversationClicked(conversation)'
        ><span *ngFor = 'let participant of conversation.participants; let i = index'>
            {{participant.displayName}}<span *ngIf='i!=conversation.participants.length-1'>,&nbsp;</span>
        </span><!-- linebreaks matter for spaces in html view-->
        </ion-item>
    </ion-list>
</ion-content>

<app-footer-bar
    (barBackEmittor)='backClick()'
></app-footer-bar> 