<ion-header>
  <ion-toolbar>
    <ion-title>Track's Tunes</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
	<ion-grid>
        <ion-row id='img-row'>
            <ion-col id="img-col">
                <ion-img [src]="slimTuneTrack?.trackImg">
                </ion-img>
            </ion-col>
        </ion-row>
		<ion-row>
            <ion-col>
                <p>
                    {{slimTuneTrack?.trackName}} &nbsp; • &nbsp; {{slimTuneTrack?.artistName}}
                </p>
            </ion-col>
        </ion-row>
		<ion-row class="flex-center-row">
			<ion-col>
				<ion-button
				(click) = "toggleLoopTuneClick()"
				id="loop-pause-button"
				>
				{{toggleLoopText}}    
				</ion-button>
			</ion-col>
			<ion-col>
				<ion-button (click) = "editNewTune()">
					New Tune
				</ion-button>
			</ion-col>

		</ion-row>
		<ion-row class="flex-center-row">
			<ion-label >Tune</ion-label>
		</ion-row>
		<ion-row class="flex-center-row">
			<ion-col>
				<ion-button
					(click)="prevTune()">
					<ion-icon 
					[src]="backIcon"
					slot='icon-only'
					>

					</ion-icon>
				</ion-button>
			</ion-col>
            <ion-col size="3.5" class="ion-justify-content-center">
			
                <ion-item  lines='full'id="input-item">
               
                    <ion-input 
						[value]="tuneIndexGui"
						[readonly]="true"
					>
						
                    </ion-input>
                </ion-item>
            </ion-col>
			<ion-col>
				<ion-button
					(click)="nextTune()">
					<ion-icon 
						[src]="forwardIcon"
						slot='icon-only'
					></ion-icon>
				</ion-button>
			</ion-col>
		</ion-row>

		<ion-row class="flex-center-row" id="tune-div-row">
			<div *ngFor="let tuneDurationDivContainer of tuneDurationDivContainerS; let i=index"
				id="tune-duration-div-container-{{i}}"
				class="tune-duration-div-container">
				<div 
					*ngFor="let tuneDurationDiv of tuneDurationDivContainer; let j=index"
					style="margin-left:{{tuneDurationDiv.marginLeft}}; width:{{tuneDurationDiv.width}}"
					id="tune-div-{{tuneDurationDiv.tuneIndex}}"
					[ngClass]="{'tune-div-selected': i===0 && j===0, 'tune-div':true}"

				></div>
			</div>
		</ion-row>

		<ion-row id="lyrics-row">
			<app-lyrics 
				[shouldShowOverride]="true"
				[disableScroll]="true"
				style=
					"width:100vw;
					justify-self: end;
					align-self: end;"
				[selectedTuneLyrics]="selectedTune?.lyrics?.lyricsStr"
			></app-lyrics>
		</ion-row>
	</ion-grid>
</ion-content>
<app-loop-progress-bar id="loop-pb"></app-loop-progress-bar>
<app-footer-bar 
    [selectedTune]='selectedTune'
    [goBackAfterSaveSendAction]=true
    [saveSendAction]="saveSendActionE.send"
	>
</app-footer-bar>