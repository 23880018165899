import { PwaInstallPromptIosModule } from './../components/pwa-install-prompt/ios/pwa-install-prompt-ios.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DevRouterPageRoutingModule } from './dev-router-routing.module';

import { DevRouterPage } from './dev-router.page';
import {MatChipsModule} from '@angular/material/chips'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DevRouterPageRoutingModule,
    MatChipsModule,
    MatInputModule,
    MatFormFieldModule,
    PwaInstallPromptIosModule
    
  ],
  declarations: [DevRouterPage]
})
export class DevRouterPageModule {}
