import { PwaInstallPromptIosModule } from './../components/pwa-install-prompt/ios/pwa-install-prompt-ios.module';
import { FindTuneRoutingModule } from './find-tune-routing.module';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FooterBarModule } from './../components/footer-bar/footer-bar.module';
import { CategoryHandlerModule } from './category-handler/category-handler.module';
import { SelectedTrackModule } from '../components/selected-track/selected-track.module'
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FindTunePage} from './find-tune.page'
import { SearchHandlerModule } from './search-handler/search-handler.module';
import { MatButtonModule } from '@angular/material/button';
import { LoopProgressBarModule } from '../components/loop-progress-bar/loop-progress-bar.module';
import { LyricsModule } from '../components/lyrics/lyrics.module';
import { AppModule } from '../app.module';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { Share } from '@capacitor/share';
import { SharedModule } from '../shared/shared.module';
 
@NgModule({
  imports: [
    FindTuneRoutingModule,
	SharedModule,
    SelectedTrackModule,
    CategoryHandlerModule,
    FooterBarModule, 
    SearchHandlerModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    LoopProgressBarModule,
    LyricsModule,
    PwaInstallPromptIosModule,
	
  ],
  declarations: [FindTunePage]
  ,
  exports:[FindTunePage]
})
export class FindTunePageModule {}
  