import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { IonHeader } from '@ionic/angular';
import { ITuneList, ITuneListPop, ITuneListPopLean, UsersTuneListM } from '../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared';
import { ModalsE, ToastEnum } from '../../classes-enums-interfaces-types/enums/enums.enum';
import { AlertButton, OverlayEventDetail } from '@ionic/core';
import { Tune } from '../../classes-enums-interfaces-types/classes/classes';
import { MyServerService } from '../../services/my-server/my-server.service';
import { GlobalService } from '../../services/global.service';
import { E } from '@angular/cdk/keycodes';
import { CreateTuneListErrors } from '../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';

@Component({
  selector: 'app-add-tune-to-list',
  templateUrl: './add-tune-to-list.component.html',
  styleUrls: ['./add-tune-to-list.component.scss'],
})
export class AddTuneToListComponent implements OnInit {

	@Input() tune: Tune;

	public modalsE = ModalsE
	tuneLists : ITuneListPopLean[]
	newListName : string
	tuneListsLoaded : boolean = false

  	constructor(
		private modalCtrl: ModalController,
		private mServer : MyServerService,
		private gs : GlobalService,
		private eh : ErrorHandlerService,
		private alertController : AlertController
	) {
		this.mServer.getUserTuneLists().subscribe((userTuneListM : UsersTuneListM)=>{
			this.tuneLists = userTuneListM?.tuneListMCreated
			this.tuneListsLoaded = true
		}, e => {
			this.tuneListsLoaded = true
			console.error(`Error getting user Tune lists`,e)
			this.eh.logSentryError(e)
		})
	}

	cancelAtTL() {
		return this.modalCtrl.dismiss(null, 'cancel', ModalsE.addToTuneList);
	}

	cancelCTL() {
		this.modalCtrl.dismiss(null, 'cancel', ModalsE.createTuneList);
	}

	confirmCTL() {
	console.log('confirmCTL')
		//this.modalCtrl.dismiss()
		this.mServer.createTuneList(this.newListName, [this.tune]).subscribe( async (tuneList)=>{
			console.log(`Tune list created`)
			try{
				await this.modalCtrl.dismiss(null, 'done', ModalsE.createTuneList);
				this.gs.showToast({msg:'Tune list created'})
				await this.modalCtrl.dismiss({createdList:true,tuneList:tuneList}, 'added', ModalsE.addToTuneList);
			} catch(e){
				console.error(`Error dismissing modal`,e)
				this.eh.logSentryError(e)
			}

		} , (e)=>{
			if(e.error == CreateTuneListErrors.tuneListNameNotUnique){
				this.gs.showToast({msg:'Tune list name not unique', type:ToastEnum.error})
			} else {
				this.gs.showToast({msg:"Error creating list", type:ToastEnum.error})
			}
			console.error(`Error creating Tune list`,e)
			this.eh.logSentryError(e)
			
			// add error log after sentry merger
		})
	}

	  onIonInfinite(event){
		event.target.complete();
	  }

	  tuneListClicked(tuneList : ITuneListPop){
		this.mServer.addTuneToTuneList(tuneList._id.toString(),this.tune._id,true).subscribe((res)=>{	
			console.log(`Tune added to tune list`)
			this.modalCtrl.dismiss({tuneList:tuneList}, 'added', ModalsE.addToTuneList);
			this.gs.showToast({msg:'Tune added to Tune list'})
		}, e => {
			if(e.status == 409){
                this.gs.showToast({msg:'Duplicates Tunes in list is not yet supported', type:ToastEnum.error, duration:4000})
				//this.showAlertCancelConfirm(tuneList)
			} else {
				console.error(`Error adding Tune to Tune list`,e)
				this.eh.logSentryError(e)
			}
			// add error log after sentry merger
		})
	  }

  ngOnInit() {

	// const scrollcontainer = this.element.nativeElement.querySelector(this.scrollContainerClass);

	// scrollcontainer.ontouchmove = function (e) {
	// 	e.stopPropagation();
	// };


  }

  onScrollStart(event){
	event.stopPropagation()
  }

  async showAlertCancelConfirm(tuneList
)
{

	const cancelBtn : AlertButton = {
		text: 'Cancel',
		role: 'cancel',
		cssClass: 'm-alert-cancel-button'
	}

	const addBtn : AlertButton = {
		text: 'Add',
		role: 'add',
		handler: () => {
			this.mServer.addTuneToTuneList(tuneList._id.toString(),this.tune._id,true,false).subscribe((res)=>{	
				console.log(`Tune added to tune list`)
				this.modalCtrl.dismiss({tuneList:tuneList}, 'added', ModalsE.addToTuneList);
				this.gs.showToast({msg:'Tune added to Tune list'})
			}, e => {
				console.error(`Error adding Tune to Tune list`,e)
				this.eh.logSentryError(e)
			})
		
		}
	}

	const alert = await this.alertController.create({
		header: "Confirm",
		message: "Tune is already in list",
		buttons: [cancelBtn, addBtn],
	  });
  
	  await alert.present();
}

}
