<ion-header>
  <ion-toolbar>
<!--     <ion-buttons slot="start">
      <ion-button>
        <ion-icon slot="icon-only" [src]="customBack"> 
        </ion-icon>  
      </ion-button>
    </ion-buttons>    -->
   <ion-title>
        Chat Page
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
    <ion-grid class="global-center">
        <ion-row>
            The chat in Tune it is not done yet
        </ion-row>
    </ion-grid>
</ion-content>

<!-- <ion-content>

    <ion-infinite-scroll
        position='top'
        (ionInfinite)='endOfScroll($event)'   
    >
        <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
        >
            
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>  

    <ion-list>
        <app-chat-message  
            *ngFor = "let message of messages"
            [chatMessage] = "message"
        >
        </app-chat-message>
    </ion-list>
</ion-content> -->
<!--
<ion-row id='typeMessageRow'>
     <ion-col 
    id='pickTuneCol'
    size='2'>
        <ion-button 
            fill='clear'
            >
            <ion-icon 
                slot="icon-only"
                [src]="logoIcon"
                id='pickTuneIcon'
            >
            </ion-icon>
          </ion -button>
    </ion-col> -->
<!--     <ion-col
    id='inputCol'
    size='12'>
        <ion-input
			label = ""
            placeholder='Type message...'
            id='messageInput'
            [(ngModel)]='typedMessage'
            (keydown.enter)="messageEntered()"
        >
        </ion-input>
    </ion-col> 

</ion-row>
-->
<app-footer-bar
    (barBackEmittor)='backClick()'
></app-footer-bar>