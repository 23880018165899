// Difference between this file global.ts and global.service.ts is that service.ts stores data .ts
// Are just functions, .ts is similar to helperFuncs.ts but relevant only to this application  

import {  Tune } from "./classes-enums-interfaces-types/classes/classes.js";

import { MGlobal } from './classes-enums-interfaces-types/interfaces/interfaces';
import {ENV} from '../../environments/environment'
import { ok, err, Result } from 'neverthrow'
import { FadeCurveTypesE } from '../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum';
import { TuneValidationError } from "./classes-enums-interfaces-types/enums/enums.enum";

let debugTune : Tune 
let debugTunes : Tune[]
const fadeInDuration : number = 100 //Update NVC.java
const fadeOutDuration : number = 300 //Update NVC.java
let shortestTotalTuneLength : number
if(ENV.DEBUG){
    //shortestTotalTuneLength = 1000
    shortestTotalTuneLength = fadeOutDuration + fadeInDuration + 500 //Update NVC.java
} else {
    shortestTotalTuneLength = fadeOutDuration + fadeInDuration + 500 //Update NVC.java
} 
const fONoS = 6
const fINoS = 3
const test1 = 'ttt'
const mGlobal : MGlobal = {
    useWebSdk: false,
    debugTune: debugTune,
    debugTunes: debugTunes,
    //Read in new fadeCurveOptions()
    fadeParams : {
        fadeInDuration: fadeInDuration, 
        fadeOutDuration: fadeOutDuration,
        lowestVolumePercentOfOrig: 0.3,
        fadeCurveType: FadeCurveTypesE.linear,
        shortestTotalTuneLength : shortestTotalTuneLength,
        fONoS:fONoS,
        fINoS:fINoS,
        fOSP : fadeOutDuration/fONoS,
        fISP : fadeInDuration/fINoS
    },

    loopLimit : {
        min: 1,
        max: 10,
        default: 3
    },

    isAdmin: !ENV.PRODUCTION && true, 
    //isAdmin: true, 
    debug:ENV.DEBUG , //#opt remove
	isDebugIos: ENV.IS_DEBUG_IOS,
	prod: ENV.PRODUCTION,
    logLocal : true,
    
    // assumes tune and not selectedTune $2? Types pls
    validateTune : ( tune: Tune) : Result<true, TuneValidationError[]> =>  { 
        
        // #Todo also validate on server
        let msg : TuneValidationError //= null
		if(!tune.startTime && tune.startTime!==0){
			msg = TuneValidationError.noStartTime
		} else if( !tune.stopTime){
			msg = TuneValidationError.noStopTime
		}
        else if(tune.stopTime - tune.startTime < shortestTotalTuneLength  ){
            msg = TuneValidationError.tooShort
        } else if(!tune?.triggers?.[0]?.triggerSentence && !tune?.triggers?.[0]?.notApplicable){
            msg = TuneValidationError.noTrigger
        } /* else if(! ((tune?.lyrics?.wordRange?.startIndex || tune?.lyrics?.wordRange?.startIndex===0) || tune?.lyrics?.lyricsStr) ){
            msg = TuneValidationError.noLyrics
        } */

        if(msg)
            return err([msg])
/*             throw new MError({
                mMessage:"Error validating Tune",
                mReason:msg
            }) */
        return ok(true)

    },
    userId: '61d18a396f0ce84a84932510',
    userDisplayName: 'user1Name'
    // INLINE https://commons.wikimedia.org/wiki/Category:Images#/media/File:Charles_Tr%C3%BCb_Le_Mont_Fangas.jpg
    
}

export {
	mGlobal,
	test1
}
