<ion-row id="spot-attribution-row">
	<ion-col id="powered-by-col">Powered by</ion-col>
	<ion-col id='spot-logo-col'>

		<ion-icon 
		[src]='spotFullLogoIcon'
		id="spot-logo-icon"
		>
		</ion-icon>
	</ion-col>
</ion-row>