

<ng-container [ngSwitch]=searchState >
	<ng-container *ngSwitchCase="searchStateEnum.noSearch">
		<div
			id='no-search-container'
		>
			<div 
			class='no-search-text'>
				Search to find songs
			</div>
			<br>
			<div
				class='no-search-text-small'>
				<p>
					or search with # to find Tunes with tag
				</p>
				<p>
					like #GoodTimes
				</p>    
    		</div>
  		</div>
	</ng-container>

	<ng-container *ngSwitchCase = "searchStateEnum.tunes">
		<ion-grid>
			<ion-content>
				<ion-list>
					<app-list-element  

						*ngFor = "let elem of elemArr;let i = index"
						id="list-element-{{i}}"
						[elem] = "elem" 
						[listElementNbr]="i"
						(listElemClicked)="elemClicked(elem)"
						
					>
					</app-list-element>
				</ion-list>
				<ion-infinite-scroll
					[disabled]="infScrollDisabled"
					(ionInfinite)='endOfScroll($event)'   
				>
				<ion-infinite-scroll-content
					loadingText="Loading more data..."
				>
					
				</ion-infinite-scroll-content>
				</ion-infinite-scroll>  
			</ion-content>
		</ion-grid>
	</ng-container>

	<ng-container *ngSwitchCase = "searchStateEnum.tracks">
		<ion-grid>
			<ion-content>
				<ion-list>
					<app-list-element  

						*ngFor = "let elem of elemArr;let i = index"
						id="list-element-{{i}}"
						[elem] = "elem" 
						[listElementNbr]="i"
						(listElemClicked)="elemClicked(elem)"
						
					>
					</app-list-element>
				</ion-list>
				<ion-infinite-scroll
					[disabled]="infScrollDisabled"
					(ionInfinite)='endOfScroll($event)'   
				>
				<ion-infinite-scroll-content
					loadingText="Loading more data..."
				>
					
				</ion-infinite-scroll-content>
				</ion-infinite-scroll>  
			</ion-content>
		</ion-grid>
	</ng-container>

	<ng-container *ngSwitchCase = "searchStateEnum.noTunesFound">

		<div class="no-search-results-container">

			<div 
				class='no-search-results-1'>
				No Tunes found with tag
			</div>
			<div 
				class='no-search-results-2'>
				{{searchText}}
			</div>

		</div>

	</ng-container>
	
	<ng-container *ngSwitchCase = "searchStateEnum.noSpotTracksFound">

	
		<div class="no-search-results-container">

			<div 
				class='no-search-results-1'>
				No Spotify tracks fround from search
			</div>
		</div>

	</ng-container>

	<!--<ion-row
      class='show-all-row'
    >
		<ion-col
		class='show-all-col'>
			<ion-card
			button=true
			(click)='showAllTracks()'
			>
			<ion-card-title>
			Show all Tracks
			</ion-card-title>
			</ion-card>
		</ion-col>
		</ion-row>
		<ion-row
		class='show-all-row'
		>
		<ion-col class='show-all-col'>
			<ion-card
			button=true
			(click)="showAllArtists()"
			>
			<ion-card-title>
				Show all Artists
			</ion-card-title>
			</ion-card>
		</ion-col>
    </ion-row> 
    -->

</ng-container>  

