<ion-grid id="main-grid">

	<app-search-bar 
		(searchEntered) = "searchBarSearchEntered($event)"
		(clearClicked)	= searchBarClearClicked()	
	>
	</app-search-bar>

	<ion-row id="segment-row" >
		<ion-segment (ionChange)="segmentChanged($event)" value="{{switchSegment}}">
			<ion-segment-button class="left-segment-button" value="{{switchSegmentEnum.tuneTriggers}}">
				<ion-label class="segment-button-label" >Tunes</ion-label>
			</ion-segment-button>
			<ion-segment-button class="right-segment-button" value="{{switchSegmentEnum.tuneLists}}">
				<ion-label class="segment-button-label">Lists</ion-label>
			</ion-segment-button>
		</ion-segment>
	</ion-row>

	<ion-row id="main-content-row">
        <ion-col id="main-content-col" [ngSwitch]="switchSegment">
			<ion-content  id="mainContent" >
				<ng-container *ngSwitchCase="switchSegmentEnum.tuneTriggers" >
					<ng-container *ngIf="(!triggers || triggers?.length == 0) && triggersLoaded; then noTriggersTemplate; else triggerListTemplate"></ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="switchSegmentEnum.tuneLists" >
					<ng-container *ngIf="tuneListState == tuneListStatesEnum.multipleTuneLists; then tuneListMTemplate; else tuneListSTemplate"></ng-container>
				</ng-container>
				<!-- should be inside content and slofseart fixed, atleast for not ion-router-outlet and inf scroll -->
				<app-lyrics 
					slot="fixed"
					[selectedTuneLyrics]="selectedTune?.lyrics?.lyricsStr"
			    ></app-lyrics>
			</ion-content>
		</ion-col>
	</ion-row>

</ion-grid>

<ion-row id="loop-progress-bar-row">
    <app-loop-progress-bar id="loop-pb"></app-loop-progress-bar>
</ion-row>
    
<app-selected-track 
    id='selected-track-component'
    *ngIf='isTuneSelected'
    [saveTunePopOver]="false"
    >
    <!-- (addedTuneToListClick)="addedTuneToTuneListClick($event)" -->
</app-selected-track>

<app-footer-bar 
    [saveSendAction]='saveSendAction.send'
    (barBackEmittor)="backClickMyLib()"
    [selectedTune]="selectedTune">
</app-footer-bar>

	<!-- <ion-menu menuId="lib-filter-menu" side="end" contentId="lib-grid">
		<ion-header >
		<ion-toolbar>
			<ion-title>Tune filter</ion-title>
			<ion-buttons slot="end">
				<ion-menu-toggle  menu="lib-filter-menu">
					<ion-button  fill='clear' size='large'>
						<ion-icon slot='icon-only' [src]='customBackIcon'></ion-icon>
					</ion-button>
				</ion-menu-toggle>
			</ion-buttons>
		</ion-toolbar>
		</ion-header>
		<ion-content scrollY=false class="ion-padding">
			<ion-list lines="inset" id="menuBarList">
				<ion-list-header lines="inset" id="filter-list-header">
					<ion-label  font-size="x-large">Show</ion-label>
				</ion-list-header>
				<ion-item class="listItem" lines="inset">
					<ion-checkbox [checked]=tuneFilter.created (ionChange)=updateTuneFilter($event) value='createdTunesCB' labelPlacement="start" class="libFilterCheckBox">Tunes created by you</ion-checkbox>
				</ion-item>
				<ion-item class="listItem" lines="none">
					<ion-checkbox  [checked]=tuneFilter.saved (ionChange)=updateTuneFilter($event) value= "savedTunesCB" labelPlacement="start" class="libFilterCheckBox">Tunes saved by you</ion-checkbox>
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-menu>-->

	<ng-template #tuneListMTemplate>
        <ng-container *ngIf="searchEntered.tuneLists then tuneListMSearchActiveTemplate; else tuneListsMNoSearchActiveTemplate">

        </ng-container>
	</ng-template>

    <ng-template #tuneListMSearchActiveTemplate>
        <ng-container *ngIf="tuneListMSearch.noListMFound then tuneListMSearchActiveNoSearchResultsTemplate; else tuneListMSearchActiveSearchResultsTemplate;"></ng-container>
    </ng-template>

    <ng-template #tuneListMSearchActiveSearchResultsTemplate>
        <ion-grid>
            <ion-list>
                <ion-item
                    *ngFor = "let tuneListSearch of tuneListMSearch.tuneListM; let i = index"
                    class = tune-list-item
                    id = "tune-list-list-elem-{{i}}"
                    (click) = "tuneListClicked(tuneListSearch, $event)"
				>
	            <!--long-press (tap) = "tuneListClicked(tuneList)"
				long-press (press) = "tuneListLongPress(tuneList, $event)" -->
				<p>{{tuneListSearch?.name}}</p>
				<ion-button 
                    id="option-button-{{i}}"
                    class="option-button"
                    icon-only 
                    slot="end"
                    fill="clear"
                    (click)="optionTuneListMClick($event,tuneListSearch)"
			    >
				<ion-icon
					[src]="threeDotsIcon"
					>
				 </ion-icon>
			    </ion-button>							
			</ion-item>
               
            </ion-list>
            <ion-infinite-scroll
                [disabled]="infScrollDisabled"
                (ionInfinite)='endOfScrollTuneList($event)'   
            >
                <ion-infinite-scroll-content
                    loadingText="Loading more data..."
                >
                    
                </ion-infinite-scroll-content>
            </ion-infinite-scroll>  
        </ion-grid>
    </ng-template> 

    <ng-template #tuneListMSearchActiveNoSearchResultsTemplate>
        <ion-grid *ngIf="tuneListMSearch.searchDone" class="no-triggers-or-lists-grid list-grid">
			<ion-row>
				<ion-col style="font-size:8vw">
					No Tune lists found for search 
				</ion-col>
			</ion-row>
			<hr>
			<ion-row>
				<ion-col style="font-size:12vw">
					{{currentSearchText}}
				</ion-col>
			</ion-row>
		</ion-grid>
    </ng-template>

    <ng-template #tuneListsMNoSearchActiveTemplate>
        <cdk-accordion class="example-accordion">
            <cdk-accordion-item

                expanded="false"
                #accordionItem1="cdkAccordionItem"
                id="accordion-header-1"
                class="example-accordion-item"
                role="button"
                [attr.id]="'accordion-header-1'"
                [attr.aria-expanded]="accordionItem1.expanded"
                [attr.aria-controls]="'accordion-body-1'">
            
                <ion-row class="example-accordion-item-header-row" (click)="accordionItem1Toggle()">
                    <ion-col id='accordion-item-header-col-label'>
                        Followed Tune Lists
                    </ion-col>
                    <ion-col id='accordion-item-header-col-icon' size="2">
                        <ion-icon id="accordion-item-header-icon" [src]="accordionItem1.expanded ? upArrowHead : downArrowHead ">
                        </ion-icon>    
                    </ion-col>     
                </ion-row>

                <div 
                    *ngIf="tuneListMLoaded"
                    class="example-accordion-item-body"
                    role="region"
                    [style.display]="accordionItem1.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-1'"
                    [attr.aria-labelledby]="'accordion-header-1'"
                >
                    <ng-container *ngIf="(!tuneListMSaved || tuneListMSaved?.length == 0); then noSavedTuneListMTemplate; else savedTuneListMTemplate">
                    </ng-container>

                </div>
            </cdk-accordion-item>

            <cdk-accordion-item

                expanded="true"
                #accordionItem2="cdkAccordionItem"
                role="button"
                class="example-accordion-item expanded-accordion-header"
                id="accordion-header-2"
                [attr.id]="'accordion-header-2'"
                [attr.aria-expanded]="accordionItem2.expanded"
                [attr.aria-controls]="'accordion-body-2'">
                
                <ion-row class="example-accordion-item-header-row" (click)="accordionItem2Toggle()">
                    <ion-col id='accordion-item-header-col-label'>
                        Created Tune Lists
                    </ion-col>
                    <ion-col id='accordion-item-header-col-icon' size="2">
                        <ion-icon id="accordion-item-header-icon" [src]="accordionItem2.expanded ? upArrowHead : downArrowHead ">
                        </ion-icon>    
                    </ion-col>    
                </ion-row>
                <div 
                    *ngIf="tuneListMLoaded"
                    class="example-accordion-item-body"
                    role="region"
                    [style.display]="accordionItem2.expanded ? '' : 'none'"	
                    [attr.id]="'accordion-body-2'"
                    [attr.aria-labelledby]="'accordion-header-2'"
                >			
                    <ng-container *ngIf="(!tuneListMCreated || tuneListMCreated?.length == 0); then noCreatedTuneListMTemplate; else createdTuneListMTemplate">
                        
                    </ng-container>
                            
                </div>

            </cdk-accordion-item>
        </cdk-accordion>

    </ng-template>


	<ng-template #triggerListTemplate>
		<!-- ion-list scroll doesnt work w/o grid -->
		<ion-grid *ngIf="triggersLoaded" id="trigger-list-grid">
			<ion-list id="trigger-list">
				<app-trigger 
					*ngFor="let trigger of triggers ; let i = index"
					[trigger]="trigger"
					[triggerElementNbr]="i"
					[attr.data-tuneTriggerListIndex]="i"
					(triggerClick)="triggerClick($event)"
					(removeTriggerClick)="removeTriggerClick($event)"
                ></app-trigger>
					<!-- (addedTuneToListClick)="addedTuneToTuneListClick($event)" -->
			</ion-list>
			<ion-infinite-scroll
				[disabled]="allTriggersFetched"
				(ionInfinite)='endOfScrollUserTunes($event)'   
			>
				<ion-infinite-scroll-content
					loadingText="Loading more data..."
				>
				</ion-infinite-scroll-content>
		    </ion-infinite-scroll>  
		</ion-grid>

	</ng-template>

	<!-- #opt -->
	<ng-template #noTriggersTemplate>
		<ng-container *ngIf="searchEntered.triggers == true; then noTriggerSearchResultsTemplate; else noTriggersOnServerTemplate"></ng-container>
	</ng-template>

	<ng-template #noTriggersOnServerTemplate>
		<ion-grid class="no-triggers-or-lists-grid list-grid">
			<ion-row>
				<ion-col style="font-size:8vw">
					Your Tune Library
				</ion-col>
			</ion-row>
			<hr>
			<ion-row>
				<ion-col style="font-size:5vw">
					You have no Tunes yet :(
				</ion-col>
			</ion-row>
			<br>
			<ion-row>
				<ion-col style="font-size:8vw">
					<a 
						style="color:white"
						class="blue-background-text"
						href="/find-tune">Make some!
					</a>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ng-template>	

	<ng-template #noTriggerSearchResultsTemplate>
		<ion-grid class="no-triggers-or-lists-grid list-grid">
			<ion-row class="no-search-results-g">
				No Tunes found matching your search
			</ion-row>
		</ion-grid>
	</ng-template>	

	<ng-template #noSavedTuneListMServerTemplate>
		<ng-container *ngIf="searchEntered.tuneLists; then noTuneListResult; else noSavedTuneListMServerTemplate"></ng-container>
	</ng-template>

	<ng-template #noSavedTuneListMTemplate>
		<ion-grid class="no-triggers-or-lists-grid">
			<ion-row>
				<ion-col style="font-size:8vw">
					Your followed Tune lists
				</ion-col>
			</ion-row>
			<hr>
			<ion-row>
				<ion-col style="font-size:5vw">
					You are not following any Tune lists yet
				</ion-col>
			</ion-row>
			<br>
			<ion-row>
				<ion-col style="font-size:8vw">
					<a 
						style="color:white"
						class="blue-background-text"
						>Follow a Tune list when someone sends you a link to one
					</a>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ng-template>

	<ng-template #noTuneListResult>
		<ion-grid class="no-triggers-or-lists-grid">
			<ion-row>
				<ion-col style="font-size:8vw">
					No matching Tune lists found
				</ion-col>
			</ion-row>
		</ion-grid>
	</ng-template>

	<ng-template #savedTuneListMTemplate>
		<ion-list lines="full" class="tune-list-ion-list">
			<ion-item
				*ngFor="let tuneList of tuneListMSaved; let i = index"
				class=tune-list-item
				id="tune-list-list-elem-{{i}}"
				(click) = "tuneListClicked(tuneList, $event)"
				>
	            <!--long-press (tap) = "tuneListClicked(tuneList)"
				long-press (press) = "tuneListLongPress(tuneList, $event)" -->
				<p>{{tuneList?.name}}</p>
				<ion-button 
                    id="option-button-{{i}}"
                    class="option-button"
                    icon-only 
                    slot="end"
                    fill="clear"
                    (click)="optionTuneListMClick($event,tuneList)"
			    >
				<ion-icon
					[src]="threeDotsIcon"
					>
				 </ion-icon>
			    </ion-button>							
			</ion-item>
		</ion-list>
	</ng-template>

	<ng-template #noCreatedTuneListMTemplate>
		<ng-container *ngIf="searchEntered.tuneLists; then noTuneListResult; else noCreatedTuneListMServerTemplate"></ng-container>
	</ng-template>

	<ng-template #noCreatedTuneListMServerTemplate>
		<ion-grid class="no-triggers-or-lists-grid">
			<ion-row>
				<ion-col style="font-size:8vw">
					Your Tune list Library
				</ion-col>
			</ion-row>
			<hr>
			<ion-row>
				<ion-col style="font-size:5vw">
					You have no Tune lists yet :(
				</ion-col>
			</ion-row>
			<br>
			<ion-row>
				<ion-col style="font-size:8vw">
					<a 
						style="color:white"
						class="blue-background-text"
						>Make some by holding down on a Tune 
					</a>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ng-template>

	<ng-template #createdTuneListMTemplate>
		<ion-list lines="full" class="tune-list-ion-list">
			<ion-item
				*ngFor="let tuneList of tuneListMCreated; let i = index"
				class=tune-list-item
				id="tune-list-list-elem-{{i}}"
				(click) = "tuneListClicked(tuneList,$event)"
            >
				<!--long-press (tap) = "tuneListClicked(tuneList)"
				long-press (press) = "tuneListLongPress(tuneList, $event)" -->
				<p>{{tuneList.name}}</p>	
				<ion-button 
					id="option-button-{i}"
					class="option-button"
					icon-only 
					slot="end"
					fill="clear"
					(click)="optionTuneListMClick($event,tuneList)"
				>
					<ion-icon
						[src]="threeDotsIcon"
						>
		 			</ion-icon>
				</ion-button>					
			</ion-item>
		</ion-list>
	</ng-template>

	<ng-template #tuneListSTemplate>
		<ion-grid *ngIf="!tuneList.missingOnServer; else noTuneListWithNumberFoundTemplate" class="class-100-w-h">
			<ion-row id="tune-list-name-row-container">
                <ion-col class="tune-list-name-col">
                    <ion-row class="tune-list-name-text-row" style="align-items: end; margin-bottom:1dvh;">
                        {{tuneList?.listLean?.name}}
                    </ion-row>
                    <ion-row style="align-items: start;margin-top:1dvh;" class="tune-list-name-text-row">
                        by {{tuneList?.listLean?.createdBy?.displayName}}
                    </ion-row>
                </ion-col>
                <ion-col id="tune-list-button-col" class="padding-0">
                    <ion-row class="padding-0">
                        <ion-button 
                            [ngClass]="{'toggled-button': shuffleActive}"
                            class="tune-list-name-button"
                            fill="clear"
                            (click)="shuffleClicked()"
                        >
                            <ion-icon
                                [src]="shuffleIcon"
                            >
                            </ion-icon>
                        </ion-button>
                    </ion-row>
                    <ion-row>
                        <ion-button 
                            *ngIf="userDisplayName == tuneList.listLean?.createdBy?.displayName"
                            [ngClass]="{'toggled-button': reorderTuneListActive}"
                            class="tune-list-name-button"
                            fill="clear"
                            (click)="reorderTuneListSclicked()"
                        >
                            <ion-icon
                                [src]="reorderIcon"
                            >
                            </ion-icon>
                        </ion-button>
                    </ion-row>
                </ion-col>
            </ion-row>
			<ion-row>
				<ion-list>
                    <ion-reorder-group 
                        [disabled]="!reorderTuneListActive"
                        (ionItemReorder)="reorderEvent($any($event))"
                    >
                       <!--  <ion-reorder> -->
                            <app-list-element  
                                *ngFor = "let tuneReorder of tuneList?.listRes?.tunesReorder; let i = index"
                                id="list-element-{{i}}"
                                [elem] = "tuneReorder.tune" 
                                [listElementNbr]="i"
                                [enablePopOverRemoveTune]="true"
                                (removeTuneFromTuneListClick)="removeTuneFromTuneListClick(tuneReorder)"	
                                (listElemClicked)="listElemClick($event,i)"
                                [listElementHtmlContext]="ListElementHtmlContextE.myLibTuneListList"
                                [reorderActive]="reorderTuneListActive"
                                >
                            </app-list-element>
                       <!--  </ion-reorder> -->
                    </ion-reorder-group>
					<!-- (addedTuneToListClick)="addedTuneToTuneListClick($event)"	 -->
				</ion-list>
				<ion-infinite-scroll
					[disabled]="tuneList.allTunesFetched || reorderTuneListActive"
					(ionInfinite)='endOfScrollTuneList($event)'   
				>
                    <ion-infinite-scroll-content
                        loadingText="Loading more data..."
                    >
                    </ion-infinite-scroll-content>
                </ion-infinite-scroll> 
            </ion-row>
        </ion-grid>
	</ng-template>

	<ng-template #noTuneListWithNumberFoundTemplate>
		<ion-grid id="no-tune-list-found-grid">
			<ion-row>
				<ion-col style="font-size:8vw">
					Tune list with name {{tuneList?.listLean?.name}} was not found	
				</ion-col>
			</ion-row>
		</ion-grid>
	</ng-template>

<ion-popover 
    side="end"
    trigger-action="none"
    dismiss-on-select="true"
    show-backdrop="true"
    alignment="center"
>
	<ng-template>
		<ion-list class="pop-over-list" lines="full" >
			<ion-item
				id="delete-button"
				class="pop-over-item"
				[button]="true"
				(click)="shareTuneListClick(tuneList.listLean)"
			>
				Share list
			</ion-item>
			<ion-item *ngIf="userDisplayName == tuneList.listLean?.createdBy?.displayName"
				id="rename-btn-pop-over"
				class="pop-over-item"
				[button]="true"
				(click)="renameTuneListClick(tuneList.listLean)"
				>
				Rename list
			</ion-item>
			<ion-item
                *ngIf="!searchEntered.tuneLists"
				id="delete-button"
				class="pop-over-item"
				[button]="true"
				(click)="removeTuneListClick(tuneList.listLean)"
				>
				Remove list
			</ion-item>

		</ion-list>
	</ng-template>
</ion-popover>



