<!-- iOS Cordova WKWebView
When deploying to iOS with Cordova, it's highly recommended to use the WKWebView plugin
in order to take advantage of iOS's higher performing webview.
Additionally, WKWebView is superior at scrolling efficiently in comparison to the older UIWebView. -->

    <div class="page-container" [ngSwitch]="selectState"> 
        <ng-container
            *ngSwitchCase='"CATEGORY_ONE"'
        >

			<ion-grid *ngIf="categoriesLoaded==true" id="cat-one-grid">
				<ion-row id="cat-one-row">
					<ion-card
					class='cat-one-ion-card'
					button=true
					tappable  
					(click)='catOneClicked($event)'
					*ngFor="let catOne of catsOneChildrenMap | keyvalue"
					[attr.data-cat-one]="catOne.key"
					>
					<ion-card-header
						[attr.data-cat-one]="catOne.key">
						<ion-card-title 
							[attr.data-cat-one]="catOne.key"
							class='cat-one-ion-card-title'
							>
							{{catOne.key}}
						</ion-card-title>
					</ion-card-header> 
					</ion-card>
				</ion-row>
				<ion-row id="spot-playlist-btn-row">
					<ion-button class="themed-ion-button" id="spot-playlist-btn" (click)="spotPlaylistBtnClicked()">
						From your Spotify playlists
					</ion-button>
				</ion-row>
			</ion-grid>
        </ng-container>

        <ion-grid 
            class='cat-two-ion-grid'
            *ngSwitchCase='selectStatesEnum.catTwo'>
			<ion-content>
				<ion-row
					*ngFor='let rowNumber of numberOfCatTwoRows'
					class='cat-two-ion-row'
					>
					<ion-col 
						class='cat-two-ion-col'
						*ngFor='let colTwo of [].constructor(numberOfColInRow[rowNumber]); index as j;'>
						<ion-card
							class='cat-two-ion-card'
							button=true
							tappable  
							(click)='catTwoClicked($event)'
							[attr.data-cat-two]="categoriesTwoArray[rowNumber*2+j]"
					>
							<ion-card-header
									style='padding-left:0px;padding-right:0px;margin-left:0px;margin-right: 0px;'
									[attr.data-cat-two]="categoriesTwoArray[rowNumber*2+j]">
								<ion-card-title
									[attr.data-cat-two]="categoriesTwoArray[rowNumber*2+j]"
									class='cat-two-ion-card-title'
									style='font-size:5vw;'>
										{{categoriesTwoArray[rowNumber*2+j]}} 
								</ion-card-title>
							</ion-card-header>
						</ion-card>
					</ion-col>
				</ion-row>
			</ion-content>
        </ion-grid>

		<ion-grid 
            class="flex-grid"
            id="spot-playlist-grid"
		    *ngSwitchCase='selectStatesEnum.listSpotPlaylistAll'
            >

            <ion-row  class="m-list-header-row">
                Your Spotify playlists
            </ion-row>

            <ion-row *ngIf=!spotExtraPermissionReadPrivateLists id="missing-playlists-row">
                <ion-accordion-group id="accordion-grp-missing-playlist">
                    <ion-accordion id="accordion" value="first"
                    >
                    <ion-item id="accordion-item" slot="header" color="light">
                        <ion-icon [src]="infoIcon"></ion-icon>
                        <ion-label>Missing some playlists?</ion-label>
                    </ion-item>
                    <div slot="content">
                        <div style="margin:3dvw; font-size:large; line-height: 1.5;" class="center-text">
                            If your playlists are private or collaborative you need to give Tune it permission to read them.
                            This can be changed back at any time in Tune it's settings
                        </div>
                        <div>
                            <ion-button class="permission-button themed-ion-button" (click)="grantPermissionSpotLibrary()">
                                Grant
                            </ion-button>
                        </div>
                    </div>
                    </ion-accordion>
                </ion-accordion-group>
            </ion-row>
            <ion-row class="flex-row">

                    <ion-content>
                        <ion-list id=playlist-list lines="inset">
                            <ion-item lines="inset" class="playlist-item"
                                (click)="likedSongsClicked()">
                                <ion-thumbnail slot="start" class="playlist-item-thumb">
                                    <img src="{{spotLikedSongsImg}}"/>
                                </ion-thumbnail>
                                <ion-label class=playlist-item-label>Liked Songs</ion-label>
                            </ion-item>
                            <ion-item lines="inset" class="playlist-item" *ngFor="let playlist of spotPlaylistM?.listsUsersAll"
                            (click)="playlistClicked(playlist)">
                                <ion-thumbnail slot="start" class="playlist-item-thumb">
                                    <img src="{{playlist?.images?.[0]?.url}}"/>
                                </ion-thumbnail>
                                <div class=playlist-item-label>
                                    {{ playlist.name }}
                                </div>
                            </ion-item>
                        </ion-list>	
                        <ion-infinite-scroll (ionInfinite)="onIonInfSpotPlaylistsM($event)">
                            <ion-infinite-scroll-content
                            loadingText="Loading more lists..."
                        >
                            
                        </ion-infinite-scroll-content>
                        </ion-infinite-scroll>
                    </ion-content>
            </ion-row>
		</ion-grid>

		<ion-grid id="spot-playlists-grid"
		*ngSwitchCase='selectStatesEnum.spotPlaylistsSearch'>

			<ion-content>
				<ion-list lines="inset">

					<ion-item lines="inset" class="playlist-item" *ngFor="let playlist of spotPlaylistM.listsUserSearched"
					(click)="playlistClicked(playlist)">
						<ion-thumbnail slot="start" class="playlist-item-thumb">
							<img src="{{playlist.images[0].url}}"/>
						</ion-thumbnail>
						<ion-label class=playlist-item-label>{{ playlist?.name }}</ion-label>
					</ion-item>
				</ion-list>	
				<ion-infinite-scroll (ionInfinite)="onIonInfSpotPlaylistsM($event)">
					<ion-infinite-scroll-content
					loadingText="Loading more lists..."
				>
					
				</ion-infinite-scroll-content>
				</ion-infinite-scroll>
			</ion-content>
	
		</ion-grid>

		<ion-grid
			class="flex-grid"
			*ngSwitchCase='selectStatesEnum.spotPlaylist'>

					<ion-row class="m-list-header-row">
						{{spotPlaylistS?.preview?.name}}
					</ion-row>

					<ion-row class="flex-row">

						<ion-content>
							<ion-list lines="inset">
							<app-list-element  
									*ngFor = "let spotTrack of spotPlaylistS.mFormatFetched;let i = index"
									id="list-element-{{i}}"
									[elem] = "spotTrack" [listElementNbr]="i"
									(listElemClicked)="spotTrackClicked(spotTrack)"
									>
							</app-list-element>
							</ion-list>	
							<ion-infinite-scroll (ionInfinite)=" onIonInfSpotPlaylistS($event)">
								<ion-infinite-scroll-content
								loadingText="Loading more lists..."
							>
								
							</ion-infinite-scroll-content>
							</ion-infinite-scroll>
						</ion-content>

					</ion-row>
		</ion-grid>

        <!-- <ion-list *ngSwitchCase='selectStatesEnum.listTunes'> -->
		<ion-content *ngSwitchDefault>
			<ion-list id="list-element-list" >
				<app-list-element
					class='app-list-element'
					id="list-element-{{i}}"
					*ngFor="let elem of elemArr; let i = index"
					[listElementNbr]="i"
					[elem]='elem'
					(listElemClicked)='elemClicked(elem)'
				
				> 
				</app-list-element>
			</ion-list>
		</ion-content>

    </div>
   
