import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import { GlobalService } from '../global.service';
import { PlayerService } from '../music/player.service';
import { IFeLogErrorSentry, IFeSetSentryContextError } from '../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared';
import { ISpotDevice } from '../../../../../shared/dist/shared.interface';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

	private showSentryLoggedToast : boolean = true;
  constructor
  	(	
		private gs : GlobalService,
		private ps : PlayerService	
	) { }

	setShowSentryLoggedToast(value:boolean){
		this.showSentryLoggedToast = value
	}

	getShowSentryLoggedToast(){
		return this.showSentryLoggedToast
	}

	async getTuneItSentryContext( ) : Promise<IFeLogErrorSentry | IFeSetSentryContextError>{
		let spotActiveDeviceId : string
		let spotDevices : ISpotDevice[]
		let tuneItContext : IFeLogErrorSentry | IFeSetSentryContextError
		try{
			try{
				spotActiveDeviceId = await this.ps.getNvcDeviceId()
			}
			catch(e){
				console.error('error getting activeDeviceId sentryContext',e)
				spotActiveDeviceId = e.toString()
			}

			try{
				spotDevices = await this.ps.getSpotDevices()
			}
			catch(e){
				console.error('error getting spotDevices sentryContext',e)
				spotDevices = e.toString()
			}

			tuneItContext = {
				dateOfOccurence : new Date(), // if logged to localstorage
				contextData : {
					isPwa : this.gs.isRunningAsPwa(),
					spot : {
						id : this.gs.getSpotId(),
                        name : this.gs.getSpotName(),
                        email : this.gs.getSpotEmail(),
						spotDevices: spotDevices,
						spotAciveDeviceId: spotActiveDeviceId,
					},
					tuneItVersion : this.gs.getTuneItVersion() ,
					userAgent :  this.gs.getUserAgent(),
				},
                userId: this.gs.getUserId()
			}
		} catch(e){
			tuneItContext = { setContextError : e.toString()}
		}

		if(this.showSentryLoggedToast){
			this.gs.showToast({msg:"Error log sent to server"})
		}
		
		return tuneItContext;

	}

	async logSentryError(e : Error){

		Sentry.captureException(e)
	}

}
