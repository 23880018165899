
import { registerPlugin } from '@capacitor/core';

import type { NativeVolumeControlPlugin,FadeOutFadeInVolumeOptions } from './definitions';
import { NativeVolumeControlWeb } from './web';

const NativeVolumeControl = registerPlugin<NativeVolumeControlPlugin>('NativeVolumeControl', {
  web: () => import('./web').then(m => new m.NativeVolumeControlWeb()),
});

export * from './definitions';
export { NativeVolumeControl, NativeVolumeControlWeb };
