import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyLibraryStateService {
	// Its not really state, but could and should be. Added later in desing.

  constructor() { }

  tuneDeletedFromTuneList$ = new BehaviorSubject<{tuneListId : string, tuneId : string}>(null)
  tuneAddedToTuneList$ = new BehaviorSubject<{tuneListId : string, tuneId : string}>(null)

  tuneDeletedFromTuneList(tuneListId : string, tuneId : string){

	this.tuneDeletedFromTuneList$.next({tuneListId : tuneListId, tuneId : tuneId})
  }

  tuneAddedToTuneList(tuneListId : string, tuneId : string){

	this.tuneAddedToTuneList$.next({tuneListId : tuneListId, tuneId : tuneId})
  }

}
