import { LoopProgressBarModule } from './../components/loop-progress-bar/loop-progress-bar.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TuneListPageRoutingModule } from './tune-list-routing.module';

import { TuneListPage } from './tune-list.page';
import { SharedModule } from '../shared/shared.module';
import { FooterBarModule } from '../components/footer-bar/footer-bar.module';
import { ListElementModule } from '../components/list-element/list-element.module';
import { SelectedTrackModule } from '../components/selected-track/selected-track.module';
import { LyricsModule } from '../components/lyrics/lyrics.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TuneListPageRoutingModule,
	SharedModule,
	FooterBarModule,
	ListElementModule,
	SelectedTrackModule,
	LoopProgressBarModule,
	LyricsModule
  ],
  declarations: [TuneListPage]
})
export class TuneListPageModule {}
