import { ToastController } from '@ionic/angular';
import { toastController } from '@ionic/core';
import { Injectable } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';

import { GlobalService } from '../global.service';
import { LoginParams } from '../../../../../backend/classes-enums-interfaces-types/types/types.shared';

@Injectable({
  providedIn: 'root'
})
export class HelperFunctionsService {

    constructor(
        private toastController : ToastController,
    ) { }

    objectToQueryParams(queryObject:LoginParams) : string {
        return Object.keys(queryObject).map(key => key + '=' + queryObject[key]).join('&');
    }


	//#opt make deep
	setHasObjectWithProperty<T>(set : Set<T>, propertyName : string, valueToMatch : any) : boolean {
		for(let item of set){
			if(item[propertyName] == valueToMatch && valueToMatch)
				return true
		}
		return false
	}

	//#Opt shouldntbe in service
	push<T>(baseArray : T[], elemsToPush : T | T[]) : void {
		// the array can be null, hence object is needed
		// #opt throw if not array

		if(Array.isArray(elemsToPush)){
			baseArray.push(...elemsToPush)
		} else {
			baseArray.push(elemsToPush)
		}
	}

    delay(ms:number){
        return new Promise(resolve => {
            setTimeout(resolve, ms)
        })
    }

	isStringInEnum(value: string, enumType: any): boolean {
		return Object.values(enumType).includes(value);
	}

	tryParseBoolean(str: string): { isBoolean: boolean; value: boolean } {
		const lowerStr = str.toLowerCase();
		
		if (lowerStr === "true") {
			return { isBoolean: true, value: true };
		} else if (lowerStr === "false") {
			return { isBoolean: true, value: false };
		} else {
			return { isBoolean: false, value: false };
		}
	}

	consoleLog(msg : any, shouldLog : boolean = true, guiOutput : boolean = false ){
		if(shouldLog){
			console.log(msg)
		}
		// if(guiOutput){
		// 	const log = document.querySelector('#log');
		// 	const msg = document.createElement('div');
        //     //msg.classList.add(verb);
        //     msg.textContent = verb + ': ' + Array.prototype.slice.call(arguments).join(' ');
        //     log.appendChild(msg);
		// }
	}

    compareJsonObjectsRemoveFields(obj1,obj2,fieldsToRemove){
        for(let field of fieldsToRemove){
            delete obj1[field]
            delete obj2[field]
        }
        return JSON.stringify(obj1) === JSON.stringify(obj2)
    }
    
}
