<div id="container-div">
	<ion-toolbar >
		<ion-buttons slot="start">
			<ion-button color="medium" (click)="cancelRename()">Cancel</ion-button>
		</ion-buttons>
		<ion-title>Rename</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="confirmRename()" [strong]="true" color="primary">Done</ion-button>
			</ion-buttons>
	</ion-toolbar>
	<ion-grid id="list-name-grid">
	<ion-row id="input-row">

			<ion-input  
				placeholder="List name"
				aria-label="List name"
				inputmode="text"
				[(ngModel)]="updatedListName"
				>
			</ion-input>			

	</ion-row>
	</ion-grid>
</div>
