<ion-toolbar >
    <ion-title>Info</ion-title>
    <ion-buttons slot="end">
        <ion-button (click)="close()" [strong]="true" color="primary">Close</ion-button>
        </ion-buttons>
</ion-toolbar>

<ion-grid>
    <ion-row>
        <ion-col class="padding">
            Read private Spotify playlists permission granted
        </ion-col>

    </ion-row>
    <ion-row class="padding">
        <ion-checkbox
        disabled="true"
        [checked]="readPrivateSpotLists"
    ></ion-checkbox>!
    </ion-row>
    <ion-row class="padding">
        <ion-col>
            To be able to create Tunes by directly selecting songs in your private Spotify playlists, you need to grant Tune it permission to read them.
            This can be undone at any by doing the steps below.
            <ol>
                <li>Press "Revoke" button below</li>
                <li>Then go to spotify.com and revoke Tune it's permssions</li>
            </ol>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col>
            <ion-button
                class="themed-ion-button"
                (click)="grant()">
                Grant
            </ion-button>
        </ion-col>
        <ion-col>
            <ion-button
                class="themed-ion-button"
                (click)="revoke()">
                Revoke
            </ion-button>
        </ion-col>

    </ion-row>
</ion-grid>
