import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { finalize, tap, map, catchError } from 'rxjs/operators';
import {ENV} from '../../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class authHttpInterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    console.log('in interceptor')
    let ok: string;
    //#FIX check domain properly
    if(req.url.includes(ENV.DOMAIN_OF_BACKEND)){
    /*     req = req.clone({
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' +this.sd.spotAccessToken
        })
        }); */
        //#LU, safe?
        console.log('http intercept, was backend')
        req = req.clone({
            withCredentials: true
        });
      
    }

    return next.handle(req)
    .pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
                console.error('This is client side error',error.message);
            } else {
                console.error('This is server side error',error.message);
                if(error.status==401){
                    this.router.navigateByUrl('/login')
                }
            }
            //return throwError(() => new Error('crap'));
            return throwError(() => error);
        })
    )

  }
}
  