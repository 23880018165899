import { TuneWithLyrics } from './../classes-enums-interfaces-types/interfaces/interfaces'
import {
    ColorThemeE,
    SetSaveTuneFieldsError,
    MNgThemesE,
    ToastEnum,
} from './../classes-enums-interfaces-types/enums/enums.enum'
import { GlobalService } from './../services/global.service'
import { HelperFunctionsService } from '../services/helper-functions/helper-functions.service'

import { MyServerService } from '../services/my-server/my-server.service'
import { IonAccordionGroup, IonicModule, Platform } from '@ionic/angular'
//Edit page is self contained, doesnt need a state manager
import { Keyboard } from '@capacitor/keyboard'
import { ITune } from '../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model'
import {
    MImageV1,
    SlimTuneTrackV1,
    TuneTag,
    TuneTrackV1,
} from '../classes-enums-interfaces-types/classes/classes'
import { HttpClient, HttpParams } from '@angular/common/http'
import { SPACE, ENTER, COMMA } from '@angular/cdk/keycodes'
import {
    Component,
    ElementRef,
    ViewChild,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    AfterViewInit,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { mGlobal } from '../mglobal'
import {
    MatChip,
    MatChipInputEvent,
    MatChipsModule,
} from '@angular/material/chips'
import { MatInputModule } from '@angular/material/input'
import { Observable } from 'rxjs'
import { Location } from '@angular/common'
import { map, startWith } from 'rxjs/operators'
import { MatIconModule } from '@angular/material/icon'
import { ENV } from '../../../environments/environment'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Tune } from '../classes-enums-interfaces-types/classes/classes'
import { PlayerService } from '../services/music/player.service'

import { SaveSendAction } from '../components/footer-bar/footer-bar.module'
import * as _ from 'lodash'
import {
    NativeVolumeControl,
    MPlayerState,
} from 'native-volume-control-npm-package-name'
import { IonContent } from '@ionic/angular'
import { Http } from '@capacitor-community/http'
import { toastController } from '@ionic/core'
import { CdkAccordionItem, CdkAccordion } from '@angular/cdk/accordion'

import * as rangy from 'rangy/lib/rangy-core.js'

import { TagNamesFE } from '../classes-enums-interfaces-types/interfaces/interfaces'
import { ok, Result } from 'neverthrow'
import { ErrorHandlerService } from '../services/error-handler/error-handler.service'
import { start } from 'repl'

//import * as RangyTypes from '@types/rangy'

//was inited multiple times
interface TuneUpdateOrigBase {
    startTime: number
    stopTime: number
    lyrics: {
        lyricsStr: string
        wordRange: {
            startIndex: number
            stopIndex: number
        }
    }
    triggerSentence: string
    autoComplete: string
    messageNa: boolean
}

interface TuneUpdate extends TuneUpdateOrigBase {
    tagNamesToAdd?: string[]
    tagsToRemove?: TagNamesFE[]
}

interface TuneOrigValues extends TuneUpdateOrigBase {}

class CheckBoxCategory {
    names: string[] = []
    values: boolean[] = []
}

@Component({
    selector: 'app-edit-tune',
    templateUrl: './edit-tune.page.html',
    styleUrls: ['./edit-tune.page.scss'],
})
export class EditTunePage implements OnInit, AfterViewInit {
    @ViewChild(IonContent, { static: false }) ionContent: IonContent
    @ViewChild('accordionGroup', { static: true })
    accordionGroup: IonAccordionGroup
    @ViewChild('modal') modal

    stateChangeItr = 0
    onSelectionChangeParent: string | null = null
    highlighter = null
    prevRangyRange //: RangyTypes.RangyRange;
    incDecTuneTimeInterval = null
    pressInc: number = 0

    origValues: TuneOrigValues = this.initTuneUpdateInterface()

    //ngMatMyClass = 'my-light-theme'
    ngMatMyClass = 'my-dark-theme'
    //no hacky 2 hand movement considered
    isSliderMoving: boolean = false
    // if coming to edit-page from track-page receive what page was before track
    // iot to go back to that page on save
    trackPagePrevRoute: string

    constructor(
        private http: HttpClient,
        private router: Router,
        private mServer: MyServerService,
        private location: Location,
        public ps: PlayerService,
        private activatedRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
        public hF: HelperFunctionsService,
        private platform: Platform,
        public gs: GlobalService,
        private eh: ErrorHandlerService
    ) {}

    spotFullLogoIcon = './assets/spotifyFullLogo.svg'

    production = ENV.PRODUCTION
    debug = ENV.DEBUG
    saveSendState = null

    addOnBlur = true

    customLyrics: string = ``
    customLyricsTextArea: string = ''

    mGlobal = mGlobal
    customMenu = '../../../assets/menu.svg'
    customFunnel = '../../../assets/funnel-outline.svg'
    downIcon = '../../assets/down-outline.svg'
    upIcon = '../../assets/up-outline.svg'
    rewind5 = '../../assets/rewind-5.svg'
    forward5 = '../../assets/forward-5.svg'
    downArrowHead = '../../assets/down-outline.svg'
    upArrowHead = '../../assets/up-outline.svg'

    tagPlaceHolder = '#Excited'
    tagPlaceHolderEmpty = ''
    tagPlaceHolderActive = this.tagPlaceHolder

    tune: Tune = new Tune()
    trackImgUrl: string = ''
    trackName: string = 'Track Name'
    artistName: string = 'Artist Name'
    trackId: string = 'Track Id'
    tuneId: string = null

    startTimeS: string = null
    startTimeSPrev: string = null
    stopTimeS: string = null
    stopTimeSPrev: string = null

    sliderTimeCache: number = null
    // to not from .ts when user is dragging but still keep track
    sliderTimeGui: number = null

    trackDuration: number = null
    currentTime: number = null
    initiatedB = false

    timeShift: number = 1000
    timeShiftShift: number = 3000

    togglePlayTextPlay: string = 'Play'
    togglePlayTextPause: string = 'Pause'
    togglePlayText: string = this.togglePlayTextPlay
    toggleLoopTextLoop = 'Loop'
    toggleLoopTextPause = 'Pause'
    toggleLoopText = this.toggleLoopTextLoop

    checkBoxCategory: CheckBoxCategory = new CheckBoxCategory()

    yesNoArray: CheckBoxCategory = new CheckBoxCategory()
    reactionArray: CheckBoxCategory = new CheckBoxCategory()
    moodArray: CheckBoxCategory = new CheckBoxCategory()

    isPlaying: boolean = false
    isPlayingGui: boolean = false
    isLoopingGui: boolean = false

    userId: string
    removableTag = true
    tagCurrentlyTyped = null

    readonly separatorKeysCodes: number[] = [ENTER]

    triggerSentence: string
    autoComplete: string

    searchText
    tuneIndex: number = 0
    loadedTunes = mGlobal.debugTunes
    lyricsArrays = []

    tags: TagNamesFE[] = [] // Could just as well be tune.tags
    tagsToRemove: TagNamesFE[] = []
    tagNamesToAdd: string[] = [] // For hmtl and tuneUpdate, tuneSave is stored at tune.tags
    //tagNames = ['tag1', 'tag2'] // not Set, want to see if it bugs with duplicates
    //tagNamesToAdd = new Set(['demoSwe'])

    //dbQueryStr = `{"slimTuneTrack.trackName":"I'm So Excited"}`
    dbQueryStr = ``

    triggerStarted: boolean = false

    saveSendAction
    shouldLoop: boolean = false
    itr = 0
    getTuneOffset = 30

    //Because getTime is async
    pend = {
        setStartTime: false,
        setStopTime: false,
    }

    testColor = '#FF00FF'
    contentPaddingBottom = '0px'
    contentOverflow = 'scroll'

    //#OPT Lit --keyboard--offset for content
    origDisplayHeight: number

    playbackPosTimerId: ReturnType<typeof setInterval>

    answerPlaceholderDefault = `"Yeah sure, I'm starving"`
    answerPlaceholder = this.answerPlaceholderDefault

    //lyrics : string
    //lyrics = `[Verse 1]\nI know you think that I shouldn't still love you\nOr tell you that\nBut if I didn't say it, well, I'd still have felt it\nWhere's the sense in that?\nI promise I'm not trying to make your life harder\nOr return to where we were\n\n[Chorus]\nBut I will go down with this ship\nAnd I won't put my hands up and surrender\nThere will be no white flag above my door\nI'm in love and always will be\n[Verse 2]\nI know I left too much mess and destruction\nTo come back again\nAnd I caused nothing but trouble\nI…tongue\nAnd you will think\nThat I've moved on\n\n[Chorus]\nI will go down with this ship\nAnd I won't put my hands up and surrender\nThere will be no white flag above my door\nI'm in love and always will be\nI will go down with this ship\nAnd I won't put my hands up and surrender\nThere will be no white flag above my door\nI'm in love and always will be\n\n[Outro]\nI will go down with this ship\nAnd I won't put my hands up and surrender\nThere will be no white flag above my door\nI'm in love and always will be'`
    lyrics =
        "[Verse 1]\nI want to break free\nI want to break free\nI want to break free from your lies\nYou're so self-satisfied, I don't need you\nI've got to break free\nGod knows, God knows I want to break free\n\n[Verse 2]\nI've fallen in love\nI've fallen in love for the first time\nAnd this time I know it's for real\nI've fallen in love, yeah\nGod knows, God knows I've fallen in love\n[Bridge]\nIt's strange but it's true, yeah\nI can't get over the way you love me like you do\nBut I have to be sure\nWhen I walk out that door\nOh, how I want to be free, baby\nOh, how I want to be free\nOh, how I want to break free\n\n[Verse 3]\nBut life still goes on\nI can't get used to living without, living without\nLiving without you by my side\nI don't want to live alone, hey\nGod knows, got to make it on my own\nSo baby, can't you see?\nI've got to break free\n\n[Outro]\nI've got to break free\nI want to break free, yeah\nI want, I want, I want, I want to break free"
    lyricsArray: string[] = []
    lyricsLoaded = false

    selectedLyricNodes
    selectedSpanPrevAcceptedElem
    selectedSpanNextAcceptedElem
    selectedPreviouslyElem
    red = 'warn'
    userSelect: boolean = false

    selectedSpanMinIdndex
    selectedSpanMaxIdIndex

    onSelectionChangeParentIdName = 'lyricsContainer'

    prevEvent

    expectingPlaybackPosZero: boolean = true
    messageNa: boolean = false

    lyricsMissing = false

    darkLightModSub
    playerStateSub
    isLoopingSub

    mAccordionLyricsExpanded = false

    ngOnInit(): void {
        //#region
        /* Http.get({url:ENV.DOMAIN_OF_BACKEND + "/static-web-page-variables"}).then(data => {
            console.log("Got static variables data");
            console.log(data);
            this.yesNoArray.names = (<any>data).documents[0].yesNos;
            this.moodArray.names = (<any>data).documents[0].moods;
            this.reactionArray.names = (<any>data).documents[0].reactions;
        }).catch(e => {
            console.error("Error get static variables",e);
        }) */
        // Keyboard.addListener('keyboardWillShow', info => {
        //     const boundingRect : DOMRect | undefined = document.activeElement?.getBoundingClientRect()
        //     console.log(`willShow bottom ${boundingRect.bottom}`)
        // })
        // Keyboard.addListener('keyboardDidShow', info => {
        //     const boundingRect : DOMRect | undefined = document.activeElement?.getBoundingClientRect()
        //     const keyboardHeight : number = info.keyboardHeight
        //     this.contentPaddingBottom = `${keyboardHeight}px`
        //     this.changeDetector.detectChanges()
        //     const remainingView = this.origDisplayHeight - keyboardHeight
        //     //const scrollPoint = //boundingRect.bottom// + boundingRect.height //+
        //     //remainingView/2 + keyboardHeight
        //     const scrollPoint =  boundingRect.bottom - remainingView/2
        //     console.log('scroll point:', scrollPoint);
        //     this.ionContent.scrollByPoint(null,scrollPoint,1000)
        //     //this.ionContent.scrollToBottom()
        // });
        // Keyboard.addListener('keyboardDidHide', () => {
        //     this.contentPaddingBottom = '0px'
        //     this.changeDetector.detectChanges()
        // });
        //#opt if used more
        //#endregion
    }

    logSelection(event) {
        // Get Selection
        console.log('selection event')
        let sel = window.getSelection()
        let range
        if (sel.rangeCount && sel.getRangeAt) {
            range = sel.getRangeAt(0)
        }
        // Set design mode to on
        document.designMode = 'on'
        if (range) {
            sel.removeAllRanges()
            sel.addRange(range)
        }
        // Colorize text
        document.execCommand('backColor', false, 'red')
        // Set design mode to off
        document.designMode = 'off'
    }

    ngAfterViewInit() {
        console.log('ET init')
        this.origDisplayHeight = window.innerHeight
        this.initSubs()

        console.log(this.itr)
        this.itr++
        const testSelect = document.getElementById('testSelect')
        //testSelect.addEventListener('click', this.logSelection);

        const span1 = document.getElementById('span1')
        span1?.addEventListener('select', () => {
            console.log('span1 selected')
        })

        const spanContainer = document.getElementById('lyricsContainer')
        if (spanContainer) {
            spanContainer.oncontextmenu = function (event) {
                event.preventDefault()
                event.stopPropagation() // not necessary in my case, could leave in case stopImmediateProp isn't available?
                event.stopImmediatePropagation()
                return false
            }
        }

        document.onselectionchange = (event) => {
            try {
                const sel = document.getSelection()
                const parentId =
                    sel?.focusNode?.parentElement?.parentElement?.id

                if (
                    parentId === this.onSelectionChangeParentIdName &&
                    parentId
                ) {
                    this.prevEvent = event

                    const rRange = rangy.getSelection().getRangeAt(0)
                    if (this.prevRangyRange) {
                        //this.prevRangyRange.refresh()
                    }

                    /* bugs currently see reason below, would benice if it worked 
						if(this.prevRangyRange && this.prevRangyRange != rRange){
                            const rangeIntersect = rRange.intersection(this.prevRangyRange)
                            if(rangeIntersect == null){
                                console.log("range not in intersect")
                                this.highlighter.removeAllHighlights()
                            }
                        } */

                    let nodes = rRange.getNodes()
                    if (nodes.length == 0) {
                        nodes = [rRange.startContainer.parentNode]
                    }

                    console.log(nodes)
                    if (nodes && nodes?.length > 0) {
                        if (this.selectedLyricNodes) {
                            for (const prevNode of this.selectedLyricNodes) {
                                if (prevNode.nodeName == 'SPAN') {
                                    prevNode.classList.remove(
                                        'lyric-select-highlight'
                                    )
                                }
                            }
                            this.selectedLyricNodes = null
                        }

                        if (
                            this.tune?.lyrics?.wordRange?.startIndex ||
                            this.tune?.lyrics?.wordRange?.startIndex == 0
                        ) {
                            this.unSetLyricsHighlight(
                                this.tune.lyrics.wordRange.startIndex,
                                this.tune.lyrics.wordRange.stopIndex
                            )
                            //this.unSetLyricsHighlight(0,this.lyricsArray.length)
                            this.tune.lyrics.wordRange.startIndex = null
                            this.tune.lyrics.wordRange.stopIndex = null
                            this.tune.lyrics.lyricsStr = null
                        }
                        this.selectedLyricNodes = []
                        for (const node of nodes) {
                            //console.log(nodes)
                            //every other isn't span, extra nesteed text elem in html
                            if (node.nodeName == 'SPAN') {
                                //console.log(node.innerText)
                                if (node.innerText.charAt(0) != '[') {
                                    node.classList.add('lyric-select-highlight')
                                    console.log('did set highlight')
                                    this.selectedLyricNodes.push(node)
                                }
                            }
                        }
                        //window.getSelection().empty();
                    }

                    //console.log('selectedLyricsNodes')
                    //console.log(this.selectedLyricNodes)

                    //Bugs calls on selection change infenite;y
                    //this.highlighter.highlightSelection('lyric-select-highlight')

                    this.prevRangyRange = rRange

                    //(document.activeElement as HTMLElement).blur();
                } else {
                }
            } catch (e) {
                console.log(e)
                this.eh.logSentryError(e)
            }
        }
    }

    stopTimeInput(event) {
        console.log(event)
    }

    initVariables() {
        this.tune = new Tune()
        this.isPlaying = false
        this.isPlayingGui = false
        this.isLoopingGui = false
        this.saveSendAction = SaveSendAction.save
        this.currentTime = 0

        this.tagsToRemove = []
        this.tagNamesToAdd = []

        this.selectedLyricNodes = []
        this.selectedSpanPrevAcceptedElem
        this.selectedSpanNextAcceptedElem

        this.lyricsLoaded = false
        this.lyricsMissing = false

        this.pressInc = 0
        if (this.incDecTuneTimeInterval)
            clearInterval(this.incDecTuneTimeInterval)
        this.incDecTuneTimeInterval = null
        this.isSliderMoving = false
        this.sliderTimeCache = 0
        this.sliderTimeGui = 0

        this.mAccordionLyricsExpanded = false
        this.trackPagePrevRoute = null

        if (ENV.DEBUG) {
            // this.startTimeS = 55.2
            // this.stopTimeS = 64.3
            // this.trigger = 'a'
            // this.autoComplete = 'b'
            // this.sliderTime = 0
            this.startTimeS = null
            this.stopTimeS = null
            this.triggerSentence = null
            this.autoComplete = null
        } else {
            this.startTimeS = null
            this.stopTimeS = null
            this.triggerSentence = null
            this.autoComplete = null
        }

        if (!ENV.PRODUCTION) {
            this.lyricsArrays = null
            this.loadedTunes = null
        }

        if (ENV.DEBUG) {
            const regExp = /(\S+[^\]]\n|\S+ (?!\d\]\n))/ // make sure not to highlight filter

            //const regExpBrackets = /\[Chorus*\]|\[Verse*\]|\[Outro\]/g
            //this.testLyrics = this.testLyrics.replace(regExpBrackets,'')
            const noUnicodeLyrics = this.lyrics.replace(/\u2005/g, ' ')
            const noSpaceLyricsArray = noUnicodeLyrics.split(regExp)
            this.lyricsArray = []
            noSpaceLyricsArray.forEach((word) => {
                if (word != '' && word) {
                    this.lyricsArray.push(word)
                }
            })
        }
    }

    initSubs() {
        this.playerStateSub = this.ps.playerStateChanged$.subscribe(
            (playerState: MPlayerState) => {
                //stop from picking up state from manual playback from Spotify App
                if (
                    playerState.currentTrackId ==
                        this.tune.slimTuneTrack.trackId ||
                    (this.tune.slimTuneTrack.artistName ==
                        playerState.artistName &&
                        this.tune.slimTuneTrack.trackName ==
                            playerState.trackName)
                ) {
                    const isPlaying = playerState.isPlaying
                    console.log(`${this.stateChangeItr}`)
                    console.log(`isPlaying: ${isPlaying}`)
                    //console.log(`shoudlLoop: ${this.shouldLoop} `)
                    console.log(
                        `playBackPosition: ${playerState.playbackPosition} `
                    )
                    this.isPlaying = isPlaying
                    if (!isPlaying) {
                        clearInterval(this.playbackPosTimerId)
                        this.playbackPosTimerId = null

                        this.playGuiSetIconPlay()
                        this.loopGuiSetIconLoop()
                    } else {
                        if (this.shouldLoop) {
                            this.togglePlayText = this.togglePlayTextPlay
                            this.toggleLoopText = this.toggleLoopTextPause

                            clearInterval(this.playbackPosTimerId)
                            this.playbackPosTimerId = null
                        } else {
                            if (
                                !this.expectingPlaybackPosZero &&
                                playerState.playbackPosition == 0 &&
                                !isPlaying
                            ) {
                                this.togglePlayText = this.togglePlayTextPlay
                                this.expectingPlaybackPosZero = true
                            } else {
                                this.togglePlayText = this.togglePlayTextPause
                            }
                            this.toggleLoopText = this.toggleLoopTextLoop
                        }

                        if (
                            !this.playbackPosTimerId &&
                            playerState.currentTrackId != this.gs.silentTrackId
                        ) {
                            this.playbackPosTimerId = setInterval(() => {
                                this.sliderTimeCache += 1000
                                if (!this.isSliderMoving) {
                                    this.sliderTimeGui = this.sliderTimeCache
                                }
                                if (
                                    this.sliderTimeCache >
                                    this.tune.slimTuneTrack.durationMs
                                ) {
                                    clearInterval(this.playbackPosTimerId)
                                } else {
                                    this.changeDetector.detectChanges()
                                }
                            }, 1000)
                        }
                    }

                    this.sliderTimeCache = playerState.playbackPosition
                    if (!this.isSliderMoving) {
                        this.sliderTimeGui = this.sliderTimeCache
                    }

                    this.changeDetector.detectChanges()
                    this.stateChangeItr++
                    //console.log(`togglePlayText: ${this.togglePlayText}`)
                    /*    
				} else if (isPlaying && (!this.isPlayingGui && !this.isLoopingGui)) { // outside app play
					this.isPlayingGui = true
					this.togglePlayText = this.togglePlayTextPause
					this.toggleLoopText = this.toggleLoopTextLoop
				} */
                }
            }
        )

        this.isLoopingSub = this.ps.isLooping$.subscribe((isLooping) => {
            if (!isLooping && this.shouldLoop) {
                this.shouldLoop = false
                this.toggleLoopText = this.toggleLoopTextLoop
                this.togglePlayText = this.togglePlayTextPlay
            }
        })

        this.isLoopingSub = this.gs.darkLightMode$.subscribe((theme) => {
            this.ngMatMyClass =
                theme == ColorThemeE.light ? MNgThemesE.light : MNgThemesE.dark
            this.changeDetector.detectChanges()
        })
    }

    ionViewWillEnter() {
        this.initVariables()
        try {
            //Assumes track doesnt have tune and can only have one tune, #FIX charming now
            //Fix, no need to fetch trackHere just upload and deal with on backend

            // used even for slimTuneTrack as they both have the required field to create new Tune below

            // slimSpotTrack = {
            // 	trackId : '56LT1fE1fhprAdnIJP9kIS',
            // 	durationMs:null,
            // 	artistName:null,9
            // 	artistId:null,
            // 	images:null,
            // 	trackName:null,
            // 	type:null
            // }

            //if(history?.state?.tune){

            this.trackPagePrevRoute = history?.state?.trackPagePrevRoute

            if (history?.state?.slimSpotTrack) {
                const slimSpotTrack = history?.state?.slimSpotTrack
                this.getLyricsFromTrack(slimSpotTrack)
                // good to set tune directly iot not to have to wait for loadp
                this.tune = new Tune()
                this.tune.slimTuneTrack.durationMs = slimSpotTrack?.durationMs
                this.tune.slimTuneTrack.trackId = slimSpotTrack?.trackId
                this.tune.slimTuneTrack.trackName = slimSpotTrack?.trackName
                this.tune.slimTuneTrack.artistName = slimSpotTrack?.artistName
                this.tune.slimTuneTrack.trackImg =
                    slimSpotTrack?.images?.[1].url

                // could either be a proper init create where find-tune already haved checked tuneByTrackId
                // or an edit of tune from lib where it has to be pofooterBarTuneSavedpulated, #opt can be removed sometimes
            } else if (history?.state?.slimTuneTrack) {
                const slimTuneTrack = history?.state?.slimTuneTrack
                this.getLyricsFromTrack(slimTuneTrack)
                // good to set tune directly iot not to have to wait for loadp
                this.tune = new Tune()
                this.tune.slimTuneTrack.durationMs = slimTuneTrack?.durationMs
                this.tune.slimTuneTrack.trackId = slimTuneTrack?.trackId
                this.tune.slimTuneTrack.trackName = slimTuneTrack?.trackName
                this.tune.slimTuneTrack.artistName = slimTuneTrack?.artistName
                this.tune.slimTuneTrack.trackImg = slimTuneTrack?.trackImg
            } else if (history?.state?.tune) {
                this.tune = history?.state?.tune

                this.getLyricsFromTrack(this.tune.slimTuneTrack)

                // wordRange undefined for legacy customLyrics null henceforth
                if (
                    this.tune.lyrics?.lyricsStr &&
                    !this.tune.lyrics?.wordRange
                ) {
                    this.customLyricsTextArea = this.tune.lyrics.lyricsStr
                }
            } else if (!ENV.PRODUCTION && !this.tune?.slimTuneTrack.trackName) {
                const retrievedTuneJSON = localStorage.getItem('EditTuneTune')
                let retrievedTune
                if (retrievedTuneJSON)
                    retrievedTune = JSON.parse(retrievedTuneJSON)
                //const retrievedTune = new Tune()
                if (retrievedTune) {
                    this.tune = retrievedTune
                    //this.tune.tags = null
                } else {
                    this.loadedTunes = mGlobal.debugTunes
                    this.tune = this.loadedTunes[this.tuneIndex]
                }
            } else {
                this.gs.showToast({
                    msg: 'Error getting Tune to edit',
                    duration: 4000,
                    type: ToastEnum.error,
                })
                this.router.navigateByUrl('/login')
            }

            this.populateTuneFields()
        } catch (e) {
            console.error('nav extras error ', e)
            this.eh.logSentryError(e)
        }
    }

    ionViewDidEnter() {
        this.gs.setPendingHistoryBackClick(false)
    }

    populateLyrics(lyricsArray: string[]) {
        this.lyricsLoaded = true
        this.lyricsArray = lyricsArray
        // cd must be before setLyricsHl otherwise the elems it sets will be hidden
        console.log('lyrics populated')
        this.changeDetector.detectChanges()
        //# opt undefined === undefined => true
        if (
            this.tune?.lyrics?.wordRange?.startIndex ||
            this.tune?.lyrics?.wordRange?.startIndex == 0
        ) {
            this.setLyricsHighlight(
                this.tune.lyrics.wordRange.startIndex,
                this.tune.lyrics.wordRange.stopIndex
            )
        } else if (this.tune?.lyrics?.lyricsStr) {
            this.customLyricsTextArea = this?.tune?.lyrics?.lyricsStr
        }
    }

    //#opt type
    getLyricsFromTrack(slimTuneTrack: any) {
        this.mServer
            .getLyricsFromGeniusFromTrackAndArtistName(
                slimTuneTrack.trackName,
                slimTuneTrack.artistName,
                slimTuneTrack.trackId
            )
            .subscribe(
                (res: any) => {
                    if (res.status == 204) {
                        this.lyricsMissing = true
                        this.lyricsLoaded = true
                        this.changeDetector.detectChanges()
                    } else {
                        this.populateLyrics(res.body.lyricsArray)
                    }
                },
                (e) => {
                    console.error('Error getting lyrics', e)
                    this.lyricsMissing = true
                    this.lyricsLoaded = true
                    this.changeDetector.detectChanges()
                }
            )
    }

    getLyricsFromTuneId(tuneId: string) {
        this.mServer.getLyricsFromTuneId(tuneId).subscribe(
            (lyricsArray) => {
                this.populateLyrics(lyricsArray)
            },
            (e) => {
                console.error('Error getting lyrics', e)
                this.eh.logSentryError(e)
                this.lyricsMissing = true
                this.lyricsLoaded = true
                this.changeDetector.detectChanges()
            }
        )
    }

    ionViewDidLeave() {
        try {
            console.log('edit did leave')
            //this.ps.playerStateChanged$.unsubscribe()
            this.ps.pauseTrack()
            clearInterval(this.playbackPosTimerId)
        } catch (e) {
            console.error('error leaving edit', e)
        }
    }

    ngOnDestroy() {
        console.log('ET ngOnDestroy')
        if (this.incDecTuneTimeInterval)
            clearInterval(this.incDecTuneTimeInterval)

        this.darkLightModSub?.unsubscribe()
        this.isLoopingSub?.unsubscribe()
        this.playerStateSub?.unsubscribe()
    }

    //#opt a calm day make abstract, for any interface
    initTuneUpdateInterface(): TuneUpdate {
        return {
            //#opt dont send empty fields to BE
            startTime: null,
            stopTime: null,
            tagNamesToAdd: [],
            tagsToRemove: [],
            lyrics: null,
            triggerSentence: null,
            autoComplete: null,
            messageNa: null,
        }
    }

    tagListChanged() {
        const lastChar = this.tagCurrentlyTyped.substr(-1)
        if ([' ', ','].includes(lastChar)) {
            this.addTagName(this.tagCurrentlyTyped.slice(0, -1))
        }
    }

    addTagName(tagName: string, event?): void {
        //Todo, require tag

        //enter for chrome is still handled here #opt
        const value = tagName

        if (value == '' && this.gs.isNativeCapPlatform()) {
            Keyboard.hide()
        } else {
            if ((value || '').trim()) {
                let addedTagName = value.trim()
                addedTagName = addedTagName.replace(/#/g, '')

                if (
                    //# opt undefined === undefined => true
                    !this.tune?.tags?.find(
                        (tagName) => tagName.name == addedTagName
                    ) &&
                    !this.tagNamesToAdd.find(
                        (alreadyAddedTagName) =>
                            alreadyAddedTagName == addedTagName
                    )
                ) {
                    this.tagNamesToAdd.push(addedTagName)
                    this.tagPlaceHolderActive = this.tagPlaceHolderEmpty
                } else {
                    this.gs.showToast({
                        msg: 'Tag already exsits',
                        type: ToastEnum.warning,
                    })
                }
            }
        }

        // Reset the input value

        if (this.tagCurrentlyTyped) {
            //input.value = '';
            this.tagCurrentlyTyped = ''
            if (event?.input) {
                event.input.value = ''
            }
        }
    }

    removeAddedTagName(tag): void {
        _.pull(this.tagNamesToAdd, tag)
        // const indexOfTag = this.tune.tags.findIndex(tagObj => {
        //     return tagObj.name == tag
        // })

        // this.tune.tags.splice(indexOfTag,1)
    }

    removeTag(tag) {
        _.pull(this.tune.tags, tag)
        this.hF.push(this.tagsToRemove, tag)
    }

    removeDbStoredTagName(tag) {
        //only for update
        this.tagsToRemove.push(tag)
        //#Fix owner of tag valudation on backend
    }

    sliderStartedMoving() {
        this.isSliderMoving = true
        console.log('slider moving')
        //#Opt could bug and be 1sec wrong if timer moves at the same time
    }

    sliderStoppedMoving() {
        this.isSliderMoving = false
        console.log('slider stopped')
        //#Opt could bug and be 1sec wrong if timer moves at the same time

        this.ps.seekTrackAbsolute(this.sliderTimeGui)
        if (this.sliderTimeGui == 0) {
            this.expectingPlaybackPosZero = true
        }
    }

    radioGrpChange(event) {
        console.log('radioGrpChange')
    }

    // populateCatTwoCheckBoxes(tune) {
    //     let catTwos: string[] = this.getCatsTwoOfTune(tune);
    //     for (let i = 0; i < this.checkBoxCategory?.names?.length; i++) {
    //         if (catTwos.includes(this.checkBoxCategory?.names[i])) {
    //             this.checkBoxCategory.values[i] = true;
    //         }
    //     }
    // }

    // getCatOne(tune: ITune): string {
    //     return tune.categories.name;
    // }

    /*     togglePlayGui() {
        if (this.isPlayingGui) {
            this.togglePlayText = this.togglePlayTextPause;
        } else {
            this.togglePlayText = this.togglePlayTextPlay   
        }
    }
    */

    loopGuiSetIconLoop() {
        this.toggleLoopText = this.toggleLoopTextLoop
    }

    loopGuiSetIconPause() {
        this.toggleLoopText = this.toggleLoopTextPause
    }

    playGuiSetIconPlay() {
        this.togglePlayText = this.togglePlayTextPlay
    }

    playGuiSetIconPause() {
        this.togglePlayText = this.togglePlayTextPause
    }

    async togglePlayTrackClick() {
        this.shouldLoop = false
        if (this.ps.isPlaying && !this.ps.isLooping) {
            this.ps.pauseTrack()
            // for most reactive ui
            this.playGuiSetIconPlay()
        } else {
            this.ps.playTrackId(this.tune.slimTuneTrack.trackId)
            this.loopGuiSetIconLoop()
            this.playGuiSetIconPause()
        }
    }

    async loopTuneClick() {
        if (this.shouldLoop) {
            this.shouldLoop = false
            this.ps.pauseTrack()
        } else {
            clearInterval(this.playbackPosTimerId)
            this.sliderTimeCache = this.tune.startTime
            if (!this.isSliderMoving) {
                this.sliderTimeGui = this.tune.startTime
            }
            const error: string | void = await this.ps.loopTuneRequest(
                this.tune
            )
            if (error) {
                this.gs.showToast({
                    duration: 5000,
                    type: ToastEnum.error,
                    msg: error,
                })
                // for fastest response time
                this.loopGuiSetIconLoop()
            } else {
                this.shouldLoop = true
            }
        }
    }

    // getCatsTwoOfTune(tune: ITune): string[] {
    //     let tmpStr: string[] = [];
    //     for (let i = 0; i < tune.categories.child?.length; i++) {
    //         tmpStr.push(tune.categories.child[i].name);
    //     }
    //     return tmpStr;
    // }

    startTimeChanged() {
        //[min],[max] not working for forms needs some fancy ng reactive-form-validation

        let startTimeSNum = this.formatStringCommaToNumDot(this.startTimeS)
        //#opt will still brake if trying to make something stupid like pasting text
        if (startTimeSNum) {
            if (startTimeSNum < 0) {
                startTimeSNum = 0
            } else if (
                startTimeSNum >=
                this.tune.slimTuneTrack.durationMs / 1000
            ) {
                startTimeSNum = this.tune.slimTuneTrack.durationMs / 1000
            }
            this.startTimeSPrev = this.startTimeS
            this.startTimeS = String(Math.round(startTimeSNum * 100) / 100)
            this.tune.startTime = startTimeSNum * 1000
        } else {
            this.startTimeS = this.startTimeSPrev
        }
    }

    formatStringCommaToNumDot(commaStr: string): number {
        let commaStrMod = commaStr.replace(/,/g, '.')
        // Splitting into two string and replacing all the dots (.'s) in the second string
        let firstOccuranceIndex = commaStrMod.search(/\./) + 1 // Index of first occurance of (.)
        commaStrMod =
            commaStrMod.substr(0, firstOccuranceIndex) +
            commaStrMod.slice(firstOccuranceIndex).replace(/\./g, '')
        let dotNum: number = Number(commaStrMod)
        return dotNum
    }

    stopTimeChanged() {
        let stopTimeSNum = this.formatStringCommaToNumDot(this.stopTimeS)
        //#opt will still brake if trying to make something stupid like pasting text
        if (stopTimeSNum) {
            if (stopTimeSNum < 0) {
                stopTimeSNum = 0
            } else if (
                stopTimeSNum >=
                this.tune.slimTuneTrack.durationMs / 1000
            ) {
                stopTimeSNum = this.tune.slimTuneTrack.durationMs / 1000
            }
            this.stopTimeSPrev = this.stopTimeS
            this.stopTimeS = String(Math.round(stopTimeSNum * 100) / 100)
            this.tune.stopTime = stopTimeSNum * 1000
        } else {
            this.stopTimeS = this.stopTimeSPrev
        }
    }

    async setStartTimeClick() {
        if (!this.pend.setStartTime) {
            this.pend.setStartTime = true
            //const tic = new Date().getTime()
            let startTime: number = await this.ps.getPlaybackTime()
            //const toc = new Date().getTime()
            //startTime = startTime - (toc-tic)
            this.setStartTime(startTime)
            this.pend.setStartTime = false
            //this.setLyricsHighlight(34,44)
        }
    }

    setStartTime = (time: number) => {
        if (time) {
            this.tune.startTime = time
            this.startTimeS = String(parseFloat((time / 1000).toFixed(1)))
            console.log(`Stop time: ${time}`)
        }
    }

    async setStopTimeClick() {
        if (!this.pend.setStopTime) {
            this.pend.setStopTime = true
            const stopTime = await this.ps.getPlaybackTime()
            this.setStopTime(stopTime)
            this.pend.setStopTime = false
        }
    }

    setStopTime = (time: number) => {
        if (time) {
            this.tune.stopTime = time
            this.stopTimeS = String(parseFloat((time / 1000).toFixed(2)))
            console.log(`Stop time: ${time}`)
        }
    }

    debugTrigger() {
        console.log('debugTrigger')
        this.unSetLyricsHighlight(0, this.lyricsArray.length)
    }

    nextTuneClicked() {
        if (this.tuneIndex >= this.loadedTunes?.length) {
            this.tuneIndex = 0
        } else {
            this.tuneIndex++
            this.lyricsArray = this?.lyricsArrays[this.tuneIndex]
            this.unSetLyricsHighlight(0, this?.lyricsArray?.length)
            this.tune = this.loadedTunes[this.tuneIndex]
            this.populateTuneFields()
        }
    }

    prevTuneClicked() {
        if (this.tuneIndex <= 0) {
            this.tuneIndex = this.loadedTunes?.length
        } else {
            this.tuneIndex--
            this.lyricsArray = this?.lyricsArrays[this.tuneIndex]
            this.unSetLyricsHighlight(0, this?.lyricsArray?.length)
            this.tune = this.loadedTunes[this.tuneIndex]
            this.populateTuneFields()
        }
    }

    tuneIndexLostFocus(event) {
        const index = event.currentTarget.value
        console.log(index)
        if (index) {
            if (index > 0 && index < this.loadedTunes?.length) {
                this.tuneIndex = index
            } else if (index >= this.loadedTunes?.length) {
                this.tuneIndex = this.loadedTunes?.length - 1
            } else if (index < 1) {
                this.tuneIndex = 0
            }
            this.populateTuneFields()
        }
        //console.log(event)
        console.log(this.tuneIndex)
    }

    populateTuneFields() {
        this.origValues = {
            startTime: this.tune.startTime,
            stopTime: this.tune.stopTime,
            lyrics: {
                lyricsStr: this.tune?.lyrics?.lyricsStr,
                wordRange: {
                    startIndex: this.tune?.lyrics?.wordRange?.startIndex,
                    stopIndex: this.tune?.lyrics?.wordRange?.stopIndex,
                },
            },
            triggerSentence: this.tune?.triggers?.[0]?.triggerSentence,
            autoComplete:
                this.tune?.triggers?.[0]?.autoCompletes?.[0]?.autoComplete,
            messageNa: this.tune?.triggers?.[0]?.notApplicable,
        }

        this.startTimeS = this.uiStartStopTimeConversionS(this.tune.startTime)
        this.stopTimeS = this.uiStartStopTimeConversionS(this.tune.stopTime)
        // if(this.tune.tags && this.tune.tags.length > 0){
        // 	this.tune?.tags.forEach(tag => {
        // 		tag.name = '#'+tag.name
        // 	})
        // }
        /* this.tagNamesToAdd = [
			'Tag1XXX','Tag2XX'		
		] */

        this.triggerSentence = this.tune.triggers?.[0]?.triggerSentence
        this.autoComplete =
            this.tune.triggers?.[0]?.autoCompletes?.[0]?.autoComplete

        this.triggerStarted =
            this.triggerSentence || this.autoComplete ? true : false

        // works with null
        this.messageNa = this.origValues?.messageNa ? true : false

        /* 
        if(this.tune.tags){
            this.tags = this.tune.tags.map((tag: any) => {
                return tag.name
            })
        } */

        this.changeDetector.detectChanges() // HL lyics text doesn't update if changeDetector last ?!
        // #2 because html dom which is base for class application has not refreshed yet whille css class change
        // doesn't seem to need detectChange
    }

    uiStartStopTimeConversionS(tuneTime: number): string {
        if (tuneTime || tuneTime === 0) {
            const guiTime = Math.round(tuneTime / 10) / 100
            return String(guiTime)
        } else {
            return null
        }
    }

    setTuneSaveFields(): Tune {
        //tune has already been verified, either will be true #opt
        if (this.selectedLyricNodes) {
            this.setTuneLyricsArrayIndex()
        } else {
            _.set(this.tune, 'lyrics.lyricsStr', this.customLyrics)
            _.set(this.tune, 'lyrics.wordRange.startIndex', null)
            _.set(this.tune, 'lyrics.wordRange.stopIndex', null)
        }
        const serverTune = _.cloneDeep(this.tune)
        serverTune.tags = []
        this.tagNamesToAdd.forEach((addedTagName) => {
            serverTune.tags.push({
                name: addedTagName,
                tag: null,
                canDelete: null,
            })
        })
        return serverTune
    }

    getTuneUpdate() {
        // null != null -> false
        //easier for now to always write startStopTime than to have sep. var. iot be comp. with createTune
        const tuneUpdate = this.initTuneUpdateInterface()

        if (mGlobal.isAdmin) {
            tuneUpdate.startTime = this.tune.startTime

            tuneUpdate.stopTime = this.tune.stopTime

            tuneUpdate.tagNamesToAdd = this.tagNamesToAdd // set 2 arr

            tuneUpdate.tagsToRemove = this.tagsToRemove

            if (this.selectedLyricNodes) {
                this.setTuneLyricsArrayIndex()
                if (
                    //Should be enough with one
                    this.origValues.lyrics.wordRange.startIndex !=
                        this.tune?.lyrics?.wordRange?.startIndex ||
                    this.origValues.lyrics.wordRange.stopIndex !=
                        this.tune?.lyrics?.wordRange?.stopIndex
                ) {
                    _.set(
                        tuneUpdate,
                        'lyrics.wordRange.startIndex',
                        this.tune.lyrics.wordRange.startIndex
                    )
                    _.set(
                        tuneUpdate,
                        'lyrics.wordRange.stopIndex',
                        this.tune.lyrics.wordRange.stopIndex
                    )
                }
            } else if (this.origValues.lyrics.lyricsStr != this.customLyrics) {
                _.set(tuneUpdate, 'lyrics.lyricsStr', this.customLyrics)
                // should not be needed, maybe avoid some bug
                _.set(tuneUpdate, 'lyrics.wordRange.startIndex', null)
                _.set(tuneUpdate, 'lyrics.wordRange.stopIndex', null)
            }

            tuneUpdate.triggerSentence = this.triggerSentence

            tuneUpdate.autoComplete = this.autoComplete

            tuneUpdate.messageNa = this.messageNa
        } else {
            if (this.tune.startTime != this.origValues.startTime)
                tuneUpdate.startTime = this.tune.startTime

            if (this.tune.stopTime != this.origValues.stopTime)
                tuneUpdate.stopTime = this.tune.stopTime

            if (this.tagNamesToAdd?.length != 0)
                tuneUpdate.tagNamesToAdd = this.tagNamesToAdd // set 2 arr

            if (this.tagsToRemove?.length != 0)
                tuneUpdate.tagsToRemove = this.tagsToRemove

            if (this.selectedLyricNodes) {
                this.setTuneLyricsArrayIndex()
                if (
                    //Should be enough with one
                    this.origValues.lyrics.wordRange.startIndex !=
                        this.tune?.lyrics?.wordRange?.startIndex ||
                    this.origValues.lyrics.wordRange.stopIndex !=
                        this.tune?.lyrics?.wordRange?.stopIndex
                ) {
                    _.set(
                        tuneUpdate,
                        'lyrics.wordRange.startIndex',
                        this.tune.lyrics.wordRange.startIndex
                    )
                    _.set(
                        tuneUpdate,
                        'lyrics.wordRange.stopIndex',
                        this.tune.lyrics.wordRange.stopIndex
                    )
                }
            } else if (this.origValues.lyrics.lyricsStr != this.customLyrics) {
                _.set(tuneUpdate, 'lyrics.lyricsStr', this.customLyrics)
                // should not be needed, maybe avoid some bug
                _.set(tuneUpdate, 'lyrics.wordRange.startIndex', null)
                _.set(tuneUpdate, 'lyrics.wordRange.stopIndex', null)
            }

            if (this.origValues.triggerSentence !== this.triggerSentence) {
                tuneUpdate.triggerSentence = this.triggerSentence
            }

            if (this.origValues.autoComplete !== this.autoComplete) {
                tuneUpdate.autoComplete = this.autoComplete
            }

            if (this.origValues.messageNa !== this.messageNa) {
                tuneUpdate.messageNa = this.messageNa
            }
        }

        return tuneUpdate
        // if (this.tune.tags) {
        //     this.tagNames = this.tune.tags.map((tag: any) => {
        //         return tag.name
        //     })
        // } else {
        //     this.tagNames = []
        // }
    }

    triggerChange() {
        if (this.triggerSentence) {
            this.triggerStarted = true
            //this.answerPlaceholder = ''
        } else {
            this.triggerStarted = false
            //this.answerPlaceholder = this.answerPlaceholderDefault
        }
    }

    triggerBlur() {
        console.log(`triggerBlur`)
        // #FIX only one trigger per tune
        // #Opt _ ignores types and interfaces
        if (this.triggerSentence == '') {
            _.set(this.tune, 'triggers[0].triggerSentence', null)
        } else {
            _.set(
                this.tune,
                'triggers[0].triggerSentence',
                this.triggerSentence
            )
        }
    }

    autoCompleteBlur() {
        // #FIX only one trigger per tune
        //_.set(this.tune, 'triggers[0].autoCompletes[0].autoComplete', this.autoComplete)

        if (this.autoComplete == '') {
            _.set(this.tune, 'triggers[0].autoCompletes[0].autoComplete', null)
        } else {
            _.set(
                this.tune,
                'triggers[0].autoCompletes[0].autoComplete',
                this.autoComplete
            )
        }

        if (this.autoComplete) this.triggerStarted = true
        else {
            this.triggerStarted = false
        }
    }

    tuneSavedOrUpdated = (tune) => {
        this.tune = tune
        this.populateTuneFields()
        if (mGlobal.isAdmin) this.loadedTunes[this.tuneIndex] = tune
    }

    getTuneUpdateBinded = this.getTuneUpdate.bind(this)
    setTuneSaveFieldsBinded = this.setTuneSaveFields.bind(this)

    pauseClick() {
        this.ps.pauseTrack()
    }

    saveTuneOnlyChange(event) {
        if (event.detail.checked) {
            //this.contentOverflow = 'scroll'
            this.saveSendAction = SaveSendAction.save
        } else {
            //this.contentOverflow = 'hidden'
            this.saveSendAction = SaveSendAction.saveAndSend
        }
        this.changeDetector.detectChanges()
    }

    messageNaChange(event) {
        if (event.detail.checked) {
            //this.contentOverflow = 'scroll'
            _.set(this.tune, 'triggers[0].notApplicable', true)
        } else {
            _.set(this.tune, 'triggers[0].notApplicable', false)
            //this.contentOverflow = 'hidden'
        }

        this.changeDetector.detectChanges()
    }

    incStartTime() {
        //#Opt use decimal pipe and one way binding, instead with just one variable
        this.tune.startTime += 100
        if (this.tune.startTime > this.tune.slimTuneTrack.durationMs) {
            this.tune.startTime = this.tune.slimTuneTrack.durationMs
        }
        this.startTimeS = this.uiStartStopTimeConversionS(this.tune.startTime)
        this.changeDetector.detectChanges()
    }

    decStartTime() {
        this.tune.startTime -= 100
        if (this.tune.startTime < 0) {
            this.tune.startTime = 0
        }
        this.startTimeS = this.uiStartStopTimeConversionS(this.tune.startTime)
        this.changeDetector.detectChanges()
    }

    incStopTime() {
        this.tune.stopTime += 100
        if (this.tune.stopTime > this.tune.slimTuneTrack.durationMs) {
            this.tune.stopTime = this.tune.slimTuneTrack.durationMs
        }
        this.stopTimeS = this.uiStartStopTimeConversionS(this.tune.stopTime)
        this.changeDetector.detectChanges()
    }

    decStopTime() {
        this.tune.stopTime -= 100
        if (this.tune.stopTime < 0) {
            this.tune.stopTime = 0
        }
        this.stopTimeS = this.uiStartStopTimeConversionS(this.tune.stopTime)
        this.changeDetector.detectChanges()
    }

    incDecTuneTime(dir, time) {
        console.log('press')
        this.incDecTuneTimeInterval = setInterval(() => {
            if (time == 'start') {
                this.tune.startTime += dir * 100
                this.startTimeS = this.uiStartStopTimeConversionS(
                    this.tune.startTime
                )
                console.log(this.tune.startTime)
            } else if ((time = 'stop')) {
                this.tune.stopTime += dir * 100
                this.stopTimeS = this.uiStartStopTimeConversionS(
                    this.tune.stopTime
                )
            }
        }, 100)
    }

    clearIncDecTuneTimeInterval() {
        console.log('clearInterval')
        clearInterval(this.incDecTuneTimeInterval)
        this.incDecTuneTimeInterval = null
    }

    rewind() {
        this.ps.seekTrackRelative(-5000)
    }

    forward() {
        this.ps.seekTrackRelative(5000)
    }

    storeTune() {
        localStorage.setItem('EditTuneTune', JSON.stringify(this.tune))
    }

    taChange(event) {
        console.log(event)
    }

    //Decap should not be called on FE
    getLyricString(): string {
        let selStr: string = ''
        try {
            const sel = rangy.getSelection()
            const range = sel.getRangeAt(0)
            const nodes = range.getNodes()

            //nodes are both span and their child #text
            let elemNodes = []
            for (const node of nodes) {
                if (node.nodeName == 'SPAN') elemNodes.push(node)
            }

            for (const [i, elemNode] of elemNodes.entries()) {
                const space = i == elemNodes?.length ? '' : ' '
                selStr += elemNode.dataset.lyricword + space
            }
        } catch (e) {
            console.error('Failed to convert selection to string', e)
            this.eh.logSentryError(e)
        }

        console.log(selStr)
        return selStr
    }

    setTuneLyricsArrayIndex() {
        let startIndex: number
        let stopIndex: number
        let selStr: string = ''

        try {
            const nodes = this.selectedLyricNodes
            //const nodes = null

            if (nodes && nodes.length != 0) {
                //nodes are both span and their child #text
                let elemNodes = []
                for (const node of nodes) {
                    if (node.nodeName == 'SPAN') elemNodes.push(node)
                }

                for (const [i, elemNode] of elemNodes.entries()) {
                    const space = i == elemNodes?.length ? '' : ' '
                    selStr += elemNode.dataset.lyricword + space
                }

                startIndex = elemNodes[0].id
                //#Opt get es2022
                //stopIndex = elemNodes.at(-1)
                stopIndex = elemNodes[elemNodes?.length - 1].id
                // + Unary operator, to turn string into number
                _.set(this.tune, 'lyrics.wordRange.startIndex', +startIndex)
                _.set(this.tune, 'lyrics.wordRange.stopIndex', +stopIndex)

                //this.tune.lyrics.lyricsStr = selStr
                //return {startIndex:startIndex, stopIndex:stopIndex}
            } else {
                // isnt required, will overwrite server values, and validation will failt anyway
                //_.set(thiss.tune,'lyrics.wordRange.startIndex', null )
                //_.set(this.tune,'lyrics.wordRange.stopIndex', null )
            }
        } catch (e) {
            console.error('Failed to convert selection to string', e)
            throw e
        }
    }

    setLyricsHighlight(startIndex: number, stopIndex: number) {
        const lyricsContainerElem = document.getElementById('lyricsContainer')
        for (let i = startIndex; i <= stopIndex; i++) {
            const lyricElem = lyricsContainerElem.children[i] as HTMLElement
            if (lyricElem?.dataset?.lyricword.charAt(0) != '[')
                lyricElem?.classList.add('lyric-select-highlight')
        }
        console.log(lyricsContainerElem)
    }

    unSetLyricsHighlight(startIndex: number, stopIndex: number) {
        const lyricsContainerElem = document.getElementById('lyricsContainer')

        //#opt unclear why = is needed in for
        if (startIndex != 0 && stopIndex != 0) {
            for (let i = startIndex; i <= stopIndex; i++) {
                const lyricElem = lyricsContainerElem.children[i] as HTMLElement
                if (lyricElem?.dataset?.lyricword.charAt(0) != '[')
                    lyricElem?.classList.remove('lyric-select-highlight')
            }
        }
        console.log(lyricsContainerElem)
    }

    saveCustomLyrics() {
        this.customLyrics = this.customLyricsTextArea // to get customLyricsTextArea to textArea for modifications from nodes
        this.selectedLyricNodes = null
        this.unSetLyricsHighlight(0, this.lyricsArray.length)
        this.changeDetector.detectChanges()
        this.modal.dismiss()
    }

    lyricsGridClicked() {
        // to clear mobile selection, workks on android, long press for lyrics
        // does not register as click so fine
        window.getSelection().removeAllRanges()
    }

    searchBarSearchEntered(searchText) {
        if (searchText) {
            this.dbQueryStr = searchText
        } else {
            this.dbQueryStr = ''
        }

        if (mGlobal.isAdmin) {
            this.mServer
                .getTunesByQuery(
                    //this.searchText
                    this.dbQueryStr,
                    this.getTuneOffset
                )
                .subscribe(
                    (tunesAndLyrics: any) => {
                        this.getTuneOffset += 3
                        this.lyricsArrays = tunesAndLyrics.lyricsArrays
                        this.loadedTunes = tunesAndLyrics.tunes
                        this.tuneIndex = 0
                        this.tune = this.loadedTunes[0]
                        this.lyricsArray = this.lyricsArrays[0]
                        this.lyricsLoaded = true
                        this.populateTuneFields()
                        this.populateLyrics(this.lyricsArray)
                        this.changeDetector.detectChanges()
                    },
                    (e) => {
                        console.error(`Error fetching tunes by tag`, e)
                        this.eh.logSentryError(e)
                    }
                )
        }
    }

    mAccordionLyricsExpandedToggle() {
        this.mAccordionLyricsExpanded = !this.mAccordionLyricsExpanded
    }

    footerBarBackClick() {
        if (this.trackPagePrevRoute) {
            this.router.navigateByUrl(this.trackPagePrevRoute)
        } else {
            this.gs.setPendingHistoryBackClick(true)
            this.location.back()
        }
    }

    footerBarTuneSaved() {
        if (!mGlobal.isAdmin) {
            if (this.trackPagePrevRoute) {
                this.router.navigateByUrl(this.trackPagePrevRoute)
            } else {
                this.gs.setPendingHistoryBackClick(true)
                this.location.back()
            }
        }
    }
}
