import { Component, OnInit } from '@angular/core'
import { LoggingLocalService } from '../services/logging-local-service/logging-local-service.service'

@Component({
    selector: 'app-admin',
    templateUrl: './admin.page.html',
    styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {
    constructor(private loggingLocal : LoggingLocalService) {}

    ngOnInit() {}

    clearLocalLogs() {

        this.loggingLocal.clearLogs()

    }
}
