import { MHttpService } from './../helper-functions/mhttp/mhttp.service'
import { GlobalService } from './../global.service'
import { PlayerService } from './../music/player.service'
import { SignUpPage } from './../../auth/sign-up/sign-up.page'
import { CookieService } from 'ngx-cookie-service'
import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Router } from '@angular/router'
import { catchError, tap } from 'rxjs/operators'
import { throwError, BehaviorSubject } from 'rxjs'
import { ENV } from '../../../../environments/environment'
import { User } from '../../classes-enums-interfaces-types/classes/classes.js'
import { Capacitor } from '@capacitor/core'
import {
    InAppBrowser,
    InAppBrowserObject,
    InAppBrowserOptions,
} from '@awesome-cordova-plugins/in-app-browser/ngx'
import { Http } from '@capacitor-community/http'
import { Preferences } from '@capacitor/preferences'
import { ToastController } from '@ionic/angular'
import { LoginParams } from '../../../../../backend/classes-enums-interfaces-types/types/types.shared'
import {
    LoggingLocalErrors,
    LoggingLocalRoutingEvents,
    ToastEnum,
} from '../../classes-enums-interfaces-types/enums/enums.enum'
import { ErrorHandlerService } from '../error-handler/error-handler.service'
import { LoggingLocalService } from '../logging-local-service/logging-local-service.service'
import { emit } from 'process'


export interface AuthResponseData {
    accessToken: string
    email: string
    refreshToken: string
    expiresIn: string
    userId: string
}

export interface AuthAccessToken {
    access_token: string
    token_type: string
    expires_in: number
    refresh_token: string
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    user = new BehaviorSubject<User>(null)

    private hasLoggedIn: boolean = null // a user w/o sign up code is still logged in
    private isSignedUp: boolean = null
    private tokenExpirationTimer: any

    backendRedirectPath = 'backend-redirect-iab'
    browsers: any[] = []
    inAppBrowserOptions: InAppBrowserOptions = {
        toolbarposition: 'top',
        toolbar: 'yes',
        keyboardDisplayRequiresUserAction: 'yes',
        location: 'yes',
        toolbartranslucent: 'no',
    }

    loggedIn$ = new BehaviorSubject<boolean>(false)

    constructor(
        private mhttp: MHttpService,
        private http: HttpClient,
        private router: Router,
        private cookieService: CookieService,
        private iab: InAppBrowser,
        private ps: PlayerService,
        private gs: GlobalService,
        public toastController: ToastController,
        private eh: ErrorHandlerService,
        private loggingLocal: LoggingLocalService
    ) {}

    async iabLoginCallback(browser: InAppBrowserObject) {
        browser.on('message').subscribe((params) => {
            console.log('message callback')
            console.log(params)
        })

        browser.on('loaderror').subscribe((params) => {
            console.log(params.url)
            console.log(params.message)
            console.log('close cordova browser')
            this.closeBrowsers()
        })

        browser.on('loadstart').subscribe(async (params) => {
            try {
                console.log(`onLoadstart`)
                console.log(params)
                if (
                    params.url.includes(
                        this.backendRedirectPath
                        //'localhost'
                    )
                ) {
                    const url = new URL(params.url)
                    const search = new URLSearchParams(url.search)
                    const frontendRedirectPath =
                        search.get('frontendRedirectPath') ?? 'find-tune'
                    console.log(`frontendRedirectPath: ${frontendRedirectPath}`)

                    if (frontendRedirectPath === 'login') {
                        await Http.clearAllCookies()

                        //this.ps.setSpotAccessToken(null)
                        this.closeBrowsers()
                        this.router.navigateByUrl('login')
                    } else {
                        const hash = new URLSearchParams(
                            url.hash.replace('#', '')
                        )
                        const spotTokens = decodeURIComponent(
                            hash.get('spotTokens') || ''
                        )
                        const connectSid = decodeURIComponent(
                            hash.get('connectSid') || ''
                        )

                        if (spotTokens) {
                            await Http.setCookie({
                                url: ENV.DOMAIN_OF_BACKEND,
                                key: 'spotTokens',
                                value: spotTokens,
                            })
                            console.log(JSON.parse(spotTokens))
                            this.ps.setSpotAccessToken(JSON.parse(spotTokens))
                            this.sethasLoggedIn(true) // will redirect to sign up page on first load
                        }

                        if (connectSid) {
                            await Http.setCookie({
                                url: ENV.DOMAIN_OF_BACKEND,
                                key: 'connect.sid',
                                value: connectSid,
                            })
                        }

                        const domainOfBackend = ENV.DOMAIN_OF_BACKEND
                        //console.log(`domain of backend: ${domainOfBackend}`)

                        console.log(`Router url ${this.router.url}`)
                        console.log(`FE redirect ${frontendRedirectPath}`)

                        if (this.router.url == frontendRedirectPath) {
                            //QnD way to reattempt fetch of triggers in my library after discovery of first
                            // 401
                            this.router
                                .navigateByUrl('/', {
                                    skipLocationChange: true,
                                })
                                .then(() => {
                                    console.log('skippedLocation')
                                    this.router.navigate([frontendRedirectPath])
                                })
                        } else {
                            this.router.navigateByUrl(frontendRedirectPath)
                        }
                    }
                    this.closeBrowsers()
                }
            } catch (e) {
                console.error(`onHandleUrl: ${e}`)
                this.eh.logSentryError(e)
            }
        })
    }

    async signup(signUpDisplayName: string) {
        const url = new URL(ENV.DOMAIN_OF_BACKEND)
        url.pathname = 'sign-up-backend'
        const search = new URLSearchParams()
        search.append('signUpDisplayName', signUpDisplayName)
        //search.append('signUpCode',signUpCode.toString())

        search.append('ngsw-bypass', 'true')

        if (this.gs.isNativeCapPlatform()) {
            search.append('platform', this.gs.getCapPlatform())
            url.search = search.toString()
            const browser = this.iab.create(
                url.toString(),
                '_blank',
                this.inAppBrowserOptions
            )
            this.browsers.push(browser)
            this.iabLoginCallback(browser)
        } else {
            url.search = search.toString()
            window.location.href = url.toString()
        }
    }

    async login(loginParams: LoginParams | any) {

        try{
            if (
                (this.gethasLoggedIn()) ||
                loginParams.calledFromGui ||
                loginParams.redirectPath.includes('/play-tune') ||
                loginParams.redirectPath.includes('/tune-list')
            ) {
                //const queryStr = this.hF.objectToQueryParams(loginParams)
                const url = new URL(ENV.DOMAIN_OF_BACKEND + '/login-backend')
                const search = new URLSearchParams(loginParams)
                search.append('ngsw-bypass', 'true')
                //const url2 = 'https://tune-it.app' +'/login?' +queryStr
                //this.router.navigateByUrl(url)
                //window.open('http://192.168.43.210:8080/login')
                console.log(this.gs.getCapPlatform())
                console.log(this.gs.isNativeCapPlatform())
                const capPlatform = this.gs.getCapPlatform()
    
                if (
                    this.gs.isNativeCapPlatform() // || true
                ) {
                    if (window.origin === ENV.DOMAIN_OF_LR) {
                        search.append('liveReload', 'true')
                    }
    
                    search.append('platform', capPlatform)
    
                    url.search = search.toString()
    
                    console.log(url.toString())
                    const browser = this.iab.create(
                        url.toString(),
                        '_blank',
                        this.inAppBrowserOptions
                    )
                    this.browsers.push(browser)
                    this.iabLoginCallback(browser)
                } else {
                    url.search = search.toString()
                    console.log(`window redirecit FE ${url.toString()}`)
                    this.loggingLocal.addLog(LoggingLocalRoutingEvents.FeToBeHrefLogin)
                    window.location.href = url.toString()
                }
            } else {
                console.log('Login else')
                await this.loggingLocal.addLog(
                    LoggingLocalRoutingEvents.AuthServiceLoginToLoginPage,
                    {
                        msg : {
                            loginParams : loginParams,
                            hasLoggedIn: this.gethasLoggedIn(),
                        }
                    }
                )
                this.router.navigateByUrl('/login')
            }
        } catch (e) {
            this.loggingLocal.addLog(
                LoggingLocalRoutingEvents.AuthServiceLoginToLoginPage,
                {
                    error: e
                }

            )
        }
 
    }

    closeBrowsers() {
        console.log(`Nbr of browser: ${this.browsers?.length}`)
        this.browsers.forEach((browser) => {
            if (browser) {
                browser.close()
            }
        })
        this.browsers = []
    }

    async logout() {
        try {
            //Do the request to clear session aka http only cookie
            this.http.get(`${ENV.DOMAIN_OF_BACKEND}/logout`).toPromise()

            Http.clearAllCookies()

            this.user.next(null)
            localStorage.removeItem('userData')
            if (this.tokenExpirationTimer) {
                clearTimeout(this.tokenExpirationTimer)
            }
            this.tokenExpirationTimer = null

            //for native
            //await Preferences.set({key:'hasLoggedIn', value:'false'})

            this.sethasLoggedIn(false)

            this.gs.showToast({
                msg: `You've logged out`,
                duration: 4000,
                type: ToastEnum.success,
                header: 'Message',
            })

            this.router.navigateByUrl('/login')
        } catch (e) {
            console.error('Error logging out', e)
            this.eh.logSentryError(e)
            this.gs.showToast({
                duration: 5000,
                msg: 'Error logging out',
                type: ToastEnum.error,
            })
        }
    }

    autoRefreshToken(expirationDuration: number) {
        this.tokenExpirationTimer = setTimeout(() => {
            this.refreshToken()
        }, expirationDuration)
    }

    private saveAuthCookie() {}

    refreshToken() {
        const refreshToken: User = JSON.parse(
            localStorage.getItem('userData')
        ).refreshToken
        if (!refreshToken) {
            return
        }

        let body = {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        }

        this.http.post<AuthAccessToken>(
            `${ENV.DOMAIN_OF_BACKEND}/refresh-token`,
            body
        )
    }

    async sethasLoggedIn(hasLoggedIn: boolean) {
        this.loggedIn$.next(hasLoggedIn)

        this.hasLoggedIn = hasLoggedIn
        //#FIX what if error
        if (Capacitor.isNativePlatform()) {
            await Preferences.set({ key: 'hasLoggedIn', value: 'true' })
        } else {
            const hasLoggedInStr = hasLoggedIn ? 'true' : 'false'
            this.cookieService.set('hasLoggedIn', hasLoggedInStr)
            this.hasLoggedIn = hasLoggedIn
        }
    }

    gethasLoggedIn() {
        if (this.hasLoggedIn == null) {
            // if(Capacitor.isNativePlatform()){
            //     const {value} = await Preferences.get({key:'hasLoggedIn'})
            //     this.hasLoggedIn = (value == 'true') ? true : false
            // } else {
            this.hasLoggedIn =
                this.cookieService.get('hasLoggedIn') == 'true' ? true : false
        }
        return this.hasLoggedIn
    }

    getIsSignedUp() {
        if (this.isSignedUp == null) {
            // if(Capacitor.isNativePlatform()){
            //     const {value} = await Preferences.get({key:'hasLoggedIn'})
            //     this.hasLoggedIn = (value == 'true') ? true : false
            // } else {
            this.isSignedUp =
                this.cookieService.get('isSignedUp') == 'true' ? true : false
        }
        return this.isSignedUp
    }
}
