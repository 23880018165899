import { start } from 'repl'

import { ListElement } from './../../classes-enums-interfaces-types/types/types'
import { IListElementType } from '../../classes-enums-interfaces-types/interfaces/list-elems/list-elems.model'
import { FindTuneselectStates } from '../../classes-enums-interfaces-types/types/types'
import { MyServerService } from './../../services/my-server/my-server.service'
import { FindTuneStateService } from './../../services/states/find-tune/find-tune-state.service'
import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    AfterViewInit,
} from '@angular/core'
import {
    SelectStatesEnum,
    SpotifySearchTypesEnum,
} from '../../classes-enums-interfaces-types/enums/find-tune/enums.enum'
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
    CommonModule,
} from '@angular/common'
import { Router, Routes, RouterModule, ActivatedRoute } from '@angular/router'
import { HostListener } from '@angular/core'
import { KeyValue } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service'
import { DataService } from '../../services/music/data.service'
import { SlimSpotTrackV1 } from '../../classes-enums-interfaces-types/classes/classes'
import { Playlist } from 'spotify-web-sdk'
import { GlobalService } from '../../services/global.service'
import { IMSpotPlaylistPreview } from '../../classes-enums-interfaces-types/interfaces/interfaces'
import { AuthService } from '../../services/auth/auth.service'
import { SpotExtraPermissions } from '../../../../../backend/classes-enums-interfaces-types/enums/enums.shared.enum'

@Component({
    selector: 'app-category-handler',
    templateUrl: './category-handler.component.html',
    styleUrls: ['./category-handler.component.scss'],
})
export class CategoryHandlerComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    constructor(
        private http: HttpClient,
        private mServer: MyServerService, // Problem with public? #Fix
        private location: Location,
        private findTuneState: FindTuneStateService,
        private changeDetector: ChangeDetectorRef,
        private eh: ErrorHandlerService,
        private ds: DataService,
        private gs: GlobalService,
        private auth: AuthService
    ) {}

    selectStatesEnum = SelectStatesEnum
    categoriesTwoArray = []
    numberOfCatTwoColums = 2
    numberOfCatTwoRows
    numberOfColInRow = []
    categoryList
    attrTest = 'XXX'

    /*   catOne : string = ''
  catTwo : string='' */

    //debug
    catOne: string = 'Moods'
    catTwo: string = 'Happy'

    fetchOffset: number = 0
    fetchLimit: number = 20

    noMoreServerElems: boolean = false
    elemtId: string = ''
    elemArr: any[] = []
    tunesInCatLoaded = false

    spotPlaylistM = {
        listsUsersAll: <Playlist[]>[],
        listsUserSearched: <Playlist[]>[],
        listsSearchedAll: <Playlist[]>[],
        offset: <number>0,
        limit: <number>10,
        allFetched: <boolean>false,
    }

    spotPlaylistLikedSongs = {
        offset: <number>0,
        limit: <number>10,
        allFetched: <boolean>false,
        playlist: <Playlist[]>[],
    }

    spotPlaylistS = {
        preview: <IMSpotPlaylistPreview>null,
        mFormatFetched: <SlimSpotTrackV1[]>[],
        offset: <number>0,
        limit: <number>10,
        id: <string>null,
        allFetched: <boolean>false,
    }

    categoriesLoaded: boolean = false

    catsOneChildrenMap: Map<string, [string]>

    selectStateB$ = this.findTuneState.select.stateB$.asObservable()
    searchB$ = this.findTuneState.searchB$.asObservable()
    selectState: SelectStatesEnum = this.findTuneState.select.state

    heart = '../../../assets/heart.svg'
    spotLikedSongsImg = '../../../assets/pixelImages/spotLikedSongsImg.jpg'

    likedSongsPlayListSelected: boolean = false

    hideLibraryPermissionInfo: boolean = false
    spotExtraPermissionReadPrivateLists: boolean = false

    infoIcon = '../../assets/information-circle-outline.svg'

    ngOnInit(): void {
        //#Opt make async in html
       

        //this.spotPlaylistBtnClicked()
    }

    ngAfterViewInit(): void {

        console.log('D3 ngAfterViewInit S')
        this.selectStateB$.subscribe(
            (selectState: {
                state: SelectStatesEnum
                statePrev: SelectStatesEnum
            }) => {
                console.log('selectState')
                console.log(selectState)
                this.selectState = selectState.state

                if (
                    this.selectState == SelectStatesEnum.listSpotPlaylistAll &&
                    selectState.statePrev == SelectStatesEnum.catOne
                ) {
                    this.spotPlaylistM.offset = 0
                    this.spotPlaylistM.allFetched = false
                    this.spotPlaylistM.listsUsersAll = []
                    this.fetchSpotPlaylistM()
                } else if (this.selectState == SelectStatesEnum.spotPlaylist) {
                    if (this.likedSongsPlayListSelected) {
                        this.fetchSpotPlaylistLikedSongs()
                    } else {
                        this.fetchSpotPlaylistS()
                    }
                }
            }
        )

        this.searchB$.subscribe((searchText: string) => {
            if (this.selectState == SelectStatesEnum.spotPlaylistsSearch) {
                this.ds
                    .search(searchText, SpotifySearchTypesEnum.playlist, 0, 10)
                    // just seems incorrect in spot ts sdk PlaylistBase in SearchResultsMap should be playlist
                    //.subscribe(( res : SearchResults<[SpotifySearchTypesEnum.playlist]>) => {
                    .subscribe(
                        (res: any) => {
                            if (
                                res.playlists.items &&
                                res.playlists.items.length > 0
                            ) {
                                this.spotPlaylistM.listsSearchedAll =
                                    res.playlists.items
                                this.spotPlaylistM.listsUserSearched =
                                    res.playlists.items.filter((list) => {
                                        return (
                                            list.owner.id ==
                                                this.gs.getSpotId() &&
                                            list?.owner.id
                                        )
                                    })
                            }
                            this.changeDetector.detectChanges()
                        },
                        (e) => {
                            console.error(
                                'error searching for user Spotify playlist'
                            )
                            this.eh.logSentryError(e)
                        }
                    )
            }
        })

        this.gs.userSettings$.subscribe((settings) => {
            this.hideLibraryPermissionInfo =
                settings?.hideLibraryPermissionInfo ?? false
                console.log('D3 ngAfterViewInit CD')
            this.changeDetector.detectChanges()
        })



        // #opt when coming from login-backen check if cookie has changed and send out all cookies
        // in BehaviorSubject
        // can't be sub. as set on backend
        this.spotExtraPermissionReadPrivateLists =
            this.gs.getSpotExtraPermissionReadPrivateLists()

        console.log('D3 ngAfterViewInit S')
    }

    /* ]
   originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  } */

    ionViewDidEnter() {
        console.log('D3 DidEnter')
        //this.fetchElems() // why is this needed?
        this.mServer.getCategories().subscribe(
            (categoryList) => {
                this.categoryList = categoryList
                this.categoriesLoaded = true
                this.catsOneChildrenMap = new Map([
                    ['Moods', this.categoryList.moods],
                    ['Yes/No', this.categoryList.yesNos],
                    ['Reactions', this.categoryList.reactions],
                ])
                this.changeDetector.detectChanges()
            },
            (e) => {
                console.error('get-categories error: ', e)
                this.eh.logSentryError(e)
            }
        )

        this.gs.setPendingHistoryBackClick(false)
        this.changeDetector.detectChanges()
    }

    ngOnDestroy(): void {
        console.log('Category Handler Destroy')
    }

    endOfScroll(event) {
        console.log('End of scroll')
    }

    originalOrder = (a, b): number => {
        return 0
    }

    catOneClicked(event) {
        //added attribute to all childs of category card. If text is clicked ion-card-title will be element
        this.catOne = event.target.getAttribute('data-cat-one') // Fix Will likely not work with icon
        this.categoriesTwoArray = this.catsOneChildrenMap.get(this.catOne)

        //Bad but apperently no better way as Ng doesn't allow to ngFor X number of times.
        this.numberOfCatTwoRows = Array(
            Math.ceil(
                this.categoriesTwoArray.length / this.numberOfCatTwoColums
            )
        )
            .fill(0)
            .map((x, i) => i)

        this.numberOfColInRow = Array(this.numberOfCatTwoRows.length).fill(
            2,
            0,
            this.numberOfCatTwoRows.length
        )

        if (this.categoriesTwoArray.length % 2 === 1) {
            this.numberOfColInRow[this.numberOfColInRow.length - 1] = 1
        }

        this.findTuneState.setSelectState(SelectStatesEnum.catTwo)
    }

    catTwoClicked(event) {
        this.catTwo = event.target.getAttribute('data-cat-two')
        this.elemArr = []
        this.findTuneState.setSelectState(SelectStatesEnum.listTunes)
        this.noMoreServerElems = false
        this.fetchElems()
    }

    spotPlaylistBtnClicked() {
        this.findTuneState.setSelectState(SelectStatesEnum.listSpotPlaylistAll)
    }

    fetchElems() {
        if (!this.noMoreServerElems) {
            this.mServer
                .getSlimTunesInCat(
                    this.catOne,
                    this.catTwo,
                    this.fetchOffset,
                    this.fetchLimit
                )
                .subscribe(
                    (tunesRes: any) => {
                        let tunes = tunesRes.tunes
                        this.elemArr.push(...tunes)
                        //console.log(this.elemArr)
                        this.fetchOffset += tunes.length
                        if (tunes.length < this.fetchLimit)
                        this.noMoreServerElems = true
                        console.log(this.elemArr)

                        // if using ngAfterViewInii in list-elem .detectChanges() must be here
                        // not to throw ExpressionChangedAfterItHasBeenCheckedError
                        //this.changeDetector.detectChanges()
                    },
                    (e) => {
                        console.error('Fetch elem error', e)
                        this.eh.logSentryError(e)
                    }
                )
        }
    }

    
    elemClicked(listElem: ListElement) {
        this.findTuneState.select.listElemClicked(listElem)
    }

    playlistClicked(playlist) {
        this.spotPlaylistS.preview = { name: playlist?.name, id: playlist?.id }
        this.spotPlaylistS.offset = 0
        this.spotPlaylistS.mFormatFetched = []
        this.spotPlaylistS.allFetched = false

        this.likedSongsPlayListSelected = false

        this.findTuneState.setSelectState(SelectStatesEnum.spotPlaylist)
    }

    likedSongsClicked() {
        this.spotPlaylistS.preview = { name: 'Liked Songs' }
        this.spotPlaylistS.offset = 0
        this.spotPlaylistS.mFormatFetched = []
        this.spotPlaylistS.allFetched = false

        this.likedSongsPlayListSelected = true

        this.findTuneState.setSelectState(SelectStatesEnum.spotPlaylist)
    }

    onIonInfSpotPlaylistsM(event) {
        if (!this.spotPlaylistM.allFetched) {
            if (this.likedSongsPlayListSelected) {
                this.fetchSpotPlaylistLikedSongs(event)
            } else {
                this.fetchSpotPlaylistM(event)
            }
        } else {
            if (event) event.target.complete()
        }
    }

    onIonInfSpotPlaylistS(event) {
        if (!this.spotPlaylistS.allFetched) {
            this.fetchSpotPlaylistS(event)
        } else {
            if (event) event.target.complete()
        }
    }

    fetchSpotPlaylistM(ionInfEvent?: any) {
        this.ds
            .getUserSpotPlaylists(
                this.spotPlaylistM.offset,
                this.spotPlaylistS.limit
            )
            .subscribe(
                (res) => {
                    if (res?.items?.length > 0) {
                        this.spotPlaylistM.listsUsersAll =
                            this.spotPlaylistM.listsUsersAll.concat(res.items)
                        this.changeDetector.detectChanges()
                        console.log(res)
                        this.spotPlaylistM.offset += this.spotPlaylistM.limit
                    }

                    if (res.items.length < this.spotPlaylistM.limit)
                        this.spotPlaylistM.allFetched = true
                    if (ionInfEvent) ionInfEvent.target.complete()
                },
                (e) => {
                    console.error("Error fetching user's Spotify playlists", e)
                    this.eh.logSentryError(e)
                }
            )
    }

    fetchSpotPlaylistLikedSongs(ionInfEvent?: any) {
        this.ds
            .getLikedSongsList(
                this.spotPlaylistS.offset,
                this.spotPlaylistS.limit
            )
            .subscribe(
                (res: any) => {
                    if (res.items?.length > 0) {
                        const spotTracks = []
                        const spotItemsAddedAt = []
                        for (const item of res.items) {
                            spotItemsAddedAt.push()
                            spotTracks.push(item.track)
                        }
                        this.spotPlaylistS.mFormatFetched =
                            this.spotPlaylistS.mFormatFetched.concat(
                                this.ds.spotFetchFormatToMFormat(
                                    spotTracks,
                                    spotItemsAddedAt
                                )
                            )
                        this.spotPlaylistS.offset += this.spotPlaylistS.limit
                        this.changeDetector.detectChanges()
                        if (res.items.length < this.spotPlaylistS.limit)
                            this.spotPlaylistS.allFetched = true
                    }
                    console.log(res)
                    if (ionInfEvent) ionInfEvent.target.complete()
                },
                (e) => {
                    console.error('Error fetching Spotify playlist', e)
                    this.eh.logSentryError(e)
                }
            )
    }

    fetchSpotPlaylistS(ionInfEvent?: any) {
        this.ds
            .getSpotPlaylist(
                this.spotPlaylistS?.preview?.id,
                this.spotPlaylistS.offset,
                this.spotPlaylistS.limit
            )
            .subscribe(
                (res) => {
                    if (res.items?.length > 0) {
                        const spotTracks = []
                        const spotItemsAddedAt = []
                        for (const item of res.items) {
                            spotItemsAddedAt.push()
                            spotTracks.push(item.track)
                        }
                        this.spotPlaylistS.mFormatFetched =
                            this.spotPlaylistS.mFormatFetched.concat(
                                this.ds.spotFetchFormatToMFormat(
                                    spotTracks,
                                    spotItemsAddedAt
                                )
                            )
                        this.spotPlaylistS.offset += this.spotPlaylistS.limit
                        this.changeDetector.detectChanges()
                        if (res.items.length < this.spotPlaylistS.limit)
                            this.spotPlaylistS.allFetched = true
                    }
                    console.log(res)
                    if (ionInfEvent) ionInfEvent.target.complete()
                },
                (e) => {
                    console.error('Error fetching Spotify playlist', e)
                    this.eh.logSentryError(e)
                }
            )
    }

    spotTrackClicked(spotTrack) {
        this.findTuneState.select.listElemClicked(spotTrack)
    }

    // #opt after granting permission should redirect back to category handler
    grantPermissionSpotLibrary() {
        this.auth.login({
            redirictPath: '/category-handler',
            spotExtraPermissions: [
                SpotExtraPermissions.userLibraryRead,
                SpotExtraPermissions.playlistReadPrivate,
                SpotExtraPermissions.playlistReadCollaborative,
            ],
        })
    }


    //#region
    /*
//For browser back, not used
@HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed'); 
  }
 */
    //this.location.go(this.router.url + "/" + urlAppend);

    /*       router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          // Perform actions
        }
      }); */

    //https://www.bennadel.com/blog/3533-using-router-events-to-detect-back-and-forward-browser-navigation-in-angular-7-0-4.htm

    // Subscribe to changes
    //The component should subscribe to route params changes and update its model/view when the application route is changed from ‘/detail/1’ to ‘/detail/2’

    // -1) kolla om comp förstörs om man routar till samma path fast med id där id är tillaggt, då bör den läggas till som  /:id/:id2
    // 2) push state på click
    // 3) spara komplext state i service med observerable
    // 4) custom back
    // 5) bara component strat, med child render
    //ngRx router
    // RouteReuseStrategy?
    //You can use subscriber route.params to listen the param's change.
    //#endregion
}
