

<ion-content id="main-content">

	<ion-button id="context-menu-trigger">Right-Click Me</ion-button>
	<ion-popover trigger="context-menu-trigger" triggerAction="context-menu">
	  <ng-template>
		<ion-content class="ion-padding">Hello World!</ion-content>
	  </ng-template>
	</ion-popover>
	

	<ion-button 
		long-press (press)="onLongPress()"
		(tap)="onTap()">
			LP BUT {{lpItr}}
		</ion-button>
	<ion-row style="height:10vh;border-style:solid;border-color:red;" class="lp-test">
		LP
	</ion-row>

	<ion-button
		size='large'
		(click)='setIncorrectSpotToken()'>
			Spot token err
	</ion-button>

	<ion-button
	size='large'
	(click)='showToastError()'>
		toast error
	</ion-button>

	<ion-button
	size='large'
	(click)='toggleLoop(1)'>
	ToogleLoop 1
	</ion-button>
	<ion-button
	size='large'
	(click)='toggleLoop(2)'>
	ToogleLoop 2
	</ion-button>

	<ion-button
		size='large'
		(click)='getSpotDevices()'>
			Get DEvices
	</ion-button>

	<ion-button
	size='large'
	(click)='presentModal()'>
		Modal
</ion-button>
	
	<ion-button
		size='large'
		(click)='getSpotTokenNVC()'>
			Get spot token
	</ion-button>

	<ion-button
	size='large'
	(click)='playTrackIdPs()'>
		playTrackId_Ps
	</ion-button>

	<ion-button
		size='large'
		(click)='spotDL1()'>
			spotDL1
    </ion-button>

	<div id="animTest"></div>
    <ion-input
		label = ""
        [(ngModel)]="sdkPlayerName">
    </ion-input>

    <ion-button
    size='large'
    (click)='constructWebPlayer()'>
    const. webP
    </ion-button>

    <ion-button
    size='large'
    (click)='activateElem()'>
    A Spot Elem
    </ion-button>

    <ion-button
    size='large'
    (click)='playId("3jG2DVbOteREs74udyVzJG")'>
    playIdA
    </ion-button>

    <ion-button
    size='large'
    (click)='playId("2lnzGkdtDj5mtlcOW2yRtG")'>
    playIdB
    </ion-button>

    <ion-button
    size='large'
    (click)='playIdAndResume("3jG2DVbOteREs74udyVzJG")'>
    play&ResumeA
    </ion-button>

    <ion-button
    size='large'
    (click)='playIdAndResume("2lnzGkdtDj5mtlcOW2yRtG")'>
    play&ResumeB
    </ion-button>

    <ion-button
    size='large'
    (click)='playIdTime()'>
    playIdTime
    </ion-button>

    <ion-button
    size='large'
    (click)='resume()'>
    resume
    </ion-button>

    <ion-button
    size='large'
    (click)='pause()'>
    pause
    </ion-button>

        <ion-button
        size='large'
        (click)='webApiTransferPlayBack()'>
        TransferPlayBack
    </ion-button>

    <ion-button
    size='large'
    (click)='anim()'>
    anim
    </ion-button>

    <ion-button
    size='large'
    (click)='printVisItr()'>
    VisItr
    </ion-button>

    <ion-button
    size='large'
    (click)='installPwa()'>
    installPWA
    </ion-button>

    <ion-button
        size='large'
        (click)='injectWebSdk()'>
        initSdkPlayer
    </ion-button>

    <ion-button
        size='large'
        (click)='playNVC()'>
        PlayNVC
    </ion-button>

    <ion-button
        size='large'
        (click)='pauseNVC()'>
        PauseNVC
    </ion-button>

    <ion-button
        size='large'
        (click)='playTimeNVC()'>
        playTimeNVC
    </ion-button>
    
    <ion-button
        size='large'
        (click)='resumeNVC()'>
        resumeNVC
    </ion-button>

    <ion-button
        (click)='loopTuneClick()'
        size='large'
    >
        LoopWeb
    </ion-button>

    <ion-button
        size='large'
        (click)='getVolume()'>
        getVol
    </ion-button>

	<ion-row>
		<div id="log" class="custom-log">
		</div>
	</ion-row>

    
    <ion-button
        size='large'
        (click)='decVol()'>
        decVol
    </ion-button>

    <ion-button
        size='large'
        (click)='incVol()'>
        incVol
    </ion-button>


    <!-- <ion-button
        size='large'
        (click)='requestReturnPushMsgFcm()'>
        request push msg
    </ion-button>
    
    <ion-button
        size='large'
        (click)='requestFcmPushPermission()'>
        Request push permission
    </ion-button> -->

	<ion-button
		size='large'
		(click)='getSpotTokenNVC()'>
			Get spot token
	</ion-button>

	<ion-button
	size='large'
	(click)='playTrackIdPs()'>
		playTrackId_Ps
	</ion-button>



    <!-- <ion-button
        size='large'
        (click)='setCookieBackend()'>
            setCookieBE
    </ion-button> -->

    <!-- <ion-button
        size='large'
        (click)='setCookieHttpPlugin()'>
            setCookiePlugin
    </ion-button> -->

    <!-- <ion-button
        size='large'
        (click)='toggleBackend()'>
        {{backendDomain}}
    </ion-button> -->

    <!--<ion-input
        placeholder="seekTimeAbs"
        type="number"
        max='100000'
        min='0'
        [(ngModel)] = 'seekTrackTime'
    ></ion-input> -->
    <!-- 
    <ion-button
        size='large'
        (click)='setVolumeClick()'>
    set Volume  
    </ion-button>
    <ion-input
        placeholder="setVolume"
        type="number"
        max='15'
        min='0'
        [(ngModel)] = 'volumeToSet'
    > 
    -->
    <!--    <ion-button
        size='large'
        (click)='incVolumeClick()'>
    inc Volume  
    </ion-button>

    <ion-button
        size='large'
        (click)='decVolumeClick()'>
    dec Volume 
    </ion-button>  

     <ion-button
        size='large'
        (click)='play()'>
    play
    </ion-button>
   

    
    <ion-button
        size='large'
        (click)='seekTrackAbsolute()'>
        Seek abs
    </ion-button>
     -->


    <ion-button
        size='large'
        (click)='webApiPlay()'>
        PlayWebApi
    </ion-button>
    


    <!-- <ion-button
        size='large'
        (click)='testInAppBrowser()'>
        Test in app browser1
    </ion-button> -->

    <!-- <ion-button
        size='large'
        (click)='testWindowHref()'>
        Test window.location
    </ion-button> -->

    <!-- <ion-button
        size='large'
        (click)='testSlugTrigger()'>
        slugtrigger
    </ion-button> -->

    <!-- <ion-button
        size='large'
        (click)='getCookies()'>
        getCookies
    </ion-button> -->

    <!--  <ion-button
        size='large'
        (click)='onlineCheckN()'>
        onlineCheckN
    </ion-button> -->

    <!-- <ion-button
        size='large'
        (click)='onlineCheckWeb()'>
        onlineCheckW
    </ion-button> -->

    <ion-button
        size='large'
        (click)='onlineCheckMyHttp()'>
        onlineCheckM
    </ion-button>
    
    <!-- <ion-button
        size='large'
        (click)='testPostM()'>
        testPostM
    </ion-button> -->

    <!-- <ion-button
        size='large'
        (click)='clearAllCookiesN()'>
        clearAllCookiesN
    </ion-button> -->
    
    <ion-button
        size='large'
        (click)='getDeviceId()'>
        GetPSdeviceID
    </ion-button>

	<ion-row 
		*ngFor="let testElem of ngIfTestA"
		><ng-container *ngIf="testElem.show == true">
			{{testElem.value}}
		</ng-container>
	</ion-row>

    <p>deviceId:</p>   
    <p> {{deviceId}}</p>

    
</ion-content>
    <div style="height:10vh; width:100%">
        <mat-form-field appearance="fill">
            <mat-label>Standard form field</mat-label>
            <input matInput placeholder="Placeholder">
            <mat-hint>Hint</mat-hint>
          </mat-form-field>
    
    </div>

<app-selected-track 
    id='selected-track-component'
   *ngIf='isTuneSelected'>
</app-selected-track>



<app-footer-bar>
        
</app-footer-bar>



<ng-template id="pop-over-selected-track">
	<ion-list class="pop-over-list">
		<ion-item
			class="pop-over-item"
			[button]="true"
		>
			Edit Tune
		</ion-item>
		<ion-item

		[button]="true"
		class="pop-over-item">
			Save Tune
		</ion-item>
		<ion-item

		class="pop-over-item"
		lines="none">
			Open Spotify
		</ion-item>
	</ion-list>
</ng-template>
