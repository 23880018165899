import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LyricsComponent } from './lyrics.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [LyricsComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [LyricsComponent]
})
export class LyricsModule { }
