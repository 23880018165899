import { Component, OnInit } from '@angular/core';
import { mGlobal } from '../mglobal';
import { ENV } from '../../../environments/environment';
import { GlobalService } from '../services/global.service';
@Component({
  selector: 'app-dev-router',
  templateUrl: './dev-router.page.html',
  styleUrls: ['./dev-router.page.scss'],
})
export class DevRouterPage implements OnInit {

  constructor(
	private gs : GlobalService
  ) { 
	
  }

  mGlobal = mGlobal    
  env = ENV
  ngOnInit() {
    
  }

  ionViewDidEnter(){
	this.gs.setPendingHistoryBackClick(false)
}

   

}
