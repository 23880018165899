export enum ListElementTypesEnum {
    slimSpotAlbum = 'SLIM_SPOT_ALBUM',
    slimSpotArtist = 'SLIM_SPOT_ARTIST',
    slimTuneTrack = 'SLIM_TUNE_TRACK', //
    tune = 'TUNE',
    slimSpotTrack = 'SLIM_SPOT_TRACK',
}

export enum FadeCurveTypesE {
    linear = 'LINEAR',
    exp = 'EXP',
    polynom2deg = 'POLYNOM2DEG',
}

export enum CreateTuneListErrors {
    noTuneListName = 'NO_TUNE_LIST_NAME',
    tuneListNameTooShort = 'TUNE_LIST_NAME_TOO_SHORT',
    tuneListNameTooLong = 'TUNE_LIST_NAME_TOO_LONG',
    tuneListNameNotUnique = 'TUNE_LIST_NAME_NOT_UNIQUE',
}

export enum BeErrorLogTypeEnum {
    // quick fix to use same interface for moongoose schema which is Mixed type but still needs a generic enum to init
    mongooseSchemaInitDummy = 'MONGOOSE_SCHEMA_INIT_DUMMY',
    lyricsNotFoundGenius = 'LYRICS_NOT_FOUND_ON_GENIUS',
    lyricsTooLongGenius = 'LYRICS_TOO_LONG_FROM_GENIUS',
    test = 'TEST_ERROR_ENUM',
}

export enum SpotExtraPermissions {
    //userReadPrivate = 'user-read-private',
    userLibraryRead = 'user-library-read',
    playlistReadPrivate = 'playlist-read-private',
    playlistReadCollaborative = 'playlist-read-collaborative',
}

// for .js reasons
export const SpotExtraPermissionsJs = Object.freeze({
    userLibraryRead: 'user-library-read', // stared?
    playlistReadPrivate: 'playlist-read-private',
    playlistReadCollaborative: 'playlist-read-collaborative',
})

// other temporary cookie fields exsits while redirecting
export enum CookieFields {
    displayName = 'displayName',
    spotId = 'spotId',
    spotEmail = 'spotEmail',
    spotName = 'spotName',
    userId = 'userId',
    isSignedUp = 'isSignedUp',
    hasLoggedIn = 'hasLoggedIn',
    spotTokens = 'spotTokens',
    spotExtraPermissions = 'spotExtraPermissions',
    spotExtraPermissionsQuery = 'spotExtraPermissionsQuery',
    sid = 'sid',
}

export enum NvcEvent {
    Ready = 'ready',
    AutoplayFailed = 'autoplay_failed',
    NotReady = 'not_ready',
    InitializationError = 'initialization_error',
    AuthenticationError = 'authentication_error',
    PlaybackError = 'playback_error',
    PlayerStateChanged = 'player_state_changed',
    AccountError = 'account_error',
}
