import { LongPressDirective } from './../../directives/long-press/long-press.directive';
import { ChangeDetectorRef, Component, ViewChild, Input, OnInit, EventEmitter, Output, HostBinding, ElementRef } from '@angular/core';
import { PlayerService } from '../../services/music/player.service';
import { ENV } from '../../../../environments/environment';
import { ITuneV3 } from '../../../../../backend/classes-enums-interfaces-types/interfaces/interfaces.shared';
import { Router } from '@angular/router';
import { MyServerService } from '../../services/my-server/my-server.service';
import { mGlobal } from '../../mglobal';
import { Gesture, GestureController, IonPopover, ModalController, PopoverController } from '@ionic/angular';
import { DevicePlatformE, ModalsE } from '../../classes-enums-interfaces-types/enums/enums.enum';
import { GlobalService } from '../../services/global.service';
import { AddTuneToListComponent } from '../add-tune-to-list/add-tune-to-list.component';
import { Tune } from '../../classes-enums-interfaces-types/classes/classes';
import { P } from '@angular/cdk/keycodes';
import { ErrorHandlerService } from '../../services/error-handler/error-handler.service';
import { TuneListPlayerService } from '../../services/tune-list-player/tune-list-player.service';


@Component({
    selector: 'app-selected-track',
    templateUrl: './selected-track.component.html',
    styleUrls: ['./selected-track.component.scss'],
})
export class SelectedTrackComponent implements OnInit {

	@ViewChild(IonPopover) ionPop : IonPopover
    @ViewChild('swipeArea', { read: ElementRef }) swipeArea: ElementRef;

    @Output('toggleLoop') toogleLoop = new EventEmitter<any>()
	@Output() addedTuneToListClick = new EventEmitter();
	@Input('saveTunePopOver') saveTunePopOver = true


    constructor(
        private ps: PlayerService,
        private changeDetector: ChangeDetectorRef,
		private router : Router,
		private mServer : MyServerService,
		private popCtrl : PopoverController,
		private gS : GlobalService,
		private modalCtrl: ModalController,
		private eh : ErrorHandlerService,
        private gestureCtrl: GestureController,
        private tuneListPlayer : TuneListPlayerService
        ) { }
        
    // Icons are extacted to circumvent entire library being compiled down in production build.
    customPlayIcon = '../../assets/play.svg'
    customPauseIcon = '../../assets/pause.svg'
	
	//spotFullLogoIcon = './assets/play.svg'
    toggleLoopIcon = this.customPlayIcon
    wasPlaying = false
    
    showLyrics: boolean = ENV.DEBUG
    debug = ENV.DEBUG
    lyrics = `I will go down with this ship\nAnd I won't put my hands up and surrender\nThere will be no white flag above my door`
    
    selectedTune : Tune //= mGlobal.debugTune

    selectedGuiElem = {
        trackName: 'Seeing Green (with Drake & Lil Wayne)',
        artistName: 'Nicki Minaj"',
        trackImg: "https://i.scdn.co/image/ab67616d00001e026d8b9f3e7337f6bff76ceff6",
        lyrics: null
    }

	longPressItr = 0
	spotId

    screenWidthPixels
    swipeLimitPx;


    ngOnInit() {
    
    }

    ngAfterViewInit(){
        // Prob. always is type/class tune here so doesnt make much sense, #opt
        console.log('selected track iniit')
        this.ps.selectedTune$.subscribe((selectedTune : Tune)=> {
            this.selectedTune = selectedTune
            console.log('st')
            console.log(selectedTune)
            if (selectedTune) {
                //if (selectedTune.type === ListElementTypesEnum.tune) {
                    this.selectedGuiElem.trackName = selectedTune.slimTuneTrack.trackName
                    this.selectedGuiElem.artistName = selectedTune.slimTuneTrack.artistName
                    this.selectedGuiElem.trackImg = selectedTune.slimTuneTrack.trackImg
                    this.selectedGuiElem.lyrics = selectedTune.lyrics
                    //this.changeDetector.detectChanges()
                //}
            } else if (ENV.DEBUG){
                this.selectedTune = mGlobal.debugTune
                this.selectedGuiElem.trackName = selectedTune.slimTuneTrack.trackName
                this.selectedGuiElem.artistName = selectedTune.slimTuneTrack.artistName
                this.selectedGuiElem.trackImg = selectedTune.slimTuneTrack.trackImg
                this.selectedGuiElem.lyrics = selectedTune.lyrics
                //this.changeDetector.detectChanges()
            }
        })

        this.ps.playerStateChanged$.subscribe(playerState => {
            const isPlaying = playerState?.isPlaying
            //console.log("received isPlaying: "+isPlaying)
            if (isPlaying && playerState?.trackName != 'Silent Track') {
                this.toggleLoopIcon = this.customPauseIcon
            } else {
                this.toggleLoopIcon = this.customPlayIcon
            }
            this.changeDetector.detectChanges()
            if(this.wasPlaying != isPlaying){
            }
            this.wasPlaying = isPlaying
        })

        
        this.spotId = this.gS.getSpotId()
        console.log('after view init selected track')
        
        this.swipeLimitPx = window.innerWidth/ 5 


        this.setupGesture();
    } 

    setupGesture() {

        if(this?.swipeArea?.nativeElement){

            const gesture: Gesture = this.gestureCtrl.create({
                el: this.swipeArea.nativeElement,   // Element to detect gestures on
                gestureName: 'swipe',
                //onStart: (detail) => console.log('Gesture started', detail),
                //onMove: (detail) => console.log('Gesture moved', detail),
                onEnd: (detail) => this.handleSwipe(detail), // Handle gesture end
            });
        
            gesture.enable(true); // Enable the gesture detection

        }
      }

      // not very elegant event infromation flow
      handleSwipe(detail: any) {
        const deltaX = detail.deltaX;
        console.log('deltaX',deltaX)
        if (deltaX > this.swipeLimitPx) {
          console.log('Swiped right!');
          this.tuneListPlayer.prevTune()
        } else if (deltaX < -this.swipeLimitPx) {
          console.log('Swiped left!');
          this.tuneListPlayer.nextTune()
        }
      }

	initVariables(){
		
	}

	onLongPress(){
		console.log('longpress trig')

		if(this.gS.getDevicePlatform() == DevicePlatformE.ios){
			this.ionPop.present()
		}
	}

	onTap(){

		this.ps.pauseTrack()

		this.mServer.getTrackHasMultipleTunes(this.selectedTune.slimTuneTrack.trackId).subscribe( (hasMultipleTunes : boolean) => {
			
			let redirectRoute
			if(hasMultipleTunes){
				redirectRoute = '/track'	
			} else {
				redirectRoute = '/edit-tune'
			}

			this.router.navigateByUrl(redirectRoute,{ state: {slimTuneTrack : this.selectedTune.slimTuneTrack}})

		}, e => {
			console.log('error getting track has multiple tunes',e)	
			this.eh.logSentryError(e)
		})

	}

   

    toggleLoopClick(event) {
		//this.ionPop.present()
		event.stopPropagation();
		this.longPressItr+=1
		this.ps.loopTuneRequest(this.selectedTune)
        //this.toogleLoop.next(this.selectedTune)
		//console.log('normal click')
    }

	popSaveClick(){
		this.mServer.saveTuneToUserLib(this.selectedTune._id)
	}

	popEditClick(){
		this.router.navigateByUrl('/edit-tune',{ state: {tune : this.selectedTune}})
	}

	popSpotClick(){
		window.open(`https://open.spotify.com/track/${this.selectedTune.slimTuneTrack.trackId}`,'_blank')	
	}

	async popAddToListClick(){
		const modal = await this.modalCtrl.create({
			component: AddTuneToListComponent,
			breakpoints:[0.7],
			initialBreakpoint:0.7,
			componentProps: { 'tune' : this.selectedTune },
			id: ModalsE.addToTuneList
		  });

		  modal.present();
	  
		  const { data, role } = await modal.onWillDismiss();
	  
		  if (role === 'added') {
			this.addedTuneToListClick.emit({tune :this.selectedTune, addTuneToListData:data})
		  }
		
	}

} 

