import { LyricsModule } from './../components/lyrics/lyrics.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FooterBarModule } from './../components/footer-bar/footer-bar.module';
import { MyLibraryPageRoutingModule } from './my-library-routing.module';
import { SelectedTrackModule } from '../components/selected-track/selected-track.module';
import { MyLibraryPage } from './my-library.page';
import { ListElementModule } from '../components/list-element/list-element.module';
import { TriggerComponent } from './trigger/trigger.component';
import { LoopProgressBarModule } from '../components/loop-progress-bar/loop-progress-bar.module';
import { DirectivesModule } from '../directives/directives.module';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { SharedModule } from '../shared/shared.module';
import {CdkAccordionModule} from '@angular/cdk/accordion';
@NgModule({
  imports: [

    FormsModule,
	SharedModule,
    MyLibraryPageRoutingModule,
    SelectedTrackModule,
    ListElementModule,
    FooterBarModule,
    LoopProgressBarModule,
	LyricsModule,
	DirectivesModule,
	CdkAccordionModule
	
  ],
  declarations: [
        MyLibraryPage,
        TriggerComponent
    ]
})
export class MyLibraryPageModule {}
